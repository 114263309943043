package org.jjoy.ltd.presentation.screen.user_form

import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.domain.use_cases.form.FormUseCases
import org.jjoy.ltd.domain.use_cases.form.UpdateFromParams
import org.jjoy.ltd.domain.use_cases.form.UploadFileParam
import org.jjoy.ltd.presentation.screen.user_form.FinalEvents.*
import org.jjoy.ltd.presentation.screen.user_form.FormRace.*
import org.jjoy.ltd.presentation.screen.user_form.FourthStringEvents.*
import org.jjoy.ltd.presentation.screen.user_form.UserFormSection.*
import org.jjoy.ltd.presentation.screen.user_form.error_handler.*
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.LocalStorageConst
import org.jjoy.ltd.core.util.decode
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.util.extension.getDate
import org.jjoy.ltd.core.util.extension.isDigit
import org.kodein.di.DI
import org.kodein.di.instance

class UserFormViewModel(di: DI = useCasesModule) :
    BaseViewModel<UserFormUIState, CommonUIEvent, UserFormActionEvent>() {

    private val formUseCases: FormUseCases by di.instance()
    // TODO: Change exceptions
    private var userId: String =
        window.localStorage.getItem(LocalStorageConst.USER_ID)
            ?: "" // ?: throw NullPointerException("UserId is null")

    init {}

    override fun initialState(): UserFormUIState {
        val form = localStorage.getItem("form")?.let { it.decode<UserFormDetailsDto>() }
        return UserFormUIState(
            isFormUpdating = false,
            isError = false,
            firstName = form?.firstName ?: "",
            middleName = form?.middleName ?: "",
            lastName = form?.lastName ?: "",
            address = form?.address ?: "",
            postcode = form?.postcode ?: "",
            telephone = form?.telephone ?: "",
            dateOfBirth = form?.dateOfBirth ?: "",
            nationalInsuranceNumber = form?.nationalInsuranceNumber ?: "",
            ukWorkDocument = listOf(),
            isFileUploading = false,
            preferredJobType = JobType.FULL_TIME,
            errors = FormErrorField(),
            formSection = FIRST,
            qualities = form?.qualities ?: "",
            roles = form?.roles ?: "",
            relocate = form?.relocate ?: "",
            ukDrivingLicence = form?.ukDrivingLicence ?: "",
            mopedScooter = form?.mopedScooter ?: "",
            cbt = form?.cbt ?: "",
            gender = form?.gender ?: "",
            relationshipStatus = form?.relationshipStatus ?: "",
            ageGroup = form?.ageGroup ?: "",
            ethinic = form?.ethinic ?: "",
            race = form?.race ?: "",
            white = "",
            black = "",
            asian = "",
            mixed = "",
            other = "",
            disability = form?.disability ?: "",
            disabilityDesc = form?.disabilityDesc ?: "",
            sexualOri = form?.sexualOri ?: "",
            religion = form?.religion ?: "",
            criminalConv = form?.criminalConv ?: "",
            signature = form?.signature ?: "",
            p2cName = form?.p2cName ?: "",
            p2cRelation = form?.p2cRelation ?: "",
            p2cTele = form?.p2cTele ?: "",
            p2cAddress = form?.p2cAddress ?: "",
            p2cEmail = form?.p2cEmail ?: "",
            p2cPersonName = form?.p2cPersonName ?: "",
            arrangements = listOf()
        )
    }

    override fun onActionEvent(action: UserFormActionEvent) {
        when (action) {
            is UserFormActionEvent.AddressChanged -> update { copy(address = action.v) }
            is UserFormActionEvent.DobChanged -> {
                if (
                    action.v.isDigit() ||
                        action.v.contains("/") && !action.v.contains("//") && action.v.length <= 10
                ) {
                    update { copy(dateOfBirth = action.v) }
                }
            }
            is UserFormActionEvent.FirstNameChanged -> update { copy(firstName = action.v) }
            is UserFormActionEvent.LastNameChanged -> update { copy(lastName = action.v) }
            is UserFormActionEvent.MiddleNameChanged -> update { copy(middleName = action.v) }
            is UserFormActionEvent.NationalInsuranceNumChanged ->
                update { copy(nationalInsuranceNumber = action.v) }
            is UserFormActionEvent.PostCodeChanged -> update { copy(postcode = action.v) }
            is UserFormActionEvent.TelePhoneChanged -> update { copy(telephone = action.v) }
            is UserFormActionEvent.UkWorkDocChanged -> update { copy(ukWorkDocument = action.v) }
            is UserFormActionEvent.PreferredJobType -> update { copy(preferredJobType = action.v) }
            is UserFormActionEvent.UploadFile -> uploadFile(action)
            is UserFormActionEvent.SectionChange -> nextSection(action)
            is UserFormActionEvent.SectionGoBack ->
                update { copy(formSection = formSection.order.toUserFormEnumPrev(1) ?: FIRST) }
            is UserFormActionEvent.AgeGroupChanged -> update { copy(ageGroup = action.v) }
            is UserFormActionEvent.CBTCertChanged -> update { copy(cbt = action.v) }
            is UserFormActionEvent.DrivingLicenceChanged ->
                update { copy(ukDrivingLicence = action.v) }
            is UserFormActionEvent.GenderChanged -> update { copy(gender = action.v) }
            is UserFormActionEvent.MopedScooterChanged -> update { copy(mopedScooter = action.v) }
            is UserFormActionEvent.PrefrRoleChanged -> update { copy(roles = action.v) }
            is UserFormActionEvent.QualitiesChanged -> update { copy(qualities = action.v) }
            is UserFormActionEvent.RaceChanged -> handleRace(action.v, action.e)
            is UserFormActionEvent.RelationshipStatusChanged ->
                update { copy(relationshipStatus = action.v) }
            is UserFormActionEvent.WorkReLocationChanged -> update { copy(relocate = action.v) }
            is UserFormActionEvent.WorkingArrangementChanged ->
                update { copy(arrangements = action.v) }
            is UserFormActionEvent.FinalDataChanged -> handlerFinal(action.e, action.v)
            is UserFormActionEvent.FourthDataChanged -> handlerFourth(action.e, action.v)
            UserFormActionEvent.SubmitForm -> submitForm()
        }
    }

    private fun submitForm() {

        val form =
            UserFormDetailsDto(
                parentUserId = userId,
                firstName = currentState.firstName,
                middleName = currentState.middleName,
                lastName = currentState.lastName,
                address = currentState.address,
                postcode = currentState.postcode,
                telephone = currentState.telephone,
                dateOfBirth = currentState.dateOfBirth,
                nationalInsuranceNumber = currentState.nationalInsuranceNumber,
                ukWorkDocument = currentState.ukWorkDocument.map { it.displayName },
                preferredJobType = currentState.preferredJobType.getName(),
                arrangements = currentState.arrangements.map { it.displayName },
                roles = currentState.roles,
                qualities = currentState.qualities,
                relocate = currentState.relocate,
                ukDrivingLicence = currentState.ukDrivingLicence,
                mopedScooter = currentState.mopedScooter,
                cbt = currentState.cbt,
                gender = currentState.gender,
                relationshipStatus = currentState.relationshipStatus,
                ageGroup = currentState.ageGroup,
                ethinic =
                    if (currentState.ethinic.isNullOrEmpty() && currentState.white.isNotEmpty())
                        currentState.white
                    else currentState.ethinic,
                race =
                    with(currentState) {
                        when {
                            race.isNotBlank() -> race
                            black.isNotBlank() -> black
                            asian.isNotBlank() -> asian
                            mixed.isNotBlank() -> mixed
                            other.isNotBlank() -> other
                            else -> race
                        }
                    },
                disability = currentState.disability,
                disabilityDesc = currentState.disabilityDesc,
                sexualOri = currentState.sexualOri,
                religion = currentState.religion,
                criminalConv = currentState.criminalConv,
                signature = currentState.signature,
                p2cName = currentState.p2cName,
                p2cRelation = currentState.p2cRelation,
                p2cTele = currentState.p2cTele,
                p2cAddress = currentState.p2cAddress,
                p2cEmail = currentState.p2cEmail,
                p2cPersonName = currentState.p2cPersonName,
                date = getDate()
            )

        localStorage.setItem("form", form.encodeToString())
        launch {
            formUseCases.updateUserForm(
                input = UpdateFromParams(form = form, id = userId),
                onLoading = {
                    sendUIEvent(CommonUIEvent.Loading)
                    update { copy(isFormUpdating = true, isError = false) }
                },
                onFailure = {
                    update { copy(isFormUpdating = false, isError = true) }
                    sendUIEvent(CommonUIEvent.Error("Could not update form data"))
                },
                onSuccess = {
                    update { copy(isFormUpdating = false) }
                    sendUIEvent(CommonUIEvent.NavigateNext)
                }
            )
        }
    }

    private fun uploadFile(action: UserFormActionEvent.UploadFile) {
        launch {
            delay(1300)
            formUseCases.uploadFile(
                input = UploadFileParam(file = action.file, path = action.path + userId),
                onLoading = {},
                onFailure = { console.log("onFailureVM::") },
                onSuccess = { console.log("onSuccessVM::${action.path}") }
            )
        }
    }

    private fun nextSection(action: UserFormActionEvent.SectionChange) {
        if (currentState.errors.hasErrors()) {
            update { copy(errors = FormErrorField()) }
        }
        when (currentState.formSection) {
            FIRST -> {
                val errors = currentState.firstSectionError()
                if (errors.hasErrors()) {
                    update { copy(errors = errors) }
                    return
                } else {
                    update { copy(formSection = action.section) }
                }
                return
            }
            SECOND -> {
                val errors = currentState.secondSectionError()
                if (errors.hasErrors2()) {
                    update { copy(errors = errors) }
                    return
                } else {
                    update { copy(formSection = action.section) }
                }
                return
            }
            THIRD -> {
                val errors = currentState.thirdSectionError()
                if (errors.hasErrors3()) {
                    update { copy(errors = errors) }
                    return
                } else {
                    update { copy(formSection = action.section) }
                }
                return
            }
            FOURTH -> {
                val errors = currentState.fourthSectionError()
                if (errors.hasErrors4()) {
                    update { copy(errors = errors) }
                    return
                } else {
                    update { copy(formSection = action.section) }
                }
                return
            }
            FINAL -> {
                val errors = currentState.fifthSectionError()
                if (errors.hasErrors5()) {
                    update { copy(errors = errors) }
                    return
                } else {
                    // update { copy(formSection = action.section) }
                    submitForm()
                }
                return
            }
        }
    }

    private fun handlerFourth(e: FourthStringEvents, v: String) {
        when (e) {
            DISABILITY -> update { copy(disability = v) }
            DISABILITY_DESC -> update { copy(disabilityDesc = v) }
            SEXUAL_ORI -> update { copy(sexualOri = v) }
            RELIGION -> update { copy(religion = v) }
            CRIMINAL_CONV -> update { copy(criminalConv = v) }
            SIGNATURE -> update { copy(signature = v) }
        }
    }
    private fun handlerFinal(e: FinalEvents, v: String) {
        when (e) {
            P2cName -> update { copy(p2cName = v) }
            RelationToU -> update { copy(p2cRelation = v) }
            Tele -> update { copy(p2cTele = v) }
            Address -> update { copy(p2cAddress = v) }
            Email -> update { copy(p2cEmail = v) }
            DECLR_NAME -> update { copy(p2cPersonName = v) }
        }
    }

    private fun handleRace(race: FormRace, v: String) {
        when (race) {
            WHITE -> update { copy(ethinic = v, white = v) }
            MIXED -> update { copy(mixed = v) }
            ASIAN -> update { copy(asian = v) }
            BLACK -> update { copy(black = v) }
            OTHER -> update { copy(other = v) }
        }
        when (race) {
            WHITE -> {}
            else -> update { copy(race = v) }
        }
    }
}
