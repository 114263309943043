package org.jjoy.ltd.pages.jjoy.admin

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.window
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.presentation.admin.home.AdminHomeScreen
import org.jjoy.ltd.presentation.admin.home.AdminHomeViewModel
import org.jjoy.ltd.presentation.components.wrapper.MainWrapper
import org.jjoy.ltd.presentation.composables.LoaderView
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.extension.disableNavigateBack

@Page("/admin/dash")
@Composable
fun AdminHomePage(ctx: PageContext = rememberPageContext()) {

    val viewModel = remember { AdminHomeViewModel() }
    val state by viewModel.collectState()
    val uiEvent by viewModel.collectEvent()

    MainWrapper(contentAlignment = Alignment.TopEnd) {
        if (!state.isPageLoading && state.validated) {
            AdminHomeScreen(ctx = ctx, onEvent = viewModel::onActionEvent)
        }
        LoaderView(state.isPageLoading)
    }

    uiEvent?.let {
        when (it) {
            is CommonUIEvent.Error -> {
                ctx.router.navigateTo("/simple/admin/dashboard/login")
            }
            CommonUIEvent.Loading -> {}
            CommonUIEvent.NavigateNext -> {}
            CommonUIEvent.NavigateUp -> {}
        }
    }

    // Disable back navigation
    window.disableNavigateBack()

    /*// Save page state when navigating away
    window.addEventListener("beforeunload", {
        val pageState = state.encodeToString() // Replace with your page state data
        window.sessionStorage.setItem("pageState", pageState)
    })*/

}
