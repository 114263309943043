package org.jjoy.ltd.core.domain.model

enum class UKWorkDocument(val displayName: String) {
    BRITISH_PASSPORT("British Passport or UK birth certificate"),
    REGISTRATION_OR_NATURALISATION("Certificate of registration / naturalisation as a British Citizen"),
    PASSPORT_LIVE_WORK_UK("Passport showing right to live & work in the UK"),
    EEC_PASSPORT_WORK_REGISTRATION("EEC passport or identity card plus work registration letter"),
    NON_EUROPEAN_PASSPORT_WORK_VISA("Non-European passport with relevant work visa"),
    OTHER_DOCUMENT("Other document that supports your eligibility to work in the UK");



    companion object {
        fun getList(): List<UKWorkDocument> {
            return enumValues<UKWorkDocument>().toList()
        }
    }


}

fun UKWorkDocument?.getName(): String? {
    if (this == null) return null
    return displayName
}
