package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css

@Composable
fun TextInputWrap(
    label: String,
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    isError: Boolean = false,
    placeHolder: String? = null,
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        Text(
            text = label,
            modifier =
                Modifier.fontSize(dimension.smallMediumText)
                    .fontWeight(450)
                    .color(color.onBackground.css(60))
                    .margin(bottom = dimension.extraSmallText)
        )
        TextInputField(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = stateVsEvent,
            isError = isError,
            placeHolder = placeHolder
        )
    }
}

@Composable
fun NumberInputWrap(
    label: String,
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    isError: Boolean = false,
    placeHolder: String? = null,
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        Text(
            text = label,
            modifier =
                Modifier.fontSize(dimension.textFieldLabelText)
                    .fontWeight(600)
                    .color(color.onBackground.css(60))
                    .margin(bottom = dimension.smallPadding)
        )
        NumberInputField(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = stateVsEvent,
            isError = isError,
            placeHolder = placeHolder
        )
    }
}

@Composable
fun PasswordInputWrap(
    label: String,
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    isError: Boolean = false,
    placeHolder: String? = null,
    isVisible: Boolean = true,
    visibilityToggle: Boolean
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        Text(
            text = label,
            modifier =
                Modifier.fontSize(dimension.textFieldLabelText * 0.75)
                    .fontWeight(550)
                    .color(color.onBackground.css(60))
                    .margin(bottom = 0.5.cssRem)
        )
        PasswordInputField(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = stateVsEvent,
            isError = isError,
            isVisible = isVisible,
            visibilityToggle = visibilityToggle,
            placeHolder = placeHolder
        )
    }
}
