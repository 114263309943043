package org.jjoy.ltd.presentation.composables

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun GoBackButton(
    text: String,
    modifier: Modifier = Modifier,
    onBack: Callback,
) {


    Row(
        modifier = modifier.then(GoBackStyle.toModifier()).onClick { onBack() },
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaIcon(
            name = "arrow-left-long",
            modifier = Modifier.margin(right = dimension.extraSmallPadding)
                .color(color.primary.css),
            style = IconCategory.SOLID,
            size = IconSize.X1
        )
        SpanText(
            text = text,
            modifier = Modifier.fontSize(dimension.smallMediumText)
                .fontWeight(600)
                .color(color.primary.css)
        )
    }
}

val GoBackStyle by ComponentStyle {
    base {
        Modifier.padding(dimension.smallPadding)
            .scale(1)
            .translateX(0.percent)
            .color(color.primary.css)
            .animate(prop = listOf("scale","color","translate"))
    }

    hover {
        Modifier
            .color(color.primary.rgb.darkened())
            .scale(1.08)
            .translateX((-2).percent)

    }
}