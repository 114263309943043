package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.buttonShadow
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb


@Composable
fun BlurShadowButton(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = BlurButtonComp,
    text: String,
    fontSize: CSSSizeValue<CSSUnit.rem> = dimension.normalButtonText,
    onClick: () -> Unit,
) {

    Button(
        onClick = { onClick() },
        modifier = modifier.then(BlurButtonStyle.toModifier()),
        variant = variant,
    ) {
        SpanText(
            text = text,
            modifier = Modifier
                .fontWeight(FontWeight.Bold)
                .fontSize(fontSize)
        )
    }
}


private val modifier =
    Modifier.backgroundColor(color.primary.rgb)
        .color(color.onPrimary.rgb)
        .padding(dimension.normalPadding)
        .borderRadius(dimension.mediumRoundness)
        .boxShadow(
            color = color.primary.css(30),
            blurRadius = 39.px,
            spreadRadius = 1.px,
            offsetX = 0.px,
            offsetY = 0.px,
        )

val BlurButtonStyle by ComponentStyle { base { modifier } }

val BlurButtonComp by
BlurButtonStyle.addVariant {
    base {
        modifier.animate(prop = listOf("translate","scale","box-shadow"))

    }
    hover { modifier
        .scale(1.02)
        .translateY((-1.2).percent)
        .buttonShadow()
    }
}