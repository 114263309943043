package org.jjoy.ltd.presentation.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AlignSelf
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.extension.css

val FooterStyle by
    ComponentStyle.base {
        Modifier.backgroundColor(color.bgGray.css)
            .margin(top = dimension.mediumPadding)
            .alignSelf(AlignSelf.Center)
    }

@Composable
fun Footer(modifier: Modifier = Modifier) {
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()

    MobileFooter(
        modifier = Modifier.displayUntil(Breakpoint.SM)
    )

    Row(
        modifier = FooterStyle.toModifier().then(modifier.fillMaxWidth()).displayIfAtLeast(Breakpoint.SM),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier
                    .width(80.vw)
                    .padding(
                        top = dimension.mediumPadding * 1.5,
                        bottom = dimension.mediumPadding * 1.5,
                    ),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {
                LogoView()

                if (breakpoint <= Breakpoint.SM) {
                    Spacer()
                }


                Column(
                    modifier = Modifier.margin(left = dimension.mediumPadding),
                ) {
                    Text(
                        text = "Quick Links",
                        modifier =
                            Modifier.fontWeight(FontWeight.SemiBold)
                                .fontSize(dimension.normalText * 0.7)
                                .color(color.onBackground.css(80))
                                .margin(bottom = dimension.smallPadding)
                    )
                    QuickLinks.values().forEach {
                        QuickLinksText(
                            text = it.title,
                            modifier =
                                Modifier.margin(
                                    top = dimension.smallPadding,
                                    bottom = dimension.smallPadding
                                ),
                            onClick = { ctx.router.navigateTo(it.url) }
                        )
                    }
                }

                Column(
                    modifier = Modifier.margin(left = dimension.mediumPadding),
                ) {
                    Text(
                        text = "Contact Info",
                        modifier =
                        Modifier.fontWeight(FontWeight.SemiBold)
                            .fontSize(dimension.normalText * 0.7)
                            .color(color.onBackground.css(80))
                            .margin(bottom = dimension.smallPadding)
                    )
                    Row {

                        Text(
                            text = Constants.PHONE,
                            modifier =
                            Modifier.fontWeight(FontWeight.Medium)
                                .fontSize(dimension.smallText)
                                .color(color.onBackground.css(80))
                        )
                    }
                    Row {

                        Text(
                            text = Constants.ADDRESS,
                            modifier =
                            Modifier.fontWeight(FontWeight.Medium)
                                .flexDirection(FlexDirection.Column)
                                .textAlign(TextAlign.Left)
                                .fontSize(dimension.smallText)
                                .color(color.onBackground.css(80))
                        )

                    }
                    Text(
                        text = Constants.CONTACT_EMAIL,
                        modifier =
                        Modifier.fontWeight(FontWeight.Bold)
                            .flexDirection(FlexDirection.Column)
                            .textAlign(TextAlign.Left)
                            .fontSize(dimension.smallText)
                            .color(color.onBackground.css(80))
                    )
                }

                Spacer()
                if (breakpoint > Breakpoint.MD) {
                    CertificationView()
                }
            }

            if (breakpoint <= Breakpoint.MD) {
                CertificationView()
            }
        }
    }
}



@Composable
fun LogoView(modifier: Modifier = Modifier) {
    val ctx = rememberPageContext()
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.Start
    ) {
        Image(src = "/logo.png", desc = "", modifier = Modifier.size(dimension.brandLogo))
        Text(
            text = "Delivering recruitment solutions for businesses on the move",
            modifier =
                Modifier.width(dimension.brandLogo * 1.2)
                    .fontWeight(FontWeight.Normal)
                    .textAlign(TextAlign.Left)
                    .fontSize(dimension.smallText * 0.7)
                    .color(color.onBackground.css(80))
        )
        NormalButton(
            text = "Read More",
            modifier = Modifier
                .margin(top = dimension.smallPadding * 1.3, bottom = dimension.smallPadding)
                .displayIfAtLeast(Breakpoint.SM),
            onClick = {
                ctx.router.navigateTo("/aboutUs")
            }
        )

        Text(
            modifier = Modifier
                .textAlign(TextAlign.Left)
                .fontWeight(FontWeight.Bold)
                .displayIfAtLeast(Breakpoint.SM)
                .fontSize(dimension.smallText).color(color.primary.css),
            text = "Copyright ©2023 ${Constants.WEBSITE_NAME}. All rights reserved"
        )
    }
}

@Composable
fun CertificationView(
    breakpoint: Breakpoint = rememberBreakpoint(),
    modifier: Modifier = Modifier
) {
    val base = if (breakpoint <= Breakpoint.MD) dimension.brandLogo * 0.7 else dimension.brandLogo

    Row(
        modifier = modifier,
    ) {
        Column {
            Image(
                src = CertificationItem.JOBS_AWARE.logo,
                desc = CertificationItem.JOBS_AWARE.title,
                modifier =
                    Modifier.onClick { window.open(CertificationItem.JOBS_AWARE.url, "_blank") }
                        .width(base * 1.5)
            )
            Image(
                src = CertificationItem.ALLIANCE.logo,
                desc = CertificationItem.ALLIANCE.title,
                modifier =
                    Modifier.onClick { window.open(CertificationItem.ALLIANCE.url, "_blank") }
                        .width(base * 1.6)
                        .height(base * 1.05)
            )
        }
        if (breakpoint <= Breakpoint.MD) {
            Spacer()
        }

        Column {
            Image(
                src = CertificationItem.BPA.logo,
                desc = CertificationItem.BPA.title,
                modifier =
                    Modifier.onClick { window.open(CertificationItem.BPA.url, "_blank") }
                        .width(base * 1.5)
            )
            Image(
                src = CertificationItem.SAFER_JOBS.logo,
                desc = CertificationItem.SAFER_JOBS.title,
                modifier =
                    Modifier.onClick { window.open(CertificationItem.SAFER_JOBS.url, "_blank") }
                        .width(base * 1.5)
                        .height(base * 0.85)
            )
        }
    }
    SimpleGrid(numColumns(2)) {}
}

@Composable
fun QuickLinksText(text: String, modifier: Modifier = Modifier, onClick: () -> Unit = {}) {
    Row(modifier = Modifier.onClick { onClick() }) {
        Text(
            text = "•  ",
            modifier =
                modifier
                    .fontWeight(FontWeight.Bold)
                    .fontSize(dimension.smallText)
                    .color(color.onBackground.css(70))
        )
        Text(
            text = text,
            modifier =
                modifier
                    .textAlign(TextAlign.Left)
                    .fontWeight(FontWeight.Medium)
                    .fontSize(dimension.smallText)
                    .color(color.onBackground.css(80))
        )
    }
}

enum class CertificationItem(val title: String, val logo: String, val url: String) {
    //TODO: Remove REC LOG //MUST DO
    /*REC(
        title = "Recruitment & Employment Confederation",
        logo = "/rec.webp",
        url =
            "https://www.rec.uk.com/jobseekers/member-directory?&q=&sector=All%20sectors&placementType=all&sortBy=A%20-%20Z&auditedOnly=false&page=1"
    ),*/
    JOBS_AWARE(
        title = "Jobs Aware",
        logo = "/jobs_aware.webp",
        url =
            "https://www.jobsaware.co.uk/"
    ),
    BPA(
        title = "British Parking Association",
        logo = "/bpa.jpg",
        url = "https://www.britishparking.co.uk/BPA-Members"
    ),
    ALLIANCE(
        title = " Alliance for Parking Data Standards (APDS)",
        logo = "/alliance.webp",
        url = "https://www.allianceforparkingdatastandards.org/"
    ),
    SAFER_JOBS(title = "SaferJobs", logo = "/saferjob.webp", url = "https://www.jobsaware.co.uk/"),
}

enum class QuickLinks(val title: String, val url: String) {
    TERMS(title = "Terms & Conditions", url = "/terms"),
    PRIVACY(title = "Privacy Policy", url = "/terms/privacy-policy"),
    CONTACT(title = "Contact Us", url = "/about/contactUs"),
    MODERN_SLAVERY(title = "Modern Slavery Statement", url = "/terms/modernSlavery"),
}
