package org.jjoy.ltd.domain.use_cases.form

import org.jjoy.ltd.core.base.ApiUseCase
import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.domain.repository.FormRepository

data class UpdateFromParams(
    val form : UserFormDetailsDto,
    val id: String
)

class UpdateUserForm(repository: FormRepository): ApiUseCase<UpdateFromParams, String>(
    { repository.updateForm(it!!.form,it.id)}
)