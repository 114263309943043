package org.jjoy.ltd.domain.use_cases.jobs

import org.jjoy.ltd.domain.repository.JobRepository

class JobsUseCases(
    repository: JobRepository
){
    val getJobs = GetJobs(repository)
    val getJobsListInfo = GetJobsListInfo(repository)
    val searchJobs = SearchJobs(repository)
    val getJob = GetJob(repository)
    val applyJob = ApplyJob(repository)
    val checkJob = CheckJob(repository)
}