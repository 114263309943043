package org.jjoy.ltd.presentation.screen.user_form.desktop

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import kotlinx.browser.document
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_form.FinalEvents
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.UserFormActionEvent
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.today
import org.w3c.dom.HTMLElement

@Composable
fun UserFormDesktopContentFinal(
    modifier: Modifier = Modifier,
    formInputs: List<Pair<Int, String>>,
    state: UserFormUIState,
    onActionEvent: (UserFormActionEvent) -> Unit
) {

    var focus by remember { mutableStateOf<Pair<Int, String>?>(formInputs[0]) }

    FormDesktopWrapper(modifier.gap(dimension.mediumPadding)) {
        FormColumn(modifier = Modifier.fillMaxWidth(), gap = dimension.smallPadding) {
            Text(
                modifier =
                    Modifier.fontSize(dimension.smallText * 1.1)
                        .fontWeight(550)
                        .textAlign(TextAlign.Left)
                        .margin(bottom = dimension.smallPadding),
                text = "Next Of KIN – Contact Information (in case of emergency)"
            )
            Row(
                modifier = Modifier.fillMaxWidth().gap(dimension.mediumPadding),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Name of person to contact",
                    id = "person_to_contact",
                    stateVsEvent =
                        StateVsEvent(state.p2cName) {
                            onActionEvent(
                                UserFormActionEvent.FinalDataChanged(FinalEvents.P2cName, it)
                            )
                        },
                    isMandatory = true,
                    error = state.errors.p2cName,
                    onIme = { focus = formInputs[1] }
                )

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Relationship to you",
                    id = "relation_to_you",
                    stateVsEvent =
                        StateVsEvent(state.p2cRelation) {
                            onActionEvent(
                                UserFormActionEvent.FinalDataChanged(FinalEvents.RelationToU, it)
                            )
                        },
                    isMandatory = true,
                    error = state.errors.p2cRelationToYou,
                    onIme = { focus = formInputs[2] }
                )
            }

            Row(
                modifier = Modifier.fillMaxWidth().gap(dimension.mediumPadding),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Telephone or Mobile",
                    id = "tele",
                    stateVsEvent =
                        StateVsEvent(state.p2cTele) {
                            onActionEvent(
                                UserFormActionEvent.FinalDataChanged(FinalEvents.Tele, it)
                            )
                        },
                    isMandatory = true,
                    isNumber = true,
                    error = state.errors.p2cTele,
                    onIme = { focus = formInputs[4] }
                )

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Email address",
                    id = "email_address",
                    stateVsEvent =
                        StateVsEvent(state.p2cEmail) {
                            onActionEvent(
                                UserFormActionEvent.FinalDataChanged(FinalEvents.Email, it)
                            )
                        },
                    isMandatory = true,
                    error = state.errors.p2cEmail,
                    onIme = { focus = formInputs[3] }
                )
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Home address",
                id = "home_address",
                stateVsEvent =
                    StateVsEvent(state.p2cAddress) {
                        onActionEvent(UserFormActionEvent.FinalDataChanged(FinalEvents.Address, it))
                    },
                isMandatory = true,
                error = state.errors.p2cHomeAddress,
                onIme = { focus = formInputs[5] }
            )

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "ENTER YOUR NAME to Declare the above information is correct",
                id = "person_name",
                stateVsEvent =
                    StateVsEvent(state.p2cPersonName) {
                        onActionEvent(
                            UserFormActionEvent.FinalDataChanged(FinalEvents.DECLR_NAME, it)
                        )
                    },
                isMandatory = true,
                error = state.errors.p2cPersonsName,
                onIme = { it.clearFocus() }
            )
            Text(
                modifier =
                    Modifier.fontSize(dimension.smallText * 1.04)
                        .fontWeight(600)
                        .textAlign(TextAlign.Left),
                text = "Date:${today()}"
            )
        }
    }

    LaunchedEffect(focus) {
        focus?.let { (document.getElementById(it.second) as HTMLElement).focus() }
    }
}
