package org.jjoy.ltd.presentation.components.text

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css

@Composable
fun Text(
    modifier: Modifier =
        BaseTextStyle.toModifier().textAlign(TextAlign.Center).color(color.primary.css),
    text: String,
    variant: ComponentVariant? = null
) {
    SpanText(text = text, modifier = modifier, variant = variant)

}
@Composable
fun ClickableText(
    modifier: Modifier = ClickableTextStyle.toModifier()
        .color(color.primary.css)
        .fontWeight(600)
        .fontSize(dimension.normalText),
    text: String,
    variant: ComponentVariant? = SimpleClickComp,
    onClick: Callback = {}
) {
    SpanText(text = text, modifier = modifier.onClick { onClick() }, variant = variant)

}


val ClickableTextStyle by ComponentStyle {
    base {
        Modifier
    }
}


val SimpleClickComp by ClickableTextStyle.addVariant {
    base {
        Modifier
            .scale(1)
            .animate(prop = listOf("scale","color","translate","text-shadow"))
    }
    hover {
        Modifier
            .translateY((-0.3).px)
            .scale(1.07)
            .textShadow(
                offsetX = 0.px,
                offsetY = 1.px,
                blurRadius = 1.cssRem,
                color = color.primary.css(85)
            )
    }
}