package org.jjoy.ltd.presentation.screen.user_form.mobile

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import kotlinx.browser.document
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.UKWorkDocument
import org.jjoy.ltd.core.domain.model.getName
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.presentation.components.input.FilePickerWithLabel
import org.jjoy.ltd.presentation.components.input.ListCheckBoxWithLabel
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.widgets.DropDownWithLabel
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.FormRow
import org.jjoy.ltd.presentation.screen.user_form.UserFormActionEvent
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.extension.smoothBlur
import org.w3c.dom.HTMLElement

@Composable
fun UserFormMobileContent(
    modifier: Modifier = Modifier,
    formInputs: List<Pair<Int,String>>,
    state: UserFormUIState,
    onActionEvent: TCallback<UserFormActionEvent>
) {

    var focus by remember { mutableStateOf(formInputs[0]) }

    Column(
        modifier =
        modifier
            .fillMaxWidth()
            .margin(dimension.normalPadding)
            .padding(dimension.mediumPadding)
            .bg()
            .smoothBlur()
            .rounded(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {

        // TODO: FIX FOCUS
        FormColumn {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "First Name",
                id = "first_name",
                stateVsEvent =
                StateVsEvent(value = state.firstName) {
                    onActionEvent(UserFormActionEvent.FirstNameChanged(it))
                },
                isMandatory = true,
                error = state.errors.firstName
            ) {
                focus = formInputs[1]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Middle Name",
                id = "middle_name",
                stateVsEvent =
                StateVsEvent(value = state.middleName) {
                    onActionEvent(UserFormActionEvent.MiddleNameChanged(it))
                },
                isMandatory = false,
                error = state.errors.middleName
            ) {
                focus = formInputs[2]
            }
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Last Name",
                id = "last_name",
                stateVsEvent =
                StateVsEvent(value = state.lastName) {
                    onActionEvent(UserFormActionEvent.LastNameChanged(it))
                },
                isMandatory = true,
                error = state.errors.lastName
            ) {
                focus = formInputs[3]
            }

        }

        FormColumn {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Address",
                id = "address",
                stateVsEvent =
                StateVsEvent(value = state.address) {
                    onActionEvent(UserFormActionEvent.AddressChanged(it))
                },
                isMandatory = true,
                error = state.errors.address
            ) {
                focus = formInputs[4]
            }
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Post Code",
                id = "post_code",
                stateVsEvent =
                StateVsEvent(value = state.postcode) {
                    onActionEvent(UserFormActionEvent.PostCodeChanged(it))
                },
                isMandatory = true,
                error = state.errors.postcode
            ) {
                focus = formInputs[5]
            }
        }

        FormRow {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "National Insurance No",
                id = "ni",
                stateVsEvent =
                StateVsEvent(value = state.postcode) {
                    onActionEvent(UserFormActionEvent.NationalInsuranceNumChanged(it))
                },
                isMandatory = true,
                error = state.errors.ni
            ) {
                focus = formInputs[6]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Telephone",
                id = "telephone",
                stateVsEvent =
                StateVsEvent(value = state.telephone) {
                    if(it.isDigit()){
                        onActionEvent(UserFormActionEvent.TelePhoneChanged(it))
                    }
                },
                isMandatory = false,
                error = state.errors.telephone
            ) {
                focus = formInputs[7]
            }

        }

        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Date Of Birth",
            id = "dob",
            stateVsEvent =
            StateVsEvent(value = state.dateOfBirth) {
                onActionEvent(UserFormActionEvent.DobChanged(it))
            },
            isMandatory = true,
            error = state.errors.dob,
            placeHolder = "DD/MM/YYYY"
        ) {
            it.clearFocus()
        }

        FormRow(
            horizontalArrangement = Arrangement.Start
        ) {
            Text(
                text =
                " Eligibility to work in the UK & Ireland: You must bring the original documentation if invited to an interview as evidence of your entitlement to work in this country. Failure to do so will invalidate your application.",
                modifier = Modifier.fontSize(dimension.smallText).fontWeight(FontWeight.Medium).padding(top = dimension.smallPadding)
            )
            // Please confirm which of the following you have:
        }

        FormColumn(margin = dimension.extraSmallPadding * 2, gap = dimension.normalPadding) {

            ListCheckBoxWithLabel(
                label = "Please confirm which of the following you have",
                list = UKWorkDocument.getList(),
                itemToName = { it?.getName() ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.UkWorkDocChanged(it))
                },
                error = state.errors.workDocument,
            )

            DropDownWithLabel(
                label = "What type of employment are you looking for?",
                selectedItem = state.preferredJobType,
                list = JobType.getList(),
                itemToName = { it?.getName() },
                onItemSelected = {
                    onActionEvent(UserFormActionEvent.PreferredJobType(it))
                },
                placeHolder = "Please Select One",
                error = state.errors.jobType,
            )
        }

        FormColumn(margin = dimension.extraSmallPadding * 2, gap = dimension.normalPadding) {

            FilePickerWithLabel(
                label = "Upload passport or visa",
                onPicked = {
                    onActionEvent(
                        UserFormActionEvent.UploadFile(path = "form/upload/passport/", file = it)
                    )
                }
            )

            FilePickerWithLabel(
                label = "Upload proof of NI",
                onPicked = {
                    onActionEvent(
                        UserFormActionEvent.UploadFile(path = "form/upload/ni/", file = it)
                    )
                }
            )

            FilePickerWithLabel(
                label = "Upload proof of address",
                onPicked = {
                    onActionEvent(
                        UserFormActionEvent.UploadFile(path = "form/upload/address/", file = it)
                    )
                }
            )

            FilePickerWithLabel(
                label = "Upload proof of second address",
                onPicked = {
                    onActionEvent(
                        UserFormActionEvent.UploadFile(path = "form/upload/secondAddress/", file = it)
                    )
                }
            )

            FilePickerWithLabel(
                label = "Upload your cv",
                onPicked = {
                    onActionEvent(
                        UserFormActionEvent.UploadFile(path = "form/upload/cv/", file = it)
                    )
                }
            )

        }


    }

    LaunchedEffect(focus) {
        (document.getElementById(focus.second) as HTMLElement).focus()
        println("Focus changed")
    }
}