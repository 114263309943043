package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.borderColor
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.*
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Input
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.TextFieldTextStyle
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun PasswordInputField(
    stateVsEvent: StateVsEvent,
    modifier: Modifier = Modifier,
    placeHolder: String? = null,
    isVisible: Boolean = true,
    visibilityToggle: Boolean = false,
    isError: Boolean
) {
    var visibility by remember(isVisible) { mutableStateOf(isVisible) }

    Box(
        modifier = modifier,
    ) {
        Input(
            type = if (visibility) InputType.Text else InputType.Password,
            attrs =
                listOf(TextInputFieldStyle, TextFieldTextStyle)
                    .toModifier()
                    .color(color.onBackground.css)
                    .then(modifier)
                    .toAttrs {
                        placeHolder?.let { placeholder(it) }
                        style {
                            if (isError) {
                                borderColor(color.error.rgb)
                            }
                        }
                        onInput { stateVsEvent.onValueChange(it.value) }
                    }
        )
        if (visibilityToggle) {
            FaIcon(
                name = if (visibility) "eye" else "eye-slash",
                modifier =
                    Modifier.color(color.primary.rgb)
                        .margin(right = dimension.smallPadding * 2)
                        .onClick { visibility = !visibility }
                        .align(Alignment.CenterEnd),
                size = IconSize.XL,
                style = IconCategory.SOLID
            )
        }
    }
}

val EmptyInputStyle by
    ComponentStyle.base() {
        Modifier.backgroundColor(Colors.Transparent)
            .outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent)
            .padding(dimension.normalPadding)
            .borderRadius(dimension.normalRoundness)
    }

val passwordBoxStyle by ComponentStyle {
    base {
        Modifier.padding(dimension.smallPadding)
            .backgroundColor(Colors.Transparent)
            .outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent)
            .borderRadius(dimension.normalRoundness)
            .border(
                width = dimension.outlineWidthSmall,
                style = LineStyle.Solid,
                color = color.onBackground.css(25)
            )
            .animate(prop = listOf("border", "border-color", "color"))
    }

    hover {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }

    focus {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }
}
