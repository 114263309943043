package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb


@Composable
fun OutlineButton(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = OutlineButtonComp,
    blur: Boolean = false,
    text: String,
    onClick: () -> Unit,
) {

    Button(
        onClick = { onClick() },
        modifier =
            modifier.then(OutlineButtonStyle.toModifier()).thenIf(blur) {
                Modifier.boxShadow(
                    color = color.primary.css(10),
                    blurRadius = 10.px,
                    spreadRadius = 1.px,
                    offsetX = 0.px,
                    offsetY = 1.px,
                )
            },
        variant = variant,
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            SpanText(
                text = text,
                modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(dimension.normalButtonText)
            )
        }
    }
}

private val outlineModifier =
    Modifier.backgroundColor(color.background.css)
        .color(color.primary.rgb)
        .border(1.px, LineStyle.Solid, color.primary.rgb)
        .padding(dimension.normalPadding)
        .borderRadius(10.px)

val OutlineButtonStyle by ComponentStyle { base { outlineModifier } }

val OutlineButtonComp by
OutlineButtonStyle.addVariant {
    base {
        Modifier.animate(prop = listOf("color","background-color"),dur = 800)
    }
    hover { Modifier
        .backgroundColor(color.primary.rgb)
        .color(color.onPrimary.rgb)
    }

}