package org.jjoy.ltd.presentation.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.util.extension.css

val widthAnimation by Keyframes {
    from { Modifier.width(8.percent) }
    to { Modifier.width(70.percent) }
}


@Composable
fun LinearProgressIndicator(
    stretch: Number = 20,
    modifier: Modifier = Modifier,
    backgroundColor: CSSColorValue = Colors.White,
    primaryColor: CSSColorValue = color.primary.css,
){

    Row(
        modifier = modifier
            .then(linearProgressStyle.toModifier())
            .padding(2.px)
            .backgroundColor(backgroundColor).fillMaxWidth(stretch.percent),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Box(
            modifier = linearProgressStyle.toModifier()
                .backgroundColor(primaryColor)
                .fillMaxHeight()
                .animation(
                    widthAnimation.toAnimation(
                        duration = 1.3.s,
                        timingFunction = AnimationTimingFunction.EaseInOut,
                        iterationCount = AnimationIterationCount.Infinite,
                        fillMode = AnimationFillMode.Both,
                        direction = AnimationDirection.AlternateReverse
                    ),

                )
        )
    }

}

val linearProgressStyle by ComponentStyle.base {
    Modifier
        .fillMaxWidth()
        .height(0.65.cssRem)
        .borderRadius(0.2.cssRem)
}