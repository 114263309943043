package org.jjoy.ltd.domain.use_cases.form

import org.jjoy.ltd.domain.repository.FormRepository

class FormUseCases(
    repository: FormRepository
) {

    val uploadFile = UploadFile(repository)
    val updateUserForm =  UpdateUserForm(repository)
    val updateBankDetails = UpdateBankDetails(repository)

}