package org.jjoy.ltd.domain.use_cases.user

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import org.jjoy.ltd.core.data.model.auth.AuthResponse
import org.jjoy.ltd.core.domain.model.user.UserDto
import org.jjoy.ltd.core.domain.model.user.UserResponse
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.extension.filterSpace
import org.jjoy.ltd.core.util.extension.formatEmail
import org.jjoy.ltd.data.repositoryImpl.LocalStorageRepositoryImpl
import org.jjoy.ltd.domain.repository.LocalStorageRepository
import org.jjoy.ltd.domain.repository.UserRepository
import org.jjoy.ltd.presentation.screen.user_login.LoginFormFieldError
import org.jjoy.ltd.presentation.screen.user_login.LoginUIState
import org.jjoy.ltd.presentation.screen.user_login.checkFieldError

class RegisterUser(private val repository: UserRepository) :
    LocalStorageRepository by LocalStorageRepositoryImpl() {
    suspend operator fun invoke(loginUIState: LoginUIState, onSuccess: TCallback<AuthResponse>) =
        flow {
                val check = loginUIState.checkFieldError()

                if (check.hasError()) {
                    emit(loginUIState.copy(error = check, errorMessage = check.msg, isError = true))
                    return@flow
                }
                emit(loginUIState.copy(isLoading = true))

                val userDto =
                    UserDto(
                        userName = loginUIState.userEmail.formatEmail(),
                        password = loginUIState.password.filterSpace(),
                        fullName = loginUIState.userFullName,
                        phoneNumber = loginUIState.userPhone
                    )

                repository.registerUser(userDto).collect { state ->
                    when (state) {
                        is ResourceState.Error -> {
                            state.errorData?.let {
                                emit(
                                    loginUIState.copy(
                                        isLoading = false,
                                        error =
                                            LoginFormFieldError(
                                                email = it.emailError,
                                                password = it.password,
                                                msg = it.error
                                            ),
                                        errorMessage = it.error,
                                        isError = true
                                    )
                                )
                            }
                            return@collect
                        }
                        is ResourceState.Loading -> emit(loginUIState.copy(isLoading = true))
                        is ResourceState.SerializationError -> {
                            emit(
                                loginUIState.copy(
                                    isLoading = false,
                                    error =
                                        LoginFormFieldError(
                                            email = true,
                                            password = true,
                                            msg = "Serialization Error"
                                        ),
                                    errorMessage = "Please contact support",
                                    isError = true
                                )
                            )
                            return@collect
                        }
                        is ResourceState.Success -> {
                            state.data.data?.let {
                                if (!saveLogin(it)) {
                                    emit(
                                        loginUIState.copy(
                                            isLoading = false,
                                            error =
                                                LoginFormFieldError(
                                                    email = true,
                                                    password = true,
                                                    msg = "Could not set session"
                                                ),
                                            errorMessage = "Could not set session",
                                            isError = true
                                        )
                                    )
                                    return@collect
                                }
                                emit(
                                    loginUIState.copy(
                                        isLoading = false,
                                        error =
                                            LoginFormFieldError(msg = "Successfully Registered"),
                                        errorMessage = "Successfully Registered",
                                        isError = false
                                    )
                                )
                                onSuccess(state.data)
                                return@collect
                            }
                                ?: run {
                                    if (saveLogin(UserResponse(userDto.id, userDto.fullName))) {
                                        emit(
                                            loginUIState.copy(
                                                isLoading = false,
                                                error =
                                                    LoginFormFieldError(
                                                        msg = "Successfully Registered"
                                                    ),
                                                errorMessage = "Successfully Registered",
                                                isError = false
                                            )
                                        )
                                    }
                                }

                            onSuccess(state.data)
                        }
                        is ResourceState.UnInitialized -> Unit
                        is ResourceState.UnAuthorized -> Unit
                    }
                }
                return@flow
            }
            .flowOn(Dispatchers.Default)
}
