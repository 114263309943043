package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.Width
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.ServiceCard
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.res.ResourceString

@Composable
fun Services() {

    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    ContentWrapper(
        modifier =
            Modifier.minWidth(Width.MinContent)
                .gap(dimension.largePadding)
                .padding(top = dimension.largePadding),
        contentId = Section.Service.id,
        columnAlignment = Alignment.TopCenter,
        contentVerticalArrangement = Arrangement.Top
    ) {
        Column(
            modifier = Modifier.fillMaxWidth().gap(dimension.mediumPadding),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            Column(
                modifier =
                    Modifier.fillMaxWidth()
                        .margin(left = dimension.largePadding, right = dimension.largePadding),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Top
            ) {
                Text(
                    text = ResourceString.servicesTitle,
                    modifier =
                        Modifier.color(color.primary.css)
                            .textAlign(TextAlign.Center)
                            .fontWeight(FontWeight.Black)
                            .fontSize(dimension.largeText)
                )

                Text(
                    text = ResourceString.servicesDesc,
                    modifier =
                        BaseTextStyle.toModifier()
                            .displayIfAtLeast(Breakpoint.MD)
                            .fillMaxWidth(60.percent)
                            .color(color.onBackground.css(80))
                            .fontSize(dimension.normalText * 0.7)
                            .textAlign(TextAlign.Center)
                            .fontWeight(FontWeight.Bold)
                )
            }

            SimpleGrid(
                modifier =
                    Modifier.gap(dimension.mediumPadding * 1.1)
                        .margin(top = dimension.mediumPadding, bottom = dimension.largePadding),
                numColumns = numColumns(base = 1, md = 2, lg = 3, xl = 3)
            ) {
                servicesList.forEachIndexed() { index, it ->
                    ServiceCard(
                        modifier = Modifier.padding(dimension.normalPadding),
                        item = it,
                        onClick = {
                            ctx.router.navigateTo("/service/$index/show")
                        }
                    )
                }
            }
        }
    }
}
