package org.jjoy.ltd.util.extension

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TransitionTimingFunction
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.boxShadow
import com.varabyte.kobweb.compose.ui.modifiers.transition
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension

fun Modifier.bg(color: CSSColorValue = Colors.White) = backgroundColor(color)
fun Modifier.rounded(value: Number = 1) = borderRadius(value.cssRem)
fun Modifier.buttonShadow(
    shadowColor: CSSColorValue = color.primary.css(35)
) = boxShadow(
    color = shadowColor,
    blurRadius = dimension.smallBlurRadius,
    spreadRadius = 1.px,
    offsetX = 0.px,
    offsetY = 2.px,
)

fun Modifier.smoothBlur() = boxShadow(
offsetY = 1.px,
offsetX = 1.px,
blurRadius = 0.90.cssRem,
spreadRadius = 0.5.cssRem,
color = color.primary.css(13)
)

fun Modifier.animate(
    prop: List<String>,
    dur: Number = 500,
    timingFunction: TransitionTimingFunction = TransitionTimingFunction.Ease,
) = transition(
    *CSSTransition.group(prop, dur.ms,timingFunction )
)

fun Modifier.animateColor() = transition(
    CSSTransition(property = "color,background-color",duration = 500.ms)
)
