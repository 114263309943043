package org.jjoy.ltd.presentation.screen.user_form.desktop

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.Gender
import org.jjoy.ltd.presentation.components.input.CheckBoxWithLabel
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.FormRace
import org.jjoy.ltd.presentation.screen.user_form.UserFormActionEvent
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState
import org.jjoy.ltd.presentation.screen.user_form.components.ListCheckBoxWithTextField
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Groups

@Composable
fun UserFormDesktopContentThird(
    modifier: Modifier = Modifier,
    state: UserFormUIState,
    onActionEvent: (UserFormActionEvent) -> Unit
) {

    FormDesktopWrapper(modifier.gap(dimension.mediumPadding)) {
        FormColumn(gap = dimension.mediumPadding) {
            Text(
                modifier =
                Modifier.fontSize(dimension.smallText * 1.1)
                    .fontWeight(500)
                    .textAlign(TextAlign.Left),
                text =
                "JJoy Recruitment wants to meet the aims and commitments set out in its equality policy. This includes not discriminating under the Equality Act 2010 and building an accurate picture of the make-up of the workforce in encouraging equality and diversity"
            )

            Text(
                modifier =
                Modifier.margin(top = dimension.mediumPadding)
                    .fontSize(dimension.smallText)
                    .textAlign(TextAlign.Left),
                text =
                "The organisation needs your help and co-operation to enable it to do this but filling in this form is voluntary."
            )
            Text(
                modifier =
                Modifier.margin(top = dimension.mediumPadding)
                    .fontSize(dimension.smallText)
                    .textAlign(TextAlign.Left),
                text =
                "The information you provide will stay confidential and be stored securely and limited to only some staff in the organisation’s Human Resources section."
            )
        }

        FormColumn(
            modifier = Modifier.fillMaxWidth().padding(top = dimension.mediumPadding),
            gap = dimension.mediumPadding
        ) {
            ListCheckBoxWithTextField(
                label = "Gender",
                list = Gender.getStringList(),
                item = state.gender,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.GenderChanged(it))
                },
                error = state.errors.gender,
                fieldLabel = "Others, specify here",
                stateVsEvent =
                StateVsEvent(state.gender) {
                    onActionEvent(UserFormActionEvent.GenderChanged(it))
                }
            )

            CheckBoxWithLabel(
                label = "Are you married or in a civil partnership?",
                list = listOf("Yes", "No", "Prefer not to say"),
                item = state.relationshipStatus,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RelationshipStatusChanged(it))
                },
                error = state.errors.relation,
            )
            val ageGroup =
                listOf(
                    "16-24",
                    "25-29",
                    "30-34",
                    "35-39",
                    "40-44",
                    "45-49",
                    "50-54",
                    "55-59",
                    "60-64",
                    "65+",
                    "Prefer not to say"
                )
            CheckBoxWithLabel(
                label = "Age group",
                list = ageGroup,
                item = state.ageGroup,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = { onActionEvent(UserFormActionEvent.AgeGroupChanged(it)) },
                error = state.errors.age,
            )
        }

        FormColumn(
            modifier = Modifier.fillMaxWidth().padding(top = dimension.mediumPadding),
            gap = dimension.smallPadding
        ) {
            // What is your ethnicity?
            // Ethnic origin is not about nationality, place of birth or citizenship. It is about
            // the group to which you perceive you belong. Please tick the appropriate box

            Text(
                modifier =
                Modifier.fontSize(dimension.smallText * 1.1)
                    .fontWeight(600)
                    .textAlign(TextAlign.Left),
                text = "What is your ethnicity?"
            )

            Text(
                modifier =
                Modifier
                    .fontSize(dimension.smallText)
                    .fontWeight(500)
                    .textAlign(TextAlign.Left),
                text =
                "Ethnic origin is not about nationality, place of birth or citizenship. It is about the group to which you perceive you belong. Please tick the appropriate box"
            )



            ListCheckBoxWithTextField(
                label = "White",
                list = Groups.ethinic,
                item = Groups.ethinic.firstOrNull { it == state.race },
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.WHITE, it))
                },
                error = state.errors.race,
                fieldLabel = "Any other white background, specify here",
                stateVsEvent =
                StateVsEvent(state.white) {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.WHITE, it))
                }
            )


            ListCheckBoxWithTextField(
                label = "Mixed/multiple ethnic group",
                list = Groups.mix,
                itemToName = { it ?: "ERROR" },
                item = Groups.mix.firstOrNull { it == state.race },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.MIXED, it))
                },
                error = state.errors.race,
                fieldLabel = "Any other mixed background, specify here",
                stateVsEvent =
                StateVsEvent(state.mixed) {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.MIXED, it))
                }
            )


            ListCheckBoxWithTextField(
                label = "Asian/Asian British",
                list = Groups.asian,
                item = Groups.asian.firstOrNull { it == state.race },
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.ASIAN, it))
                },
                error = state.errors.race,
                fieldLabel = "Any other asian background, specify here",
                stateVsEvent =
                StateVsEvent(state.asian) {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.ASIAN, it))
                }
            )

            ListCheckBoxWithTextField(
                label = "Black/ African/ Caribbean/ Black British",
                list = Groups.black,
                item = Groups.other.firstOrNull { it == state.race },
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.BLACK, it))
                },
                error = state.errors.race,
                fieldLabel = "Any other black/african background, specify here",
                stateVsEvent =
                StateVsEvent(state.black) {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.BLACK, it))
                }
            )


            ListCheckBoxWithTextField(
                label = "Other ethnic group",
                list = Groups.other,
                item = Groups.other.firstOrNull { it == state.race },
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.OTHER, it))
                },
                error = state.errors.race,
                fieldLabel = "Any other background, specify here",
                stateVsEvent =
                StateVsEvent(state.other) {
                    onActionEvent(UserFormActionEvent.RaceChanged(FormRace.OTHER, it))
                }
            )

            Text(text = "")

            /*CheckBoxWithLabel(
                label = "Prefer not to say",
                onChange = {
                    StateVsEvent(state.gender) {
                        onActionEvent(UserFormActionEvent.RaceChanged(FormRace.OTHER, it))
                    }
                }
            )*/
        }
    }
}
