package org.jjoy.ltd.core.base

import kotlinx.coroutines.*

abstract class CoroutineHandler {

    private val mainScope: CoroutineScope = MainScope()
    private val ioScope: CoroutineScope = CoroutineScope(Dispatchers.Default)

    fun onMain(
        errorHandler: ((Throwable) -> Unit)? = null,
        completionHandler: (() -> Unit)? = null,
        action: suspend CoroutineScope.() -> Unit,
    ): Job {
        val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
            errorHandler?.invoke(throwable)
        }

        return mainScope.launch(exceptionHandler) {
            try {
                action()
                completionHandler?.invoke()
            } catch (e: Exception) {
                errorHandler?.invoke(e)
            }
        }
    }

    fun onMainLaunch(
        errorHandler: ((Throwable) -> Unit)? = null,
        completionHandler: (() -> Unit)? = null,
        action: suspend CoroutineScope.() -> Unit,
    ): Job {
        val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
            errorHandler?.invoke(throwable)
        }

        return MainScope().launch(exceptionHandler) {
            try {
                action()
                completionHandler?.invoke()
            } catch (e: Exception) {
                errorHandler?.invoke(e)
            }
        }
    }

    fun onIO(
        errorHandler: ((Throwable) -> Unit)? = null,
        completionHandler: (() -> Unit)? = null,
        action: suspend CoroutineScope.() -> Unit,
    ): Job {
        val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
            errorHandler?.invoke(throwable)
        }

        return ioScope.launch(exceptionHandler) {
            try {
                action()
                completionHandler?.invoke()
            } catch (e: Exception) {
                errorHandler?.invoke(e)
            }
        }
    }

    fun onIoLaunch(
        errorHandler: ((Throwable) -> Unit)? = null,
        completionHandler: (() -> Unit)? = null,
        action: suspend CoroutineScope.() -> Unit,
    ): Job {
        val exceptionHandler = CoroutineExceptionHandler { _, throwable ->
            errorHandler?.invoke(throwable)
        }

        return CoroutineScope(Dispatchers.Default).launch(exceptionHandler) {
            try {
                action()
                completionHandler?.invoke()
            } catch (e: Exception) {
                errorHandler?.invoke(e)
            }
        }
    }



    fun cancelAll() {
        mainScope.cancel()
        ioScope.cancel()
    }

}