package org.jjoy.ltd.presentation.screen.user_form.mobile

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.padding
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.extension.smoothBlur


@Composable
fun FormMobileWrapper(
    modifier: Modifier,
    content: @Composable ColumnScope.() -> Unit
){
    Column(
        modifier =
        modifier
            .fillMaxWidth()
            .margin(dimension.normalPadding)
            .padding(dimension.mediumPadding)
            .bg()
            .smoothBlur()
            .rounded(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        content(this)
    }
}
