package org.jjoy.ltd.presentation.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.extension.css

// TODO: Footer Mobile
@Composable
fun MobileFooter(modifier: Modifier = Modifier) {

    val ctx = rememberPageContext()
    Row(
        modifier = FooterStyle.toModifier().then(modifier.fillMaxWidth()),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier.width(92.vw)
                    .gap(dimension.smallPadding)
                    .padding(
                        top = dimension.mediumPadding,
                        bottom = dimension.mediumPadding,
                    ),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {
                LogoView()
                Spacer()
                Column(
                    modifier = Modifier.margin(left = dimension.mediumPadding),
                ) {
                    Text(
                        text = "Quick Links",
                        modifier =
                        Modifier.fontWeight(FontWeight.SemiBold)
                            .fontSize(dimension.normalText * 0.7)
                            .color(color.onBackground.css(80))
                            .margin(bottom = dimension.smallPadding)
                    )
                    QuickLinks.values().forEach {
                        QuickLinksText(
                            text = it.title,
                            modifier =
                            Modifier.margin(
                                top = dimension.smallPadding,
                                bottom = dimension.smallPadding
                            ),
                            onClick = { ctx.router.navigateTo(it.url) }
                        )
                    }
                }
            }
            CertificationView()

            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {


                Column(
                    modifier = Modifier.overflowWrap(OverflowWrap.BreakWord),
                ) {
                    Text(
                        text = "Contact Info",
                        modifier =
                            Modifier.fontWeight(FontWeight.SemiBold)
                                .fontSize(dimension.normalText * 0.7)
                                .color(color.onBackground.css(80))
                                .margin(bottom = dimension.smallPadding)
                    )
                    Row {
                        Text(
                            text = Constants.PHONE,
                            modifier =
                                Modifier.fontWeight(FontWeight.Medium)
                                    .fontSize(dimension.smallText)
                                    .color(color.onBackground.css(80))
                        )
                    }
                    Row {
                        Text(
                            text = Constants.ADDRESS,
                            modifier =
                                Modifier.fontWeight(FontWeight.Medium)
                                    .flexDirection(FlexDirection.Column)
                                    .textAlign(TextAlign.Left)
                                    .fontSize(dimension.smallText)
                                    .color(color.onBackground.css(80))
                        )
                    }
                    Text(
                        text = Constants.CONTACT_EMAIL,
                        modifier =
                        Modifier.fontWeight(FontWeight.Bold)
                            .flexDirection(FlexDirection.Column)
                            .textAlign(TextAlign.Left)
                            .fontSize(dimension.smallText)
                            .color(color.onBackground.css(80))
                    )
                }
            }
            Text(
                modifier = Modifier
                    .textAlign(TextAlign.Left)
                    .fontWeight(FontWeight.Bold)
                    .fontSize(dimension.smallText).color(color.primary.css),
                text = "Copyright ©2023 ${Constants.WEBSITE_NAME}. All rights reserved"
            )
        }
    }
}
