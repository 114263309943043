package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Isolation
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.*
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Span
import org.jjoy.ltd.components.widgets.buttons.BlurShadowButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.input.SearchJobField
import org.jjoy.ltd.presentation.components.input.SearchJobFieldMobile
import org.jjoy.ltd.presentation.components.sections.CertificationItem
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.theme.style.text.LargeTitleComp
import org.jjoy.ltd.theme.style.text.LargeTitleStyle
import org.jjoy.ltd.util.const.Pages
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.res.ResourceString

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun HomeSection() {

    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    var field by remember { mutableStateOf("") }

    ContentWrap(
        modifier =
            Modifier.thenIf(
                    breakpoint <= Breakpoint.MD,
                    Modifier.margin(top = dimension.extraLargePadding * 1.2)
                )
                .fillMaxWidth()
                .fillMaxHeight(),
        contentId = Section.Home.id,
    ) {
        if (breakpoint <= Breakpoint.MD) {
            MobileContent(
                stateVsEvent = StateVsEvent(field) { field = it },
                onSearch = { ctx.router.navigateTo(Pages.searchJobs + field + "/all") }
            )
        } else {
            Box(
                modifier = Modifier
                    .width(100.vw).height(100.vh),
                contentAlignment = Alignment.Center
            ) {
                /*PatternBackground (
                    Modifier.styleModifier { filter {
                        grayscale(100.percent)
                    } }
                        .fillMaxSize()
                        .objectFit(ObjectFit.Cover)
                )*/
                Column(
                    modifier = Modifier.isolation(Isolation.Isolate).gap(dimension.smallPadding),
                    horizontalAlignment = Alignment.CenterHorizontally,
                    verticalArrangement = Arrangement.Top
                ) {
                    DesktopContent(
                        stateVsEvent = StateVsEvent(field) { field = it },
                        onSearch = {
                            // ctx.router.navigateTo(PageRoutes.FORM_DETAILS)
                            ctx.router.navigateTo(Pages.searchJobs + field + "/all")
                        }
                    )
                }
            }
        }
    }
}

@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun ColumnScope.DesktopContent(
    stateVsEvent: StateVsEvent,
    onSearch: Callback
) {

    Span(attrs = Modifier.textAlign(TextAlign.Center).toAttrs()) {
        Text(
            text = "Find ",
            modifier =
                LargeTitleStyle.toModifier().textAlign(TextAlign.Center).color(color.primary.css),
            variant = LargeTitleComp
        )
        Text(
            text = "your desired job",
            modifier = LargeTitleStyle.toModifier().textAlign(TextAlign.Center)
        )
    }

    Text(
        text = ResourceString.jobsDesc,
        modifier =
            BaseTextStyle.toModifier()
                .fontSize(dimension.normalText * dimension.normalPadding.value)
                .color(color.onBackground.css(80))
                .textAlign(TextAlign.Center)
                .fontWeight(FontWeight.Bold)
                .margin(bottom = dimension.largePadding)
    )

    var graphicsWidth by remember { mutableStateOf(0.px) }
    SearchJobField(
        modifier = Modifier.displayIfAtLeast(Breakpoint.SM),
        stateVsEvent = stateVsEvent,
        onClick = onSearch,
        compWidth = { graphicsWidth = it }
    )
    // ADD GRAPHICS
    Row(
        modifier =
            Modifier.align(Alignment.CenterHorizontally)
                .margin(top = dimension.mediumPadding)
                .maxWidth(graphicsWidth),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        /*Image(
            src = CertificationItem.REC.logo,
            desc = "",
            modifier =
                Modifier.fillMaxWidth(33.percent).scale(0.5).styleModifier {
                    filter {
                        grayscale(100.percent)
                        opacity(0.7)
                    }
                }
        )*/
        Image(
            src = CertificationItem.BPA.logo,
            desc = "",
            modifier =
                Modifier.fillMaxWidth(50.percent).scale(0.7).styleModifier {
                    filter {
                        grayscale(100.percent)
                        opacity(0.7)
                    }
                }
        )
        Image(
            src = CertificationItem.SAFER_JOBS.logo,
            desc = "",
            modifier =
                Modifier.fillMaxWidth(33.percent).scale(0.85).styleModifier {
                    filter {
                        grayscale(100.percent)
                        opacity(0.5)
                    }
                }
        )
    }
}

@Composable
private fun ColumnScope.MobileContent(stateVsEvent: StateVsEvent, onSearch: Callback) {

    Span(
        attrs =
            Modifier.padding(top = dimension.largePadding)
                .align(Alignment.Start)
                .textAlign(TextAlign.Left)
                .width(95.percent)
                .margin(dimension.normalPadding)
                .flexDirection(FlexDirection.Column)
                .toAttrs()
    ) {
        Text(
            text = "Find ",
            modifier =
                LargeTitleStyle.toModifier().textAlign(TextAlign.Left).color(color.primary.css),
            variant = LargeTitleComp
        )
        Text(
            text = "your desired job",
            modifier = LargeTitleStyle.toModifier().textAlign(TextAlign.Left)
        )
    }

    Column(
        modifier =
            Modifier.gap(dimension.smallPadding).width(95.percent).margin(dimension.normalPadding),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        Text(
            text = ResourceString.jobsDesc,
            modifier =
                BaseTextStyle.toModifier()
                    .fontSize(dimension.normalText)
                    .color(color.onBackground.css(80))
                    .textAlign(TextAlign.Left)
                    .fontWeight(FontWeight.Bold)
                    .margin(bottom = dimension.mediumPadding)
        )

        Text(
            text = "  ",
        )
        Text(
            text = "  ",
        )
        SearchJobFieldMobile(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = stateVsEvent,
            onClick = {}
        )

        BlurShadowButton(
            modifier =
                Modifier.fillMaxWidth()
                    .padding(dimension.mediumPadding)
                    .margin(dimension.mediumPadding),
            text = "Find Jobs",
            fontSize = dimension.normalText,
            onClick = onSearch
        )

        Text(
            text = "  ",
        )
    }
}
