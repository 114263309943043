package org.jjoy.ltd.presentation.screen.user_form.desktop

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.id
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.CommonReaction
import org.jjoy.ltd.core.domain.model.WorkingArrangement
import org.jjoy.ltd.presentation.components.input.CheckBoxWithLabel
import org.jjoy.ltd.presentation.components.input.ListCheckBoxWithLabel
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.UserFormActionEvent
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState
import org.jjoy.ltd.theme.dimension
import org.w3c.dom.HTMLElement

@Composable
fun UserFormDesktopContentSecond(
    modifier: Modifier = Modifier,
    formInputs: List<Pair<Int, String>>,
    state: UserFormUIState,
    onActionEvent: (UserFormActionEvent) -> Unit
) {


    var focus by remember { mutableStateOf(formInputs[0]) }

    FormDesktopWrapper(modifier.gap(dimension.mediumPadding)) {

        // TODO: FIX FOCUS
        FormColumn(
            modifier = Modifier.fillMaxWidth().id("test"),
            horizontalAlignment = Alignment.Start,
            gap = dimension.mediumPadding
        ) {

            ListCheckBoxWithLabel(
                modifier = Modifier.fillMaxWidth(),
                label = "Do you require flexible working arrangement?",
                list = WorkingArrangement.getList(),
                itemToName = { it?.getName() ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.WorkingArrangementChanged(it))
                },
                isMandatory = true,
                error = state.errors.workArrangements,
            )

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Which roles are you looking for?",
                id = "roles",
                stateVsEvent =
                    StateVsEvent(value = state.roles) {
                        onActionEvent(UserFormActionEvent.PrefrRoleChanged(it))
                    },
                isMandatory = false,
                error = state.errors.roles
            ) {
                focus = formInputs[1]
            }

            FormTextInput(
                label =
                    "Tell us 5 top qualities you have that will make you a great for the above position",
                id = "qualities",
                stateVsEvent =
                    StateVsEvent(value = state.qualities) {
                        onActionEvent(UserFormActionEvent.QualitiesChanged(it))
                    },
                isMandatory = true,
                singleLine = false,
            ) {
                it.clearFocus()
            }

            CheckBoxWithLabel(
                label = "Are you willing to relocate?",
                list = CommonReaction.getStringList(),
                itemToName = { it ?: "ERROR" },
                item = state.relocate,
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.WorkReLocationChanged(it))
                },
                error = state.errors.relocate,
                isMandatory = true,
            )
            CheckBoxWithLabel(
                label = "Do you have a UK driving licence?",
                list = listOf("Full/Manual","Auto","Provisional","No"),
                item = state.ukDrivingLicence,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.DrivingLicenceChanged(it))
                },
                error = state.errors.drivingLicence,
                isMandatory = true,
            )
            CheckBoxWithLabel(
                label = "Are you willing to ride a moped/scooter?",
                list = CommonReaction.getStringList(),
                item = state.mopedScooter,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.MopedScooterChanged(it))
                },
                error = state.errors.mopedScooter,
                isMandatory = true,
            )

            CheckBoxWithLabel(
                label = "Do you have a CBT Certificate?",
                list = listOf("Yes","No"),
                item = state.cbt,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(UserFormActionEvent.CBTCertChanged(it))
                },
                isMandatory = true,
                error = state.errors.cbt,
            )

        }

    }

    LaunchedEffect(focus) {
        (document.getElementById(focus.second) as HTMLElement).focus()
    }

    LaunchedEffect(Unit) {
        val element = document.getElementById("test") as HTMLElement
        delay(500)
        element.scrollIntoView()
    }
}
