package org.jjoy.ltd.core.domain.model.job

import com.benasher44.uuid.uuid4
import kotlinx.serialization.Serializable

@Serializable
data class JobAppDto(
    val id: String = uuid4().toString(),
    val userId: String,
    val jobId: String,
    val isRejected: Boolean = false
)

@Serializable
data class JobApplicationInfo(
    val id: String,
    val jobTitle: String,
    val jobId: String,
    val userEmail: String,
    val appliedAt: Long,
)