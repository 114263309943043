package org.jjoy.ltd.presentation.admin.home

import kotlinx.browser.localStorage
import kotlinx.coroutines.delay
import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.AdminApi
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.model.NavRailItem
import org.jjoy.ltd.domain.use_cases.admin.users.AdminUsersUseCases
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.EndPoints
import org.jjoy.ltd.util.const.LocalStorageConst
import org.kodein.di.DI
import org.kodein.di.instance

class AdminHomeViewModel(
    di: DI = useCasesModule
): BaseViewModel<AdminHomeUIState, CommonUIEvent, AdminHomeActionEvent>() {

    private val adminUsersUseCases: AdminUsersUseCases by di.instance()


    init {

        val adminApi = AdminApi().headers {
            localStorage.getItem(LocalStorageConst.TOKEN)?.let {
                if (!has("Auth-Token")) {
                    append("Auth-Token", it)
                }
            }
        }.build()

        launch {
            apiFlowResponse<String>(
                apiCall = {
                    adminApi.fetch(path = EndPoints.ADMIN_TOKEN_VERIFY, method = "GET")
                }
            ).collect{
                when(it){
                    is ResourceState.Error -> {
                        sendUIEvent(CommonUIEvent.Error("Invalid Token"))
                        update { copy(isPageLoading = false, validated = false) }
                    }
                    is ResourceState.Loading -> {
                        update { copy(isPageLoading = true) }
                    }
                    is ResourceState.Success -> {
                        update { copy(isPageLoading = false, validated = true) }
                        sendUIEvent(CommonUIEvent.NavigateNext)
                    }
                    else -> {
                        sendUIEvent(CommonUIEvent.Error("Invalid Token"))
                        update { copy(isPageLoading = false, validated = false) }
                    }
                }
            }
        }

    }

    override fun initialState(): AdminHomeUIState {
       return AdminHomeUIState(
           dummy = "Dummy Test",
           isPageLoading = true
       )
    }

    override fun onActionEvent(action: AdminHomeActionEvent) {
        when(action){
            AdminHomeActionEvent.Dummy -> {}
            is AdminHomeActionEvent.NavRailItemChanged -> update { copy(navRailItem = action.item) }
        }
    }

    fun validate(
        token: String,
        onSuccess: () -> Unit,
        onFailure: () -> Unit
    ){
        launch {
            adminUsersUseCases.validateToken(
                input = token ?: "dummy",
                onLoading = {

                },
                onSuccess = {
                    onSuccess()
                    delay(300)
                    update { copy(isPageLoading = false, validated = true) }
                    sendUIEvent(CommonUIEvent.NavigateNext)
                },
                onFailure = {
                    onFailure()
                    delay(300)
                    update { copy(isPageLoading = false, validated = false) }
                    sendUIEvent(CommonUIEvent.Error("Invalid Token"))
                }
            )
        }
    }


}

data class AdminHomeUIState(
    val dummy: String,
    val isPageLoading: Boolean = true,
    val validated: Boolean = false,

    val navRailItem: NavRailItem = NavRailItem.JOBS
) : UIState

sealed interface AdminHomeActionEvent : ActionEvent {
    object Dummy : AdminHomeActionEvent
    data class NavRailItemChanged(val item: NavRailItem) : AdminHomeActionEvent
}