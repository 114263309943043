package org.jjoy.ltd.pages

import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.Job
import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.job.JobCategoryInfo
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.admin.jobs.AdminJobUseCases
import org.jjoy.ltd.domain.use_cases.jobs.JobsUseCases
import org.jjoy.ltd.domain.use_cases.jobs.SearchQuery
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.LocalStorageConst.JOB_DETAIL
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID
import org.kodein.di.instance

class MainViewModel(
    private val query: String? = null,
    private val category: String? = null,
) : BaseViewModel<MainUIState, CommonUIEvent, MainActionEvent>() {

    private val jobsUseCases: JobsUseCases by useCasesModule.instance()
    private val adminUseCases: AdminJobUseCases by useCasesModule.instance()
    private var jobsListJob: Job? = null

    init {
        /*onIO { generateJobs(15).forEach {
            adminUseCases.addJob(it)
        } }*/
        val userId = localStorage.getItem(USER_ID)

        update { copy(userId = userId, currentCategory = category ?: "All") }
        jobsListJob?.cancel()
        if (query != null || category != null) {
            // update { copy() }
            searchJobs(query ?: "all", category ?: "all")
        } else {
            fetchJobsListInfo()
        }
    }

    override fun initialState(): MainUIState {
        return MainUIState(
            userId = null,
            isLoading = false,
            isPageLoading = true,
            isError = false,
            errorMsg = "",
            jobs = listOf(),
            currentCategory = "All"
        )
    }

    override fun onActionEvent(action: MainActionEvent) {
        when (action) {
            is MainActionEvent.SetNavArgJob -> {
                val encodeString = action.jobDTO.encodeToString()
                window.localStorage.setItem(JOB_DETAIL, encodeString)
            }
            is MainActionEvent.SearchJobs ->
                searchJobs(
                    query = action.v.ifBlank { "all" },
                    category = action.c.ifBlank { "all" }
                )
            is MainActionEvent.PageLoading -> {
                update { copy(isPageLoading = action.v) }
            }
        }
    }

    private fun fetchJobsListInfo() {
        launch {
            jobsUseCases.getJobsListInfo(
                onLoading = { update { copy(isJobsListInfoLoading = true) } },
                onFailure = { update { copy(isJobsListInfoLoading = false, jobsInfo = listOf()) } },
                onSuccess = {
                    update { copy(isJobsListInfoLoading = false, jobsInfo = it) }
                    sessionStorage.setItem("isBuffered", "true")
                }
            )
        }
    }

    private fun searchJobs(query: String, category: String) {
        jobsListJob?.cancel()
        jobsListJob = launch {
            jobsUseCases.searchJobs(
                input = SearchQuery(query, category),
                onLoading = {
                    update { copy(isLoading = true) }
                },
                onFailure = {
                    update {
                        copy(isLoading = false, isError = true, errorMsg = "Could Not Fetch Jobs")
                    }
                },
                onSuccess = {
                    update {
                        copy(
                            isLoading = false,
                            isError = false,
                            jobs = it,
                            jobsEmpty = it.isEmpty()
                        )
                    }
                }
            )
        }
    }
}

data class MainUIState(
    val isPageLoading: Boolean,
    val userId: String? = null,
    val isLoading: Boolean = false,
    val isError: Boolean = false,
    val errorMsg: String = "",
    val jobs: List<JobListItem> = listOf(),
    val isJobsListInfoLoading: Boolean = false,
    val jobsInfo: List<JobCategoryInfo> = listOf(),
    val jobsEmpty: Boolean = false,
    val currentCategory: String
) : UIState

sealed interface MainActionEvent : ActionEvent {
    data class SetNavArgJob(val jobDTO: JobListItem) : MainActionEvent
    data class SearchJobs(val v: String, val c: String) : MainActionEvent

    data class PageLoading(val v: Boolean) : MainActionEvent
}
