package org.jjoy.ltd.core.domain.model.user

import kotlinx.datetime.LocalDate
import kotlinx.serialization.Serializable

@Serializable
data class UserFormDetailsDto(
    val parentUserId: String,
    val firstName: String,
    val middleName: String,
    val lastName: String,
    val address: String,
    val postcode: String,
    val telephone: String?,
    val dateOfBirth: String?,
    val nationalInsuranceNumber: String,
    val ukWorkDocument: List<String>,
    val preferredJobType: String,

    val arrangements: List<String>?,
    val roles: String?,
    val qualities: String?,
    val relocate: String,
    val ukDrivingLicence: String,
    val mopedScooter: String,
    val cbt: String,

    val gender: String?,
    val relationshipStatus: String?,
    val ageGroup: String?,
    val ethinic: String?,
    val race: String?,

    val disability: String,
    val disabilityDesc: String?,
    val sexualOri: String,
    val religion: String,
    val criminalConv: String?,
    val signature: String,

    val p2cName: String,
    val p2cRelation: String,
    val p2cTele: String,
    val p2cAddress: String,
    val p2cEmail: String,
    val p2cPersonName: String,
    val date: LocalDate
)