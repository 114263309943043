package org.jjoy.ltd.data.remote.network

import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.withContext
import org.jjoy.ltd.util.const.Constants.API_BASE_URL
import org.jjoy.ltd.util.const.LocalStorageConst
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import kotlin.js.Promise

class AdminApi {

    private var baseUrl: String = API_BASE_URL

    private val headers = Headers(init = {})

    init {
        // TODO: Fix when logged in registered issue, not using X-id header
        headers.apply {
            localStorage.getItem(LocalStorageConst.TOKEN)?.let {
                if (!has("Auth-Token")) {
                    append("Auth-Token", it)
                }
            }
        }
    }
    fun baseUrl(baseUrl: String): AdminApi {
        this.baseUrl = baseUrl
        return this
    }

    fun headers(block: Headers.() -> Unit): AdminApi {
        headers.apply { block(this) }
        return this
    }

    fun build(): AdminApiClient {
        return AdminApiClient(baseUrl, headers)
    }
}

class AdminApiClient(val baseUrl: String, val headers: Headers) {

    suspend inline fun fetch(
        path: String,
        method: String? = null,
        body: dynamic = undefined,
        options: RequestInit? = null,
    ): Promise<Response> =
        withContext(Dispatchers.Default) {
            headers.apply {
                if (!has("Auth-Token")) {
                    localStorage.getItem(LocalStorageConst.TOKEN)?.let { append("Auth-Token", it) }
                }
            }
            return@withContext window.fetch(
                input = "$baseUrl$path",
                init = options
                        ?: RequestInit(
                            method = method,
                            headers =
                                if (method == "POST") {
                                    headers.apply {
                                        if (!has("Content-Type")) {
                                            append("Content-Type", "application/json")
                                        }
                                        // append("Authorization", "Bearer <your_token>")
                                    }
                                } else headers,
                            body = body ?: undefined
                        )
            )
        }

    // Use admin id / fileId as url --> dgjsg/17SzigqN5N_h8RlEYO_bc89GGHA9ZxMbA
    fun download(url: String, onSuccess: (String) -> Unit, onError: (String) -> Unit) {

        window
            .fetch("${baseUrl}admin/form/download/$url")
            .then { response -> response.text() }
            .then { blob ->
                // Create a temporary anchor element
                val a = document.createElement("a") as HTMLAnchorElement
                a.href = blob
                a.download = "passport_test.jpg" // todo: use Name

                // Programmatically click the anchor element to trigger the download
                a.click()
                // Clean up the temporary URL and anchor element
                URL.revokeObjectURL(blob)
            }
            .catch { error ->
                // Handle the error
                console.error("Error downloading file: ${error.message}")
            }
    }
}
