package org.jjoy.ltd.data.repositoryImpl

import kotlinx.browser.window
import org.jjoy.ltd.core.domain.model.user.UserResponse
import org.jjoy.ltd.domain.repository.LocalStorageRepository
import org.jjoy.ltd.util.const.LocalStorageConst
import org.jjoy.ltd.util.safeTry

class LocalStorageRepositoryImpl: LocalStorageRepository {

    override suspend fun saveLogin(userResponse: UserResponse): Boolean {
        return safeTry {
            window.localStorage.setItem(LocalStorageConst.USER_ID,userResponse.id)
            window.localStorage.setItem(LocalStorageConst.USER_FULL_NAME,userResponse.fullName)
            window.localStorage.setItem(LocalStorageConst.IS_LOGGED_IN,"true")
        }
    }






}