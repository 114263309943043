package org.jjoy.ltd.presentation.screen.user_login

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.util.extension.isEmail

data class LoginUIState(
    val userEmail: String,
    val userPhone: String,
    val userFullName: String,
    val password: String,
    val confirmPassword: String,
    val error: LoginFormFieldError,
    val isError: Boolean,
    val errorMessage: String,
    val isLoading: Boolean,
) : UIState

fun LoginUIState.checkFieldError(): LoginFormFieldError {

    if (
        userEmail.isBlank() ||
            userFullName.isBlank() ||
            userPhone.isBlank() ||
            password.isBlank() ||
            confirmPassword.isBlank()
    ) {
        return LoginFormFieldError(
            email = userEmail.isBlank(),
            fullName = userFullName.isBlank(),
            phone = userPhone.isBlank(),
            password = password.isBlank(),
            confirmPassword = confirmPassword.isBlank(),
            msg = "Please fill all the require field"
        )
    }

    if (!userEmail.isEmail())
        return LoginFormFieldError(email = true, msg = "Please enter a valid email address")
    // TODO: PHONE LENGTH
    if (userPhone.length != 12)
        return LoginFormFieldError(phone = true, msg = "Please enter a valid phone number with country code")

    if (password != confirmPassword)
        return LoginFormFieldError(confirmPassword = true, password = true, msg = "Password does not match")

    return LoginFormFieldError()
}


fun LoginUIState.checkLoginError(): LoginFormFieldError {

    if (userEmail.isBlank() || password.isBlank())
        return LoginFormFieldError(
            email = userEmail.isBlank(),
            password = password.isBlank(),
            msg = if (userEmail.isBlank()) "Please enter your registered email" else "Please enter your password"
        )

    if (!userEmail.isEmail())
        return LoginFormFieldError(email = true, msg = "Please enter a valid email address")

    if (password.length < 8)
        return LoginFormFieldError(password = true, msg = "Incorrect password")

    if (password.isBlank())
        return LoginFormFieldError(password = true, msg = "Incorrect password")

    return LoginFormFieldError()
}

