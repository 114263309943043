package org.jjoy.ltd.data.repositoryImpl

import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.core.domain.model.user.UserInfo
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.AdminApi
import org.jjoy.ltd.domain.repository.AdminUserRepository
import org.jjoy.ltd.domain.use_cases.admin.users.AdminLoginRequest
import org.jjoy.ltd.domain.use_cases.admin.users.AdminRegisterRequest
import org.jjoy.ltd.domain.use_cases.admin.users.AdminRegisterResponse
import org.jjoy.ltd.domain.use_cases.admin.users.AdminResponse
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.const.EndPoints

class AdminUserRepositoryImpl(
) : AdminUserRepository {

    private val adminApi by lazy { AdminApi().baseUrl(Constants.API_BASE_URL).build() }

    override suspend fun getAllUsers(): ApiFlow<List<UserInfo>> {
        return apiFlowResponse<List<UserInfo>> {
            adminApi.fetch(path = EndPoints.GET_ALL_USERS, method = "GET")
        }
    }

    override suspend fun searchUsers(query: String): ApiFlow<List<UserInfo>> {
        return apiFlowResponse<List<UserInfo>> {
            adminApi.fetch(path = "${EndPoints.SEARCH__ALL_USERS}$query", method = "GET")
        }
    }

    override suspend fun getUserDetails(id: String): ApiFlow<UserFormDetailsDto> {
        // GET_USER_DETAILS
        return apiFlowResponse<UserFormDetailsDto> {
            adminApi.fetch(path = "${EndPoints.GET_USER_DETAILS}$id", method = "GET")
        }
    }

    override suspend fun adminLogin(request: AdminLoginRequest): ApiFlow<AdminResponse> {
        return apiFlowResponse<AdminResponse> {
            adminApi.fetch(
                path = EndPoints.ADMIN_LOGIN,
                method = "POST",
                body = request.encodeToString()
            )
        }
    }

    override suspend fun adminRegister(request: AdminRegisterRequest): ApiFlow<AdminRegisterResponse> {
        return apiFlowResponse<AdminRegisterResponse> {
            adminApi.fetch(
                path = EndPoints.ADMIN_REGISTER,
                method = "POST",
                body = request.encodeToString()
            )
        }
    }

    override suspend fun validateAdminToken(token: String): ApiFlow<String> {
        return apiFlowResponse<String> {
            adminApi.fetch(path = EndPoints.ADMIN_TOKEN_VERIFY, method = "GET")
        }
    }
}
