package org.jjoy.ltd.presentation.admin.jobs

import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.domain.model.job.JobType

sealed interface JobsDashActionEvent: ActionEvent {
        data class TitleChanged(val v: String): JobsDashActionEvent
        data class DescriptionChanged(val v: String): JobsDashActionEvent
        data class LocationChanged(val v: String): JobsDashActionEvent
        data class WorkLocationChanged(val v: String): JobsDashActionEvent
        data class RequirementsChanged(val v: List<String>): JobsDashActionEvent
        data class CertificatesRequiredChanged(val v: List<String>): JobsDashActionEvent

        data class JobTypeChanged(val v: JobType): JobsDashActionEvent

        data class JobCategoryIdChanged(val v: String): JobsDashActionEvent

        data class JobCategoryNameChanged(val v: String): JobsDashActionEvent
        data class SalaryChanged(val v: String): JobsDashActionEvent

        data class IsPerHourChanged(val v: SalaryType): JobsDashActionEvent

        data class IsFeaturedChanged(val v: Boolean): JobsDashActionEvent

        data class ExpiryAtChanged(val v: Long): JobsDashActionEvent

        data class IsDeletedChanged(val v: Boolean): JobsDashActionEvent

        data class IsExpiredChanged(val v: Boolean): JobsDashActionEvent

        object AddJob: JobsDashActionEvent

        data class SearchJob(val v: String,val c: String): JobsDashActionEvent


        data class UpdateCurrentPanel(val v: JobPanel): JobsDashActionEvent
        data class UpdateJobId(val v: String): JobsDashActionEvent
}

data class AddJobFocus(
        val title: String,
)