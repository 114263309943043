package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css

@Composable
fun Avatar(
    modifier: Modifier = Modifier,
    userName: String = "U",
    onclick: () -> Unit
){

    Box(
        modifier = modifier
            .then(AvatarStyle.toModifier())
            .onClick { onclick() }
        ,
        contentAlignment = Alignment.Center
    ){

        Text(
            modifier = BaseTextStyle.toModifier()
                .textAlign(TextAlign.Center)
                .fontWeight(650)
                .fontSize(dimension.avatarSize * 0.8)
                .color(color.onPrimary.css),
            text = userName.first().toString()
                .replaceFirstChar { if (it.isLowerCase()) it.titlecase() else it.toString() }
        )

    }

}

val AvatarStyle by ComponentStyle {
    base {
        Modifier
            .size(dimension.avatarSize * 1.3)
            .scale(1)
            .backgroundColor(color.primary.css)
            .borderRadius(18.percent)
            .borderColor(color.primary.css)
            .translateY(0.percent)
            .boxShadow(
                color = color.primary.css(50),
                blurRadius = dimension.smallBlurRadius,
                spreadRadius = 0.25.cssRem,
                offsetX = 0.px,
                offsetY = 2.px,
            )
            .animate(prop = listOf("scale","translate","box-shadow"))
    }

    hover {
        Modifier
            .scale(1.06)
            .translateY((-8).percent)
            .boxShadow(
                color = color.primary.css(60),
                blurRadius = dimension.mediumBlurRadius,
                spreadRadius = 0.35.cssRem,
                offsetX = 0.px,
                offsetY = 3.px,
            )
    }

}