package org.jjoy.ltd.presentation.composables

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Span
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.presentation.components.input.TextInputFieldSmall
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.animateColor
import org.jjoy.ltd.util.extension.css
import org.w3c.dom.HTMLElement

@Composable
fun FormTextInput(
    label: String,
    stateVsEvent: StateVsEvent,
    modifier: Modifier = Modifier,
    fieldModifier: Modifier = Modifier,
    id: String = "",
    isMandatory: Boolean = false,
    singleLine: Boolean = true,
    isNumber: Boolean = false,
    limit: Int = 12,
    error: FormInputError = FormInputError(),
    placeHolder: String? = null,
    gap: CSSSizeValue<CSSUnit.rem> = dimension.normalRoundness,
    onIme: TCallback<HTMLElement> = {
    }
) {
    Column(
        modifier = modifier,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.Start
    ) {
        Row(
            modifier = Modifier.margin(bottom = gap),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(
                attrs = Modifier.verticalAlign(VerticalAlign.Middle).toAttrs()
            ) {
                Text(
                    text = label,
                    modifier =
                    Modifier.fontSize(dimension.smallButtonText * 0.8f)
                        .fontWeight(550)
                        .color(color.onBackground.css(60))
                        .thenIf(error.isError,Modifier.color(color.error.css))
                        .animateColor()
                )
                if (isMandatory) {
                    Text(
                        text = "*",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText)
                            .fontWeight(550)
                            .color(color.error.css)
                    )
                }
            }

        }

        TextInputFieldSmall(
            modifier = fieldModifier.id(id).fillMaxWidth(),
            stateVsEvent = StateVsEvent(value = stateVsEvent.value){
                  if (isNumber){
                      if ((it.isDigit() || it == "") && (it.length) <= limit) {
                          stateVsEvent.onValueChange(it)
                      }
                  } else stateVsEvent.onValueChange(it)
            },
            isError = error.isError,
            placeHolder = placeHolder,
            onIme = onIme,
            singleLine = singleLine
        )
        Text(
            text = error.msg,
            modifier =
                Modifier.opacity(0)
                    .thenIf(error.isError, Modifier.opacity(1))
                    .fontSize(dimension.smallText)
                    .fontWeight(400)
                    .color(color.error.css)
                    .margin(top = dimension.extraSmallPadding, bottom = dimension.extraSmallPadding)
                    .animate(prop = listOf("opacity", "scale", "width"))
        )
    }
}

data class FormInputError(
    var isError: Boolean = false,
    var msg: String = "                 "
)
