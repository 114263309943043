package org.jjoy.ltd.presentation.screen.user_login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.const.LoginSectionID.REGISTER_VIEW

@Composable
fun RegistrationScreen(
    ctx: PageContext,
    views: LoginViews,
    viewModel: UserLoginViewModel,
    modifier: Modifier = Modifier.id(REGISTER_VIEW)
) {

    val state by viewModel.collectState()
    val uiEventFlow by viewModel.collectEvent()

    Column(
        modifier = Modifier.fillMaxWidth(90.percent).fillMaxHeight(90.percent),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        Content(
            modifier = Modifier,
            state = state,
            views = views,
            onEvent = viewModel::onActionEvent
        )
    }

    uiEventFlow?.let {
        when (it) {
            is CommonUIEvent.Error -> TODO()
            CommonUIEvent.Loading -> TODO()
            CommonUIEvent.NavigateNext -> ctx.router.navigateTo("/")
            CommonUIEvent.NavigateUp -> TODO()
        }
    }
}

@Composable
private fun ColumnScope.Content(
    onEvent: TCallback<LoginActionEvent>,
    state: LoginUIState,
    views: LoginViews,
    modifier: Modifier = Modifier
) {

    val brk = rememberBreakpoint()

    Row(
        modifier = modifier.displayIfAtLeast(Breakpoint.MD).padding(dimension.largePadding * 1.8),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        RegistrationBrandView(
            modifier = Modifier.width(60.percent).height(100.percent),
            view = views,
            onViewChange = { onEvent(LoginActionEvent.SwitchView(it)) }
        )
        Spacer()
        RegistrationForm(
            modifier =
                Modifier.width(40.percent)
                    .padding(left = dimension.largeText, right = dimension.largeText),
            error = state.error,
            isLoading = state.isLoading,
            isAlert = state.isError,
            userEmailStateVsEvent =
                StateVsEvent(state.userEmail) { onEvent(LoginActionEvent.UserEmailChanged(it)) },
            userFullNameStateVsEvent =
                StateVsEvent(state.userFullName) {
                    if (!it.isDigit()) {
                        onEvent(LoginActionEvent.UserFullNameChanged(it))
                    }
                },
            userPhoneStateVsEvent =
                StateVsEvent(state.userPhone) { onEvent(LoginActionEvent.UserPhoneChanged(it)) },
            userPasswordStateVsEvent =
                StateVsEvent(state.password) { onEvent(LoginActionEvent.UserPasswordChanged(it)) },
            userConfirmPasswordStateVsEvent =
                StateVsEvent(state.confirmPassword) {
                    onEvent(LoginActionEvent.UserPasswordConfirmChanged(it))
                },
            onSubmit = onEvent,
            views = views
        )
    }

    RegistrationFormMobile(
        modifier = Modifier.displayUntil(Breakpoint.MD).fillMaxSize(),
        error = state.error,
        isLoading = state.isLoading,
        isAlert = state.isError,
        userEmailStateVsEvent =
            StateVsEvent(state.userEmail) { onEvent(LoginActionEvent.UserEmailChanged(it)) },
        userFullNameStateVsEvent =
            StateVsEvent(state.userFullName) { onEvent(LoginActionEvent.UserFullNameChanged(it)) },
        userPhoneStateVsEvent =
            StateVsEvent(state.userPhone) { onEvent(LoginActionEvent.UserPhoneChanged(it)) },
        userPasswordStateVsEvent =
            StateVsEvent(state.password) { onEvent(LoginActionEvent.UserPasswordChanged(it)) },
        userConfirmPasswordStateVsEvent =
            StateVsEvent(state.confirmPassword) {
                onEvent(LoginActionEvent.UserPasswordConfirmChanged(it))
            },
        onSubmit = { onEvent(LoginActionEvent.Register) },
        onViewChange = { onEvent(LoginActionEvent.SwitchView(it)) }
    )

    LaunchedEffect(state.isError) {
        if (state.isError) {
            delay(2000)
            onEvent(LoginActionEvent.ResetError)
        }
    }
}
