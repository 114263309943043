package org.jjoy.ltd.presentation.composables.wrappers

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.sections.Footer
import org.jjoy.ltd.presentation.components.sections.NavHeader
import org.jjoy.ltd.presentation.composables.LoaderView
import org.jjoy.ltd.presentation.composables.OverflowMenu
import org.jjoy.ltd.theme.dimension

@Composable
fun FullPageWrapper(
    title: String,
    isLoading: Boolean = false,
    modifier: Modifier = Modifier.fillMaxWidth(),
    contentId: String = "",
    content: @Composable ColumnScope.() -> Unit
) {
    /*LaunchedEffect(title) { document.title = title }*/

    val breakpoint = rememberBreakpoint()

    var menuOpened by remember { mutableStateOf(false) }
    Box(
        modifier = modifier.id(contentId).gridTemplateRows("minmax(0, 1fr) min-content"),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier =
                Modifier.backgroundColor(color = Colors.White)
                    .fillMaxSize()
                    .align(Alignment.TopCenter),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            NavHeader { menuOpened = !menuOpened }

            Box(
                modifier =
                    Modifier.fillMaxWidth()
                        .margin(top = dimension.extraLargePadding * 1.05)
                        .align(Alignment.TopCenter)
            ) {
                Column(
                    modifier =
                        Modifier.fillMaxWidth()
                            .align(Alignment.TopCenter)
                            .padding(dimension.normalPadding),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    content(this)
                }
                LoaderView(isLoading = isLoading)
            }
            Footer(Modifier.gridRowStart(2).gridRowEnd(3))
        }

        if (menuOpened) {
            OverflowMenu(onMenuClosed = { menuOpened = false })
        }


    }
}
