package org.jjoy.ltd.presentation.admin.users

import kotlinx.coroutines.Job
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.admin.users.AdminUsersUseCases
import org.jjoy.ltd.util.base.launch
import org.kodein.di.DI
import org.kodein.di.instance

class UsersViewModel(di: DI = useCasesModule) :
    BaseViewModel<UsersUIState, CommonUIEvent, UsersActionEvent>() {

    private val adminUsersUseCases: AdminUsersUseCases by di.instance()
    private var retryNum = 1
    private var usersListJob: Job? = null

    override fun initialState(): UsersUIState {

        return UsersUIState(
            isPageLoading = true,
            isError = false,
            errorMessage = "",
            isEmpty = false,
            users = listOf(),
        )
    }

    init {
        //getUsers()

        usersListJob?.cancel()
        usersListJob = onIO {
            adminUsersUseCases.getAllUsers(
                onLoading = { update { copy(isPageLoading = true) } },
                onFailure = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = true,
                            errorMessage = "Cloud not fetch users"
                        )
                    }
                },
                onUnauthorized = {
                    update {
                        copy(isPageLoading = false, isError = true, errorMessage = "Please Refresh")
                    }
                },
                onSuccess = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = false,
                            errorMessage = "",
                            isEmpty = it.isEmpty(),
                            users = it
                        )
                    }
                }
            )
        }



    }

    /*fun checkFailureRetry(){
        if(retryNum < 3){
            getUsers()
        }
    }*/

    override fun onActionEvent(action: UsersActionEvent) {
        when (action) {
            UsersActionEvent.Refresh -> TODO()
            is UsersActionEvent.SearchUser -> searchJobs(action.v)
        }
    }

    private fun searchJobs(query: String) {
        launch {
            adminUsersUseCases.searchUsers(
                input = query,
                onLoading = { update { copy(isPageLoading = true) } },
                onFailure = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = true,
                            errorMessage = "Cloud not fetch users"
                        )
                    }
                },
                onSuccess = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = false,
                            errorMessage = "",
                            isEmpty = it.isEmpty(),
                            users = it
                        )
                    }
                }
            )
        }
    }

    private fun getUsers() {
        usersListJob?.cancel()
        usersListJob = onIO {
            adminUsersUseCases.getAllUsers(
                onLoading = { update { copy(isPageLoading = true) } },
                onFailure = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = true,
                            errorMessage = "Cloud not fetch users"
                        )
                    }
                    retryNum++
                },
                onUnauthorized = {
                    update {
                        copy(isPageLoading = false, isError = true, errorMessage = "Unauthorized")
                    }
                },
                onSuccess = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = false,
                            errorMessage = "",
                            isEmpty = it.isEmpty(),
                            users = it
                        )
                    }
                }
            )
        }
    }
}
