package org.jjoy.ltd.util.extension

import kotlinx.browser.window

fun clearLocalStorage(vararg keysToPreserve: String = arrayOf("")) {
    val preservedValues = mutableMapOf<String, String>()

    // Save the desired values in the 'preservedValues' map
    for (key in keysToPreserve) {
        preservedValues[key] = window.localStorage.getItem(key) ?: ""
    }

    // Clear the entire 'localStorage'
    window.localStorage.clear()

    // Restore the preserved values back to 'localStorage'
    for ((key, value) in preservedValues) {
        window.localStorage.setItem(key, value)
    }
}