package org.jjoy.ltd.presentation.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.*
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animateColor
import org.jjoy.ltd.util.extension.css

@Composable
fun <T>DropDownWithLabel(
    label: String,
    modifier: Modifier = Modifier,
    isMandatory: Boolean = false,
    selectedItem: T?,
    list: List<T>,
    itemToName: (T?) -> String?,
    onItemSelected: (T) -> Unit,
    placeHolder: String = "Please select one",
    error: FormInputError
) {

    Column(
        modifier = modifier.gap(dimension.extraSmallPadding * 2),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        Row(
            modifier = Modifier.margin(bottom = dimension.smallPadding),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(attrs = Modifier.verticalAlign(VerticalAlign.Middle).toAttrs()) {
                org.jjoy.ltd.presentation.components.text.Text(
                    text = label,
                    modifier =
                        Modifier.fontSize(dimension.smallButtonText * 0.9)
                            .fontWeight(520)
                            .color(color.onBackground.css(85))
                            .thenIf(error.isError, Modifier.color(color.error.css))
                            .animateColor()
                )
                if (isMandatory) {
                    org.jjoy.ltd.presentation.components.text.Text(
                        text = "*",
                        modifier =
                            Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
                if (error.isError){
                    org.jjoy.ltd.presentation.components.text.Text(
                        text = " ${error.msg}",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
            }
        }
        DropDown(
            selectedItem = selectedItem,
            list = list,
            itemToName = itemToName,
            onItemSelected = onItemSelected,
            placeHolder = placeHolder
        )

    }
}

@Composable
fun <T> DropDown(
    selectedItem: T?,
    list: List<T>,
    itemToName: (T?) -> String?,
    onItemSelected: (T) -> Unit,
    modifier: Modifier = Modifier,
    isError: Boolean = false,
    placeHolder: String = "Please select one",
) {

    Div(
        attrs =
            modifier
                .classNames("dropdown", "my-1", "mx-1")
                .fillMaxWidth()
                .alignSelf(AlignSelf.Center)
                .toAttrs()
    ) {
        Button(
            attrs =
                Modifier.classNames("btn", "btn-light", "dropdown-toggle", "btn-md")
                    .then(dropDownStyle.toModifier())
                    .toAttrs { attr("data-bs-toggle", "dropdown") }
        ) {
            Text(itemToName(selectedItem) ?: placeHolder)
        }
        Ul(attrs = Modifier.classNames("dropdown-menu").cursor(Cursor.Pointer).toAttrs()) {
            Li(attrs = Modifier.classNames("dropdown-header").toAttrs()) {
                org.jjoy.ltd.presentation.components.text.Text(
                    text = "Please Select The proper One",
                    modifier =
                        Modifier.fontSize(dimension.smallButtonText)
                            .fontWeight(500)
                            .color(color.onBackground.css(80))
                )
            }

            list.forEach { item ->
                Li(
                    attrs =
                        Modifier.classNames("dropdown-item")
                            .thenIf(
                                selectedItem == item,
                                Modifier.backgroundColor(color.primary.css).classNames("active")
                            )
                            .onClick { onItemSelected(item) }
                            .toAttrs()
                ) {
                    org.jjoy.ltd.presentation.components.text.Text(
                        text = itemToName(item) ?: "ERROR",
                        modifier = Modifier.fontSize(dimension.smallText * 1.15).fontWeight(500)
                    )
                }
            }
        }
    }
}

val dropDownStyle by
    ComponentStyle.base {
        Modifier.backgroundColor(color.bgGray.css)
            .padding(dimension.smallPadding)
            .borderRadius(dimension.normalRoundness)
            .border(
                width = 0.09.cssRem,
                style = LineStyle.Solid,
                color = color.onBackground.css(10)
            )
            .fontSize(1.1.cssRem)
            .fontWeight(500)
    }
