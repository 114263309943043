package org.jjoy.ltd.pages.jjoy.user_login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.collectAsState
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.presentation.screen.user_login.LoginScreen
import org.jjoy.ltd.presentation.screen.user_login.LoginViews
import org.jjoy.ltd.presentation.screen.user_login.RegistrationScreen
import org.jjoy.ltd.presentation.screen.user_login.UserLoginViewModel
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css

val tra by Keyframes {
    from { Modifier.translateY(0.percent) }
    from { Modifier.translateY(110.percent) }
}

@Page("/login")
@Composable
fun UserLogin() {

    if (window.location.pathname == "/login") {
        window.history.replaceState(null, document.title, window.location.origin)
        window.addEventListener(
            "popstate",
            { event ->
                // Navigate to the root URL
                window.location.href = window.location.origin
            }
        )
    }

    val ctx = rememberPageContext()
    val viewModel = rememberViewModel { UserLoginViewModel() }
    val viewStateFlow by viewModel.viewState.collectAsState()

    Box(
        modifier = Modifier.width(100.vw).height(100.vh).displayIfAtLeast(Breakpoint.MD),
        contentAlignment = Alignment.Center
    ) {

        Image(
            src = "/categories_header.jpg",
            modifier = Modifier
                .fillMaxSize()
                .objectFit(ObjectFit.Cover)
        )

        RegistrationScreen(
            ctx = ctx,
            viewModel = viewModel,
            views = viewStateFlow,
            modifier =
                Modifier
                    .animate(prop = listOf("opacity", "scale"))
        )
    }

    Box(
        modifier = Modifier.width(100.vw).height(100.vh).displayUntil(Breakpoint.MD).bg(color.background.css),
        contentAlignment = Alignment.Center
    ) {

        RegistrationScreen(
            ctx = ctx,
            viewModel = viewModel,
            views = viewStateFlow,
            modifier =
            Modifier
                .opacity(0)
                .scale(0)
                .thenIf(viewStateFlow == LoginViews.REGISTER, Modifier.opacity(1).scale(1))
                .animate(prop = listOf("opacity", "scale"))
        )

        LoginScreen(
            ctx = ctx,
            viewModel = viewModel,
            modifier =
            Modifier.opacity(0)
                .scale(0)
                .thenIf(viewStateFlow == LoginViews.LOGIN, Modifier.opacity(1).scale(1))
                .animate(prop = listOf("opacity", "scale"))
        )

    }




    LaunchedEffect(viewStateFlow) {
       // console.log("viewStateFlow::$viewStateFlow")
    }
}
