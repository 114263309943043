package org.jjoy.ltd.pages

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.home.JobSection
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID
import org.jjoy.ltd.util.const.MetaConst
import org.jjoy.ltd.util.const.PageRoutes

@Page("/search/jobs/{query}/{category}")
@Composable
fun SearchJobs(ctx: PageContext = rememberPageContext()) {

    LaunchedEffect(Unit){
        document.title = MetaConst.JobsTitle
        setMetaDescription(MetaConst.JobsDescription)
    }

    val breakpoint = rememberBreakpoint()
    val query = ctx.route.params.getValue("query")
    val category = ctx.route.params.getValue("category")
    val viewModel = rememberViewModel {
        MainViewModel(query = query.ifBlank { "all" }, category = category.ifBlank { "all" })
    }
    val state by viewModel.collectState()

    val userId = localStorage.getItem(USER_ID)

    FullPageWrapper(
        title = "Search Jobs",
    ) {
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
            Image(
                modifier =
                    Modifier.objectFit(ObjectFit.Cover)
                        .borderRadius(
                            bottomLeft = dimension.mediumRoundness,
                            bottomRight = dimension.mediumRoundness
                        )
                        .width(100.vw)
                        .height(48.vh),
                src = "/search_header.jpg"
            )
            JobSection(
                modifier =
                    Modifier.margin(
                        top =
                            if (breakpoint <= Breakpoint.MD) dimension.extraLargePadding * 4.5
                            else dimension.extraLargePadding * 3
                    ),
                state = state,
                onApply = {
                    userId?.let { id ->
                        ctx.router.navigateTo(
                            "${PageRoutes.JOB_DETAILS}/${it.id}/${it.userApplied}"
                        )
                    } ?: run {
                        ctx.router.navigateTo("/")
                        window.location.hash = "register"
                    }
                },
                // TODO: Param
                onSearch = { search, category ->
                    viewModel.onActionEvent(MainActionEvent.SearchJobs(search, category))
                }
            )
        }
    }
}
