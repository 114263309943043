package org.jjoy.ltd.presentation.screen.job

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rounded

@Composable
fun JobDetailsIcon(
    iconName: String = "user-nurse",
    size: CSSSizeValue<CSSUnit.rem> = dimension.extraLargeText,
    modifier: Modifier = Modifier
){
    Box(
        modifier =
        Modifier.backgroundColor(color.primary.css)
            .rounded()
            .boxShadow(
                offsetY = 1.px,
                offsetX = 1.px,
                blurRadius = 0.90.cssRem,
                spreadRadius = 0.5.cssRem,
                color = color.primary.css(10)
            )
            .padding(dimension.mediumPadding),
        contentAlignment = Alignment.Center
    ) {
        FaIcon(
            modifier = Modifier
                .margin(
                    left = dimension.smallPadding * 2.5,
                    right = dimension.smallPadding * 2.5,
                    bottom = dimension.smallPadding,
                    top = dimension.smallPadding
                ).fontSize(dimension.largeText)
                .color(color.onPrimary.css),
            name = iconName,
            style = IconCategory.SOLID,
        )
    }
}