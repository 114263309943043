package org.jjoy.ltd.data.repositoryImpl

import org.jjoy.ltd.core.domain.model.job.JobAppDto
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.domain.model.user.UserDto
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.AdminApi
import org.jjoy.ltd.domain.repository.AdminRepository
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.const.EndPoints

class AdminRepositoryImpl() : AdminRepository {


    private val adminApi by lazy { AdminApi().baseUrl(Constants.API_BASE_URL).build() }


    override suspend fun getAllJobs(): ApiFlow<List<JobListItem>> {
        return apiFlowResponse<List<JobListItem>> {
            adminApi.fetch(path = EndPoints.GET_ALL_JOBS, method = "GET")
        }
    }

    override suspend fun searchAllJobs(query: String,category: String): ApiFlow<List<JobListItem>> {
        return apiFlowResponse<List<JobListItem>> {
            adminApi.fetch(path = "${EndPoints.SEARCH_ALL_JOBS}$query/$category", method = "GET")
        }
    }

    override suspend fun addJob(jobDTO: JobDTO): ApiFlow<String> {
        return apiFlowResponse<String> {
            adminApi.fetch(
                path = EndPoints.ADD_JOB,
                method = "POST",
                body = jobDTO.encodeToString()
            )
        }
    }

    override suspend fun updateJob(jobDTO: JobDTO): ApiFlow<String> {
        return apiFlowResponse<String> {
            adminApi.fetch(
                path = EndPoints.EDIT_JOB,
                method = "POST",
                body = jobDTO.encodeToString()
            )
        }
    }

    override suspend fun deleteJob(id: String): ApiFlow<String> {
        return apiFlowResponse<String> {
            adminApi.fetch(
                path = "${EndPoints.DELETE_JOB}$id",
                method = "POST",
            )
        }
    }

    override suspend fun getAllApplications(): ApiFlow<List<JobAppDto>> {
        TODO("Not yet implemented")
    }

    override suspend fun getAllUsers(): ApiFlow<List<UserDto>> {
        TODO("Not yet implemented")
    }
}
