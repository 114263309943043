package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css

@Composable
fun TextWithIcon(
    text: String,
    icon: String,
    modifier: Modifier = Modifier,
    style: IconCategory = IconCategory.SOLID,
    fontSize: CSSNumeric = dimension.textFieldLabelText,
    fontWeight: FontWeight = FontWeight.SemiBold,
    fontColor: CSSColorValue = color.onBackground.css(65),
    iconSize: IconSize = IconSize.XL,
    iconColor: CSSColorValue = color.primary.css(90)
){

    Row(
        modifier = modifier,
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.End
    ) {

        FaIcon(
            name = icon,
            modifier = Modifier
                .color(iconColor)
                .margin(right = dimension.normalRoundness)
                .fontSize(fontSize * 1.03),
            style = style,
        )

        SpanText(
            text = text,
            modifier =
            Modifier.fontSize(fontSize)
                .fontWeight(fontWeight)
                .color(fontColor)
        )

    }

}