package org.jjoy.ltd.presentation.screen.job

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.compose.ui.modifiers.width
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.theme.dimension

@Composable
fun JobDetailsMobileContent(
    state: JobDetailsUIState,
    onApply: TCallback<JobDetailsActionEvent>,
    modifier: Modifier = Modifier
){
    Column(
        modifier = modifier.width(90.vw).margin(top = dimension.largePadding),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        JobDetailsView(
            jobDTO = state.job ?: JobDTO.dummy(),
            state = state,
            onApply = {
                // ctx.router.navigateTo(FORM_DETAILS)
                onApply(JobDetailsActionEvent.ApplyForJob)
            }
        )
    }

}