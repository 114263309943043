package org.jjoy.ltd.presentation.components.wrapper

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun Icon(
    name: String,
    modifier: Modifier = Modifier,
    componentStyle: ComponentStyle = IconWrapperStyle,
    iconColor: CSSColorValue = color.onPrimary.css,
    style: IconCategory = IconCategory.SOLID,
    size: IconSize? = IconSize.X1,
    onClick: Callback = {}
){

    Box(
        modifier = modifier
            .then(componentStyle.toModifier())
            .onClick { onClick() },
        contentAlignment = Alignment.Center
    ){
        FaIcon(
            modifier = Modifier.color(iconColor).margin(dimension.smallPadding),
            name = name,
            style = style,
            size = size
        )
    }

}

@Composable
fun IconMain(
    name: String,
    modifier: Modifier = Modifier,
    componentStyle: ComponentStyle = IconMainStyle,
    iconColor: CSSColorValue = color.onPrimary.css,
    size: CSSSizeValue<CSSUnit.rem> = dimension.mediumText,
    onClick: Callback = {}
){

    Box(
        modifier = modifier
            .then(componentStyle.toModifier())
            .onClick { onClick() },
        contentAlignment = Alignment.Center
    ){
        FaIcon(
            modifier = Modifier
                .fontSize(size)
                .color(iconColor)
                .margin(dimension.normalPadding),
            name = name,
            style = IconCategory.SOLID,
        )
    }

}

val IconMainStyle by ComponentStyle {
    base {
        Modifier
            .borderRadius(dimension.mediumRoundness * 0.88)
            .backgroundColor(color.primary.css(80))
            .animate(prop = listOf("background-color","color","translate"))
    }

    hover {
        Modifier
            .backgroundColor(color.primary.css(99))
    }
}

val IconWrapperStyle by ComponentStyle {
    base {
        Modifier
            .borderRadius(0.3.cssRem)
            .padding(dimension.extraSmallPadding)
            .backgroundColor(color.bgGray.rgb.darkened(0.1f))
            .animate(prop = listOf("background-color","color","translate"))
    }

    hover {
        Modifier
            .backgroundColor(color.primary.rgb.darkened(0.5f))
    }
}