package org.jjoy.ltd.presentation.admin.application

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.domain.model.job.JobApplicationInfo
import org.jjoy.ltd.presentation.components.JobItemCardStyle
import org.jjoy.ltd.presentation.components.TextWithIcon
import org.jjoy.ltd.presentation.components.wrapper.PanelWrapper
import org.jjoy.ltd.presentation.composables.wrappers.ListWrapper
import org.jjoy.ltd.presentation.screen.home.FilterJobsView
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.toDateString

@Composable
fun ApplicationScreen(
    viewModel: ApplicationViewModel,
    modifier: Modifier = Modifier.fillMaxWidth(dimension.navRailContentPercent),
) {
    val ctx = rememberPageContext()
    val state by viewModel.collectState()
    // viewModel.checkFailureRetry()

    PanelWrapper {
        FilterJobsView(
            modifier = Modifier.fillMaxWidth(),
            onSearch = { search, _ ->
                viewModel.onActionEvent(ApplicationActionEvent.SearchUser(search))
            },
            title = "Latest Applications",
            searchHint = "Search Applications"
        )

        ListWrapper(
            isLoading = state.isPageLoading,
            isError = state.isError,
            isEmpty = state.isEmpty,
            errorMsg = state.errorMessage,
            list = state.applications,
            numColumns = numColumns(base = 3)
        ) {
            ApplicationListItem(
                application = it,
                onApply = {
                    // ctx.router.navigateTo(FORM_DETAILS)
                }
            )
        }
    }
}

@Composable
private fun ApplicationListItem(
    application: JobApplicationInfo,
    modifier: Modifier = Modifier,
    onApply: () -> Unit
) {

    Row(
        modifier =
            modifier.then(JobItemCardStyle.toModifier()).padding(dimension.smallPadding).onClick {
                onApply()
            },
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Start
    ) {
        Column(
            modifier =
                Modifier.margin(dimension.normalPadding)
                    .gap(dimension.extraSmallPadding)
                    .fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.Top
        ) {
            TextWithIcon(
                text = application.jobTitle,
                fontSize = dimension.smallText * 1.2,
                fontColor = color.primary.css,
                fontWeight = FontWeight.SemiBold,
                icon = "person-digging"
            )

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.Bottom
            ) {
                TextWithIcon(
                    text = application.userEmail,
                    fontSize = dimension.smallText,
                    icon = "envelope",
                    iconColor = color.primary.css(70),
                    fontColor = color.onBackground.css(80),
                )
                Spacer()
                TextWithIcon(
                    text = application.appliedAt.toDateString(),
                    fontColor = color.primary.css,
                    fontSize = dimension.smallText * 0.8,
                    icon = "clock"
                )
            }
        }
    }
}
