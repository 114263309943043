package org.jjoy.ltd.pages.jjoy

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Isolation
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import io.ktor.util.*
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.filter
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.core.domain.model.ServiceItem
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css

@Page("/service/{index}/show")
@Composable
fun ServiceDescription() {
    val ctx = rememberPageContext()
    val index = ctx.route.params.getValue("index").toInt()

    val serviceItem = servicesList[index]

    LaunchedEffect(Unit) {
        try {
            ctx.router.navigateTo("#")
        } catch (exception: Exception) {
            console.log(exception)
        }
    }

    FullPageWrapper(
        title = "services",
    ) {
        ServiceItemHeader(
            title = serviceItem.title,
            headerImage = serviceItem.header
        )
        SimpleDesktopWrapper(modifier = Modifier.displayIfAtLeast(Breakpoint.MD), variant = plainVariant) {
            Content(item = serviceItem)
        }

        SimpleMobileWrapper(
            modifier = Modifier.displayUntil(Breakpoint.MD),
            variant = plainVariant
        ) {
            Content(item = serviceItem)
        }
    }
}

val plainVariant by ComponentStyle { base { Modifier.bg() } }

@Composable
private fun ColumnScope.Content(item: ServiceItem) {
    val bkp = rememberBreakpoint()

    Column(
        modifier = Modifier.gap(dimension.mediumPadding).fillMaxWidth(),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        Text(
            modifier =
                Modifier.fontSize(dimension.smallMediumText)
                    .fontWeight(550)
                    .color(color.onBackground.css).margin(topBottom = dimension.normalPadding),
            text = item.desc
        )

        item.items.forEach {
            Row(
                modifier = Modifier.fillMaxWidth().gap(dimension.normalPadding),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaIcon(
                    name = item.icon,
                    modifier =
                        Modifier.fontSize(dimension.normalText * 0.85).color(color.primary.css),
                    style = IconCategory.SOLID
                )

                Text(
                    modifier =
                        Modifier.fontSize(dimension.normalText * 0.85)
                            .fontWeight(FontWeight.Bold)
                            .textAlign(TextAlign.Left),
                    text = it
                )
            }
        }
    }
}



@OptIn(ExperimentalComposeWebApi::class)
@Composable
private fun ColumnScope.ServiceItemHeader(
    title: String,
    headerImage: String,
    modifier: Modifier = Modifier,
){

    Box(
        modifier = modifier,
        contentAlignment = Alignment.Center
    ){
        Image(
            modifier =
            Modifier.objectFit(ObjectFit.Cover)
                .borderRadius(
                    bottomLeft = dimension.mediumRoundness,
                    bottomRight = dimension.mediumRoundness
                ).styleModifier {
                    filter {
                        grayscale(60)

                    }
                }
                .width(100.vw)
                .height(40.vh),
            src = headerImage
        )

        Text(
            modifier =
            Modifier
                .isolation(Isolation.Isolate)
                .fontSize(dimension.largeText)
                .fontWeight(800)
                .color(color.background.css(95)),
            text = title.toUpperCasePreservingASCIIRules()
        )
    }

}
