package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.sections.PatternBackground
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.*

@Composable
fun ApplicationProcess() {

    val breakpoint = rememberBreakpoint()
    ContentWrap(
        modifier =
            Modifier
                .cursor(Cursor.Default)
                .overflowWrap(OverflowWrap.BreakWord)
                .thenIf(
                    breakpoint <= Breakpoint.MD,
                    Modifier.margin(top = dimension.extraLargePadding * 1.2)
                )
                .fillMaxWidth(),
        contentId = Section.Process.id,
    ) {
        Box(
            modifier = Modifier.fillMaxSize().bg(color.primary.css),
            contentAlignment = Alignment.Center
        ) {
            PatternBackground()

            Row(
                modifier =
                    Modifier.fillMaxSize()
                        .flexDirection(
                            if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                            else FlexDirection.Row
                        )
                        .thenIf(
                            breakpoint > Breakpoint.MD,
                            Modifier.padding(dimension.largePadding)
                        ),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                Column(
                    modifier = Modifier.fillMaxWidth().thenIf(breakpoint <= Breakpoint.MD, Modifier.padding(dimension.largePadding)),
                    verticalArrangement = Arrangement.Top,
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    Text(
                        modifier =
                            Modifier.fontSize(dimension.normalText)
                                .fontWeight(600)
                                .color(color.onPrimary.css(85)),
                        text = "APPLICATION PROCESS"
                    )
                    Text(
                        modifier =
                            Modifier
                                .padding(bottom = dimension.largePadding)
                                .fontSize(dimension.mediumText * 1.1)
                                .fontWeight(700)
                                .color(color.onPrimary.css(99)),
                        text = "How It Works"
                    )

                    Row(
                        modifier =
                            Modifier
                                .gap(dimension.mediumPadding)
                                .flexDirection(
                                    if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                                    else FlexDirection.Row
                                ),
                        verticalAlignment = Alignment.CenterVertically,
                        horizontalArrangement = Arrangement.Start
                    ) {
                        ProcessSteps.values().forEach { ProcessItemCard(item = it) }
                    }
                }
            }
        }
    }
}

@Composable
fun ProcessItemCard(item: ProcessSteps, modifier: Modifier = Modifier) {

    Column(
        modifier =
            Modifier.smoothBlur()
                .rounded()
                .gap(dimension.normalPadding)
                .bg(color.primaryContainer.css)
                .width(dimension.stepsCard)
                .height(dimension.stepsCard * 0.8)
                .padding(dimension.normalPadding)
                .then(stepsCardStyle.toModifier()),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {

        FaIcon(
            modifier = Modifier
                .padding(dimension.mediumPadding)
                .color(color.onPrimaryContainer.css)
                .fontSize(dimension.largeText),
            name = item.icon,
            style = IconCategory.SOLID,
        )

        Text(
            modifier =
                Modifier.fontSize(dimension.normalText)
                    .fontWeight(550)
                    .textAlign(TextAlign.Center)
                    .color(color.onPrimaryContainer.css(95)),
            text = item.title
        )

        Text(
            modifier =
                Modifier
                    .fillMaxWidth(65.percent)
                    .fontSize(dimension.smallText)
                    .fontWeight(480)
                    .textAlign(TextAlign.Center)
                    .color(color.onPrimaryContainer.css(70)),
            text = item.desc
        )
    }
}

val stepsCardStyle by ComponentStyle {
    base { Modifier.animate(prop = listOf("color", "scale", "background-color", "translate")) }
    hover { Modifier.translateY((-3).percent).scale(1.03) }
}

enum class ProcessSteps(val icon: String, val title: String, val desc: String) {
    STEP1(
        icon = "magnifying-glass",
        title = "1. Search A Job",
        desc = "Simply search the job you wish to apply for",
    ),
    STEP2(
        icon = "user-tie",
        title = "2. Apply For A Job",
        desc = "Read through the job specification and apply",
    ),
    STEP3(
        icon = "handshake-simple",
        title = "3. Get Your Job",
        desc = "We will talk to our client and arrange the interview and start date.",
    )
}
