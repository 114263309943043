package org.jjoy.ltd.core.util

import io.ktor.utils.io.charsets.*
import io.ktor.utils.io.core.*
import kotlinx.serialization.*
import kotlinx.serialization.builtins.serializer
import kotlinx.serialization.descriptors.SerialDescriptor
import kotlinx.serialization.descriptors.buildClassSerialDescriptor
import kotlinx.serialization.encoding.*
import kotlinx.serialization.json.Json
import org.jjoy.ltd.core.data.model.BaseResponse

val json = Json { ignoreUnknownKeys = true }
inline fun <reified T> T.encode(serializer: SerializationStrategy<T> = serializer()): ByteArray {
    return json.encodeToString<T>(serializer =  serializer,this).toByteArray(Charset.forName("utf-8"))
}
inline fun <reified T> T.encode(): ByteArray {
    val jsonString = json.encodeToString<T>(this)
    return jsonString.toByteArray(Charsets.UTF_8)
}

inline fun <reified T> ByteArray.decode(): T {
    val decodedJsonString = String(this, charset = Charsets.UTF_8)
    return json.decodeFromString<T>(decodedJsonString)
}

inline fun <reified T> ByteArray.decodeT(deserializer: DeserializationStrategy<T>): T {
    val decodedJsonString = String(this, charset = Charsets.UTF_8)
    return json.decodeFromString(deserializer, decodedJsonString)
}

inline fun <reified T> T.encodeToString(): String {
    return json.encodeToString<T>(this)
}
inline fun <reified T> T.toBody(): String {
    return json.encodeToString<T>(this)
}
inline fun <reified T> String.decode(): T {
    return json.decodeFromString<T>(this)
}








class BaseSerializer<T>(private val dataSerializer: KSerializer<T>) : KSerializer<BaseResponse<T>> {
    override val descriptor: SerialDescriptor = buildClassSerialDescriptor("BaseResponse") {
        element("success", Boolean.serializer().descriptor)
        element("response", dataSerializer.descriptor)
    }

    override fun serialize(encoder: Encoder, value: BaseResponse<T>) {
        encoder.encodeStructure(descriptor) {
            encodeBooleanElement(descriptor, 0, value.success)
            encodeSerializableElement(descriptor, 1, dataSerializer, value.response)
        }
    }

    override fun deserialize(decoder: Decoder): BaseResponse<T> {
        return decoder.decodeStructure(descriptor) {
            var success: Boolean? = null
            var response: T? = null

            loop@ while (true) {
                when (val i = decodeElementIndex(descriptor)) {
                    0 -> success = decodeBooleanElement(descriptor, i)
                    1 -> response = decodeSerializableElement(descriptor, i, dataSerializer)
                    CompositeDecoder.DECODE_DONE -> break@loop
                    else -> throw SerializationException("Unknown index $i")
                }
            }

            BaseResponse(success!!, response!!)
        }
    }
}