package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.borderRadius
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.cssRem
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.util.extension.css

@Composable
fun SmallDivider(
    modifier: Modifier = Modifier,
    divColor: CSSColorValue = color.onBackground.css(20),
) {
    Box(modifier = modifier.backgroundColor(divColor).borderRadius(0.1.cssRem))
}

val DividerStyle by ComponentStyle { base { Modifier.width(0.1.cssRem) } }
