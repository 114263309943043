package org.jjoy.ltd.presentation.admin.jobs.edit

import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.presentation.admin.jobs.SalaryType

sealed interface JobEditActionEvent: ActionEvent {
    data class TitleChanged(val v: String): JobEditActionEvent
    data class DescriptionChanged(val v: String): JobEditActionEvent
    data class LocationChanged(val v: String): JobEditActionEvent
    data class WorkLocationChanged(val v: String): JobEditActionEvent
    data class RequirementsChanged(val v: List<String>): JobEditActionEvent
    data class CertificatesRequiredChanged(val v: List<String>): JobEditActionEvent

    data class JobTypeChanged(val v: JobType): JobEditActionEvent

    data class JobCategoryIdChanged(val v: String): JobEditActionEvent

    data class JobCategoryNameChanged(val v: String): JobEditActionEvent
    data class SalaryChanged(val v: String): JobEditActionEvent

    data class IsPerHourChanged(val v: SalaryType): JobEditActionEvent

    data class IsFeaturedChanged(val v: Boolean): JobEditActionEvent

    data class ExpiryAtChanged(val v: Long): JobEditActionEvent

    data class IsDeletedChanged(val v: Boolean): JobEditActionEvent

    data class IsExpiredChanged(val v: Boolean): JobEditActionEvent

    object UpdateJob: JobEditActionEvent

    object DeleteJob: JobEditActionEvent
}