package org.jjoy.ltd.presentation.screen.user_form.mobile

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import kotlinx.browser.document
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.CommonReaction
import org.jjoy.ltd.presentation.components.input.CheckBoxWithLabel
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.FourthStringEvents
import org.jjoy.ltd.presentation.screen.user_form.UserFormActionEvent
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState
import org.jjoy.ltd.presentation.screen.user_form.components.ListCheckBoxWithTextField
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.const.Groups
import org.jjoy.ltd.util.extension.today
import org.w3c.dom.HTMLElement

@Composable
fun UserFormMobileContentFourth(
    modifier: Modifier = Modifier,
    formInputs: List<Pair<Int, String>>,
    state: UserFormUIState,
    onActionEvent: TCallback<UserFormActionEvent>
) {

    var focus by remember { mutableStateOf<Pair<Int, String>?>(null) }

    FormMobileWrapper(modifier.gap(dimension.mediumPadding)) {
        FormColumn(gap = dimension.mediumPadding) {
            CheckBoxWithLabel(
                label = "Do you consider yourself to have a disability or health condition?",
                list = CommonReaction.getStringList(),
                item = state.disability,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(
                            FourthStringEvents.DISABILITY,
                            it
                        )
                    )
                },
                error = state.errors.disability
            )
            FormTextInput(
                label =
                "What is the effect or impact of your disability or health condition on your ability to give your best at work? Please write in here",
                stateVsEvent =
                StateVsEvent(value = state.disabilityDesc) {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(
                            FourthStringEvents.DISABILITY_DESC,
                            it
                        )
                    )
                },
                singleLine = false
            )

            Text(
                modifier =
                Modifier
                    .fontSize(dimension.smallText)
                    .textAlign(TextAlign.Left),
                text =
                "The information in this form is for monitoring purposes only. If you believe you need a ‘reasonable adjustment’, then please discuss this with your manager, or the manager running the recruitment process if you are a job applicant. "
            )
        }

        FormColumn(
            modifier = Modifier.fillMaxWidth().padding(top = dimension.smallPadding),
            gap = dimension.mediumPadding
        ) {
            ListCheckBoxWithTextField(
                label = "What is your sexual orientation?",
                list = Groups.sexualOri,
                item = state.sexualOri,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(FourthStringEvents.SEXUAL_ORI, it)
                    )
                },
                error = state.errors.sexualOri,
                fieldLabel = "Any other, specify here",
                stateVsEvent =
                StateVsEvent(state.sexualOri) {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(FourthStringEvents.SEXUAL_ORI, it)
                    )
                }
            )

            ListCheckBoxWithTextField(
                label = "Religion",
                list = Groups.religions,
                item = state.religion,
                itemToName = { it ?: "ERROR" },
                onItemsPicked = {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(FourthStringEvents.RELIGION, it)
                    )
                },
                error = state.errors.religion,
                fieldLabel = "Any other, specify here",
                stateVsEvent =
                StateVsEvent(state.religion) {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(FourthStringEvents.RELIGION, it)
                    )
                }
            )
        }
        FormColumn(modifier = Modifier.fillMaxWidth(), gap = dimension.mediumPadding) {
            Text(
                modifier =
                Modifier.fontSize(dimension.smallText * 1.1)
                    .fontWeight(550)
                    .textAlign(TextAlign.Left),
                text = "Criminal Conviction"
            )

            FormTextInput(
                label =
                "Have you ever been convicted of a criminal offence which is not a spent conviction within the terms of the Rehabilitation of Offenders Act 1974? If yes, please give full details.",
                stateVsEvent =
                StateVsEvent(state.criminalConv) {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(
                            FourthStringEvents.CRIMINAL_CONV,
                            it
                        )
                    )
                },
                id = "conviction",
                singleLine = false,
                onIme = {
                    focus = formInputs[1]
                }
            )
        }

        FormColumn(modifier = Modifier.fillMaxWidth(), gap = dimension.smallPadding) {
            Text(
                modifier =
                Modifier.fontSize(dimension.smallText * 1.1)
                    .fontWeight(600)
                    .textAlign(TextAlign.Left),
                text =
                "My signature confirms that all the information given on this application form is true and complete. I understand that any falsification or deliberate omissions may disqualify my application or lead to my dismissal. I confirm that I am entitled to work in the UK & Ireland and can provide original documentation to confirm this. I understand that my employment is subject to references that are satisfactory to comity recruitment."
            )
            FormTextInput(
                label = "Name",
                id = "signature",
                isMandatory = true,
                error = state.errors.signature,
                stateVsEvent =
                StateVsEvent(state.signature) {
                    onActionEvent(
                        UserFormActionEvent.FourthDataChanged(FourthStringEvents.SIGNATURE, it)
                    )
                },
                onIme = {
                    it.clearFocus()
                }
            )
            Text(
                modifier =
                Modifier.fontSize(dimension.smallText * 1.04)
                    .fontWeight(600)
                    .textAlign(TextAlign.Left),
                text = "Date:${today()}"
            )
        }
    }

    LaunchedEffect(focus) {
        focus?.let {
            (document.getElementById(it.second) as HTMLElement).focus()
        }
    }
}
