package org.jjoy.ltd.core.data.model.auth

import kotlinx.serialization.Serializable


@Serializable
data class AuthRequest(
    val userEmail: String,
    val password: String
)
