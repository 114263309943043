package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun BigButton(
    text: String,
    onClick: () -> Unit,
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = BigButtonStyleComp,
) {

    Button(
        onClick = { onClick() },
        modifier = modifier.then(BigButtonStyle.toModifier()),
        variant = variant,
    ) {
        SpanText(
            text = text
        )
    }
}

val BigButtonStyle by ComponentStyle {
    base {
        Modifier
            .color(color.onPrimary.rgb)
            .padding(dimension.mediumPadding * 0.90)
            .fontWeight(650)
            .fontSize(dimension.mediumText * 0.90)
            .borderRadius(dimension.mediumRoundness)
            .animate(prop = listOf("color", "box-shadow", "background-color", "translate"))
    }
}

val BigButtonStyleComp by BigButtonStyle.addVariant() {
    hover {
        Modifier
            .boxShadow(
                offsetY = 1.px,
                offsetX = 1.px,
                blurRadius = dimension.smallBlurRadius,
                spreadRadius = 0.6.cssRem,
                color = color.primary.css(16)
            )
            .translateY((-3).percent)
    }
}

