package org.jjoy.ltd.presentation.screen.user_form.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.presentation.components.input.CheckBoxWithLabel
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.TCallback

@Composable
fun <T> ListCheckBoxWithTextField(
    label: String,
    fieldLabel: String,
    modifier: Modifier = Modifier,
    fieldModifier: Modifier = Modifier.fillMaxWidth(),
    isMandatory: Boolean = false,
    singleLine: Boolean = true,
    list: List<T>,
    item: T? = null,
    itemToName: (T?) -> String,
    onItemsPicked: TCallback<T>,
    stateVsEvent: StateVsEvent,
    error: FormInputError
) {
    val text = if(list.find { itemToName(it) == stateVsEvent.value } != null) "" else stateVsEvent.value

    Column(modifier = modifier.gap(dimension.smallPadding)) {
        CheckBoxWithLabel(
            label = label,
            list = list,
            itemToName = itemToName,
            onItemsPicked = onItemsPicked,
            error = error,
            item = item
        )
        FormTextInput(
            modifier = fieldModifier,
            label = fieldLabel,
            stateVsEvent = StateVsEvent(
                value = text,
                onValueChange = stateVsEvent.onValueChange
            ),
            isMandatory = isMandatory,
            singleLine = singleLine
        )
    }
}