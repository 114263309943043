package org.jjoy.ltd.presentation.admin.home

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.cssRem
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.domain.model.NavRailItem
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css

@Composable
fun NavRailItemView(
    navRailItem: NavRailItem,
    onClick: Callback,
    isActive: Boolean,
    modifier: Modifier = Modifier,
) {

    val isEnabled by remember(isActive) { mutableStateOf(isActive) }

    LaunchedEffect(navRailItem){
        //console.log("navRailItem changed >> ${navRailItem.isactive}")
    }

    Row(
        modifier =
            modifier
                .margin(top = 0.2.cssRem, bottom = 0.2.cssRem)
                .then(
                    if (isEnabled) NavRailItemStyleAlt.toModifier()
                    else NavRailItemStyle.toModifier()
                ).thenIf(isEnabled){
                    Modifier.boxShadow(
                        blurRadius = 0.35.cssRem,
                        spreadRadius = 0.25.cssRem,
                        color = color.primary.css(20)
                    )
                }
                .onClick {
                    onClick()
                },
        verticalAlignment = Alignment.CenterVertically
    ) {
        FaIcon(
            name = navRailItem.icon,
            modifier = Modifier.margin(right = 0.5.cssRem, left = 0.2.cssRem),
            size = IconSize.X1,
            style = IconCategory.SOLID
        )

        SpanText(
            text = navRailItem.itemName,
            modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(dimension.smallButtonText)
        )
    }
}

val NavRailItemStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth()
            .color(color.onBackground.css)
            .padding(dimension.normalPadding)
            .borderRadius(dimension.normalRoundness)
            .animate(prop = listOf("background-color", "color"), dur = 200)
    }

    hover { Modifier.backgroundColor(color.primary.css).color(color.onPrimary.css) }
}

val NavRailItemStyleAlt by
    ComponentStyle.base {
        Modifier.fillMaxWidth()
            .backgroundColor(color.primary.css)
            .color(color.onPrimary.css)
            .padding(dimension.normalPadding)
            .borderRadius(dimension.normalRoundness)
            .animate(prop = listOf("background-color", "color"), dur = 300)
    }
