package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.CheckboxInput
import org.jetbrains.compose.web.dom.Span
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animateColor
import org.jjoy.ltd.util.extension.css



@Composable
fun <T>ListCheckBoxWithLabel(
    label: String,
    modifier: Modifier = Modifier,
    isMandatory: Boolean = false,
    list: List<T>,
    itemToName: (T) -> String,
    onItemsPicked: TCallback<List<T>>,
    error: FormInputError
) {

    Column(
        modifier = modifier.gap(dimension.smallPadding),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        Row(
            modifier = Modifier.margin(bottom = dimension.normalRoundness),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(attrs = Modifier.verticalAlign(VerticalAlign.Middle).toAttrs()) {
                Text(
                    text = label,
                    modifier =
                    Modifier.fontSize(dimension.smallButtonText * 1.1)
                        .fontWeight(550)
                        .color(color.onBackground.css(85))
                        .thenIf(error.isError, Modifier.color(color.error.css))
                        .animateColor()
                )
                if (isMandatory) {
                    Text(
                        text = "*",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
                if (error.isError){
                    Text(
                        text = " ${error.msg}",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
            }
        }
        ListCheckBox(
            listItems = list,
            itemToLabel = itemToName,
            onItemsPicked = onItemsPicked
        )

    }
}

@Composable
fun <T>CheckBoxWithLabel(
    label: String,
    modifier: Modifier = Modifier,
    isMandatory: Boolean = false,
    list: List<T>,
    item: T? = null,
    itemToName: (T?) -> String,
    onItemsPicked: TCallback<T>,
    error: FormInputError
) {

    Column(
        modifier = modifier.gap(dimension.smallPadding),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        Row(
            modifier = Modifier.margin(bottom = dimension.normalRoundness),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(attrs = Modifier.verticalAlign(VerticalAlign.Middle).toAttrs()) {
                Text(
                    text = label,
                    modifier =
                    Modifier.fontSize(dimension.smallButtonText)
                        .fontWeight(550)
                        .color(color.onBackground.css(85))
                        .thenIf(error.isError, Modifier.color(color.error.css))
                        .animateColor()
                )
                if (isMandatory) {
                    Text(
                        text = "*",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
                if (error.isError){
                    Text(
                        text = " ${error.msg}",
                        modifier =
                        Modifier.fontSize(dimension.smallButtonText).fontWeight(550).color(color.error.css)
                    )
                }
            }
        }

        CheckBox(
            listItems = list,
            itemToLabel = itemToName,
            onItemsPicked = onItemsPicked,
            item = item
        )

    }
}



@Composable
fun <T> ListCheckBox(
    listItems: List<T> = listOf(),
    itemToLabel: (T) -> String,
    modifier: Modifier = Modifier,
    onItemsPicked: TCallback<List<T>>
) {

    val stateList = remember { mutableStateListOf<T>() }

    Column(
        modifier = modifier.gap(dimension.extraSmallPadding),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        listItems.forEach {
            CheckBoxWithLabel(
                label = itemToLabel(it),
                onChange = { bool ->
                    if (bool) {
                        stateList.add(it)
                    } else {
                        try {
                            stateList.remove(it)
                        } catch (exception: Exception) {
                            console.log("Checkbox with label::\n $exception")
                        }
                    }
                }
            )
        }
    }

    LaunchedEffect(stateList.toList()) {
        if (stateList.toList().isNotEmpty()) {
            onItemsPicked(stateList.toList())
        }
    }
}

@Composable
fun <T> CheckBox(
    listItems: List<T> = listOf(),
    itemToLabel: (T?) -> String,
    modifier: Modifier = Modifier,
    item: T? = null,
    onItemsPicked: TCallback<T>
) {

    var selectedItem by remember(item) { mutableStateOf<T?>(item) }

    Column(
        modifier = modifier.gap(dimension.extraSmallPadding),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        listItems.forEach { item ->
            CheckBoxWithLabelAlt(
                label = itemToLabel(item),
                isEnabled = item == selectedItem,
                onChange = { isChecked ->
                    selectedItem = if (isChecked) item else null

                }
            )
        }
    }

    LaunchedEffect(selectedItem) {
        selectedItem?.let(onItemsPicked)
    }

}


@Composable
fun CheckBoxWithLabel(
    label: String,
    modifier: Modifier = Modifier,
    onChange: TCallback<Boolean>
) {

    var isEnabled by remember { mutableStateOf(false) }

    Row(modifier = modifier.gap(dimension.smallPadding)) {
        CheckboxInput(
            checked = isEnabled,
            attrs =
                Modifier.size(dimension.mediumPadding).toAttrs {
                    onInput {
                        isEnabled = it.value
                        onChange(it.value)
                    }
                    style {}
                }
        )

        Text(
            text = label,
            modifier =
                Modifier.fontSize(dimension.smallText)
                    .fontWeight(550)
                    .color(color.onBackground.css(80))
        )
    }
}
@Composable
fun CheckBoxWithLabelAlt(
    label: String,
    isEnabled: Boolean = false,
    modifier: Modifier = Modifier,
    onChange: TCallback<Boolean>
) {

    Row(modifier = modifier.gap(dimension.smallPadding)) {

        CheckboxInput(
            checked = isEnabled,
            attrs =
                Modifier.size(dimension.mediumPadding).toAttrs {
                    onInput {
                        onChange(it.value)
                    }
                    style {}
                }
        )

        Text(
            text = label,
            modifier =
                Modifier.fontSize(dimension.smallText)
                    .fontWeight(550)
                    .color(color.onBackground.css(80))
        )
    }
}
