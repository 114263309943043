package org.jjoy.ltd.presentation.screen.user_login

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.presentation.components.sections.PatternBackground
import org.jjoy.ltd.presentation.components.wrapper.MainWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.const.LoginSectionID.LOGIN_VIEW
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.buttonShadow
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rounded

@Composable
fun LoginScreen(
    ctx: PageContext,
    viewModel: UserLoginViewModel,
    modifier: Modifier = Modifier.id(LOGIN_VIEW)
) {

    val state by viewModel.collectState()
    val uiEventFlow by viewModel.collectEvent()

    MainWrapper(modifier = modifier.fillMaxSize().bg(color.bgGray.css(90))) {
        PatternBackground()
        Content(
            modifier = Modifier.fillMaxSize(),
            state = state,
            onEvent = viewModel::onActionEvent
        )
    }

    uiEventFlow?.let {
        when (it) {
            is CommonUIEvent.Error -> Unit
            CommonUIEvent.Loading -> Unit
            CommonUIEvent.NavigateNext -> {
                ctx.router.navigateTo("/")
            }
            CommonUIEvent.NavigateUp -> Unit
        }
    }
}

@Composable
private fun Content(
    onEvent: TCallback<LoginActionEvent>,
    state: LoginUIState,
    modifier: Modifier = Modifier
) {

    Box(
        modifier = modifier
            .displayIfAtLeast(Breakpoint.MD)
            .padding(dimension.largePadding * 2),
        contentAlignment = Alignment.Center
    ) {
        LoginForm(
            modifier =
                Modifier.backgroundColor(Colors.White)
                    .buttonShadow()
                    .rounded()
                    .width(40.percent),
            isLoading = state.isLoading,
            error = state.error,
            isAlert = state.isError,
            userEmailStateVsEvent =
                StateVsEvent(state.userEmail) { onEvent(LoginActionEvent.UserEmailChanged(it)) },
            userPasswordStateVsEvent =
                StateVsEvent(state.password) { onEvent(LoginActionEvent.UserPasswordChanged(it)) },
            onSubmit = { onEvent(LoginActionEvent.Login) },
            onForgot = { onEvent(LoginActionEvent.OnForgot) },
            onViewChange = { onEvent(LoginActionEvent.SwitchView(it)) },
        )
    }

    LoginFormMobile(
        modifier =
        Modifier.backgroundColor(Colors.White)
            .fillMaxSize()
            .displayUntil(Breakpoint.MD),
        isLoading = state.isLoading,
        error = state.error,
        isAlert = state.isError,
        userEmailStateVsEvent =
        StateVsEvent(state.userEmail) { onEvent(LoginActionEvent.UserEmailChanged(it)) },
        userPasswordStateVsEvent =
        StateVsEvent(state.password) { onEvent(LoginActionEvent.UserPasswordChanged(it)) },
        onSubmit = { onEvent(LoginActionEvent.Login) },
        onForgot = { onEvent(LoginActionEvent.OnForgot) },
        onViewChange = { onEvent(LoginActionEvent.SwitchView(it)) },
    )

    LaunchedEffect(state.isError) {
        if (state.isError) {
            delay(2000)
            onEvent(LoginActionEvent.ResetError)
        }
    }
}
