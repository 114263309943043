package org.jjoy.ltd.core.base

import kotlinx.coroutines.channels.Channel
import kotlinx.coroutines.flow.*

interface UIState

interface UIEvent

interface ActionEvent

/**
 * BaseViewModel is an abstract class that provides the basic structure and functionality for
 * ViewModels in the application. It provides an optional Timber logger, an initial UIState, and
 * handles UI Actions. It also manages the UIState, UIEvent, and ActionEvent flows, and provides
 * methods for updating the UIState and sending UIEvents and ActionEvents.
 *
 * @param State: The type of UIState for the screen
 * @param Event: The type of UIEvent for the screen
 * @param Action: The type of ActionEvent for the screen
 */
abstract class BaseViewModel<State : UIState, Event : UIEvent, Action : ActionEvent>() :
    CoroutineHandler() {

    /** Initial UIState for the screen * */
    private val initialState: State by lazy { initialState() }

    /**
     * Returns the initial state for the ViewModel. Must be implemented by the subclass.
     *
     * @return The initial UIState for the screen
     */
    abstract fun initialState(): State

    /** Handles UI Actions */
    abstract fun onActionEvent(action: Action)

    private val _uiState = MutableStateFlow(initialState)
    val uiState: StateFlow<State> = _uiState.asStateFlow()

    private val _uiEventFlow = Channel<Event>()
    val uiEvent = _uiEventFlow.receiveAsFlow()

    private val _uiActionEvent = Channel<Action>()
    val uiActionEvent = _uiActionEvent.receiveAsFlow()

    init {
        collectActionEvent()
    }

    /**
     * Returns the current UIState.
     *
     * @return The current UIState for the screen
     */
    protected val currentState: State
        get() = uiState.value

    /**
     * Updates the UIState with the provided function.
     *
     * @param updatedState: A function that takes in the current UIState and returns an updated
     *   UIState
     */
    protected fun update(updatedState: State.() -> State) = _uiState.update(updatedState)

    /**
     * Sends a UIEvent.
     *
     * @param uiEventFlow: The UIEvent to be sent
     */
    protected fun sendUIEvent(uiEventFlow: Event) {
        onIO { _uiEventFlow.send(uiEventFlow) }
    }

    /**
     * Sends an ActionEvent.
     *
     * @param action: The ActionEvent to be sent
     */
    fun sendActionEvent(action: Action) {
        onIO { _uiActionEvent.send(action) }
    }

    /** Collects UI Actions */
    private fun collectActionEvent() {
        onIO { uiActionEvent.collectLatest { onActionEvent(it) } }
    }

    /** Closes the UIEventFlow channel. */
    fun closeUIEventFlow() {
        _uiEventFlow.close()
    }
}
