package org.jjoy.ltd.presentation.composables

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Color.Companion.argb
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.navigation.OpenLinkStrategy
import com.varabyte.kobweb.silk.components.icons.fa.FaXmark
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.NavigationItemStyle
import org.jjoy.ltd.util.const.LocalStorageConst

@Composable
fun OverflowMenu(onMenuClosed: () -> Unit) {
    val scope = rememberCoroutineScope()
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    var translateX by remember { mutableStateOf((-100).percent) }
    var opacity by remember { mutableStateOf(0.percent) }

    val isHome = remember { ctx.route.path == "/" || ctx.route.path == "/homeAlt" }

    var userId by remember { mutableStateOf<String?>(null) }



    LaunchedEffect(Unit) {
        userId = window.localStorage.getItem(LocalStorageConst.USER_ID)
    }


    LaunchedEffect(breakpoint) {
        translateX = 0.percent
        opacity = 100.percent
        if(breakpoint > Breakpoint.MD) {
            scope.launch {
                translateX = (-100).percent
                opacity = 0.percent
                delay(500)
                onMenuClosed()
            }
        }
    }

    Column(
        modifier = Modifier
            .fillMaxWidth()
            .height(100.vh)
            .position(Position.Fixed)
            .zIndex(2)
            .opacity(opacity)
            .backgroundColor(argb(a = 0.5f, r = 0.0f, g = 0.0f, b = 0.0f))
            .transition(CSSTransition(property = "opacity", duration = 500.ms))
    ) {
        Column(
            modifier = Modifier
                .fillMaxHeight()
                .padding(all = 25.px)
                .width(if (breakpoint < Breakpoint.MD) 50.percent else 25.percent)
                .overflow(Overflow.Auto)
                .scrollBehavior(ScrollBehavior.Smooth)
                .backgroundColor(Colors.White)
                .translateX(tx = translateX)
                .transition(CSSTransition(property = "translate", duration = 500.ms))
        ) {
            Row(
                modifier = Modifier.margin(bottom = 25.px),
                verticalAlignment = Alignment.CenterVertically
            ) {
                FaXmark(
                    modifier = Modifier
                        .cursor(Cursor.Pointer)
                        .margin(right = 20.px)
                        .onClick {
                            scope.launch {
                                translateX = (-100).percent
                                opacity = 0.percent
                                delay(500)
                                onMenuClosed()
                            }
                        },
                    size = IconSize.LG
                )

            }


            Column(
                modifier = Modifier
                    .gap(dimension.smallPadding * 1.3)
                    .margin(left = dimension.normalPadding),
                verticalArrangement = Arrangement.Top,
                horizontalAlignment = Alignment.Start,
            ) {

                NavRailLink(
                    text = "Home",
                    path = "/",
                    onClick = {
                        scope.launch {
                            translateX = (-100).percent
                            opacity = 0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                )
                NavRailLink(
                    text = "Jobs",
                    path = "/search/jobs/all/all",
                    onClick = {
                        scope.launch {
                            translateX = (-100).percent
                            opacity = 0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                )

                NavRailLink(
                    text = "About Us",
                    path = "/aboutUs",
                    onClick = {
                        scope.launch {
                            translateX = (-100).percent
                            opacity = 0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                )
                NavRailLink(
                    text = "Contact Us",
                    path = "/about/contactUs",
                    onClick = {
                        scope.launch {
                            translateX = (-100).percent
                            opacity = 0.percent
                            delay(500)
                            onMenuClosed()
                        }
                    }
                )


            }

        }
    }
}


@Composable
private fun NavRailLink(
    path: String,
    text: String,
    onClick: () -> Unit,
){
    Link(
        modifier = NavigationItemStyle.toModifier()
            .margin(bottom = dimension.normalPadding)
            .fontSize(dimension.normalText)
            .fontWeight(FontWeight.Medium)
            .textDecorationLine(TextDecorationLine.None)
            .onClick {
                onClick()
            },
        path = path,
        text = text,
        openInternalLinksStrategy = OpenLinkStrategy.IN_PLACE
    )
}