package org.jjoy.ltd.presentation.screen.user_form

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.UKWorkDocument
import org.jjoy.ltd.core.domain.model.WorkingArrangement
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.presentation.screen.user_form.error_handler.FormErrorField

data class UserFormUIState(
    val isFormUpdating: Boolean,
    val isError: Boolean,
    val formSection: UserFormSection,
    val firstName: String,
    val middleName: String,
    val lastName: String,
    val address: String,
    val postcode: String,
    val telephone: String,
    val dateOfBirth: String,
    val nationalInsuranceNumber: String,
    val isFileUploading: Boolean,
    val ukWorkDocument: List<UKWorkDocument>,
    val preferredJobType: JobType,
    val errors: FormErrorField,

    val arrangements: List<WorkingArrangement>,
    val roles: String,
    val qualities: String,
    val relocate: String,
    val ukDrivingLicence: String,
    val mopedScooter: String,
    val cbt: String,

    val gender: String,
    val relationshipStatus: String,
    val ageGroup: String,
    val ethinic: String,
    val race: String,
    val black: String,
    val asian: String,
    val mixed: String,

    val disability: String,
    val disabilityDesc: String,
    val sexualOri: String,
    val religion: String,
    val criminalConv: String,
    val signature: String,

    val p2cName: String,
    val p2cRelation: String,
    val p2cTele: String,
    val p2cAddress: String,
    val p2cEmail: String,
    val p2cPersonName: String,
    val white: String,
    val other: String,

    ) : UIState