package org.jjoy.ltd.util.const

object LocalStorageConst {
    const val USER_ID = "user_id"
    const val USER_FULL_NAME = "user_name"
    const val IS_LOGGED_IN = "is_logged_in"

    const val JOB_DETAIL = "job_detail"

    const val BREAK_POINT = "BREAK_POINT"

    const val REQ = "requirements"
    const val CERT = "certificates"

    const val TOKEN = "admin_token"
}