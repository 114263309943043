package org.jjoy.ltd.pages.jjoy.form

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import kotlinx.browser.localStorage
import org.jjoy.ltd.presentation.screen.user_form.UserFormScreen
import org.jjoy.ltd.presentation.screen.user_form.UserFormViewModel
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID

@Page("/form/update")
@Composable
fun UserForm(){
    val ctx = rememberPageContext()
    val viewModel = rememberViewModel { UserFormViewModel() }

    val userId = localStorage.getItem(USER_ID) ?: run {
        ctx.router.navigateTo("/login")
    }

    UserFormScreen(
        ctx = ctx,
        viewModel = viewModel,
    )

}