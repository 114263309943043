package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.sections.PatternBackground
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.dimensionMd
import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME
import org.jjoy.ltd.util.const.Constants.WELCOME_DESC
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.smoothBlur

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun Welcome() {
    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    ContentWrap(
        modifier =
            Modifier.overflowWrap(OverflowWrap.BreakWord)
                .thenIf(
                    breakpoint <= Breakpoint.MD,
                    Modifier.margin(top = dimension.extraLargePadding * dimensionMd.smallButtonText.value)
                ).cursor(Cursor.Pointer)
                .fillMaxWidth(),
        contentId = Section.Welcome.id,
    ) {
        Box(
            modifier = Modifier
                .fillMaxSize()
                .bg(color = color.bgGray.css(85)),
            contentAlignment = Alignment.Center
        ) {
            PatternBackground()
            Row(
                modifier =
                    Modifier
                        .fillMaxSize()
                        .flexDirection(
                            if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                            else FlexDirection.Row
                        ),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Center
            ) {
                Row(
                    modifier =
                        Modifier.thenIf(
                                breakpoint <= Breakpoint.LG,
                                Modifier.margin( top = dimension.largePadding)
                                    .padding(dimension.largePadding)
                            )
                            .fillMaxWidth(
                                if (breakpoint <= Breakpoint.MD) 100.percent else 80.percent
                            )
                            .flexDirection(
                                if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                                else FlexDirection.Row
                            ),
                    verticalAlignment = Alignment.Top,
                    horizontalArrangement = Arrangement.Center
                ) {
                    Column(
                        modifier =
                            Modifier
                                .thenIf(breakpoint > Breakpoint.MD,Modifier.padding(right = dimension.mediumPadding))
                                .fillMaxWidth(
                                    if (breakpoint <= Breakpoint.MD) 100.percent else 45.percent
                                )
                                .flexDirection(FlexDirection.Column),
                        verticalArrangement = Arrangement.Top,
                        horizontalAlignment = Alignment.Start
                    ) {
                        Text(
                            modifier =
                                Modifier.fontWeight(600)
                                    .textAlign(TextAlign.Left)
                                    .color(color = color.primary.css(95))
                                    .fontSize(dimension.mediumText * 0.9),
                            text = "Welcome to $WEBSITE_NAME"
                        )
                        Text(
                            modifier =
                                Modifier.fontWeight(520)
                                    .textAlign(TextAlign.Left)
                                    .color(color = color.onSurfaceVariant.css(95))
                                    .fontSize(dimension.mediumText * 0.55),
                            text = "SOLUTIONS FOR HIRING GIVING LIFE TO YOUR OBJECTIVES AND GOALS"
                        )

                        Text(text = "   ")

                        Column {
                            WELCOME_DESC.split("\n").forEach { line ->
                                Text(
                                    modifier =
                                        Modifier.fontWeight(550)
                                            .textAlign(TextAlign.Left)
                                            .color(color = color.onSurfaceVariant.css(70))
                                            .fontSize(dimension.normalText * 0.65),
                                    text = line
                                )
                            }
                        }
                        Text(text = "   ")
                    }

                    Column(
                        modifier =
                            Modifier.gap(dimension.normalPadding * 1.3)
                                .thenIf(
                                    breakpoint <= Breakpoint.MD,
                                    Modifier.padding(top = dimension.normalPadding)
                                )
                                .fillMaxWidth(
                                    if (breakpoint <= Breakpoint.MD) 100.percent else 45.percent
                                )
                                .flexDirection(FlexDirection.Column),
                        verticalArrangement = Arrangement.Top,
                        horizontalAlignment = Alignment.Start
                    ) {
                        Image(
                            src = "/brfcse.jpg",
                            modifier =
                                Modifier.smoothBlur()
                                    .fillMaxWidth()
                                    .fillMaxHeight(
                                        if (breakpoint <= Breakpoint.MD) 100.percent else 54.percent
                                    )
                                    .borderRadius(dimension.mediumRoundness)
                                    .objectFit(ObjectFit.Cover),
                            desc = "     "
                        )

                        NormalButton(
                            modifier = Modifier.fillMaxWidth(),
                            text = "Learn More",
                            onClick = { ctx.router.navigateTo("/aboutUs") }
                        )
                    }
                }
            }
        }
    }
}
