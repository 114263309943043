package org.jjoy.ltd.presentation.screen.user_form

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIf
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import org.jetbrains.compose.web.css.CSSNumeric
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jjoy.ltd.components.widgets.buttons.BaseButtonStyle
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.UserFormSection.*
import org.jjoy.ltd.presentation.screen.user_form.desktop.*
import org.jjoy.ltd.presentation.screen.user_form.mobile.*
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.const.JobSectionID
import org.jjoy.ltd.util.extension.rgb

@Composable
fun UserFormScreen(
    ctx: PageContext,
    viewModel: UserFormViewModel,
    modifier: Modifier = Modifier.id(JobSectionID.USER_FORMS)
) {

    val state by viewModel.collectState()
    val uiEvent by viewModel.collectEvent()
    val breakpoint = rememberBreakpoint()

    val formInputsFirst =
        listOf(
            1 to "first_name",
            2 to "middle_name",
            3 to "last_name",
            4 to "address",
            5 to "post_code",
            6 to "ni",
            7 to "telephone",
            8 to "dob"
        )

    val formInputsSecond = listOf(1 to "roles", 2 to "qualities")

    val formInputsFourth = listOf(1 to "conviction", 2 to "signature")

    val formInputsFinal =
        listOf(
            1 to "person_to_contact",
            2 to "relation_to_you",
            3 to "tele",
            4 to "home_address",
            5 to "email_address",
            6 to "person_name"
        )

    LaunchedEffect(state.isError) {
        if (state.isError){
            window.alert("Could not Update your details, please try again later")
        }
    }





    FullPageWrapper("Registration Form", isLoading = state.isFormUpdating) {
        PageSimpleTitle(
            textMain = "Finish Registration",
            textDesc =
                "" // "please provide the required details, This is necessary to proceed your job
            // applications"
        )

        when (state.formSection) {
            FIRST -> {
                UserFormDesktopContent(
                    modifier = Modifier.displayIf(Breakpoint.MD),
                    formInputs = formInputsFirst,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
                UserFormMobileContent(
                    modifier = Modifier.displayUntil(Breakpoint.MD),
                    formInputs = formInputsFirst,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
            }
            SECOND -> {

                UserFormDesktopContentSecond(
                    modifier = Modifier.displayIf(Breakpoint.MD),
                    formInputs = formInputsSecond,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
                UserFormMobileContentSecond(
                    modifier = Modifier.displayUntil(Breakpoint.MD),
                    formInputs = formInputsSecond,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
            }
            THIRD -> {
                UserFormDesktopContentThird(
                    modifier = Modifier.displayIf(Breakpoint.MD),
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
                UserFormMobileContentThird(
                    modifier = Modifier.displayUntil(Breakpoint.MD),
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
            }
            FOURTH -> {
                UserFormDesktopContentFourth(
                    modifier = Modifier.displayIf(Breakpoint.MD),
                    formInputs = formInputsFourth,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
                UserFormMobileContentFourth(
                    modifier = Modifier.displayUntil(Breakpoint.MD),
                    formInputs = formInputsFourth,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
            }
            FINAL -> {
                UserFormDesktopContentFinal(
                    modifier = Modifier.displayIf(Breakpoint.MD),
                    formInputs = formInputsFinal,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )

                UserFormMobileContentFinal(
                    modifier = Modifier.displayUntil(Breakpoint.MD),
                    formInputs = formInputsFinal,
                    state = state,
                    onActionEvent = viewModel::onActionEvent
                )
            }
        }

        FormRow(
            Modifier.fillMaxWidth(80.percent).gap(0.85.cssRem).margin(top = dimension.normalPadding)
        ) {
            if (state.formSection != FIRST) {
                NormalButton(
                    text = "  PREV  ",
                    onClick = { viewModel.onActionEvent(UserFormActionEvent.SectionGoBack) }
                )
            }
            Spacer()
            NormalButton(
                text = if (state.formSection != FINAL) "  NEXT  " else "  FINISH  ",
                onClick = {
                    viewModel.onActionEvent(
                        if (state.formSection == FINAL) {
                            UserFormActionEvent.SectionChange(
                                state.formSection.order.toUserFormEnumNext(0) ?: FIRST
                            )
                        } else {
                            UserFormActionEvent.SectionChange(
                                state.formSection.order.toUserFormEnumNext(1) ?: FIRST
                            )
                        }
                    )
                },
                variant = pageSwitchButton
            )
        }
    }

    LaunchedEffect(state.errors) {
        if (
            state.errors.hasErrors() ||
                state.errors.hasErrors2() ||
                state.errors.hasErrors3() ||
                state.errors.hasErrors4() ||
                state.errors.hasErrors5()
        ) {
            window.alert("Please fill required fields to proceed")
        }
    }

    uiEvent?.let {
        when (it) {
            is CommonUIEvent.NavigateNext -> {
                window.alert("Successfully Updated your details")
                ctx.router.navigateTo("/")
            }
            else -> Unit
        }
    }
}

val pageSwitchButton by
    BaseButtonStyle.addVariant {
        base {
            Modifier.fontWeight(FontWeight.Bold)
                .fontSize(dimension.normalText)
                .transition(CSSTransition(property = "color"))
        }
        hover { Modifier.backgroundColor(color.primary.rgb.darkened()) }
    }

enum class UserFormSection(val order: Int) {
    FIRST(1),
    SECOND(2),
    THIRD(3),
    FOURTH(4),
    FINAL(5)
}

fun Int.toUserFormEnumPrev(diff: Int = 1): UserFormSection? {
    return UserFormSection.values().find { it.order == this.minus(diff) }
}

fun Int.toUserFormEnumNext(plus: Int = 1): UserFormSection? {
    return UserFormSection.values().find { it.order == this.plus(plus) }
}

@Composable
fun ColumnScope.FormRow(
    modifier: Modifier = Modifier.fillMaxWidth().gap(dimension.normalPadding),
    margin: CSSNumeric = 0.1.cssRem,
    horizontalArrangement: Arrangement.Horizontal = Arrangement.Center,
    verticalAlignment: Alignment.Vertical = Alignment.CenterVertically,
    content: @Composable RowScope.() -> Unit
) {
    Row(
        modifier = modifier.margin(margin),
        verticalAlignment = verticalAlignment,
        horizontalArrangement = horizontalArrangement
    ) {
        content(this)
    }
}

@Composable
fun ColumnScope.FormColumn(
    modifier: Modifier = Modifier.fillMaxWidth(),
    margin: CSSNumeric = 0.cssRem,
    gap: CSSNumeric = dimension.outlineWidthSmall,
    horizontalAlignment: Alignment.Horizontal = Alignment.Start,
    content: @Composable ColumnScope.() -> Unit
) {
    Column(
        modifier = modifier.margin(margin).gap(gap),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = horizontalAlignment
    ) {
        content(this)
    }
}

@Composable fun FormRegistrationMain() {}
