package org.jjoy.ltd.presentation.admin.jobs.edit

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.presentation.admin.jobs.JobsDashUIErrors
import org.jjoy.ltd.presentation.admin.jobs.SalaryType

data class JobEditUIState(
    val isJobLoading: Boolean,
    val isLoading: Boolean,
    val isError: Boolean,
    val errorMsg: String,
    val errors: JobsDashUIErrors = JobsDashUIErrors(),

    val id: String,
    val title: String,
    val description: String,
    val location: String,
    val workLocation: String,
    val requirements: List<String>,
    val certificatesRequired: List<String>,
    val jobType: JobType,
    val jobCategoryId: String,
    val jobCategoryName: String,
    val salary: String,
    val isPerHour: SalaryType = SalaryType.HOURLY,
    val isFeatured: Boolean = false,
    val postedAt: Long,
    val expiryAt: Long,
    val isDeleted: Boolean = false,
    val isExpired: Boolean = false,
): UIState
