package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Input
import org.jjoy.ltd.core.domain.model.ServiceItem
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.pages.MainUIState
import org.jjoy.ltd.presentation.components.JobItemCard
import org.jjoy.ltd.presentation.components.JobItemCardMobile
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.widgets.ModernDropDown
import org.jjoy.ltd.presentation.composables.wrappers.ListWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.*
import org.jjoy.ltd.util.res.ResourceString
import org.w3c.dom.HTMLElement
import org.w3c.dom.SMOOTH
import org.w3c.dom.ScrollIntoViewOptions

@Composable
fun JobSection(
    state: MainUIState,
    onSearch: (search: String, category: String) -> Unit,
    onApply: TCallback<JobListItem>,
    modifier: Modifier = Modifier
) {
    val breakpoint = rememberBreakpoint()

    Column(
        modifier
            .bg(color.background.css(35))
            .rounded()
            .gap(dimension.mediumPadding)
            .width(
                when (breakpoint) {
                    Breakpoint.ZERO -> 99.percent
                    Breakpoint.SM -> 98.percent
                    Breakpoint.MD -> 95.percent
                    Breakpoint.LG -> 90.percent
                    Breakpoint.XL -> 85.percent
                }
            ),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {
        FilterJobsView(
            Modifier.width(
                    when (breakpoint) {
                        Breakpoint.ZERO -> 95.percent
                        Breakpoint.SM -> 95.percent
                        Breakpoint.MD -> 95.percent
                        Breakpoint.LG -> 96.percent
                        Breakpoint.XL -> 95.percent
                    }
                )
                .padding(top = dimension.mediumPadding),
            onSearch = onSearch,
            titleColor = color.background.css,
            showCategory = true,
            selectedCategory = state.currentCategory
        )

        ListWrapper(
            isLoading = state.isLoading,
            isError = state.isError,
            isEmpty = state.jobsEmpty,
            errorMsg = state.errorMsg,
            list = state.jobs,
            modifier = Modifier.padding(dimension.mediumPadding).cursor(Cursor.Default)
        ) {
            // DashBoardJobItem(jobDTO = it, onApply = { onApply(it) })
            JobItemCard(
                modifier = Modifier.displayIfAtLeast(Breakpoint.MD),
                jobDTO = it,
                onApply = { onApply(it) }
            )

            JobItemCardMobile(
                modifier = Modifier.displayUntil(Breakpoint.MD),
                jobDTO = it,
                onApply = { onApply(it) }
            )
        }
        /*LaunchedEffect(Unit){
            window.setTimeout({
                focusAndScrollIntoView()
            }, 400)
        }*/

    }
}

@Composable
fun FilterJobsView(
    modifier: Modifier = Modifier.fillMaxWidth(),
    onSearch: (search: String, category: String) -> Unit,
    title: String = ResourceString.latest,
    showCategory: Boolean = false,
    selectedCategory: String = "All",
    titleColor: CSSColorValue = color.onBackground.css(80),
    searchHint: String = "Search Jobs"
) {
    Row(
        modifier = modifier.gap(dimension.normalPadding).cursor(Cursor.Default),
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        Row(verticalAlignment = Alignment.Bottom, horizontalArrangement = Arrangement.Start) {
            Text(
                text = title,
                modifier =
                    BaseTextStyle.toModifier()
                        .textAlign(TextAlign.Left)
                        .fontSize(dimension.normalText)
                        .fontWeight(550)
                        .color(titleColor)
            )
            /*ClickableText(
                modifier = ClickableTextStyle.toModifier().color(color.primary.css)
                    .textAlign(TextAlign.Left)
                    .fontSize(dimension.largeText)
                    .fontWeight(600),
                text = ResourceString.latestJobs
            )*/
        }

        Spacer()

        SearchJobs(
            placeHolder = searchHint,
            modifier = Modifier.displayIfAtLeast(Breakpoint.SM),
            onClick = onSearch,
            showCategory = showCategory,
            selectedCategory = selectedCategory
        )
    }
}

@Composable
fun SearchJobs(
    placeHolder: String = "Search Jobs",
    modifier: Modifier = Modifier,
    selectedCategory: String = "All",
    showCategory: Boolean = false,
    onClick: (search: String, category: String) -> Unit
) {



    var search by remember { mutableStateOf("") }
    var category by remember(selectedCategory) { mutableStateOf(js("decodeURIComponent")(selectedCategory)) }
    var isError by remember { mutableStateOf(false) }

    Row(
        modifier = modifier.then(SearchJobsStyle.toModifier()),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        var element: HTMLElement? by remember { mutableStateOf(null) }
        Input(
            type = InputType.Text,
            attrs =
                SearchJobsInputStyle.toModifier()
                    .cursor(Cursor.Text)
                    .margin(left = dimension.normalPadding)
                    .padding(dimension.extraSmallPadding)
                    .fontSize(dimension.smallText)
                    .backgroundColor(Colors.Transparent)
                    .color(color.onBackground.css)
                    .toAttrs {
                        ref {
                            element = it
                            onDispose {
                                // element = null
                            }
                        }
                        onKeyDown {
                            if (it.key == "Enter") {
                                onClick(search, category)
                            }
                        }
                        style { border(0.px) }
                        placeholder(placeHolder)
                        onInput { search = it.value }
                    }
        )

        Row(
            modifier =
                buttonStyle
                    .toModifier()
                    .onClick {
                        if (showCategory) {
                            onClick(search, category)
                        } else {
                            if (search.isEmpty()) {
                                isError = true
                            } else {
                                isError = false
                                onClick(search, category)
                            }
                        }
                    }
                    .borderRadius(dimension.normalRoundness)
                    .gap(dimension.extraSmallPadding)
                    .padding(dimension.normalPadding * 0.45)
                    .thenIf(isError, Modifier.backgroundColor(color.error.rgb))
                    .animate(prop = listOf("background-color", "color"))
                    .margin(dimension.smallPadding),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        ) {
            Text(
                modifier =
                    Modifier.color(Colors.White)
                        .fontSize(dimension.smallText)
                        .fontWeight(550),
                text = "Search "
            )
        }
        if (showCategory) {
            ModernDropDown(
                list = servicesList + ServiceItem(title = "All", icon = "", items = emptyList(), header = ""),
                itemToName = { it?.title ?: "All" },
                onItemSelected = { category = it.title },
                selectedItem = servicesList.find { it.title == category }
            )
        }
    }
}

val buttonStyle by ComponentStyle {
    base {
        Modifier.rounded()
            .backgroundColor(color.primary.css)
            .animate(prop = listOf("color", "background-color"))
    }

    hover { Modifier.backgroundColor(color.primary.rgb.darkened()) }
}

private val baseModifier =
    Modifier.backgroundColor(color.background.css)
        .borderRadius(dimension.mediumRoundness * 0.8)
        .boxShadow(
            color = color.primary.css(40),
            blurRadius = dimension.smallBlurRadius,
            spreadRadius = 1.px,
            offsetX = 2.px,
            offsetY = 2.px,
        )
        .border(dimension.divider, LineStyle.Solid, Colors.Transparent)

val SearchJobsStyle by ComponentStyle {
    base {
        baseModifier.animate(prop = listOf("box-shadow", "border", "color", "width"), dur = 600)
    }

    hover {
        baseModifier
            .boxShadow(
                color = color.primary.css(70),
                blurRadius = dimension.smallBlurRadius * 1.2,
                spreadRadius = 1.px,
                offsetX = 2.px,
                offsetY = 2.px,
            )
            .border(dimension.divider, LineStyle.Solid, color.primary.rgb)
    }
}

val SearchJobsInputStyle by ComponentStyle {
    base { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }

    hover { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }

    focus { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }
}

fun focusAndScrollIntoView() {
    val myInput = document.getElementById("test") as? HTMLElement
    myInput?.focus()
    myInput?.scrollIntoView(ScrollIntoViewOptions(behavior = org.w3c.dom.ScrollBehavior.SMOOTH))
}