package org.jjoy.ltd.presentation.screen.job

import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.core.domain.model.job.JobAppDto
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.domain.use_cases.jobs.JobsUseCases
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID
import org.kodein.di.DI
import org.kodein.di.instance

class JobDetailsViewModel(
    private val jobId: String,
    di: DI = useCasesModule,
    networkDI: DI = netWorkModule
) :
    BaseViewModel<JobDetailsUIState, CommonUIEvent, JobDetailsActionEvent>() {

    private val jobsUseCases: JobsUseCases by di.instance()

    private val apiClient: ApiClient by networkDI.instance()

    override fun initialState(): JobDetailsUIState {
        return JobDetailsUIState(
            job = null,
            itemId = "",
            isApplied = false,
            isJobApplied = false,
            isPageLoading = true,
            isChecking = true,
            isLoading = false,
            isError = false,
            errorMsg = "",
            isPageLoadingError = false,
            pageLoadingErrorMsg = "",
        )
    }

    init {
        fetchJobDetails(jobId)
        checkIfJobApplied()
    }

    override fun onActionEvent(action: JobDetailsActionEvent) {
        when (action) {
            is JobDetailsActionEvent.ApplyForJob -> applyForJob()
            is JobDetailsActionEvent.AddUserInfo -> {}
            is JobDetailsActionEvent.RestError -> update { copy(isError = false, errorMsg = "") }
            is JobDetailsActionEvent.ContactUs -> {
                launch {

                }
            } // TODO
            is JobDetailsActionEvent.FetchJobDetails -> fetchJobDetails(action.id)
            is JobDetailsActionEvent.SetIsApplied -> update { copy(isJobApplied = action.v.toBoolean()) }
        }
    }



    private fun applyForJob() {
        resetErrors()
        val userId =
            window.localStorage.getItem(USER_ID)
                ?: run {
                    update { copy(isError = false) }
                    throw NullPointerException("UserId is null")
                }
        launch {
            jobsUseCases.applyJob(
                input = JobAppDto(userId = userId, jobId = currentState.job?.id ?: "gdgd"),
                onLoading = { update { copy(isLoading = true) } },
                onFailure = {
                    update {
                        copy(
                            isLoading = false,
                            isError = true,
                            errorMsg = it ?: "Something went wrong"
                        )
                    }
                },
                onSuccess = {
                    update {
                        copy(isError = false, isLoading = false, isApplied = true, errorMsg = it)
                    }
                    sendUIEvent(CommonUIEvent.NavigateNext)
                }
            )
        }
    }

    private fun fetchJobDetails(id: String) {
        launch {
            jobsUseCases.getJob(
                input = id,
                onLoading = { update { copy(isPageLoading = true) } },
                onFailure = {
                    delay(3000)
                    update { copy(
                        isPageLoading = false,
                        isPageLoadingError = true,
                        pageLoadingErrorMsg = ""
                    ) }
                },
                onSuccess = {
                    delay(3000)
                    update { copy(
                        job = it,
                        isPageLoading = false
                    ) }
                }
            )
        }
    }

    private fun checkIfJobApplied() {
        launch {
            jobsUseCases.checkJob(
                input = jobId,
                onLoading = { update { copy(isChecking = true) } },
                onFailure = {
                    update { copy(isChecking = false,isJobApplied = false ) }
                },
                onSuccess = {
                    update { copy(isChecking = false, isJobApplied = true) }
                }
            )
        }
    }

    private fun resetErrors() {
        update { copy(isError = false, isLoading = false, isPageLoading = false, errorMsg = "") }
    }
}

data class JobDetailsUIState(
    val job: JobDTO? = null,
    val itemId: String,
    val isApplied: Boolean,
    val isJobApplied: Boolean,
    val isPageLoading: Boolean,
    val isChecking: Boolean,
    val isPageLoadingError: Boolean,
    val pageLoadingErrorMsg: String,
    val isLoading: Boolean,
    val isError: Boolean,
    val errorMsg: String
) : UIState
