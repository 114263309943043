package org.jjoy.ltd.presentation.admin.users

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jjoy.ltd.core.domain.model.user.UserInfo
import org.jjoy.ltd.presentation.components.JobItemCardStyle
import org.jjoy.ltd.presentation.components.TextWithIcon
import org.jjoy.ltd.presentation.components.wrapper.PanelWrapper
import org.jjoy.ltd.presentation.composables.wrappers.ListWrapper
import org.jjoy.ltd.presentation.screen.home.FilterJobsView
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.extension.css

@Composable
fun UsersScreen(
    viewModel: UsersViewModel,
    modifier: Modifier = Modifier.fillMaxWidth(dimension.navRailContentPercent),
) {
    val ctx = rememberPageContext()
    val state by viewModel.collectState()
    //viewModel.checkFailureRetry()

    PanelWrapper(
    ) {

        FilterJobsView(
            modifier = Modifier.fillMaxWidth(),
            onSearch = { it,_ -> viewModel.onActionEvent(UsersActionEvent.SearchUser(it)) },
            title = "ALL USERS",
            searchHint = "Search Users"
        )

        ListWrapper(
            isLoading = state.isPageLoading,
            isError = state.isError,
            isEmpty = state.isEmpty,
            errorMsg = state.errorMessage,
            list = state.users,
            numColumns = numColumns(base = 3)
        ) {
            UserItem(
                user = it,
                onApply = {
                    ctx.router.navigateTo("/admin/user/${it.id}")
                }
            )
        }
    }
}


@Composable
fun UserItem(
    user: UserInfo,
    modifier: Modifier = Modifier,
    onApply: () -> Unit
){

    Row(
        modifier =
        modifier
            .then(JobItemCardStyle.toModifier())
            .padding(dimension.smallPadding)
            .onClick { onApply() },
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Start
    ) {
        Column(
            modifier = Modifier
                .margin(dimension.normalPadding)
                .gap(dimension.extraSmallPadding).fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.Top
        ){
            TextWithIcon(
                text = user.email,
                fontSize = dimension.smallText,
                icon = "envelope"
            )

            Row(
                modifier = Modifier.fillMaxWidth(),
                horizontalArrangement = Arrangement.Start,
                verticalAlignment = Alignment.Bottom
            ){

                TextWithIcon(
                    text = user.fullName,
                    fontSize = dimension.smallText,
                    icon = "user"
                )
                Spacer()
                TextWithIcon(
                    text = user.phoneNumber,
                    fontColor = color.primary.css,
                    fontSize = dimension.smallText,
                    icon = "phone"
                )
            }

        }
    }



}