package org.jjoy.ltd.theme.style.text

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

private val textColor = color.onBackground.rgb
private val largeTextModifier =
    Modifier
        .color(textColor)
        .fontWeight(FontWeight.Black)
        .fontSize(dimension.extraLargeText)
val LargeTitleStyle by ComponentStyle {
    base { largeTextModifier }
}

val LargeTitleComp by SpanTextStyle.addVariant {
    base {
        Modifier
            .transition(CSSTransition(property = "translate,text-shadow", duration = 400.ms))
    }
    hover {
        Modifier
            .translateY((-1).px)
            .textShadow(
                offsetX = 0.px,
                offsetY = 1.px,
                blurRadius = 39.px,
                color = color.primary.css(85)
            )
    }
}
