package org.jjoy.ltd.pages.jjoy.admin.job

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import org.jjoy.ltd.presentation.admin.jobs.edit.JobEditViewModel
import org.jjoy.ltd.util.base.rememberViewModel

@Page("/admin/jobs/edit/{id}")
@Composable
fun JobEditPage(
    ctx: PageContext = rememberPageContext(),
) {
    val id = ctx.route.params["id"] ?: error("Missing id")
    val viewModel = rememberViewModel { JobEditViewModel(id) }



    /*JobEditScreen(
        viewModel = viewModel,
    )*/

}