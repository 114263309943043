package org.jjoy.ltd.presentation.components.wrapper

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.px
import org.jjoy.ltd.presentation.components.sections.MainBackground
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants

@Composable
fun ContentWrapper(
    modifier: Modifier = Modifier,
    contentId: String = "",
    columnAlignment: Alignment = Alignment.Center,
    contentVerticalArrangement: Arrangement.Vertical = Arrangement.Center,
    contentHorizontalAlignment: Alignment.Horizontal = Alignment.CenterHorizontally,
    contentPadding: CSSSizeValue<CSSUnit.rem> = dimension.largePadding,
    contentMargin: CSSSizeValue<CSSUnit.rem> = dimension.normalPadding,
    content: @Composable () -> Unit
) {
    Box(
        modifier = Modifier
            .id(contentId)
            .maxWidth(Constants.SECTION_WIDTH.px),
        contentAlignment = Alignment.Center
    ) {
        Column(
            modifier =
                modifier.align(columnAlignment).padding(contentPadding).margin(contentMargin),
            horizontalAlignment = contentHorizontalAlignment,
            verticalArrangement = contentVerticalArrangement
        ) {
            content()
        }
    }
}


@Composable
fun ContentWrap(
    modifier: Modifier = Modifier,
    contentId: String = "",
    columnModifier: Modifier =
        Modifier.fillMaxWidth(),
    contentVerticalArrangement: Arrangement.Vertical = Arrangement.Center,
    contentHorizontalAlignment: Alignment.Horizontal = Alignment.CenterHorizontally,
    content: @Composable ColumnScope.() -> Unit
) {

    Box(
        modifier = modifier.id(contentId).fillMaxWidth(),
        contentAlignment = Alignment.Center
    ) {

        MainBackground()
        Column(
            modifier = columnModifier.fillMaxSize(),
            horizontalAlignment = contentHorizontalAlignment,
            verticalArrangement = contentVerticalArrangement
        ) {
            content(this)
        }
    }
}
