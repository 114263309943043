package org.jjoy.ltd.presentation.screen.user_login

import kotlinx.coroutines.flow.MutableStateFlow
import kotlinx.coroutines.flow.StateFlow
import kotlinx.coroutines.flow.asStateFlow
import kotlinx.coroutines.flow.collectLatest
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.user.UserUseCases
import org.jjoy.ltd.util.base.launch
import org.kodein.di.instance

class UserLoginViewModel() : BaseViewModel<LoginUIState, CommonUIEvent, LoginActionEvent>() {

    private val userUseCases: UserUseCases by useCasesModule.instance()

    private val _viewState = MutableStateFlow(LoginViews.REGISTER)
    val viewState: StateFlow<LoginViews> = _viewState.asStateFlow()

    override fun initialState(): LoginUIState {
        return LoginUIState(
            userEmail = "",
            userFullName = "",
            userPhone = "",
            password = "",
            confirmPassword = "",
            error = LoginFormFieldError(),
            errorMessage = "",
            isLoading = false,
            isError = false
        )
    }


    override fun onActionEvent(action: LoginActionEvent) {
        when (action) {
            LoginActionEvent.Login -> login()
            LoginActionEvent.Register -> register()
            is LoginActionEvent.UserEmailChanged -> update { copy(userEmail = action.text) }
            is LoginActionEvent.UserPasswordChanged -> update { copy(password = action.text) }
            is LoginActionEvent.UserPasswordConfirmChanged ->
                update { copy(confirmPassword = action.text) }
            is LoginActionEvent.UserPhoneChanged -> update { copy(userPhone = action.text) }
            is LoginActionEvent.UserFullNameChanged -> update { copy(userFullName = action.text) }
            LoginActionEvent.ResetError -> update { copy(isError = false) }
            is LoginActionEvent.SwitchView -> {
                launch { _viewState.emit(action.view) }
            }
            LoginActionEvent.OnForgot -> launch { _viewState.emit(LoginViews.FORGOT) }
        }
    }

    private fun register() {
        update { copy(error = LoginFormFieldError()) }
        launch(errorHandler = { console.log("Crash >> $it") }) {
            userUseCases
                .registerUser(
                    loginUIState = currentState,
                    onSuccess = {
                        sendUIEvent(CommonUIEvent.NavigateNext)
                    }
                )
                .collectLatest { state -> update { state } }
        }
    }

    private fun login() {
        update { copy(error = LoginFormFieldError()) }
        launch(errorHandler = { console.log("Crash >> $it") }) {
            userUseCases
                .loginUser(
                    loginUIState = currentState,
                    onSuccess = {
                        sendUIEvent(CommonUIEvent.NavigateNext)
                    }
                )
                .collectLatest { state -> update { state } }
        }
    }
}
