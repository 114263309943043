package org.jjoy.ltd.core.base

import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.core.util.SCallback

abstract class BaseUseCase<Params, Return>(private val block: suspend (Params?) -> Return) {
    suspend operator fun invoke(input: Params? = null) = block(input)
}

abstract class ApiUseCase<Params, Return>(private val block: suspend (Params?) -> ApiFlow<Return>) {
    suspend operator fun invoke(
        input: Params? = null,
        onLoading: Callback = {},
        onFailure: suspend (Return?) -> Unit = {},
        onUnauthorized: SCallback = {},
        onSuccess: suspend (Return) -> Unit = {}
    ) {

        block(input).collect { state ->
            when (state) {
                is ResourceState.Error -> onFailure(state.errorData)
                is ResourceState.Loading -> onLoading()
                is ResourceState.SerializationError -> onFailure(null)
                is ResourceState.Success -> onSuccess(state.data)
                is ResourceState.UnInitialized -> Unit
                is ResourceState.UnAuthorized -> onUnauthorized()
            }
        }
    }
}
