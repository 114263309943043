package org.jjoy.ltd.presentation.admin.jobs

import kotlinx.datetime.Clock
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.presentation.composables.FormInputError
import kotlin.time.Duration.Companion.seconds

data class JobsDashUIState(
    val currentJob: JobDTO? = null,
    val isJobLoading: Boolean = true,
    val currentPage: JobPanel = JobPanel.LIST_ADD,
    val editJobId: String,


    val jobs: List<JobListItem>,
    val isLoading: Boolean,
    val isError: Boolean,
    val errorMsg: String,
    val adminId: String? = null,
    val jobsEmpty: Boolean = false,
    val errors: JobsDashUIErrors = JobsDashUIErrors(),

    val id: String,
    val title: String,
    val description: String,
    val location: String,
    val workLocation: String,
    val requirements: List<String>,
    val certificatesRequired: List<String>,
    val jobType: JobType,
    val jobCategoryId: String,
    val jobCategoryName: String,
    val salary: String,
    val isPerHour: SalaryType = SalaryType.HOURLY,
    val isFeatured: Boolean = false,
    val expiryAt: Long = Clock.System.now().plus(20000000.seconds).toEpochMilliseconds(),
    val isDeleted: Boolean = false,
    val isExpired: Boolean = false,
): UIState {

    fun checkErrors(): JobsDashUIErrors {
        var errorField = JobsDashUIErrors()

        // Check first name
        if (title.isBlank()) {
            errorField.title.apply {
                isError = true
                msg = "Title is required"
            }
        } else if (title.length <= 3){
            errorField.title.apply {
                isError = true
                msg = "Title is should be longer"
            }
        }

        if (description.isBlank()) {
            errorField.description.apply {
                isError = true
                msg = "Description is required"
            }
        } else if (description.length <= 3){
            errorField.description.apply {
                isError = true
                msg = "Description is should be longer"
            }
        }

        if (location.isBlank()) {
            errorField.location.apply {
                isError = true
                msg = "Location is required"
            }
        } else if (location.length <= 3){
            errorField.location.apply {
                isError = true
                msg = "Location is should be longer"
            }
        }
        if (workLocation.isBlank()) {
            errorField.workLocation.apply {
                isError = true
                msg = "Work Location is required"
            }
        } else if (workLocation.length <= 3){
            errorField.workLocation.apply {
                isError = true
                msg = "Work Location is should be longer"
            }
        }
        if (requirements.isEmpty()) {
            errorField.requirements.apply {
                isError = true
                msg = "Requirements is required"
            }
        }
        /*if (certificatesRequired.isEmpty()) {
            errorField.certificatesRequired.apply {
                isError = true
                msg = "Certificates Required is required"
            }
        }*/
        if (jobCategoryName.isBlank()) {
            errorField.jobCategoryName.apply {
                isError = true
                msg = "Job Category Name is required"
            }
        } else if (jobCategoryName.length <= 3){
            errorField.jobCategoryName.apply {
                isError = true
                msg = "Job Category Name is should be longer"
            }
        }
        if (salary.isBlank()) {
            errorField.salary.apply {
                isError = true
                msg = "Salary is required"
            }
        } else {
            val parts = salary.split("-")
            if (parts.size != 2) {
                errorField.salary.apply {
                    isError = true
                    msg = "Please use a valid format"
                }
            }
            try {
                parts[0].toDouble()
                parts[1].toDouble()
            }catch (e: Exception) {
                errorField.salary.apply {
                    isError = true
                    msg = "Please use a valid format"
                }
            }
        }

        return errorField
    }


}

data class JobsDashUIErrors (
    val title: FormInputError = FormInputError(),
    val description: FormInputError = FormInputError(),
    val location: FormInputError = FormInputError(),
    val workLocation: FormInputError = FormInputError(),
    val requirements: FormInputError = FormInputError(),
    val certificatesRequired: FormInputError = FormInputError(),
    val jobType: FormInputError = FormInputError(),
   // val jobCategoryId: FormInputError = FormInputError(),
    val jobCategoryName: FormInputError = FormInputError(),
    val salary: FormInputError = FormInputError(),
    val isPerHour: FormInputError = FormInputError(),
    val isFeatured: FormInputError = FormInputError(),
    val expiryAt: FormInputError = FormInputError(),
){
    fun hasError(): Boolean {
        return title.isError ||
                description.isError ||
                location.isError ||
                workLocation.isError ||
                requirements.isError ||
                certificatesRequired.isError ||
                jobType.isError ||
                jobCategoryName.isError ||
                salary.isError

    }
}