package org.jjoy.ltd.components.layouts

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.gridRowEnd
import com.varabyte.kobweb.compose.ui.modifiers.gridRowStart
import com.varabyte.kobweb.compose.ui.modifiers.gridTemplateRows
import org.jjoy.ltd.presentation.components.sections.Footer
import org.jjoy.ltd.presentation.components.sections.NavHeader

@Composable
fun PageLayout(title: String, content: @Composable () -> Unit) {

    /*LaunchedEffect(title) {
        document.title = "jjoy -$title"
    }*/

    Box(
        Modifier.fillMaxSize().gridTemplateRows("1fr auto"),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier = Modifier.fillMaxSize().align(Alignment.TopCenter),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            NavHeader()
            content()
        }
        // Associate the footer with the row that will get pushed off the bottom of the page if it can't fit.
        Footer(Modifier.gridRowStart(2).gridRowEnd(3))
    }
}