package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css

@Composable
fun ColumnScope.PageSimpleTitle(textMain: String, textDesc: String){
    Text(
        text = textMain,
        modifier =
        Modifier.fontSize(dimension.largeText).fontWeight(800).color(color.primary.css)
    )

    Text(
        text = textDesc,
        modifier = Modifier
            .fontSize(dimension.smallMediumText * 0.8)
            .fontWeight(500).color(color.primary.css)
            .fillMaxWidth(85.percent)
            .textAlign(TextAlign.Center)
    )
}