package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.borderColor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.attributes.builders.InputAttrsScope
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.TextArea
import org.jetbrains.compose.web.dom.TextInput
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.TextFieldTextStyle
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb
import org.w3c.dom.HTMLElement

@Composable
fun TextInputField(
    stateVsEvent: StateVsEvent,
    modifier: Modifier = Modifier,
    placeHolder: String? = null,
    isError: Boolean
) {

    TextInput(
        value = stateVsEvent.value ?: "",
        attrs =
            listOf(TextInputFieldStyle, TextFieldTextStyle)
                .toModifier()
                .color(color.onBackground.css)
                .then(modifier)
                .toAttrs {
                    placeHolder?.let { placeholder(it) }
                    style {
                        if (isError) {
                            borderColor(color.error.rgb)
                        }
                    }
                    onInput { stateVsEvent.onValueChange(it.value) }
                }
    )
}

val TextInputFieldStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Transparent)
            .outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent)
            .padding(dimension.normalPadding)
            .borderRadius(dimension.normalRoundness)
            .border(
                width = dimension.outlineWidthSmall,
                style = LineStyle.Solid,
                color = color.onBackground.css(25)
            )
            .animate(prop = listOf("border", "border-color", "color"))
    }

    hover {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }

    focus {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }
}

@Composable
fun TextInputFieldSmall(
    stateVsEvent: StateVsEvent,
    modifier: Modifier = Modifier,
    placeHolder: String? = null,
    isError: Boolean,
    singleLine: Boolean = true,
    onIme: TCallback<HTMLElement>
) {

    var element : HTMLElement? by remember { mutableStateOf(null) }

    val attrs : InputAttrsScope<String>.() -> Unit = {
        placeHolder?.let { placeholder(it) }
        style {
            if (isError) {
                borderColor(color.error.rgb)
            }
        }
        ref {
            element = it
            onDispose {
                //element = null
            }
        }
        onKeyDown {
            if (it.key == "Enter") {
                onIme(element!!)
            }
        }
        onInput { stateVsEvent.onValueChange(it.value) }
    }
    if (singleLine){
        TextInput(
            value = stateVsEvent.value ?: "",
            attrs =
            listOf(TextInputFieldSmallStyle, TextFieldTextStyle)
                .toModifier()
                .color(color.onBackground.css)
                .then(modifier)
                .toAttrs(attrs)
        )
    }else {
        TextArea(
            value = stateVsEvent.value ?: "",
            attrs =
            listOf(TextInputFieldSmallStyle, TextFieldTextStyle)
                .toModifier()
                .color(color.onBackground.css)
                .then(modifier)
                .toAttrs{
                    placeHolder?.let { placeholder(it) }
                    style {
                        if (isError) {
                            borderColor(color.error.rgb)
                        }
                    }
                    ref {
                        element = it
                        onDispose {
                            //element = null
                        }
                    }
                    onKeyDown {
                        if (it.key == "Enter") {
                            onIme(element!!)
                        }
                    }
                    onInput { stateVsEvent.onValueChange(it.value) }
                }
        )
    }
}



val TextInputFieldSmallStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Transparent)
            .outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent)
            .padding(dimension.smallPadding)
            .borderRadius(dimension.normalRoundness)
            .border(
                width = dimension.outlineWidthSmall,
                style = LineStyle.Solid,
                color = color.onBackground.css(25)
            )
            .animate(prop = listOf("border", "border-color", "color"))
    }

    cssRule("::placeholder") {
        Modifier.color(color.onBackground.css(70))
            .padding(dimension.smallPadding)
            .fontWeight(400)
            .fontSize(dimension.smallText * 1.1)
    }

    hover {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }

    focus {
        Modifier.border(
            width = dimension.outlineWidthSmall,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }
}
