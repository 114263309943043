package org.jjoy.ltd.util.base

import androidx.compose.runtime.*
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Job
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.UIEvent
import org.jjoy.ltd.core.base.UIState

@Composable
fun <S : UIState, VM : BaseViewModel<S, *, *>> VM.collectState() = uiState.collectAsState()

@Composable
fun <E : UIEvent, VM : BaseViewModel<*, E, *>> VM.collectEvent() = uiEvent.collectAsState(null)

fun BaseViewModel<*, *, *>.launch(
    type: DisPat = DisPat.IO,
    errorHandler: ((Throwable) -> Unit)? = null,
    operation: suspend CoroutineScope.() -> Unit
): Job {
    return when (type) {
        DisPat.IO -> onIoLaunch(errorHandler = errorHandler) { operation() }
        DisPat.IO_LONG -> onIO { operation() }
        DisPat.MAIN -> onMain { operation() }
        DisPat.MAIN_LONG -> onMain { operation() }
    }
}

enum class DisPat {
    IO_LONG,
    IO,
    MAIN,
    MAIN_LONG,
}

@OptIn(ExperimentalStdlibApi::class)
@Composable
fun <VM : BaseViewModel<*, *, *>> rememberViewModel(vm: () -> VM): VM {
    return remember { vm() }
}
