package org.jjoy.ltd.util

suspend fun <T> safeTry(error: T? = null, operation: suspend () -> T): T? {
    return try {
        operation()
    } catch (exception: Exception) {
        console.log("SAFE TRY EXCEPTION >>\n$exception")
        error
    }
}

suspend fun safeTry(operation: suspend () -> Unit): Boolean {
    return try {
        operation()
        true
    } catch (exception: Exception) {
        console.log("SAFE TRY EXCEPTION >>\n$exception")
        false
    }
}
