package org.jjoy.ltd.util.extension

import kotlinx.datetime.internal.JSJoda.DateTimeFormatter
import kotlin.js.Date

private val formatter = DateTimeFormatter.ISO_LOCAL_DATE_TIME

fun Long.toDateString(): String {
    val date = Date(this)
    val options = js("{month: 'long', day: 'numeric', year: 'numeric'}")
    return date.toLocaleDateString("en-US", options as Date.LocaleOptions)
}
