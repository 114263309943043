package org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.components.widgets.buttons.AuthButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.presentation.components.AlertToast
import org.jjoy.ltd.presentation.components.sections.PatternBackground
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.extension.*
import org.w3c.dom.HTMLElement

@Page("/simple/admin/dash/Gb72KGa/Xhsjbywgdl")
@Composable
fun AdminRegister(
    ctx: PageContext = rememberPageContext(),
    viewModel: AdminLoginViewModel = rememberViewModel { AdminLoginViewModel() }
) {

    val state by viewModel.collectState()
    val uiEventFlow by viewModel.collectEvent()

    Box(
        modifier = Modifier.height(100.vh).width(100.vw).bg(color.bgGray.css),
        contentAlignment = Alignment.Center
    ) {

        PatternBackground()
        when (uiEventFlow) {
            CommonUIEvent.NavigateNext -> {
                AdminRegisterSuccess{
                    ctx.router.navigateTo("/simple/admin/dashboard/login")
                }
            }
            else -> {

                AdminRegisterForm(
                    modifier = Modifier
                        .backgroundColor(Colors.White)
                        .buttonShadow()
                        .margin(dimension.mediumPadding)
                        .rounded(),
                    isLoading = state.isLoading,
                    error = state.errors,
                    isAlert = state.isError && state.errorMsg.isNotBlank(),
                    alertMessage = state.errorMsg,
                    adminEmail =
                    StateVsEvent(state.userEmail) {
                        viewModel.onActionEvent(AdminLoginActionEvent.UserEmailChanged(it))
                    },
                    adminPass = StateVsEvent(state.password) {
                        viewModel.onActionEvent(AdminLoginActionEvent.UserPasswordChanged(it))
                    },
                    adminRegToken = StateVsEvent(state.regToken) {
                        viewModel.onActionEvent(AdminLoginActionEvent.UserRegTokenChanged(it))
                    },
                    onSubmit = {
                        viewModel.onActionEvent(AdminLoginActionEvent.Register)
                    }
                )

            }
        }
    }
}

@Composable
private fun AdminRegisterForm(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: AdminLoginErrors,
    isAlert: Boolean,
    alertMessage: String,
    adminEmail: StateVsEvent,
    adminPass: StateVsEvent,
    adminRegToken: StateVsEvent,
    onSubmit: Callback
) {
    var loading by remember { mutableStateOf(false) }

    val formList =
        listOf(
            "email",
            "pass",
            "reg",
        )
    var focus by remember { mutableStateOf<String?>(formList[0]) }

    // TODO: Forgot Pass Impl

    Column(
        modifier = modifier
            .width(35.percent)
            .padding(dimension.normalPadding),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier
                    .scale(1)
                    .opacity(1)
                    .translateY(0.percent)
                    .animate(prop = listOf("scale", "opacity", "translate")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.width(90.percent).margin(top = dimension.largePadding),
            ) {
                Text(
                    modifier =
                        Modifier.fontWeight(800)
                            .fontSize(dimension.largeText)
                            .color(color.primary.css),
                    text = "Admin Registration"
                )
                Row {
                    Text(
                        modifier =
                            Modifier.fontWeight(FontWeight.Bold)
                                .fontSize(dimension.normalText * 0.9f)
                                .color(color.onBackground.css),
                        text = "Contact your admin if you don't have Registration Token"
                    )
                }

                AlertToast(
                    modifier = Modifier.margin(top = dimension.mediumPadding),
                    show = isAlert,
                    msg = alertMessage
                )
            }

            Column(
                modifier =
                    Modifier.margin(top = dimension.mediumPadding)
                        .fillMaxWidth(90.percent)
                        .gap(dimension.normalPadding),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = adminEmail,
                    label = "Email",
                    id = "email",
                    error = error.emailError,
                    onIme = { focus = formList[1] }
                )

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = adminPass,
                    label = "Password",
                    id = "pass",
                    error = error.passwordError,
                    onIme = { focus = formList[2] }
                )

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = adminRegToken,
                    label = "Registration Token",
                    id = "reg",
                    error = error.inviteTokenError,
                    onIme = { it.clearFocus() }
                )

                AuthButton(
                    modifier =
                        Modifier.margin(
                            top = dimension.largePadding,
                            bottom = dimension.largePadding * 3
                        ),
                    isLoading = loading,
                    label = "REGISTER",
                    onClick = onSubmit,
                )
            }
        }
    }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}


@Composable
private fun AdminRegisterSuccess(
    onProceedLogin: Callback
){

    Column(
        modifier = Modifier
            .padding(dimension.mediumPadding),
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ) {


        Text(
            modifier = Modifier
                .fontSize(dimension.mediumText)
                .color(color.primary.css)
                .fontWeight(FontWeight.SemiBold),
            text = "Admin Registration Successful"
        )

        com.varabyte.kobweb.silk.components.forms.Button(
            modifier = SimpleButtonStyle.toModifier()
                .backgroundColor(color.primary.css),
            onClick = { onProceedLogin()}
        ){
            Text(
                modifier = Modifier
                    .color(color.onPrimary.css)
                    .fontSize(dimension.normalText).fontWeight(600),
                text = "Proceed to Login"
            )
        }


    }


}

val SimpleButtonStyle by ComponentStyle {
    base {
        Modifier
            .backgroundColor(color.primary.css)
            .rounded()
            .padding(leftRight = dimension.largePadding, topBottom = dimension.normalPadding)
            .animate(prop = listOf("background-color"))

    }

    hover {
            Modifier.backgroundColor(color.primary.rgb.darkened())
    }

}