package org.jjoy.ltd.core

data class StateVsEvent(
    val value: String? = null,
    val onValueChange: (String) -> Unit
)

data class TStateVsEvent<T>(
    val value: T? = null,
    val onValueChange: (T) -> Unit
)
