package org.jjoy.ltd.util.extension

import com.varabyte.kobweb.compose.ui.graphics.Color
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb
import org.jetbrains.compose.web.css.rgba
import org.jjoy.ltd.core.util.extension.toAlpha
import org.jjoy.ltd.theme.AppColor

private fun convertToHex(value: Int): String {
    return value.toString(16).padStart(2, '0')
}

val AppColor.hex: String
    get() {
        val hexR = convertToHex(r.toInt())
        val hexG = convertToHex(g.toInt())
        val hexB = convertToHex(b.toInt())
        return "#$hexR$hexG$hexB"
    }

val AppColor.css: CSSColorValue
    get() =
        rgb(
            r,
            g,
            b,
        )

fun AppColor.css(alpha: Number? = null): CSSColorValue {
    return alpha?.let { rgba(r, g, b, it.toAlpha()) }
        ?: rgb(
            r,
            g,
            b,
        )
}

val AppColor.rgb: Color.Rgb
    get() = Color.rgb(r.toInt(), g.toInt(), b.toInt())