package org.jjoy.ltd.presentation.admin.application

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.job.JobApplicationInfo

data class ApplicationUIState(
    val isPageLoading: Boolean,
    val isError: Boolean = false,
    val errorMessage: String = "",
    val isEmpty : Boolean = false,
    val applications: List<JobApplicationInfo>,
): UIState

