package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.presentation.components.widgets.LinearProgressIndicator
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

val opacity by Keyframes {
    from { Modifier.opacity(0) }
    to { Modifier.opacity(1) }
}

val scale by Keyframes {
    0.percent { Modifier.scale(0) }
    100.percent { Modifier.scale(1) }
}

@Composable
fun AuthButton(
    label: String,
    onClick: Callback,
    isLoading: Boolean,
    modifier: Modifier = Modifier
) {

    Button(
        onClick = {
            if (!isLoading) {
                onClick()
            }
        },
        modifier =
            modifier
                .then(BlurButtonStyle.toModifier())
                .padding(dimension.normalPadding)
                .thenIf(isLoading, Modifier.backgroundColor(color.primary.rgb.lightened(0.3f)))
                .fillMaxWidth()
                .animation(),
        variant = BlurButtonComp,
    ) {
        Box(modifier = Modifier, contentAlignment = Alignment.Center) {
            LinearProgressIndicator(
                stretch = 35,
                modifier =
                    Modifier.thenIf(!isLoading) { Modifier.scale(0) }
                        .thenIf(isLoading) {
                            Modifier.animation(
                                    scale.toAnimation(
                                        duration = 0.4.s,
                                        timingFunction = AnimationTimingFunction.EaseInOut,
                                        iterationCount = AnimationIterationCount.of(1),
                                        fillMode = AnimationFillMode.Both,
                                        direction = AnimationDirection.Normal
                                    )
                                )
                                .animate(prop = listOf("opacity", "scale"))
                        }
            )
            SpanText(
                text = label,
                modifier =
                    Modifier.fontWeight(FontWeight.Bold)
                        .fontSize(dimension.textFieldLabelText)
                        .padding(dimension.smallPadding)
                        .thenIf(!isLoading) { Modifier.scale(1) }
                        .thenIf(isLoading) {
                            Modifier.animation(
                                scale.toAnimation(
                                    duration = 0.35.s,
                                    timingFunction = AnimationTimingFunction.EaseInOut,
                                    iterationCount = AnimationIterationCount.of(1),
                                    fillMode = AnimationFillMode.Both,
                                    direction = AnimationDirection.Reverse
                                )
                            )
                        }
                        .animate(prop = listOf("opacity", "scale"))
            )
        }
    }
}

@Composable
fun AuthButtonWithIcon(
    label: String,
    iconName: String,
    isEnabled: Boolean = true,
    onClick: Callback,
    isLoading: Boolean,
    modifier: Modifier = Modifier
) {

    Button(
        onClick = {
            if (!isLoading) {
                if (isEnabled) {
                    onClick()
                }
            }
        },
        modifier =
            modifier
                .then(BlurButtonStyle.toModifier())
                .scale(1)
                .boxShadow(
                    color = color.primary.css(13),
                    blurRadius = 20.px,
                    spreadRadius = 1.px,
                    offsetX = 0.px,
                    offsetY = 0.px,
                )
                .padding(
                    top = dimension.normalPadding,
                    bottom = dimension.normalPadding,
                    left = dimension.mediumPadding,
                    right = dimension.mediumPadding
                )
                .thenIf(isLoading, Modifier.backgroundColor(color.primary.rgb.lightened(0.3f))),
        variant = BlurButtonComp,
    ) {
        Box(modifier = Modifier, contentAlignment = Alignment.Center) {
            LinearProgressIndicator(
                modifier =
                    Modifier.thenIf(!isLoading) { Modifier.scale(0) }
                        .thenIf(isLoading) {
                            Modifier.animation(
                                    scale.toAnimation(
                                        duration = 0.4.s,
                                        timingFunction = AnimationTimingFunction.EaseInOut,
                                        iterationCount = AnimationIterationCount.of(1),
                                        fillMode = AnimationFillMode.Both,
                                        direction = AnimationDirection.Normal
                                    )
                                )
                                .animate(prop = listOf("opacity", "scale", "width"))
                        }
            )
            Row(
                modifier =
                    Modifier.gap(0.8.cssRem)
                        .fontWeight(FontWeight.Bold)
                        .fontSize(dimension.textFieldLabelText)
                        .thenIf(!isLoading) { Modifier.scale(1) }
                        .thenIf(isLoading) {
                            Modifier.animation(
                                scale.toAnimation(
                                    duration = 0.35.s,
                                    timingFunction = AnimationTimingFunction.EaseInOut,
                                    iterationCount = AnimationIterationCount.of(1),
                                    fillMode = AnimationFillMode.Both,
                                    direction = AnimationDirection.Reverse
                                )
                            )
                        }
                        .animate(prop = listOf("opacity", "scale")),
                verticalAlignment = Alignment.CenterVertically
            ) {
                SpanText(
                    text = label,
                    modifier =
                        Modifier.fontWeight(FontWeight.Bold).fontSize(dimension.textFieldLabelText)
                )
                FaIcon(
                    name = iconName,
                    modifier =
                        Modifier.color(color.onPrimary.css)
                            .fontSize(dimension.textFieldLabelText * 1.05),
                    style = IconCategory.SOLID,
                )
            }
        }
    }
}

val AuthProgress by ComponentStyle.base { Modifier.opacity(0) }
