package org.jjoy.ltd.presentation.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.CSSAnimation
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.animation.Keyframes
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Div
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.rgb

val blinkingAnimation by Keyframes {
    from { Modifier.backgroundColor(color.bgGray.rgb) }
    to { Modifier.backgroundColor(color.primary.rgb) }
}

@Composable
fun DotAnimation(
    size: CSSSizeValue<CSSUnit.rem> = dimension.smallMediumText
){
    Div(
        Modifier
        .display(DisplayStyle.Flex)
        .justifyContent(JustifyContent.Center)
        .alignItems(AlignItems.Center)
        .backgroundColor(Colors.Transparent)
        .toAttrs()
    ) {
        for( i in 1..4){
            Div( Modifier
                .width(size)
                .margin(left = dimension.smallPadding, right = dimension.smallPadding)
                .height(size)
                .borderRadius(50.percent)
                .animation(
                    CSSAnimation(
                        blinkingAnimation.name,
                        2.s,
                        AnimationTimingFunction.EaseInOut,
                        i.s,
                        AnimationIterationCount.Infinite
                    )
                ).toAttrs()
            )
        }
    }
}
