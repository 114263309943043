package org.jjoy.ltd.presentation.composables.wrappers

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.breakpoint.ResponsiveValues
import org.jetbrains.compose.web.css.vh
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.widgets.LinearProgressIndicator
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rounded

@Composable
fun <T> ListWrapper(
    isLoading: Boolean,
    isError: Boolean,
    isEmpty: Boolean,
    errorMsg: String,
    list: List<T>,
    modifier: Modifier = Modifier,
    numColumns: ResponsiveValues<Int> = numColumns(base = 1, sm = 1, md = 2),
    content: @Composable (T) -> Unit
) {

    Box(
        modifier = modifier.fillMaxWidth().fillMaxHeight().bg(),
        contentAlignment = Alignment.Center,
    ) {
        SimpleGrid(
            modifier =
                Modifier.fillMaxWidth()
                    .bg()
                    .gap(dimension.normalPadding)
                    .scale(0)
                    .opacity(0)
                    .align(Alignment.TopCenter)
                    .thenIf(!isLoading && !isError && !isEmpty, Modifier.scale(1).opacity(1))
                    .animate(prop = listOf("scale", "opacity")),
            numColumns = numColumns
        ) {
            list.forEach { content(it) }
        }

        Column(
            modifier =
                Modifier.fillMaxWidth()
                    .height(100.vh)
                    .scale(0)
                    .bg()
                    .rounded()
                    .opacity(0)
                    .thenIf(!isLoading && list.isEmpty(), Modifier.scale(1).opacity(1))
                    .animate(prop = listOf("scale", "opacity")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Text(
                modifier =
                    Modifier.fontWeight(FontWeight.Bold)
                        .fontSize(dimension.normalText)
                        .color(color.error.css),
                text = if (list.isEmpty()) "Empty Items" else "No Matches Found"
            )
        }

        LinearProgressIndicator(
            modifier =
                Modifier.scale(1)
                    .opacity(1)
                    .thenIf(!isLoading, Modifier.scale(0).opacity(0))
                    .animate(prop = listOf("scale", "opacity"), dur = 450),
            backgroundColor = color.primary.css,
            primaryColor = color.onPrimary.css
        )

        Column(
            modifier =
                Modifier.fillMaxSize()
                    .bg()
                    .scale(0)
                    .opacity(0)
                    .thenIf(isError && !isLoading, Modifier.scale(1).opacity(1))
                    .animate(prop = listOf("scale", "opacity")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Text(
                modifier =
                    Modifier.fontWeight(FontWeight.Bold)
                        .fontSize(dimension.normalText)
                        .color(color.error.css),
                text = errorMsg
            )
        }
    }
}
