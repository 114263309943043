package org.jjoy.ltd.presentation.screen.user_login

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.components.widgets.buttons.AuthButton
import org.jjoy.ltd.presentation.components.AlertToast
import org.jjoy.ltd.presentation.components.input.PasswordInputWrap
import org.jjoy.ltd.presentation.components.text.ClickableText
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.res.ResourceString

val transitionTest by Keyframes {
    0.percent { Modifier.scale(1).opacity(1).translateY(0.percent) }
    // 50.percent {  Modifier.scale(0.5).opacity(0.4).translateY((-15).percent) }
    100.percent { Modifier.scale(0).opacity(0).translateY((-35).percent) }
}

@Composable
fun LoginForm(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: LoginFormFieldError,
    isAlert: Boolean,
    userEmailStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    onViewChange: TCallback<LoginViews>,
    onSubmit: Callback,
    onForgot: Callback
) {
    var loading by remember { mutableStateOf(false) }
    var forgot by remember { mutableStateOf(false) }

    // TODO: Forgot Pass Impl

    Column(
        modifier = modifier.padding(dimension.normalPadding),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier.fillMaxSize()
                    .scale(1)
                    .opacity(1)
                    .translateY(0.percent)
                    .thenIf(forgot) {
                        Modifier.animation(
                            transitionTest.toAnimation(
                                duration = 0.4.s,
                                iterationCount = AnimationIterationCount.of(1),
                                fillMode = AnimationFillMode.Both
                            )
                        )
                        // Modifier.scale(0).opacity(0).translateY((-30).percent)
                    }
                    .animate(prop = listOf("scale", "opacity", "translate")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.width(80.percent).margin(top = dimension.largePadding),
            ) {
                Text(
                    modifier =
                        Modifier.fontWeight(800)
                            .fontSize(dimension.largeText)
                            .color(color.primary.css),
                    text = ResourceString.loginTitle
                )
                Row {
                    Text(
                        modifier =
                            Modifier.fontWeight(FontWeight.Bold)
                                .fontSize(dimension.normalText)
                                .color(color.onBackground.css),
                        text = ResourceString.signupQuestion
                    )
                    ClickableText(
                        onClick = { onViewChange(LoginViews.REGISTER) },
                        text = "Register"
                    )
                }

                AlertToast(
                    modifier = Modifier.margin(top = 1.4.cssRem),
                    show = isAlert,
                    msg = error.msg
                )
            }

            Column(
                modifier =
                    Modifier.margin(top = 2.cssRem)
                        .fillMaxWidth(80.percent)
                        .gap(dimension.normalPadding),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userEmailStateVsEvent,
                    label = "Email",
                    id = "email",
                    error = FormInputError(isError = error.email),
                    onIme = {

                    }
                )

                PasswordInputWrap(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userPasswordStateVsEvent,
                    label = "Password",
                    isError = error.password,
                    visibilityToggle = true,
                    isVisible = false
                )

                AuthButton(
                    modifier = Modifier.margin(top = dimension.largePadding, bottom = dimension.largePadding * 3),
                    isLoading = loading,
                    label = "LOGIN",
                    onClick = onSubmit,
                )

                /*ClickableText(
                    modifier = ClickableTextStyle.toModifier()
                        .color(color.primary.css)
                        .fontWeight(600)
                        .fontSize(dimension.normalText)
                        .margin(bottom = 6.cssRem),
                    onClick = { forgot = true },
                    text = "Forgot password"
                )*/
            }
        }
    }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }
}
@Composable
fun LoginFormMobile(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: LoginFormFieldError,
    isAlert: Boolean,
    userEmailStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    onViewChange: TCallback<LoginViews>,
    onSubmit: Callback,
    onForgot: Callback
) {
    var loading by remember { mutableStateOf(false) }
    var forgot by remember { mutableStateOf(false) }

    // TODO: Forgot Pass Impl

    Column(
        modifier = modifier.padding(dimension.mediumPadding),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier.fillMaxSize()
                    .scale(1)
                    .opacity(1)
                    .translateY(0.percent)
                    .thenIf(forgot) {
                        Modifier.animation(
                            transitionTest.toAnimation(
                                duration = 0.4.s,
                                iterationCount = AnimationIterationCount.of(1),
                                fillMode = AnimationFillMode.Both
                            )
                        )
                        // Modifier.scale(0).opacity(0).translateY((-30).percent)
                    }
                    .animate(prop = listOf("scale", "opacity", "translate")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.fillMaxWidth(),
                horizontalAlignment = Alignment.Start,
                verticalArrangement = Arrangement.Center
            ) {
                Text(
                    modifier =
                        Modifier.fontWeight(800)
                            .fontSize(dimension.largeText)
                            .color(color.primary.css),
                    text = ResourceString.loginTitle
                )
                Row {
                    Text(
                        modifier =
                            Modifier.fontWeight(FontWeight.Bold)
                                .fontSize(dimension.normalText)
                                .color(color.onBackground.css),
                        text = ResourceString.signupQuestion
                    )
                    ClickableText(
                        onClick = { onViewChange(LoginViews.REGISTER) },
                        text = "Register"
                    )
                }

                AlertToast(
                    modifier = Modifier.margin(top = dimension.normalPadding),
                    show = isAlert,
                    msg = error.msg
                )
            }

            Column(
                modifier =
                    Modifier.margin(top = dimension.largePadding)
                        .fillMaxWidth()
                        .gap(dimension.normalPadding),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userEmailStateVsEvent,
                    label = "Email",
                    id = "email",
                    error = FormInputError(isError = error.email),
                    onIme = {

                    }
                )

                PasswordInputWrap(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userPasswordStateVsEvent,
                    label = "Password",
                    isError = error.password,
                    visibilityToggle = true
                )
                Spacer()

                AuthButton(
                    modifier = Modifier.margin(top = dimension.largePadding, bottom = dimension.largePadding * 3),
                    isLoading = loading,
                    label = "LOGIN",
                    onClick = onSubmit,
                )

                /*ClickableText(
                    modifier = ClickableTextStyle.toModifier()
                        .color(color.primary.css)
                        .fontWeight(600)
                        .fontSize(dimension.normalText)
                        .margin(bottom = 6.cssRem),
                    onClick = { forgot = true },
                    text = "Forgot password"
                )*/
            }
        }
    }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }
}
