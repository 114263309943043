package org.jjoy.ltd.core.util.extension


fun String.isDigit(): Boolean {
    return matches(Regex("\\d+"))
}

fun String.formatEmail(): String {
    return filter { !it.isWhitespace() }.lowercase()
}

fun String.filterSpace(): String {
    return filter { !it.isWhitespace() }
}

/*
fun String.isEmail(): Boolean {
    return emailRegex.matches(this)
}*/
