package org.jjoy.ltd.presentation.screen.job

import org.jjoy.ltd.core.base.ActionEvent

sealed interface JobDetailsActionEvent : ActionEvent {
    object ApplyForJob : JobDetailsActionEvent
    object AddUserInfo : JobDetailsActionEvent
    object RestError: JobDetailsActionEvent
    data class ContactUs(val name: String, val email: String): JobDetailsActionEvent
    data class FetchJobDetails(val id: String): JobDetailsActionEvent
    data class SetIsApplied(val v: String): JobDetailsActionEvent

}
