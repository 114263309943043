package org.jjoy.ltd.components.layouts

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.gridTemplateRows
import com.varabyte.kobweb.compose.ui.modifiers.textAlign
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import org.jjoy.ltd.presentation.components.sections.Footer
import org.jjoy.ltd.presentation.components.sections.MainBackground
import org.jjoy.ltd.presentation.composables.LoaderViewHome

@Composable
fun PageWrapper(
    title: String,
    isLoading: Boolean = false,
    modifier: Modifier = Modifier,
    content: @Composable () -> Unit
){
    val breakpoint = rememberBreakpoint()


    Box(modifier
        .fillMaxSize()
        // Create a box with two rows: the main content (fills as much space as it can) and the footer (which reserves
        // space at the bottom). "min-content" means the use the height of the row, which we use for the footer. "1fr"
        // means give the rest of the space to that row, and "minmax(0, 1fr)" is a CSS trick which essentially means
        // the same thing but can avoid a measurement issue if you end up nesting additional grids inside of it. (See
        // also: https://github.com/w3c/csswg-drafts/issues/1777) Since this box is set to *at least* 100%, the footer
        // will always appear at least on the bottom but can be pushed further down if the first row grows beyond the
        // page.
        .gridTemplateRows("minmax(0, 1fr) min-content")
    ) {

        MainBackground()

        Column(
            modifier = Modifier.fillMaxSize().textAlign(TextAlign.Center),
            horizontalAlignment = Alignment.CenterHorizontally
        ) {
            if (!isLoading){
                content()
                Footer()
            }
        }
        LoaderViewHome(isLoading = isLoading)

    }



}