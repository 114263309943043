package org.jjoy.ltd.core.util

sealed interface ResourceState<T> {

    data class UnInitialized<T>(val reload: Boolean = false)  : ResourceState<T>
    data class Success<T>(val data: T) : ResourceState<T>
    data class Error<T>(val error: String? = null, val errorData: T? = null) :
        ResourceState<T>
    data class SerializationError<T>(val error: String? = null) :
        ResourceState<T>
    data class Loading<T>(val message: String? = null) : ResourceState<T>

    data class UnAuthorized<T>(val message: String? = null) : ResourceState<T>
}
