package org.jjoy.ltd.presentation.admin.jobs

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.components.widgets.buttons.ButtonWithIcon
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.ServiceItem.Companion.getList
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.widgets.DropDownWithLabel
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.presentation.components.wrapper.Icon
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_form.FormRow
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.css
import org.w3c.dom.HTMLElement

@Composable
fun AdminAddJob(
    state: JobsDashUIState,
    uiEvent: JobDashUIEvent?,
    onEvent: (JobsDashActionEvent) -> Unit,
    modifier: Modifier = Modifier
) {

    val focusList =
        listOf(
            "job_title",
            "job_location",
            "work_location",
            "job_desc",
            "job_salary",
            "job_requirements",
            "job_certificates"
        )

    var focus by remember { mutableStateOf<String?>(null) }

    ContentWrap(
        modifier = modifier.height(100.vh),
        columnModifier =
            Modifier.gap(dimension.extraSmallPadding)
                .padding(dimension.largePadding)
                .margin(dimension.normalPadding)
                .fillMaxWidth()
                .fillMaxHeight(),
        contentId = Section.Jobs.id,
        contentVerticalArrangement = Arrangement.Top,
        contentHorizontalAlignment = Alignment.CenterHorizontally
    ) {
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.TopCenter) {
            Column(
                modifier = Modifier.fillMaxSize().padding(bottom = dimension.mediumPadding),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Top
            ) {
                FormRow {
                    FormTextInput(
                        modifier = Modifier.fillMaxWidth(50.percent),
                        label = "Job Title",
                        id = "job_title",
                        stateVsEvent =
                            StateVsEvent(value = state.title) {
                                onEvent(JobsDashActionEvent.TitleChanged(it))
                            },
                        isMandatory = true,
                        error = state.errors.title,
                        onIme = { focus = focusList[1] }
                    )
                    FormTextInput(
                        modifier = Modifier.fillMaxWidth(25.percent),
                        label = "Job Location",
                        id = "job_location",
                        stateVsEvent =
                            StateVsEvent(value = state.location) {
                                onEvent(JobsDashActionEvent.LocationChanged(it))
                            },
                        isMandatory = true,
                        error = state.errors.location,
                        onIme = { focus = focusList[2] }
                    )
                    FormTextInput(
                        modifier = Modifier.fillMaxWidth(25.percent),
                        label = "Work Location",
                        id = "work_location",
                        stateVsEvent =
                            StateVsEvent(value = state.workLocation) {
                                onEvent(JobsDashActionEvent.WorkLocationChanged(it))
                            },
                        isMandatory = true,
                        error = state.errors.workLocation,
                        onIme = { focus = focusList[3] }
                    )
                }

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Description",
                    id = "job_desc",
                    stateVsEvent =
                        StateVsEvent(value = state.description) {
                            onEvent(JobsDashActionEvent.DescriptionChanged(it))
                        },
                    isMandatory = true,
                    singleLine = false,
                    error = state.errors.description,
                    onIme = { focus = focusList[4] }
                )

                FormRow(horizontalArrangement = Arrangement.Start) {
                    FormTextInput(
                        modifier = Modifier,
                        label = "Wage",
                        id = "job_salary",
                        stateVsEvent =
                            StateVsEvent(value = state.salary) {
                                if (it.isDigit() || it.contains("-")) {
                                    onEvent(JobsDashActionEvent.SalaryChanged(it))
                                }
                            },
                        placeHolder = "14-25 (use this format)",
                        isMandatory = true,
                        error = state.errors.salary,
                        onIme = { focus = focusList[5] }
                    )
                    DropDownWithLabel(
                        label = "Is Hourly",
                        selectedItem = state.isPerHour,
                        list = SalaryType.getList(),
                        itemToName = { it?.text },
                        onItemSelected = { onEvent(JobsDashActionEvent.IsPerHourChanged(it)) },
                        placeHolder = "Please Select One",
                        error = state.errors.isPerHour,
                    )

                    DropDownWithLabel(
                        label = "Job Type",
                        selectedItem = state.jobType,
                        list = JobType.getList(),
                        itemToName = { it?.getName() },
                        onItemSelected = { onEvent(JobsDashActionEvent.JobTypeChanged(it)) },
                        placeHolder = "Please Select One",
                        error = state.errors.jobType,
                    )
                    DropDownWithLabel(
                        label = "Job Category",
                        selectedItem = state.jobCategoryName,
                        list = servicesList.getList(),
                        itemToName = { it },
                        onItemSelected = {
                            onEvent(JobsDashActionEvent.JobCategoryNameChanged(it))
                        },
                        placeHolder = "Please Select One",
                        error = state.errors.jobCategoryName,
                    )
                }

                FormRow(
                    verticalAlignment = Alignment.Top,
                ) {
                    var reqList by remember {
                        mutableStateOf(state.requirements.toMutableStateList())
                    }
                    var reqText by remember { mutableStateOf("") }
                    Column(modifier = Modifier.fillMaxWidth(50.percent)) {
                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            label = "Requirements",
                            id = "job_requirements",
                            stateVsEvent = StateVsEvent(value = reqText) { reqText = it },
                            isMandatory = true,
                            error = state.errors.requirements,
                            onIme = {
                                if (!reqList.contains(reqText) && reqText.isNotBlank()) {
                                    reqList.add(reqText)
                                    reqText = ""
                                } else {
                                    reqText = ""
                                }
                            }
                        )

                        SimpleGrid(
                            modifier = Modifier.gap(dimension.smallPadding).fillMaxWidth(),
                            numColumns = numColumns(1, 1, 1, 1, 1)
                        ) {
                            reqList.sortDescending()
                            reqList.forEach {
                                Box(
                                    modifier =
                                        Modifier.boxShadow(
                                                offsetY = 1.px,
                                                offsetX = 1.px,
                                                blurRadius = 0.45.cssRem,
                                                spreadRadius = 0.34.cssRem,
                                                color = color.primary.css(10)
                                            )
                                            .borderRadius(dimension.mediumRoundness * 0.5)
                                            .fillMaxWidth()
                                            .padding(dimension.normalPadding),
                                    contentAlignment = Alignment.CenterStart
                                ) {
                                    org.jjoy.ltd.presentation.components.text.Text(
                                        modifier =
                                            Modifier.fontSize(dimension.smallText)
                                                .fontWeight(FontWeight.Bold)
                                                .color(color.primary.css),
                                        text = it
                                    )
                                    Icon(
                                        modifier =
                                            Modifier.align(Alignment.CenterEnd).scale(1).opacity(1),
                                        name = "xmark",
                                        onClick = { reqList.remove(it) }
                                    )
                                }
                            }
                        }
                        LaunchedEffect(reqList.toList()) {
                            onEvent(JobsDashActionEvent.RequirementsChanged(reqList.toList()))
                        }
                    }

                    var certList by remember {
                        mutableStateOf(state.certificatesRequired.toMutableStateList())
                    }
                    var certText by remember { mutableStateOf("") }
                    Column(modifier = Modifier.fillMaxWidth(50.percent)) {
                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            label = "Require Certificates",
                            id = "job_certificates",
                            stateVsEvent = StateVsEvent(value = certText) { certText = it },
                            isMandatory = true,
                            error = state.errors.certificatesRequired,
                            onIme = {
                                if (!certList.contains(certText) && certText.isNotBlank()) {
                                    certList.add(certText)
                                    certText = ""
                                } else {
                                    certText = ""
                                }
                            }
                        )

                        SimpleGrid(
                            modifier = Modifier.gap(dimension.smallPadding).fillMaxWidth(),
                            numColumns = numColumns(1, 1, 1, 1, 1)
                        ) {
                            certList.sortDescending()
                            certList.forEach {
                                Box(
                                    modifier =
                                        Modifier.boxShadow(
                                                offsetY = 1.px,
                                                offsetX = 1.px,
                                                blurRadius = 0.45.cssRem,
                                                spreadRadius = 0.34.cssRem,
                                                color = color.primary.css(10)
                                            )
                                            .borderRadius(dimension.mediumRoundness * 0.5)
                                            .fillMaxWidth()
                                            .padding(dimension.normalPadding),
                                    contentAlignment = Alignment.CenterStart
                                ) {
                                    org.jjoy.ltd.presentation.components.text.Text(
                                        modifier =
                                            Modifier.fontSize(dimension.smallText)
                                                .fontWeight(FontWeight.Bold)
                                                .color(color.primary.css),
                                        text = it
                                    )
                                    Icon(
                                        modifier =
                                            Modifier.align(Alignment.CenterEnd).scale(1).opacity(1),
                                        name = "xmark",
                                        onClick = { certList.remove(it) }
                                    )
                                }
                            }
                        }

                        LaunchedEffect(certList.toList()) {
                            onEvent(
                                JobsDashActionEvent.CertificatesRequiredChanged(certList.toList())
                            )
                        }

                    }
                }
                Spacer()
            }



            /** Bottom Section */
            Row(
                modifier =
                Modifier
                    //.bg(color.primaryContainer.css)
                    .padding(
                        leftRight = dimension.navRailWidthPercent,
                    )
                    .padding(dimension.normalPadding)
                    .align(Alignment.BottomEnd)
                    .position(Position.Fixed)
                    .zIndex(2)
                    .margin(bottom = dimension.normalPadding),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.End
            ) {
                ButtonWithIcon(
                    modifier = Modifier,
                    iconName = "plus",
                    text = "Add",
                    onClick = { onEvent(JobsDashActionEvent.AddJob) },
                )
            }
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }

    uiEvent?.let {
        when (it) {
            is JobDashUIEvent.Error -> window.alert("Error :: ${it.msg}")
            JobDashUIEvent.JobAdded -> {}
            JobDashUIEvent.ListCollected -> {}
            JobDashUIEvent.NavigateBack -> {}
            JobDashUIEvent.NavigateNext -> {}
            else -> {}
        }
    }
}

enum class SalaryType(val text: String, val v: Boolean) {
    HOURLY("HOURLY", true),
    YEARLY("YEARLY", false);
    companion object {
        fun getList(): List<SalaryType> {
            return enumValues<SalaryType>().asList()
        }
    }
}
