package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.screen.user_login.*
import org.jjoy.ltd.theme.dimension

@Composable
fun RegisterSection(
    state: LoginUIState,
    uiEvent: CommonUIEvent?,
    views: LoginViews,
    onEvent: (LoginActionEvent) -> Unit,
    ctx: PageContext,
    breakpoint: Breakpoint
) {

    Box(
        modifier =
            Modifier.thenIf(
                    breakpoint <= Breakpoint.LG,
                    Modifier.margin(top = dimension.extraLargePadding * 1.2)
                )
                .flexDirection(FlexDirection.Column)
                .cursor(Cursor.Pointer)
                .id(Section.Register.id)
                .width(100.vw)
                .then(
                    if (breakpoint < Breakpoint.XL) Modifier.fillMaxHeight()
                    else Modifier.height(100.vh)
                ),
        contentAlignment = Alignment.CenterStart
    ) {
        Image(
            src = "/categories_header.jpg",
            modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover)
        )

        Row(
            modifier =
                Modifier.fillMaxWidth()
                    .align(
                        if (breakpoint < Breakpoint.XL) Alignment.TopStart
                        else Alignment.BottomCenter
                    )
                    .then(
                        if(breakpoint > Breakpoint.MD){
                            Modifier.padding(
                                dimension.extraLargePadding.times(dimension.mediumPadding.value)
                            )
                        } else {
                            Modifier.padding(
                                dimension.normalPadding
                            )
                        }

                    )
                    .flexDirection(
                        if (breakpoint <= Breakpoint.MD) FlexDirection.Column else FlexDirection.Row
                    ),
            verticalAlignment = Alignment.Bottom,
            horizontalArrangement = Arrangement.Start
        ) {
            RegistrationBrandView(
                modifier = Modifier.fillMaxWidth().thenIf(
                    breakpoint <= Breakpoint.MD,
                    Modifier.scale(0.9f)
                ),
                view = views,
                onViewChange = { onEvent(LoginActionEvent.SwitchView(it)) }
            )

            RegistrationForm(
                modifier =
                    Modifier.thenIf(breakpoint <= Breakpoint.MD, Modifier.fillMaxWidth())
                        .thenIf(
                            breakpoint > Breakpoint.MD,
                            Modifier.margin(dimension.largePadding)
                        ),
                error = state.error,
                isLoading = state.isLoading,
                isAlert = state.isError,
                userEmailStateVsEvent =
                    StateVsEvent(state.userEmail) {
                        onEvent(LoginActionEvent.UserEmailChanged(it))
                    },
                userFullNameStateVsEvent =
                    StateVsEvent(state.userFullName) {
                        if (!it.isDigit()) {
                            onEvent(LoginActionEvent.UserFullNameChanged(it))
                        }
                    },
                userPhoneStateVsEvent =
                    StateVsEvent(state.userPhone) {
                        onEvent(LoginActionEvent.UserPhoneChanged(it))
                    },
                userPasswordStateVsEvent =
                    StateVsEvent(state.password) {
                        onEvent(LoginActionEvent.UserPasswordChanged(it))
                    },
                userConfirmPasswordStateVsEvent =
                    StateVsEvent(state.confirmPassword) {
                        onEvent(LoginActionEvent.UserPasswordConfirmChanged(it))
                    },
                onSubmit = onEvent,
                views = views
            )
        }
    }

    uiEvent?.let {
        when (it) {
            is CommonUIEvent.Error -> TODO()
            CommonUIEvent.Loading -> TODO()
            CommonUIEvent.NavigateNext -> ctx.router.navigateTo(Section.Jobs.id)
            CommonUIEvent.NavigateUp -> TODO()
        }
    }
}
