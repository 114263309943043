package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css

@Composable
fun AlertToast(
    show: Boolean,
    msg: String,
    modifier: Modifier = Modifier,
) {

    Row(
        modifier =
            modifier
                .padding(dimension.normalPadding)
                .backgroundColor(Colors.Transparent)
                .borderRadius(dimension.normalRoundness)
                .scale(0)
                .opacity(0)
                .thenIf(show,Modifier.scale(1).opacity(1).backgroundColor(color.error.css))
                .animate(prop = listOf("scale","opacity","color", "background-color")),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically
    ) {
        Text(
            text = msg,
            modifier =
                Modifier.color(color.onError.css).fontSize(dimension.smallText).fontWeight(700)
        )
    }
}

val errorAlertStyle by ComponentStyle.base { Modifier }
