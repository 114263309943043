package org.jjoy.ltd.presentation.components.sections

import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.silk.components.disclosure.TabPanelScope
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css


fun TabPanelScope.TabTitle(
    modifier: Modifier = Modifier.fillMaxWidth(),
    title: String,
    textModifier: Modifier = Modifier
        .color(color.onBackground.css)
        .fontSize(dimension.smallText)
        .fontWeight(FontWeight.Bold),
){
    Tab(modifier = modifier) {
        Text(
            modifier = textModifier,
            text = title
        )
    }
}