package org.jjoy.ltd.data.remote

import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.await
import kotlinx.coroutines.flow.flow
import kotlinx.coroutines.flow.flowOn
import kotlinx.coroutines.withTimeoutOrNull
import org.jjoy.ltd.core.data.model.BaseResponse
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.core.util.decode
import org.jjoy.ltd.util.extension.fetchError
import org.w3c.fetch.Response
import kotlin.js.Promise

suspend inline fun <reified T> apiFlowResponse(
    crossinline apiCall: suspend () -> Promise<Response>
) =
    flow {
            withTimeoutOrNull(25000) {
                var status: Short = 1
                try {
                    emit(ResourceState.Loading())
                    val result =
                        apiCall()
                            .then {
                                val response = it
                                status = response.status
                                response.text()
                            } // DO NOT Change anything
                            .then { it.decode<BaseResponse<T>>() }
                            .await()
                    if (result.success) {
                        emit(ResourceState.Success(result.response))
                    } else {
                        if (status == 401.toShort()) {
                            console.log("UnAuthorized")
                            emit(ResourceState.UnAuthorized("UnAuthorized"))
                        } else {
                            emit(ResourceState.Error(error = "Error", errorData = result.response))
                        }
                    }
                    return@withTimeoutOrNull
                } catch (throwable: Throwable) {
                    console.log("Throwable>>>::${throwable.message}")
                    if (status == 401.toShort()) {
                        console.log("UnAuthorized")
                        emit(ResourceState.UnAuthorized("UnAuthorized"))
                        return@withTimeoutOrNull
                    }
                    emit(ResourceState.Error("Exception", errorData = throwable.fetchError<T>()))
                    return@withTimeoutOrNull
                }
            }
                ?: emit(ResourceState.Error("Something went wrong"))

            return@flow
        }
        .flowOn(Dispatchers.Default)
