package org.jjoy.ltd.core.domain.model.user

import com.benasher44.uuid.uuid4
import kotlinx.serialization.Serializable

@Serializable
data class UserDto(
    val id: String = uuid4().toString(),
    val userName: String,
    val password: String,
    val fullName: String,
    val phoneNumber: String
)

@Serializable
data class UserInfo(
    val id: String = "",
    val fullName: String = "",
    val email: String = "",
    val phoneNumber: String = ""
)

@Serializable
data class UserResponse(
    val id: String,
    val fullName: String,
)