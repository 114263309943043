package org.jjoy.ltd.presentation.screen.user_login

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.FlexDirection
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Span
import org.jjoy.ltd.components.widgets.buttons.AuthButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.presentation.components.AlertToast
import org.jjoy.ltd.presentation.components.input.PasswordInputWrap
import org.jjoy.ltd.presentation.components.text.ClickableText
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.res.ResourceString
import org.w3c.dom.HTMLElement

@Composable
fun RegistrationForm(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: LoginFormFieldError,
    isAlert: Boolean,
    userEmailStateVsEvent: StateVsEvent,
    userFullNameStateVsEvent: StateVsEvent,
    userPhoneStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    userConfirmPasswordStateVsEvent: StateVsEvent,
    views: LoginViews,
    onSubmit: TCallback<LoginActionEvent>
) {
    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()

    var loading by remember { mutableStateOf(false) }

    val formList =
        listOf(
            "email",
            "full_name",
            "phone",
        )
    var focus by remember { mutableStateOf<String?>(null) }

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier = Modifier.fillMaxWidth().flexWrap(FlexWrap.Wrap),
        ) {
            AlertToast(
                modifier = Modifier.margin(top = dimension.normalPadding * 1.1),
                show = isAlert,
                msg = error.msg
            )
        }

        when (views) {
            LoginViews.REGISTER -> {
                Column(
                    modifier =
                        Modifier.thenIf(
                                breakpoint > Breakpoint.MD,
                                Modifier.gap(dimension.smallPadding)
                            )
                            .padding(dimension.mediumPadding * 2)
                            .bg(color.background.css(75))
                            .rounded()
                            .fillMaxWidth(),
                ) {
                    FormTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        stateVsEvent = userEmailStateVsEvent,
                        label = "Email",
                        id = "email",
                        isMandatory = true,
                        error = FormInputError(isError = error.email),
                        onIme = { focus = formList[1] }
                    )
                    Row(
                        modifier =
                            Modifier.thenIf(
                                    breakpoint > Breakpoint.MD,
                                    Modifier.gap(dimension.smallPadding)
                                )
                                .flexDirection(
                                    if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                                    else FlexDirection.Row
                                )
                    ) {
                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            stateVsEvent = userFullNameStateVsEvent,
                            label = "Full Name",
                            id = "full_name",
                            isMandatory = true,
                            error = FormInputError(isError = error.fullName),
                            onIme = { focus = formList[2] }
                        )

                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            label = "Phone Number",
                            id = "phone",
                            stateVsEvent = userPhoneStateVsEvent,
                            isMandatory = true,
                            isNumber = true,
                            placeHolder = "With Country Code",
                            error = FormInputError(isError = error.phone),
                            onIme = { it.clearFocus() }
                        )
                    }

                    Row(
                        modifier =
                            Modifier.thenIf(
                                    breakpoint > Breakpoint.MD,
                                    Modifier.gap(dimension.smallPadding)
                                )
                                .flexDirection(
                                    if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                                    else FlexDirection.Row
                                )
                    ) {
                        PasswordInputWrap(
                            modifier = Modifier.fillMaxWidth(),
                            stateVsEvent = userPasswordStateVsEvent,
                            label = "Password",
                            isError = error.password,
                            isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                            visibilityToggle = true
                        )
                        PasswordInputWrap(
                            modifier = Modifier.fillMaxWidth(),
                            stateVsEvent = userConfirmPasswordStateVsEvent,
                            label = "Confirm Password",
                            isError = error.confirmPassword,
                            visibilityToggle = false,
                            isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                        )
                    }

                    Row(
                        modifier =
                        Modifier.thenIf(
                            breakpoint > Breakpoint.MD,
                            Modifier.gap(dimension.smallPadding)
                        ).flexDirection(
                                if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                                else FlexDirection.Row
                            ).fillMaxSize().align(Alignment.CenterHorizontally),
                        horizontalArrangement = Arrangement.Center
                    ){
                        Span {
                            Text(
                                modifier = Modifier
                                    .color(color.onBackground.css)
                                    .fontWeight(450)
                                    .fontSize(dimension.smallText),
                                text = "By continuing, you agree to our ",
                            )
                            ClickableText(
                                modifier = Modifier
                                    .color(color.primary.css)
                                    .fontWeight(550)
                                    .fontSize(dimension.smallText),
                                onClick = {
                                    ctx.router.navigateTo("/terms")
                                },
                                text = "Terms & Conditions",
                            )
                        }
                    }
                }
            }
            LoginViews.LOGIN -> {
                Column(
                    modifier =
                        Modifier.gap(dimension.normalPadding)
                            .padding(dimension.mediumPadding * 2)
                            .bg(color.background.css(75))
                            .rounded()
                            .fillMaxWidth(),
                ) {
                    Text(
                        text = "Welcome back",
                        modifier =
                            Modifier.fontWeight(600)
                                .fontSize(dimension.normalText)
                                .color(color.primary.css)
                    )

                    Row(modifier = Modifier.gap(dimension.smallPadding).opacity(0)) {
                        PasswordInputWrap(
                            modifier = Modifier.fillMaxWidth(),
                            stateVsEvent = userPasswordStateVsEvent,
                            label = "Password",
                            isError = error.password,
                            isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                            visibilityToggle = true
                        )
                        PasswordInputWrap(
                            modifier = Modifier.fillMaxWidth(),
                            stateVsEvent = userConfirmPasswordStateVsEvent,
                            label = "Confirm Password",
                            isError = error.confirmPassword,
                            visibilityToggle = false,
                            isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                        )
                    }

                    FormTextInput(
                        modifier = Modifier.fillMaxWidth(),
                        stateVsEvent = userEmailStateVsEvent,
                        label = "Email",
                        id = "email",
                        isMandatory = true,
                        error = FormInputError(isError = error.email),
                        onIme = { focus = formList[1] }
                    )

                    PasswordInputWrap(
                        modifier = Modifier.fillMaxWidth(),
                        stateVsEvent = userPasswordStateVsEvent,
                        label = "Password",
                        isError = error.password,
                        isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                        visibilityToggle = true
                    )
                }
            }
            else -> Unit
        }

        AuthButton(
            modifier = Modifier.margin(top = dimension.largePadding , bottom = dimension.largePadding),
            isLoading = loading,
            label = if (views == LoginViews.LOGIN) "LOGIN" else "REGISTER",
            onClick = {
                when (views) {
                    LoginViews.LOGIN -> {
                        onSubmit(LoginActionEvent.Login)
                    }
                    LoginViews.REGISTER -> {
                        onSubmit(LoginActionEvent.Register)
                    }
                    else -> Unit
                }
            },
        )
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }
}

@Composable
fun RegistrationFormMobile(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: LoginFormFieldError,
    isAlert: Boolean,
    userEmailStateVsEvent: StateVsEvent,
    userFullNameStateVsEvent: StateVsEvent,
    userPhoneStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    userConfirmPasswordStateVsEvent: StateVsEvent,
    onViewChange: TCallback<LoginViews>,
    onSubmit: Callback
) {

    val ctx = rememberPageContext()

    var loading by remember { mutableStateOf(false) }
    val formList =
        listOf(
            "email",
            "full_name",
            "phone",
        )
    var focus by remember { mutableStateOf<String?>(null) }

    Column(
        modifier = modifier.padding(dimension.mediumPadding),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.Top
        ) {
            Text(
                modifier =
                    Modifier.fontWeight(800).fontSize(dimension.largeText).color(color.primary.css),
                text = ResourceString.signupTitle
            )
            Row {
                Text(
                    modifier =
                        Modifier.fontWeight(FontWeight.Bold)
                            .fontSize(dimension.normalText)
                            .color(color.onBackground.css),
                    text = ResourceString.signQuestion
                )
                ClickableText(onClick = { onViewChange(LoginViews.LOGIN) }, text = "Login")
            }

            AlertToast(
                modifier = Modifier.margin(top = dimension.mediumPadding),
                show = isAlert,
                msg = error.msg
            )
        }

        Column(
            modifier =
                Modifier.margin(top = dimension.normalPadding)
                    .fillMaxWidth()
                    .gap(dimension.smallPadding)
        ) {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                stateVsEvent = userEmailStateVsEvent,
                label = "Email",
                id = "email",
                isMandatory = true,
                error = FormInputError(isError = error.email),
                onIme = { focus = formList[1] }
            )

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                stateVsEvent = userFullNameStateVsEvent,
                label = "Full Name",
                id = "full_name",
                isMandatory = true,
                error = FormInputError(isError = error.fullName),
                onIme = { focus = formList[2] }
            )

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Phone Number",
                id = "phone",
                stateVsEvent = userPhoneStateVsEvent,
                isMandatory = true,
                isNumber = true,
                error = FormInputError(isError = error.phone),
                onIme = { it.clearFocus() }
            )

            PasswordInputWrap(
                modifier = Modifier.fillMaxWidth(),
                stateVsEvent = userPasswordStateVsEvent,
                label = "Password",
                isError = error.password,
                isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                visibilityToggle = true
            )
            PasswordInputWrap(
                modifier = Modifier.fillMaxWidth(),
                stateVsEvent = userConfirmPasswordStateVsEvent,
                label = "Confirm Password",
                isError = error.confirmPassword,
                visibilityToggle = false,
                isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
            )

            Row{
                Span {
                    Text(
                        modifier = Modifier
                            .color(color.onBackground.css)
                            .fontWeight(450)
                            .fontSize(dimension.smallText),
                        text = "By continuing, you agree to our ",
                    )
                    ClickableText(
                        modifier = Modifier
                            .color(color.primary.css)
                            .fontWeight(550)
                            .fontSize(dimension.smallText),
                        onClick = {
                            ctx.router.navigateTo("/terms")
                        },
                        text = "Terms & Conditions",
                    )
                }
            }

            Spacer()



            AuthButton(
                modifier = Modifier.margin(top = dimension.largePadding),
                isLoading = loading,
                label = "REGISTER",
                onClick = onSubmit,
            )
        }
    }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}

data class LoginFormFieldError(
    val email: Boolean = false,
    val fullName: Boolean = false,
    val phone: Boolean = false,
    val password: Boolean = false,
    val confirmPassword: Boolean = false,
    val msg: String = ""
) {
    fun hasError(): Boolean {
        return email || fullName || phone || password || confirmPassword
    }
}

@Composable
private fun ColumnScope.LoginNewForm(
    userEmailStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    modifier: Modifier,
    error: LoginFormFieldError
) {

    Column(
        modifier = modifier,
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = userEmailStateVsEvent,
            label = "Email",
            id = "email",
            isMandatory = true,
            error = FormInputError(isError = error.email),
            onIme = {}
        )

        PasswordInputWrap(
            modifier = Modifier.fillMaxWidth(),
            stateVsEvent = userPasswordStateVsEvent,
            label = "Password",
            isError = error.password,
            isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
            visibilityToggle = true
        )
    }
}
