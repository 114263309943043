package org.jjoy.ltd.util.extension

import org.w3c.dom.Window

fun Window.disableNavigateBack() {
    history.pushState(null, kotlinx.browser.document.title, kotlinx.browser.window.location.href)
    addEventListener(
        "popstate",
        { event ->
            // Revert back to the initial state
            history.pushState(
                null,
                kotlinx.browser.document.title,
                kotlinx.browser.window.location.href
            )
            event.preventDefault()
        }
    )
}
