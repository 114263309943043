package org.jjoy.ltd.domain.use_cases.jobs

import org.jjoy.ltd.core.base.ApiUseCase
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.domain.repository.JobRepository

data class SearchQuery(
    val query: String,
    val category: String,
)

class SearchJobs(repository: JobRepository) :
    ApiUseCase<SearchQuery, List<JobListItem>>({ repository.searchJobs(it?.query!!, it.category) })