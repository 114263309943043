package org.jjoy.ltd.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.launch
import kotlinx.serialization.Serializable
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.pages.jjoy.plainVariant
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.FormRow
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants.ADDRESS
import org.jjoy.ltd.util.const.Constants.PHONE
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.extension.smoothBlur
import org.kodein.di.DI
import org.kodein.di.instance
import org.w3c.dom.HTMLElement

@Page("/about/contactUs")
@Composable
fun ContactUs(
    ctx: PageContext = rememberPageContext(),
    di: DI = netWorkModule,
) {

    val apiClient: ApiClient by di.instance()
    val scope = rememberCoroutineScope()

    val breakpoint = rememberBreakpoint()
    FullPageWrapper(title = "Contact us", modifier = Modifier.fillMaxSize()) {
        PageSimpleTitle(textMain = "Contact Us", textDesc = "")

        SimpleDesktopWrapper(
            modifier = Modifier.displayIfAtLeast(Breakpoint.MD).fillMaxSize(),
            variant = plainVariant,
            horizontal = Alignment.CenterHorizontally
        ) {
            Content(breakpoint = breakpoint) {
                scope.launch {
                    apiClient.fetch(
                        path = "contact/request",
                        method = "POST",
                        body = it.encodeToString()
                    )
                    window.alert("Your Request has been submitted. We will respond soon.")
                }
            }
        }

        SimpleMobileWrapper(
            modifier = Modifier.displayUntil(Breakpoint.MD).fillMaxSize(),
            variant = plainVariant,
            horizontal = Alignment.CenterHorizontally
        ) {
            Content(breakpoint = breakpoint, isMobile = true) {
                scope.launch {
                    apiClient.fetch(
                        path = "contact/request",
                        method = "POST",
                        body = it.encodeToString()
                    )
                    window.alert("Your Request has been submitted. We will respond soon.")
                }
            }
        }
    }
}

@Composable
private fun ColumnScope.Content(
    breakpoint: Breakpoint,
    isMobile: Boolean = false,
    onSubmit: TCallback<SupportRequest>
) {

    var fullName by remember { mutableStateOf("") }
    var email by remember { mutableStateOf("") }
    var phone by remember { mutableStateOf("") }
    var message by remember { mutableStateOf("") }

    var isNameError by remember { mutableStateOf(false) }
    var isEmailError by remember { mutableStateOf(false) }
    var isPhoneError by remember { mutableStateOf(false) }
    var isMsgError by remember { mutableStateOf(false) }

    val focusItems =
        listOf(
            "c_name",
            "c_phone",
            "c_email",
            "c_message",
        )

    var focus by remember { mutableStateOf(focusItems[0]) }

    val address =
        if (!isMobile) {
            Row(
                modifier =
                    Modifier.thenIf(
                            breakpoint == Breakpoint.LG || breakpoint == Breakpoint.XL,
                            Modifier.fillMaxWidth(70.percent)
                        )
                        .thenIf(isMobile, Modifier.fillMaxWidth(95.percent))
                        .gap(dimension.mediumPadding),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Center
            ) {
                SimpleContactUsCard(
                    modifier = Modifier.fillMaxWidth(),
                    icon = "house",
                    title = "Address",
                    desc = ADDRESS
                )
                SimpleContactUsCard(
                    modifier = Modifier.fillMaxWidth(),
                    icon = "phone",
                    title = "Phone",
                    desc = PHONE
                )
            }
        } else {
            Column(
                modifier = Modifier.fillMaxWidth(95.percent).gap(dimension.mediumPadding),
                horizontalAlignment = Alignment.CenterHorizontally,
                verticalArrangement = Arrangement.Top
            ) {
                SimpleContactUsCard(
                    modifier = Modifier.fillMaxWidth(),
                    icon = "house",
                    title = "Address",
                    desc = ADDRESS
                )
                SimpleContactUsCard(
                    modifier = Modifier.fillMaxWidth(),
                    icon = "phone",
                    title = "Phone",
                    desc = PHONE
                )
            }
        }

    Column(
        modifier =
            Modifier.thenIf(!isMobile, Modifier.fillMaxWidth(70.percent))
                .thenIf(isMobile, Modifier.fillMaxWidth(95.percent).gap(dimension.smallPadding)),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {
        FormRow {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Full Name",
                id = focusItems[0],
                stateVsEvent = StateVsEvent(fullName) { fullName = it },
                isMandatory = true,
                error = FormInputError(isError = isNameError, msg = "Required"),
                onIme = { focus = focusItems[1] }
            )

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Phone",
                id = focusItems[1],
                stateVsEvent = StateVsEvent(phone) { phone = it },
                isMandatory = true,
                isNumber = true,
                error =
                    FormInputError(
                        isError = isPhoneError,
                        msg = if (phone.length != 10) "Invalid phone Number" else "Required"
                    ),
                onIme = { focus = focusItems[2] }
            )
        }
        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Email",
            id = focusItems[2],
            stateVsEvent = StateVsEvent(email) { email = it },
            isMandatory = true,
            error = FormInputError(isError = isEmailError, msg = "Required"),
            onIme = { focus = focusItems[3] }
        )

        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Message",
            id = focusItems[3],
            stateVsEvent = StateVsEvent(message) { message = it },
            isMandatory = true,
            singleLine = false,
            error = FormInputError(isError = isMsgError, msg = "Required"),
            onIme = {}
        )

        Spacer()

        NormalButton(
            modifier = Modifier.fillMaxWidth().padding(dimension.normalPadding),
            onClick = {
                apply {
                    isEmailError = false
                    isNameError = false
                    isPhoneError = false
                    isMsgError = false
                }
                if (fullName.isBlank() || fullName.length < 2) {
                    isNameError = true
                }

                if (email.isBlank()) {
                    isEmailError = true
                }

                if (phone.isBlank() || phone.length < 12) {
                    isPhoneError = true
                }
                if (message.isBlank()) {
                    isMsgError = true
                }

                if (isEmailError || isNameError || isPhoneError || isMsgError) {
                    // TODO: Show error
                } else {

                    onSubmit(
                        SupportRequest(name = fullName, email = email, phone = phone, msg = message)
                    )
                    apply {
                        fullName = ""
                        email = ""
                        phone = ""
                        message = ""
                    }
                }
            },
            text = "Submit"
        )
        val ctx = rememberPageContext()

        FormRow(
            Modifier
                .displayIfAtLeast(Breakpoint.LG)
                .fillMaxWidth().gap(dimension.smallPadding).cursor(Cursor.Pointer)
        ) {
            Text(
                modifier =
                    Modifier
                        .onClick {
                            ctx.router.navigateTo("/form/update")
                        }
                        .color(color.primary.css)
                        .fontWeight(600)
                        .textDecorationLine(TextDecorationLine.Underline)
                        .fontSize(dimension.normalText * 0.8),
                text = "Finish Registration Form",
            )

            Text(text = " ")

            Text(
                modifier =
                    Modifier
                        .onClick {
                            ctx.router.navigateTo("/form/bank/update")
                        }
                        .color(color.primary.css)
                        .fontWeight(600)
                        .textDecorationLine(TextDecorationLine.Underline)
                        .fontSize(dimension.normalText * 0.8),
                text = "Bank Account Details Form",
            )
        }

        FormColumn(
            Modifier
                .align(Alignment.CenterHorizontally)
                .displayUntil(Breakpoint.MD)
                .fillMaxWidth().gap(dimension.smallPadding).cursor(Cursor.Pointer),
            horizontalAlignment = Alignment.CenterHorizontally,
        ) {
            Text(
                modifier =
                    Modifier
                        .onClick {
                            ctx.router.navigateTo("/form/update")
                        }
                        .color(color.primary.css)
                        .fontWeight(550)
                        .textDecorationLine(TextDecorationLine.Underline)
                        .fontSize(dimension.normalText * 0.6),
                text = "Finish Registration Form",
            )

            Text(
                modifier =
                    Modifier
                        .onClick {
                            ctx.router.navigateTo("/form/bank/update")
                        }
                        .color(color.primary.css)
                        .fontWeight(550)
                        .textDecorationLine(TextDecorationLine.Underline)
                        .fontSize(dimension.normalText * 0.6),
                text = "Bank Account Details Form",
            )
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}

@Composable
fun SimpleContactUsCard(
    modifier: Modifier = Modifier,
    icon: String,
    title: String,
    desc: String,
) {

    Row(
        modifier =
            simpleContactUsCardStyle
                .toModifier()
                .then(modifier.padding(dimension.normalPadding * 0.85))
                .gap(dimension.normalPadding * 0.85),
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Start
    ) {
        FaIcon(
            modifier = Modifier
                .fontSize(dimension.largeText*0.9)
                .color(color.primary.css)
                .margin(dimension.normalPadding),
            name = icon,
            style = IconCategory.SOLID,
        )
        TextWithTitle(
            modifier = Modifier.gap(dimension.extraSmallPadding),
            title = title,
            desc = desc
        )
    }
}

val simpleContactUsCardStyle by ComponentStyle { base { Modifier.rounded().smoothBlur() } }

@Serializable
data class SupportRequest(
    val name: String?,
    val email: String?,
    val phone: String?,
    val msg: String?
)
