package org.jjoy.ltd.util.const

object EndPoints{
    const val ADMIN_LOGIN = "basic/auth/signin"
    const val ADMIN_REGISTER = "basic/auth/signup"
    const val ADMIN_TOKEN_VERIFY = "basic/auth/verify"

    const val ADD_JOB = "admin/job/addJob"
    const val EDIT_JOB = "admin/job/editJob"
    const val DELETE_JOB = "admin/job/delete/"
    const val GET_ALL_JOBS = "admin/job/getAll"
    const val SEARCH_ALL_JOBS = "admin/job/search/"
    const val GET_APPLICATIONS = "admin/application/getAll"

    const val GET_ALL_USERS = "admin/users/getAll"
    const val GET_USER_DETAILS = "admin/users/details/"
    const val SEARCH__ALL_USERS = "admin/users/search/"



    const val GET_JOBS = "job/getAll"
    const val GET_JOBS_INFO = "job/info"
    const val SEARCH_JOBS = "job/search/"
    const val GET_JOB_DETAILS = "job/get/"
    const val APPLY_FOR_JOB = "job/apply"
    const val JOB_CHECK = "job/isApplied/"


    const val REGISTER_USER = "auth/signup"
    const val LOGIN_USER = "auth/signin"
    const val USER_FORM = "form/update/"
    const val USER_BANK_FORM = "form/update/bank"
}