package org.jjoy.ltd.presentation.components.widgets

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.*
import org.jetbrains.compose.web.css.AlignSelf
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.*
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun <T> ModernDropDown(
    selectedItem: T?,
    list: List<T>,
    itemToName: (T?) -> String?,
    onItemSelected: (T) -> Unit,
    modifier: Modifier = Modifier,
    isError: Boolean = false,
    placeHolder: String = "Please select one",
) {



    Div(
        attrs =
        modifier
            .classNames("dropdown", "my-1", "mx-1")
            .alignSelf(AlignSelf.Center)
            .toAttrs()
    ) {
        Button(
            attrs =
            Modifier.classNames("btn", "btn-light", "dropdown-toggle", "btn-md")
                .then(modernDropDownStyle.toModifier())
                .toAttrs { attr("data-bs-toggle", "dropdown") }
        ) {
            Text(itemToName(selectedItem) ?: placeHolder)
        }
        Ul(attrs = Modifier.classNames("dropdown-menu").cursor(Cursor.Pointer).toAttrs()) {
            Li(attrs = Modifier.classNames("dropdown-header").toAttrs()) {
                Text(
                    text = "Please Select The proper One",
                    modifier =
                    Modifier.fontSize(dimension.smallButtonText)
                        .fontWeight(500)
                        .color(color.onBackground.css(80))
                )
            }

            list.forEach { item ->
                Li(
                    attrs =
                    Modifier.classNames("dropdown-item")
                        .thenIf(
                            selectedItem == item,
                            Modifier.backgroundColor(color.primary.css).classNames("active")
                        )
                        .onClick { onItemSelected(item) }
                        .toAttrs()
                ) {
                    Text(
                        text = itemToName(item) ?: "ERROR",
                        modifier = Modifier.fontSize(dimension.smallText * 1.2).fontWeight(500)
                    )
                }
            }
        }
    }
}

val modernDropDownStyle by ComponentStyle{
    base {
        Modifier.backgroundColor(color.primary.css)
            .padding(dimension.normalPadding * 0.55)
            .borderRadius(dimension.normalRoundness)
            .border(
                width = dimension.divider,
                style = LineStyle.Solid,
                color = color.primary.css
            ).color(color.onPrimary.css)
            .fontSize(dimension.smallText)
            .fontWeight(600)
            .animate(prop = listOf("color","background-color","width"))
    }

    hover {
        Modifier
            .border(
                width = dimension.divider,
                style = LineStyle.Solid,
                color = color.primary.rgb.darkened()
            )
            .backgroundColor(color.primary.rgb.darkened()).color(color.onPrimary.css)
    }
    focus {
        Modifier
            .border(
                width = dimension.divider,
                style = LineStyle.Solid,
                color = color.primary.rgb.darkened()
            )
            .backgroundColor(color.primary.rgb.darkened()).color(color.onPrimary.css)
    }

    active {
        Modifier
            .border(
                width = dimension.divider,
                style = LineStyle.Solid,
                color = color.primary.rgb.darkened()
            )
            .backgroundColor(color.primary.rgb.darkened()).color(color.onPrimary.css)
    }
}