package org.jjoy.ltd.data.remote.network

import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.Dispatchers
import kotlinx.coroutines.channels.awaitClose
import kotlinx.coroutines.flow.channelFlow
import kotlinx.coroutines.launch
import kotlinx.coroutines.withContext
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.const.LocalStorageConst
import org.w3c.dom.HTMLAnchorElement
import org.w3c.dom.url.URL
import org.w3c.fetch.Headers
import org.w3c.fetch.RequestInit
import org.w3c.fetch.Response
import org.w3c.files.File
import org.w3c.xhr.FormData
import org.w3c.xhr.XMLHttpRequest
import kotlin.js.Promise

class Api {

    var baseUrl: String = Constants.API_BASE_URL

    val headers = Headers(init = {})

    init {
        //TODO: Fix when logged in registered issue, not using X-id header
        headers.apply {
            localStorage.getItem(LocalStorageConst.USER_ID)?.let {
                if (!has("X-Id")) {
                    append("X-Id", it)
                }
            }
        }
    }
    fun baseUrl(baseUrl: String): Api {
        this.baseUrl = baseUrl
        return this
    }

    fun headers(block: Headers.() -> Unit): Api {
        headers.apply { block(this) }
        return this
    }

    fun build(): ApiClient {
        return ApiClient(baseUrl, headers)
    }
}

class ApiClient(val baseUrl: String, val headers: Headers) {

    val uploadMaxBytes = 8 * 1024 * 1024 // 8MB

    suspend inline fun fetch(
        path: String,
        method: String? = null,
        body: dynamic = undefined,
        options: RequestInit? = null,
    ): Promise<Response> =
        withContext(Dispatchers.Default) {
            return@withContext window.fetch(
                input = "$baseUrl$path",
                init = options
                        ?: RequestInit(
                            method = method,
                            headers =
                                if (method == "POST") {
                                    headers.apply {
                                        if (!has("Content-Type")) {
                                            append("Content-Type", "application/json")
                                        }
                                        // append("Authorization", "Bearer <your_token>")
                                    }
                                } else headers,
                            body = body ?: undefined
                        )
            )
        }

    suspend inline fun multipartUpload(path: String, file: File) =
        channelFlow<ResourceState<String>> {
            val formData = FormData()
            val request = XMLHttpRequest()

            if (file.size.toInt() > uploadMaxBytes) {
                send(ResourceState.Error("Please use file under 8MB"))
                return@channelFlow
            }

            //console.log("FILE NAME:: ${file.size}")
            formData.append(name = "file", value = file, filename = file.name)

            request.open(method = "POST", url = "$baseUrl$path", async = true)
            request.onloadstart = {
                CoroutineScope(Dispatchers.Default).launch { send(ResourceState.Loading()) }
            }
            request.onload = {
                if (request.status == 200.toShort()) {
                    CoroutineScope(Dispatchers.Default).launch {
                        send(ResourceState.Success("Success"))
                    }
                } else {
                    CoroutineScope(Dispatchers.Default).launch {
                        send(ResourceState.Error("Error uploading files ${request.status}"))
                    }
                }
            }
            request.onprogress = {
                //console.log("OnProgress::${it.loaded}")
                CoroutineScope(Dispatchers.Default).launch {
                    send(ResourceState.Loading("Loading:: ${it.loaded}"))
                }
            }
            request.onerror = {
                CoroutineScope(Dispatchers.Default).launch {
                    send(ResourceState.Error("Error uploading files ${request.status}"))
                }
            }

            request.send(formData)
            awaitClose()
        }

    // Use admin id / fileId as url --> dgjsg/17SzigqN5N_h8RlEYO_bc89GGHA9ZxMbA
    fun download(url: String, onSuccess: (String) -> Unit, onError: (String) -> Unit) {

        window
            .fetch("${baseUrl}admin/form/download/$url")
            .then { response -> response.text() }
            .then { blob ->
                // Create a temporary anchor element
                val a = document.createElement("a") as HTMLAnchorElement
                a.href = blob
                a.download = "passport_test.jpg" // todo: use Name

                // Programmatically click the anchor element to trigger the download
                a.click()
                // Clean up the temporary URL and anchor element
                URL.revokeObjectURL(blob)
            }
            .catch { error ->
                // Handle the error
                console.error("Error downloading file: ${error.message}")
            }
    }
}
