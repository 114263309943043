package org.jjoy.ltd.pages

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.height
import com.varabyte.kobweb.compose.ui.modifiers.width
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.sessionStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.percent
import org.jjoy.ltd.components.layouts.PageWrapper
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.components.sections.NavHeader
import org.jjoy.ltd.presentation.composables.OverflowMenu
import org.jjoy.ltd.presentation.screen.home.*
import org.jjoy.ltd.presentation.screen.user_login.LoginActionEvent
import org.jjoy.ltd.presentation.screen.user_login.LoginUIState
import org.jjoy.ltd.presentation.screen.user_login.LoginViews
import org.jjoy.ltd.presentation.screen.user_login.UserLoginViewModel
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID
import org.jjoy.ltd.util.const.MetaConst
import org.w3c.dom.HTMLMetaElement
import org.w3c.dom.get

@Page
@Composable
fun HomePage() {

    LaunchedEffect(Unit){
        document.title = MetaConst.HomeTitle
        window.onload = {
            optimizePageForMeta()
        }
    }

    val breakpoint = rememberBreakpoint()
    val ctx = rememberPageContext()
    val viewModel = rememberViewModel { MainViewModel() }
    val loginViewModel = rememberViewModel { UserLoginViewModel() }

    val userId = localStorage.getItem(USER_ID)
    val isBuffered = sessionStorage.getItem("isBuffered").toBoolean()


    val state by viewModel.collectState()
    val loginState by loginViewModel.collectState()
    val uiEvent by loginViewModel.collectEvent()
    val viewState by loginViewModel.viewState.collectAsState()


    /*val firstRun = !localStorage.getItem("FIRST_RUN").toBoolean()
    if (firstRun){
        localStorage.setItem("FIRST_RUN","true")
    }*/

    var menuOpened by remember { mutableStateOf(false) }


    PageWrapper(title = WEBSITE_NAME, isLoading = state.isPageLoading && !isBuffered) {

        NavHeader{
            menuOpened = !menuOpened
        }

        SimpleHomeSections(
            state = state,
            loginState = loginState,
            onEvent = loginViewModel::onActionEvent,
            onMainEvent = viewModel::onActionEvent,
            ctx = ctx,
            isUserAvailable = !userId.isNullOrBlank(),
            uiEvent = uiEvent,
            views = viewState,
            breakpoint = breakpoint
        )
        if (menuOpened) {
            OverflowMenu(onMenuClosed = { menuOpened = false })
        }
    }

    LaunchedEffect(state.isPageLoading){
        delay(1500)
        viewModel.onActionEvent(MainActionEvent.PageLoading(false))
    }

}


@Composable
fun SimpleHomeSections(
    loginState: LoginUIState,
    uiEvent: CommonUIEvent?,
    views: LoginViews,
    onEvent: (LoginActionEvent) -> Unit,
    onMainEvent: (MainActionEvent) -> Unit,
    isUserAvailable: Boolean,
    state: MainUIState,
    ctx: PageContext,
    breakpoint: Breakpoint
){


    Column(
        Modifier.width(100.percent).height(100.percent),
        horizontalAlignment = Alignment.CenterHorizontally,
    ) {

        if (uiEvent == CommonUIEvent.NavigateNext){
            ctx.router.navigateTo(Section.Jobs.path)
        }

        if (isUserAvailable || uiEvent == CommonUIEvent.NavigateNext) {
            HomeSection()
        } else{
            RegisterSection(
                state = loginState,
                uiEvent = uiEvent,
                views = views,
                ctx = ctx,
                breakpoint = breakpoint,
                onEvent = onEvent
            )
        }
        Welcome()
        Services()
        ApplicationProcess()
        JobCategories(
            ctx = ctx,
            isLoading = state.isJobsListInfoLoading,
            jobsInfoList = state.jobsInfo,
            onEvent = onMainEvent
        )
    }
}




fun setMetaDescription(description: String) {
    val metaTag = document.createElement("meta") as HTMLMetaElement
    metaTag.name = "description"
    metaTag.content = description

    val head = document.getElementsByTagName("head")[0]
    head?.appendChild(metaTag)
}

fun setKeywordsMetaTag(keywords: String) {
    val metaTag = document.createElement("meta") as HTMLMetaElement
    metaTag.name = "keywords"
    metaTag.content = keywords

    val head = document.querySelector("head")
    head?.appendChild(metaTag)
}

fun optimizePageForMeta() {
    // Target keywords related to your content
    val targetKeywords = listOf("J Joy Services", "Service Provider", "Quality Services")

    /*// Set the title tag with a relevant keyword
    val pageTitle = "J Joy Services - Your Trusted Service Provider"
    document.title = pageTitle*/

    // Set the meta description with a keyword-rich description
    val metaDescription = MetaConst.HomeDescription
    setMetaDescription(metaDescription)
    setKeywordsMetaTag("J Joy Services,Service Provider,Quality Services")

    // Add relevant keywords to your content
    val pageContent = """
        <h1>Welcome to J Joy Services</h1>
        <p>We are your trusted <strong>service provider</strong>, offering a wide range of <strong>quality services</strong>.</p>
        <p>At J Joy Services, we pride ourselves on delivering exceptional results for all your <strong>service provider</strong> needs.</p>
        <p>Contact us today to experience the best in <strong>service excellence</strong>.</p>
    """
    document.querySelector("#content")?.innerHTML = pageContent
}
