package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.icons.fa.FaMagnifyingGlass
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Input
import org.jjoy.ltd.components.widgets.buttons.BlurShadowButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun SearchJobField(
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    onClick: Callback,
    compWidth: (CSSSizeValue<CSSUnit.px>) -> Unit = {},
) {

    Row(
        modifier = modifier.then(SearchJobFieldStyle.toModifier()),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
        ref = ref { compWidth(it.getBoundingClientRect().width.px) }
    ) {
        FaMagnifyingGlass(
            modifier = Modifier
                .color(color.primary.rgb)
                .margin(dimension.mediumPadding),
            size = IconSize.XXL
        )
        Input(
            type = InputType.Text,
            attrs =
                SearchJobFieldInputStyle.toModifier()
                    .padding(dimension.normalPadding)
                    .margin(top = dimension.normalPadding, bottom = dimension.normalPadding)
                    .fontSize(dimension.normalText)
                    .backgroundColor(Colors.Transparent)
                    .color(color.onBackground.css)
                    .toAttrs {
                        style { border(0.px) }
                        placeholder("Search for jobs")
                        onInput { stateVsEvent.onValueChange(it.value) }
                    }
        )
        BlurShadowButton(
            modifier = Modifier
                .padding(dimension.mediumPadding)
                .margin(dimension.mediumPadding),
            text = "SEARCH JOB",
            onClick = onClick
        )
    }
}


@Composable
fun SearchJobFieldMobile(
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    onClick: Callback
) {

    Row(
        modifier = modifier.backgroundColor(color.bgGray.css)
            .borderRadius(dimension.mediumRoundness)
            .padding(dimension.normalPadding * 0.7)
            .border(dimension.divider, LineStyle.Solid, Colors.Transparent),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically,
    ) {

        Input(
            type = InputType.Text,
            attrs =
            SearchJobFieldInputStyle.toModifier()
                .padding(dimension.normalPadding * 0.7)
                .margin(top = dimension.smallPadding, bottom = dimension.smallPadding)
                .fontSize(dimension.normalText)
                .backgroundColor(Colors.Transparent)
                .color(color.onBackground.css)
                .toAttrs {
                    style { border(0.px) }
                    placeholder("Search for jobs")
                    onInput { stateVsEvent.onValueChange(it.value) }
                }
        )
    }
}

val stockBase =
    Modifier.backgroundColor(color.background.css)
        .borderRadius(dimension.mediumRoundness)
        .boxShadow(
            color = color.primary.css(35),
            blurRadius = dimension.mediumBlurRadius,
            spreadRadius = 1.px,
            offsetX = 2.px,
            offsetY = 2.px,
        ).border(dimension.divider, LineStyle.Solid, Colors.Transparent)

val SearchJobFieldStyle by ComponentStyle {

    base {
        stockBase
            .animate(prop = listOf("box-shadow","border","color"), dur = 350)
    }

    hover {
        stockBase.boxShadow(
            color = color.primary.css(48),
            blurRadius = dimension.mediumBlurRadius * dimension.mediumPadding.value,
            spreadRadius = 1.px,
            offsetX = 2.px,
            offsetY = 2.px,
        ).border(dimension.divider, LineStyle.Solid, color.primary.rgb)
    }
}

val SearchJobFieldInputStyle by ComponentStyle {
    base { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }

    hover { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }

    focus { Modifier.outline(width = 0.px, style = LineStyle.None, color = Colors.Transparent) }
}
