package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css

@Composable
fun ButtonWithIcon(
    iconName: String = "paper-plane",
    size: CSSSizeValue<CSSUnit.rem> = dimension.normalText,
    text: String = "Apply Now",
    onClick: Callback,
    modifier: Modifier = Modifier
) {
    Button(
        modifier = modifier
            .then(BaseButtonStyle.toModifier()),
        onClick = { onClick() },
        variant = BaseButtonComp,
    ) {
        Row(modifier = Modifier.gap(dimension.smallPadding * 1.7), verticalAlignment = Alignment.CenterVertically) {
            SpanText(
                text = text,
                modifier = Modifier.fontWeight(FontWeight.Bold).fontSize(dimension.normalText)
            )
            FaIcon(
                name = iconName,
                modifier = Modifier
                    .fontSize(size)
                    .color(color.onPrimary.css),
                style = IconCategory.SOLID,
            )
        }
    }
}
