package org.jjoy.ltd.pages.jjoy.job

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import org.jjoy.ltd.presentation.screen.job.JobDetailsScreen
import org.jjoy.ltd.presentation.screen.job.JobDetailsViewModel
import org.jjoy.ltd.util.base.rememberViewModel

@Page("/job/details/{jobId}/{isApplied}")
@Composable
fun JobDetails(){
    val ctx = rememberPageContext()
    val jobId = ctx.route.params.getValue("jobId")
    val isApplied = ctx.route.params.getValue("isApplied")

    val viewModel = rememberViewModel { JobDetailsViewModel(jobId = jobId) }



    /*viewModel.onActionEvent(JobDetailsActionEvent.FetchJobDetails(jobId))
    viewModel.onActionEvent(JobDetailsActionEvent.SetIsApplied(isApplied))*/

    JobDetailsScreen(
        ctx = ctx,
        viewModel = viewModel,
    )
    //TODO: 12.06.23
}