package org.jjoy.ltd.presentation.components.sections

import androidx.compose.runtime.Composable
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.TextDecorationLine
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.navigation.Link
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.anyLink
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.window
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.*
import org.jetbrains.compose.web.dom.Input
import org.jjoy.ltd.components.widgets.buttons.BlurShadowButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.presentation.components.input.SearchJobFieldInputStyle
import org.jjoy.ltd.presentation.components.widgets.AvatarMenu
import org.jjoy.ltd.presentation.components.widgets.AvatarPopover
import org.jjoy.ltd.presentation.components.wrapper.IconMainStyle
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.TopBarStyle
import org.jjoy.ltd.util.const.LocalStorageConst
import org.jjoy.ltd.util.const.LocalStorageConst.BREAK_POINT
import org.jjoy.ltd.util.const.Pages
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.clearLocalStorage
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
private fun NavLink(path: String, text: String) {
    Link(
        path = path,
        text = text,
        modifier = NavLinkStyleBase.toModifier(),
    )
}

@Composable
fun NavHeader(
    modifier: Modifier = Modifier,
    onOverflowClick: () -> Unit = {},
) {
    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()
    val isHome = remember { ctx.route.path == "/" }

    val userId = window.localStorage.getItem(LocalStorageConst.USER_ID)
    val userFullName = window.localStorage.getItem(LocalStorageConst.USER_FULL_NAME)

    Box(modifier = modifier.then(TopBarStyle.toModifier()).position(Position.Fixed).zIndex(2)) {
        val sidePadding =
            if (breakpoint <= Breakpoint.MD) {
                dimension.smallPadding
            } else {
                dimension.largePadding * 0.9
            }
        Row(
            modifier = Modifier.fillMaxSize().padding(left = sidePadding, right = sidePadding),
            verticalAlignment = Alignment.CenterVertically,
        ) {
            if (breakpoint <= Breakpoint.MD) {
                FaIcon(
                    name = "bars",
                    modifier =
                        Modifier.padding(right = dimension.normalPadding)
                            .onClick { onOverflowClick() }
                            .fontSize(dimension.normalText * 1.2)
                            .color(color.primary.css),
                    style = IconCategory.SOLID,
                )
            }
            Image(
                src = "/logo.png",
                desc = "",
                modifier = Modifier.size(dimension.largeText).onClick { ctx.router.navigateTo("/") }
            )

            if (breakpoint > Breakpoint.MD) {
                Row(
                    modifier =
                        Modifier.gap(dimension.normalPadding * 1.3)
                            .margin(left = dimension.normalPadding),
                    verticalAlignment = Alignment.CenterVertically,
                    horizontalArrangement = Arrangement.Start,
                ) {
                    NavLink(path = "/", text = "Home")

                    NavLink(path = "/search/jobs/all/all", text = "Jobs")

                    /*NavLink(
                        path =
                            if (
                                userId.isNullOrBlank() ||
                                    ctx.route.path == "/homeAlt" ||
                                    (ctx.route.path == "/" && userId.isNullOrBlank())
                            )
                                "#service"
                            else "/homeAlt",
                        text = "Service"
                    )*/

                    NavLink(path = "/aboutUs", text = "About Us")

                    NavLink(path = "/about/contactUs", text = "Contact Us")
                }
            }

            Spacer()

            if (userId.isNullOrBlank()) {
                if (isHome) {
                    val (field, setField) = remember { mutableStateOf("") }
                    NavHeaderSearch(
                        stateVsEvent = StateVsEvent(field, setField),
                        onClick = { ctx.router.navigateTo(Pages.searchJobs + field + "/all") }
                    )
                } else {
                    BlurShadowButton(
                        modifier = Modifier.padding(dimension.normalPadding),
                        text = "REGISTER",
                        onClick = {
                            ctx.router.navigateTo("/")
                            window.location.hash = "register"
                        }
                    )
                }
            } else {

                AvatarPopover(
                    userName = userFullName ?: "",
                ) {
                    when (it) {
                        AvatarMenu.LOGOUT -> {
                            clearLocalStorage(BREAK_POINT)
                            ctx.router.navigateTo("/")
                            window.setTimeout({ window.location.reload() }, 300)
                        }
                        else -> {}
                    }
                }
            }
        }
    }
}

val NavLinkStyleBase by ComponentStyle {
    base {
        Modifier.color(color.onBackground.css(80))
            .textAlign(TextAlign.Left)
            .fontSize(dimension.smallText)
            .fontWeight(FontWeight.SemiBold)
            .textDecorationLine(TextDecorationLine.None)
            .animate(prop = listOf("color", "translate"))
    }
    anyLink { Modifier.textDecorationLine(TextDecorationLine.None).color(color.onBackground.rgb) }
    hover { Modifier.color(color.primary.rgb).translateY((-5).percent) }
}

@Composable
fun NavHeaderSearch(
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    onClick: Callback,
) {

    Row(
        modifier =
            modifier.displayIfAtLeast(Breakpoint.MD)
                .backgroundColor(color.bgGray.css)
                .borderRadius(dimension.mediumRoundness)
                .border(dimension.divider, LineStyle.Solid, Colors.Transparent),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.CenterVertically,
    ) {
        Input(
            type = InputType.Text,
            attrs =
                SearchJobFieldInputStyle.toModifier()
                    .padding(dimension.extraSmallPadding)
                    .margin(
                        top = dimension.extraSmallPadding,
                        bottom = dimension.extraSmallPadding,
                        left = dimension.normalPadding
                    )
                    .fontSize(dimension.smallText)
                    .backgroundColor(Colors.Transparent)
                    .color(color.onBackground.css)
                    .toAttrs {
                        style { border(0.px)
                            fontSize(dimension.smallText)
                        }
                        placeholder("Search for jobs")
                        onInput { stateVsEvent.onValueChange(it.value) }
                    }
        )


        Box(
            modifier = modifier
                .margin(dimension.smallPadding)
                .padding(dimension.smallPadding)
                .then(IconMainStyle.toModifier())
                .onClick { onClick() },
            contentAlignment = Alignment.Center
        ){
            FaIcon(
                modifier = Modifier
                    .fontSize(dimension.smallText)
                    .color(color.onPrimary.css)
                    .margin(dimension.smallPadding),
                name = "magnifying-glass",
                style = IconCategory.SOLID,
            )
        }

    }
}
