package org.jjoy.ltd.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIf
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME
import org.jjoy.ltd.util.extension.css

@Page("/terms/privacy-policy")
@Composable
fun PrivacyPolicy() {
    val ctx = rememberPageContext()
    FullPageWrapper(
        title = "privacy-policy",
    ) {
        PageSimpleTitle(
            textMain = "Privacy Policy",
            textDesc =
                "" // "please provide the required details, This is necessary to proceed your job
            // applications"
        )

        SimpleDesktopWrapper(
            modifier = Modifier.displayIf(Breakpoint.MD),
        ) {
            Content()
        }
        SimpleMobileWrapper(
            modifier = Modifier.displayUntil(Breakpoint.MD),
        ) {
            Content()
        }
    }
}

@Composable
private fun ColumnScope.Content() {
    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "At ${Constants.WEBSITE_NAME}, protecting your privacy is very important to us. Our goal is to treat the personal information you provide to us with the utmost respect, and in accordance with this Privacy Policy. This Privacy Policy explains how personal information about you may be collected, used, or disclosed as a consequence of your activities on this Site. We urge you to read it carefully."
    )

    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "By accessing, using and/or downloading materials from or sending or posting materials to, this Site, you agree on your own behalf and on behalf of each entity on whose behalf you act, to the terms of this Privacy Policy and to the practices for the collection, use or disclosure of your personal information as described here."
    )

    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "The following is a condition of use of this website. The submission of information or use of the website is deemed acceptance of these terms.\n" +
                "\n" +
                "All information supplied by candidates (individuals seeking engagement’s, including temporary workers) and clients (organisations paying fees or using free services) is edited, coded and retained within our computerised data systems. Editing and coding is used to standardise the information into acceptable and retrievable formats.\n" +
                "\n" +
                "All information supplied is retained until we are requested to remove this information or at our discretion.\n" +
                "\n" +
                "Access to our computerised systems is protected by differing levels of passwords. Our computer users (often called data processors) have restricted access to the information. Additional security procedures are designed to control access to data (e.g. the printing of data lists) and we have imposed a restriction on staff “taking home” copy files. Such safeguards do not guarantee security of the data (e.g. hacking)\n" +
                "\n" +
                "Some aspects of the personal data stored on our computer systems is transmitted via electronic mail and this includes the sending of electronic mail to countries outside of the EEA. This data may or may not be encoded."
    )

    TextWithTitle(
        title = "Personal Information Collection, Use and Distribution to Third Parties Generally",
        desc =
            "There are times when we may ask you for or you may choose to provide information about yourself or your business to assist us in meeting your various needs. This information may be used for the following purposes: answering your questions, providing you with services you have requested, giving producers and developers of the Site information that is useful in determining appropriate new features, content and services, to provide advertisers with aggregate, not individual, information about our customers, or informing you of additional services or job opportunities provided by $WEBSITE_NAME or by our business partners, that may interest you.\n" +
                "\n" +
                "To provide these services, we may need to ask you directly to provide, among other things, your name, mailing address, postal code, email address and phone number (“Registration Information”). You may also elect to apply on-line to a job posting (free of charge) OR to purchase one of our services. Purchasing services with us may require us to gather additional personal information (including credit or debit card information, where appropriate).\n" +
                "\n" +
                "At times, we also conduct on-line surveys to better understand the needs, profiles, and experience of our visitors. You will be given the option to participate in such surveys. Unless you request to receive the results of the survey via email, no personal information is requested during such surveys and all information is used on an aggregated basis only. If, during the registration process, you indicate you would like us to keep you updated on new site developments and information about our services or about offerings from $WEBSITE_NAME, we will send you these updates by email.\n" +
                "\n" +
                "If you contact $WEBSITE_NAME we may keep a record of that correspondence. We do not collect the email addresses of those who communicate with $WEBSITE_NAME via email. We use the information provided only so that we may respond to the email. We do not knowingly solicit information from children and we do not knowingly market our services to children.\n" +
                "\n" +
                "When you provide personal information to us, we may communicate and disclose it to third parties for the purpose of fulfilling, servicing and completing your requests and the transactions related to it. These third parties may be in the UK or in other parts of the world. By registering with $WEBSITE_NAME, you agree that your information may be shared with third parties for the purpose of fulfilling, servicing and completing your requests and transactions related to it.\n" +
                "\n" +
                "We may also aggregate statistics that we gather about the visitors to the Site, sales, traffic patterns, and services and provide these statistics to third parties; however, when we do, these statistics will not include any personal information that identifies individuals.\n" +
                "\n" +
                "We may also use, disclose and communicate your personal information if required to do so by law including by an order of any court. Although we use all reasonable means to ensure that the information you provide to us is not used by third parties for purposes other than those described in this Privacy Policy, $WEBSITE_NAME is not responsible for any improper use of your personal information that is beyond our reasonable control."
    )

    TextWithTitle(
        title = "CV Collection Policy",
        desc =
            "As part of the services we provide, you may choose to provide us with your CV, to be viewed by our staff members and potential employer who access this Website. In addition to the provisions of this Privacy Policy, the collection, use and distribution of your CV is governed by the Terms & Conditions of Use.\n" +
                "\n" +
                "CVs are accessed by our staff who seek to find you employment with third parties (“Third Parties”). Staff are not permitted to disclose information outside our organization or beyond the Third Party, as applicable. Recruiters are also required to have entered into an agreement with Third Parties that requires such Third Parties to use your CV solely for the purpose of filling a job within the Third Party for which the information was provided and not disclosing your CV outside the organization. However, although $WEBSITE_NAME deals only with reputable organizations, we cannot guarantee that all Employers and Third Parties will adhere to the limitations we impose on them. If at any time you would like your CV removed from our database, you may do so via contact us instructing to do.\n" +
                "\n" +
                "Some job postings which are posted on the $WEBSITE_NAME Site allow you to follow links to where you can apply, and provide your CV, for a specific job posting. In some cases, such applications are still within the $WEBSITE_NAME Site and its control. If you are not asked to login using your $WEBSITE_NAME Registration User ID and password, however, then both the application process, and your CV are outside the $WEBSITE_NAME Site and $WEBSITE_NAME’s control. In such cases you will be providing your CV directly to the Employer or Recruiter and your CV will be subject to their privacy policy. $WEBSITE_NAME is not responsible for the privacy practices of such Employer or Recruiter. In such cases, we encourage you to review the Employer or Recruiter’s privacy policy.\n" +
                "\n" +
                "Candidates should note that a curriculum vitae (CV howsoever obtained and subsequently passed to our clients) may contain information which could identify the candidate. As such, we do not accept liability for disclosure arising from a curriculum vitae supplied by us, to our clients, if the disclosure arose as a result of service.\n" +
                "\n" +
                "Although we use all reasonable means to protect your personal information, $WEBSITE_NAME is not responsible for any improper use of your personal information that is beyond our reasonable control."
    )

    TextWithTitle(
        title = "Purchase of Record",
        desc =
            "From time to time we “purchase” records from recruitment web sites. This information is only obtained in furtherance to a specific request from a client. Once stored on our computerised system the data is processed in the same way as all other records and is afforded the same degree of protection. $WEBSITE_NAME cannot be held responsible for any inaccuracies in the data obtained from these sources, but will take immediate steps to erase or amend data which is known to be inaccurate or appears to be misrepresenting the candidate."
    )
    TextWithTitle(
        title = "Client",
        desc =
            "We consider that all information supplied to us by a potential or existing candidate, (including CV’s and application forms, received electronically or otherwise) is provided for our exclusive use in assisting employers to find potential workers. As such, the provider of the information warrants that the information is correct, that the supplier has authority to provide the information and that $WEBSITE_NAME may use the information in the manner our trained recruitment Staff feel appropriate for the purpose (i.e. in pursuit of seeking potential applicants or workers).\n" +
                "\n" +
                "Various statues (laws) require us to retain some information, even after a candidate has requested to be “removed from our database”. Such records are retained to ensure our compliance with the legislation only and is reduced to the minimum required under the legislation"
    )

    TextWithTitle(
        title = "How Is Your Personal Information Secured and Protected?",
        desc =
            "Where $WEBSITE_NAME considers it appropriate, $WEBSITE_NAME uses encryption and/or authentication tools among other methods to protect certain web-based personal information. E-mails you send us are not necessarily secure when they are transmitted to us. If your communication is sensitive or includes confidential information such as a credit card number, you may want to provide it by post or via the telephone instead.\n" +
                "\n" +
                "We instruct our employees with access to your personal information that it is to be used only in adherence with the principles set forth in this Privacy Policy and the laws applicable to each specific business. The only employees who have access to your personal information are administrative personnel. Employees who misuse customer personal information may be subject to disciplinary action."
    )

    TextWithTitle(
        title = "What If We Change Our Privacy Policy?",
        desc =
            "$WEBSITE_NAME reserves the right to modify or supplement this Privacy Policy statement at any time. If we make any material change to this Privacy Policy, we will update this Site to include such changes and post a notice on our home page, for a period of 30 days, with a link to the updated Privacy Policy. Please read any such notice and the new policy statement. If you return to this Site after a period of more than 30 days, please check this Privacy Policy to confirm that you are aware of the details of the most recent update. Please look at the top of this Privacy Policy to check the date that it was updated and to confirm that you are familiar with the terms of the most recent update. Your continued use of this Site after we post such notice will constitute your acceptance of the new terms and their application to your use of this Site and the personal information disclosed for such use, including personal information previously furnished us, as if they were the initial terms, and your consent to the use of your personal information as described in this privacy policy and elsewhere at our Website. However, we will seek your consent if we want to use your personal information for purposes other than those you have agreed to previously."
    )

    TextWithTitle(
        title = "Website Links",
        desc =
            "This Site may contain links to other sites, including those of our business partners, vendors and advertisers. While we try to link only to sites that share our high standards and respect for privacy, please understand that we are not responsible for the content of, or the privacy practices employed by, other sites."
    )
    TextWithTitle(
        title = "Cookies",
        desc =
            "Cookies are small pieces of data stored by your Internet browser on your computer’s hard drive, which permits us to recognize you when you access this Site. If you are browsing, a cookie is used to help us measure the number of visits, average time spent, page views, and other statistics relating to your use of this Site. If you are searching, a cookie is used to carry the search request data from the request page to the results page. This cookie, by itself, doesn’t tell us your email address or who you are. If you decide to register on-line with us, we may collect additional information to provide tailored job opportunities or other information to you (see Personal Information Collection below). In this case, we use cookies to recognize you on subsequent visits and make your on-line experience more convenient, unless prohibited by law. Please note portions of this Site will not function if you do not accept cookies. Tracking user preferences also helps us serve you targeted advertising. Targeted advertisements are probably more interesting for you and are more productive for our advertisers."
    )

    TextWithTitle(
        title = "What personal data we collect and why we collect it",
        titleSize = dimension.normalText * 1.1,
        desc = ""
    )


    TextWithTitle(
        title = "Media",
        desc =
            "If you upload images to the website, you should avoid uploading images with embedded location data (EXIF GPS) included. Visitors to the website can download and extract any location data from images on the website."
    )

    TextWithTitle(title = "Contact forms", desc = "")

    TextWithTitle(
        title = "Cookies",
        desc =
            "If you leave a comment on our site you may opt-in to saving your name, email address and website in cookies. These are for your convenience so that you do not have to fill in your details again when you leave another comment. These cookies will last for one year.\n" +
                "\n" +
                "If you visit our login page, we will set a temporary cookie to determine if your browser accepts cookies. This cookie contains no personal data and is discarded when you close your browser.\n" +
                "\n" +
                "When you log in, we will also set up several cookies to save your login information and your screen display choices. Login cookies last for two days, and screen options cookies last for a year. If you select “Remember Me”, your login will persist for two weeks. If you log out of your account, the login cookies will be removed.\n" +
                "\n" +
                "If you edit or publish an article, an additional cookie will be saved in your browser. This cookie includes no personal data and simply indicates the post ID of the article you just edited. It expires after 1 day."
    )

    TextWithTitle(
        title = "Embedded content from other websites",
        desc =
            "Articles on this site may include embedded content (e.g. videos, images, articles, etc.). Embedded content from other websites behaves in the exact same way as if the visitor has visited the other website.\n" +
                "\n" +
                "These websites may collect data about you, use cookies, embed additional third-party tracking, and monitor your interaction with that embedded content, including tracking your interaction with the embedded content if you have an account and are logged in to that website."
    )

    TextWithTitle(title = "Analytics", desc = "")

    TextWithTitle(
        title = "Who we share your data with",
        desc =
            "If you request a password reset, your IP address will be included in the reset email."
    )

    TextWithTitle(
        title = "How long we retain your data",
        desc =
            "If you leave a comment, the comment and its metadata are retained indefinitely. This is so we can recognize and approve any follow-up comments automatically instead of holding them in a moderation queue.\n" +
                "\n" +
                "For users that register on our website (if any), we also store the personal information they provide in their user profile. All users can see, edit, or delete their personal information at any time (except they cannot change their username). Website administrators can also see and edit that information."
    )

    TextWithTitle(
        title = "What rights you have over your data",
        desc =
            "If you have an account on this site, or have left comments, you can request to receive an exported file of the personal data we hold about you, including any data you have provided to us. You can also request that we erase any personal data we hold about you. This does not include any data we are obliged to keep for administrative, legal, or security purposes."
    )

    TextWithTitle(
        title = "Where we send your data",
        desc = "Visitor comments may be checked through an automated spam detection service."
    )
}
