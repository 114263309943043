package org.jjoy.ltd.presentation.components.sections

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.objectFit
import com.varabyte.kobweb.silk.components.graphics.Image

@Composable
fun MainBackground() {
    Image(
        modifier = Modifier.fillMaxSize().objectFit(ObjectFit.Cover),
        src = "/bg.svg",
        desc = "     "
    )
}

@Composable
fun PatternBackground(
    modifier: Modifier = Modifier
        .fillMaxSize()
        .objectFit(ObjectFit.Cover)
) {
    Image(
        modifier = modifier,
        src = "/pattern1.svg",
        desc = "     "
    )
}