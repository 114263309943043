package org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs

import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.AdminApi
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.admin.users.AdminLoginRequest
import org.jjoy.ltd.domain.use_cases.admin.users.AdminRegisterRequest
import org.jjoy.ltd.domain.use_cases.admin.users.AdminUsersUseCases
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.EndPoints
import org.jjoy.ltd.util.const.LocalStorageConst
import org.jjoy.ltd.util.const.LocalStorageConst.TOKEN
import org.jjoy.ltd.util.extension.isEmail
import org.kodein.di.DI
import org.kodein.di.instance

class AdminLoginViewModel(
    di: DI = useCasesModule,
) : BaseViewModel<AdminLoginUIState, CommonUIEvent, AdminLoginActionEvent>() {

    private val adminUsersUseCases: AdminUsersUseCases by di.instance()

    init {

        val adminApi =
            AdminApi()
                .headers {
                    localStorage.getItem(LocalStorageConst.TOKEN)?.let {
                        if (!has("Auth-Token")) {
                            append("Auth-Token", it)
                        }
                    }
                }
                .build()

        launch {
            apiFlowResponse<String>(
                    apiCall = {
                        adminApi.fetch(path = EndPoints.ADMIN_TOKEN_VERIFY, method = "GET")
                    }
                )
                .collect {
                    when (it) {
                        is ResourceState.Error -> {}
                        is ResourceState.Loading -> {}
                        is ResourceState.Success -> {
                            sendUIEvent(CommonUIEvent.NavigateNext)
                        }
                        else -> {}
                    }
                }
        }
    }

    override fun initialState(): AdminLoginUIState {
        return AdminLoginUIState(
            userEmail = "",
            password = "",
            isLoading = false,
            isError = false,
            regToken = "",
            errors = AdminLoginErrors()
        )
    }

    override fun onActionEvent(action: AdminLoginActionEvent) {
        when (action) {
            AdminLoginActionEvent.Login -> login()
            AdminLoginActionEvent.Register -> register()
            is AdminLoginActionEvent.UserEmailChanged -> update { copy(userEmail = action.text) }
            is AdminLoginActionEvent.UserPasswordChanged -> update { copy(password = action.text) }
            is AdminLoginActionEvent.UserRegTokenChanged -> update { copy(regToken = action.text) }
        }
    }

    fun login() {
        update { copy(isLoading = true) }
        launch(errorHandler = { console.log("Crash >> $it") }) {
            adminUsersUseCases.adminLogin(
                input =
                    AdminLoginRequest(
                        email = currentState.userEmail,
                        password = currentState.password
                    ),
                onLoading = { update { copy(isLoading = true) } },
                onSuccess = {
                    update { copy(isLoading = false) }
                    localStorage.setItem(TOKEN, it.token)
                    sendUIEvent(CommonUIEvent.NavigateNext)
                },
                onFailure = {
                    update { copy(isLoading = false, isError = true) }
                    window.alert("Login Failed")
                },
            )
        }
    }

    private fun register() {
        val errors = currentState.checkErrors()
        if (errors.hasErrors()){
            update { copy(
                errors = errors
            ) }
            return
        } else {
            update { copy(errors = AdminLoginErrors(), isError = false) }
            launch {
                adminUsersUseCases.adminRegister(
                    input = AdminRegisterRequest(
                        email = currentState.userEmail,
                        password = currentState.password,
                        adminRegToken = currentState.regToken
                    ),
                    onLoading = { update { copy(isLoading = true) } },
                    onSuccess = {
                        update { copy(isLoading = false) }
                        sendUIEvent(CommonUIEvent.NavigateNext)
                    },
                    onFailure = {
                        update {
                            copy(isLoading = false, isError = true, errorMsg = it?.error ?: "Something went wrong")
                        }
                    },
                )
            }
        }
    }
}

data class AdminLoginUIState(
    val userEmail: String,
    val password: String,
    val isError: Boolean,
    val isLoading: Boolean,
    val regToken: String,
    val errorMsg: String = "",
    val errors: AdminLoginErrors
) : UIState {
    fun checkErrors(): AdminLoginErrors {
        val errors = AdminLoginErrors()

        if (userEmail.isEmpty()) {
            errors.emailError.apply {
                isError = true
                msg = "Email is required"
            }
        } else if (!userEmail.isEmail()) {
            errors.emailError.apply {
                isError = true
                msg = "Email is invalid"
            }
        }

        if (password.isEmpty()) {
            errors.passwordError.apply {
                isError = true
                msg = "Password is required"
            }
        } else if (password.length < 12) {
            errors.passwordError.apply {
                isError = true
                msg = "Password must contain at least 12 characters"
            }
        } else if (!".*\\d.*".toRegex().matches(password)) {
            errors.passwordError.apply {
                isError = true
                msg = "Password must contain at least one digit"
            }
        } else if (!".*[A-Z].*".toRegex().matches(password)) {
            errors.passwordError.apply {
                isError = true
                msg = "Password must contain at least one uppercase letter"
            }
        } else if (!".*[a-z].*".toRegex().matches(password)) {
            errors.passwordError.apply {
                isError = true
                msg = "Password must contain at least one lowercase letter"
            }
        } else if (!".*[!@#$%^&*()].*".toRegex().matches(password)) {
            errors.passwordError.apply {
                isError = true
                msg = "Password must contain at least one special character (!@#$%^&*())"
            }
        }

        if (regToken.isEmpty()) {
            errors.inviteTokenError.apply {
                isError = true
                msg = "Registration Token is required"
            }
        } else if (regToken.length < 15) {
            errors.inviteTokenError.apply {
                isError = true
                msg = "Not a valid Registration token, Contact Admin"
            }
        }

        return errors
    }
}

data class AdminLoginErrors(
    var emailError: FormInputError = FormInputError(),
    val passwordError: FormInputError = FormInputError(),
    val inviteTokenError: FormInputError = FormInputError()
){
    fun hasErrors(): Boolean {
        return emailError.isError || passwordError.isError || inviteTokenError.isError
    }
}

sealed interface AdminLoginActionEvent : ActionEvent {
    data class UserEmailChanged(val text: String) : AdminLoginActionEvent
    data class UserPasswordChanged(val text: String) : AdminLoginActionEvent
    data class UserRegTokenChanged(val text: String) : AdminLoginActionEvent
    object Login : AdminLoginActionEvent
    object Register : AdminLoginActionEvent
}
