package org.jjoy.ltd.data.repositoryImpl

import kotlinx.coroutines.InternalCoroutinesApi
import kotlinx.coroutines.flow.Flow
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.core.domain.model.user.UserBankDetails
import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.domain.repository.FormRepository
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.util.const.EndPoints
import org.jjoy.ltd.core.util.toBody
import org.kodein.di.DI
import org.kodein.di.instance
import org.w3c.files.File

class FormRepositoryImpl(di: DI = netWorkModule) : FormRepository {

    private val apiClient: ApiClient by di.instance()

    @InternalCoroutinesApi
    override suspend fun uploadFile(path: String, file: File): Flow<ResourceState<String>> {
        return apiClient.multipartUpload(path = path, file = file)
    }

    // TODO: Do this tmmrww
    override suspend fun downloadFile(path: String, file: File): Flow<ResourceState<String>> {
        TODO("Not yet implemented")
    }

    override suspend fun updateForm(
        userFormDetailsDto: UserFormDetailsDto,
        id: String
    ): ApiFlow<String> {
        return apiFlowResponse<String> {
            apiClient.fetch(
                path = EndPoints.USER_FORM + "/" + id,
                method = "POST",
                body = userFormDetailsDto.toBody(),
            )
        }
    }

    override suspend fun updateBankForm(userBankDetails: UserBankDetails): ApiFlow<String> {
        return apiFlowResponse<String> {
            apiClient.fetch(
                path = EndPoints.USER_BANK_FORM,
                method = "POST",
                body = userBankDetails.toBody(),
            )
        }
    }
}
