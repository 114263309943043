package org.jjoy.ltd.core.domain.model

enum class  WorkingArrangement(val displayName: String) {
    NO("No"),
    FLEXTIME("Flextime"),
    STAGGERED_HOURS("Staggered Hours"),
    JOB_SHARE("Job Share"),
    WORK_FROM_HOME("Work From Home");

    companion object {
        fun getList(): List<WorkingArrangement> {
            return enumValues<WorkingArrangement>().toList()
        }
    }
    fun getName(): String? {
        if (this == null) return null
        return displayName
    }
}

enum class  CommonReaction(val displayName: String) {
    YES("Yes"),
    NO("No"),
    MAYBE("Maybe");

    companion object {
        fun getList(): List<CommonReaction> {
            return enumValues<CommonReaction>().toList()
        }
        fun getStringList(): List<String> {
            return enumValues<CommonReaction>().toList().map {
                it.displayName
            }
        }
    }
    fun getName(): String? {
        if (this == null) return null
        return displayName
    }
}

enum class  Gender(val displayName: String) {
    MALE("Male"),
    FEMALE("Female"),
    NON_BINARY("Non Binary"),
    PREFER_NOT_TO_SAY("Prefer not to say");

    companion object {
        fun getList(): List<Gender> {
            return enumValues<Gender>().toList()
        }
        fun getStringList(): List<String> {
            return enumValues<Gender>().map { it.displayName }
        }
    }
    fun getName(): String? {
        if (this == null) return null
        return displayName
    }
}