package org.jjoy.ltd.presentation.admin.home

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxHeight
import com.varabyte.kobweb.compose.ui.modifiers.padding
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jjoy.ltd.domain.model.NavRailItem
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.extension.css

@Composable
fun NavRail(
    modifier: Modifier = Modifier,
    selectedItem: NavRailItem,
    onItemClick: TCallback<NavRailItem>
) {

    val items = remember { NavRailItem.get() }
    var selectedItem by remember { mutableStateOf(selectedItem) }
    var selectedIndex by remember { mutableStateOf(items.indexOf(selectedItem)) }

    Column(
        modifier = modifier.then(NavRailBG.toModifier()),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        items.forEachIndexed { index, item ->
            NavRailItemView(
                navRailItem = item,
                isActive = index == selectedIndex,
                onClick = {
                    onItemClick(item)
                    selectedItem = item
                    selectedIndex = items.indexOf(item)
                },
            )
        }
    }

    LaunchedEffect(selectedItem) { onItemClick(selectedItem) }
}

val NavRailBG by
    ComponentStyle.base {
        Modifier.fillMaxHeight()
            .backgroundColor(color.background.css(0))
            .padding(dimension.normalPadding)
    }
