package org.jjoy.ltd.presentation.admin.jobs.edit

import kotlinx.datetime.Clock
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.admin.jobs.AdminJobUseCases
import org.jjoy.ltd.domain.use_cases.jobs.JobsUseCases
import org.jjoy.ltd.presentation.admin.jobs.SalaryType
import org.jjoy.ltd.presentation.admin.jobs.toSalary
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.servicesList
import org.kodein.di.DI
import org.kodein.di.instance

class JobEditViewModel(
    id: String,
    di: DI = useCasesModule,
) : BaseViewModel<JobEditUIState, CommonUIEvent, JobEditActionEvent>() {

    private val jobUseCases: JobsUseCases by di.instance()
    private val adminJobUseCases: AdminJobUseCases by di.instance()

    override fun initialState(): JobEditUIState {
        return JobEditUIState(
            isJobLoading = true,
            isLoading = false,
            isError = false,
            errorMsg = "",

            // JObDto
            id = "",
            title = "",
            description = "",
            location = "",
            workLocation = "",
            requirements = listOf(),
            certificatesRequired = listOf(),
            jobType = JobType.FULL_TIME,
            jobCategoryId = "",
            jobCategoryName = servicesList.random().title,
            salary = "",
            isPerHour = SalaryType.HOURLY,
            isFeatured = false,
            postedAt = 1,
            isDeleted = false,
            isExpired = false,
            expiryAt = 1
        )
    }

    init {
        fetchJob(id)
    }

    override fun onActionEvent(action: JobEditActionEvent) {
        when (action) {
            is JobEditActionEvent.CertificatesRequiredChanged ->
                update { copy(certificatesRequired = action.v) }
            is JobEditActionEvent.DescriptionChanged -> update { copy(description = action.v) }
            is JobEditActionEvent.ExpiryAtChanged -> update { copy(expiryAt = action.v) }
            is JobEditActionEvent.IsDeletedChanged -> update { copy(isDeleted = action.v) }
            is JobEditActionEvent.IsExpiredChanged -> update { copy(isExpired = action.v) }
            is JobEditActionEvent.IsFeaturedChanged -> update { copy(isFeatured = action.v) }
            is JobEditActionEvent.IsPerHourChanged -> update { copy(isPerHour = action.v) }
            is JobEditActionEvent.JobCategoryIdChanged -> update { copy(jobCategoryId = action.v) }
            is JobEditActionEvent.JobCategoryNameChanged ->
                update { copy(jobCategoryName = action.v) }
            is JobEditActionEvent.JobTypeChanged -> update { copy(jobType = action.v) }
            is JobEditActionEvent.LocationChanged -> update { copy(location = action.v) }
            is JobEditActionEvent.RequirementsChanged -> update { copy(requirements = action.v) }
            is JobEditActionEvent.SalaryChanged -> {

                if (action.v.contains("--")) return
                if (action.v == "-") return

                val parts = action.v.split("-")

                val result =
                    when (parts.size) {
                        1 -> action.v.trim()
                        2 -> {
                            val firstPart = parts[0].trim()
                            val secondPart = parts[1].trim()
                            if (secondPart.isBlank()) action.v
                            if (
                                firstPart.toIntOrNull() != null && secondPart.toIntOrNull() != null
                            ) {
                                "$firstPart-$secondPart"
                            } else {
                                action.v.trim()
                            }
                        }
                        else -> return
                    }
                update { copy(salary = result) }
            }
            is JobEditActionEvent.TitleChanged -> update { copy(title = action.v) }
            is JobEditActionEvent.WorkLocationChanged -> update { copy(workLocation = action.v) }
            JobEditActionEvent.UpdateJob -> {
                if (!currentState.isJobLoading && !currentState.isLoading) {
                    updateJob()
                }
            }
            JobEditActionEvent.DeleteJob -> {
                if (!currentState.isJobLoading && !currentState.isLoading) {
                    deleteJob()
                }
            }
        }
    }

    private fun updateJob() {

        val jobDto =
            JobDTO(
                id = currentState.id,
                title = currentState.title,
                description = currentState.description,
                location = currentState.location,
                workLocation = currentState.workLocation,
                requirements = currentState.requirements,
                certificatesRequired = currentState.certificatesRequired,
                jobType = currentState.jobType,
                jobCategory = currentState.jobCategoryName,
                salary = currentState.salary.toSalary(),
                isPerHour = currentState.isPerHour.v,
                isFeatured = currentState.isFeatured,
                postedAt = currentState.postedAt,
                updatedAt = Clock.System.now().toEpochMilliseconds(),
                expiryAt = currentState.expiryAt,
                isDeleted = currentState.isDeleted,
                isExpired = currentState.isExpired
            )


        launch {
            adminJobUseCases.updateJob(
                input = jobDto,
                onLoading = {
                    update { copy(isLoading = true) }
                },
                onFailure = {
                    update {
                        copy(isLoading = false, isError = true, errorMsg = "Could Not Update Job")
                    }
                },
                onSuccess = {
                    update {
                        copy(isLoading = false, isError = false)
                    }
                    sendUIEvent(CommonUIEvent.NavigateUp)
                }
            )
        }
    }

    private fun deleteJob() {
        launch {
            adminJobUseCases.deleteJob(
                input = currentState.id,
                onLoading = {
                    update { copy(isLoading = true) }
                },
                onFailure = {

                    update {
                        copy(isLoading = false, isError = true, errorMsg = "Could Not Delete Job")
                    }
                },
                onSuccess = {

                    update {
                        copy(isLoading = false, isError = false)
                    }
                    sendUIEvent(CommonUIEvent.NavigateUp)
                }
            )
        }
    }

    private fun fetchJob(id: String) {
        launch {
            jobUseCases.getJob(
                input = id,
                onLoading = {
                    update { copy(isJobLoading = true) }
                },
                onFailure = {
                    update {
                        copy(isJobLoading = false, isError = true, errorMsg = "Could Not Fetch Job")
                    }
                },
                onSuccess = { job ->
                    update {
                        copy(
                            id = job.id,
                            title = job.title,
                            description = job.description,
                            location = job.location,
                            workLocation = job.workLocation,
                            requirements = job.requirements,
                            certificatesRequired = job.certificatesRequired,
                            jobType = job.jobType,
                            jobCategoryId = "",
                            jobCategoryName = job.jobCategory,
                            salary = "${job.salary.first}-${job.salary.second}",
                            isPerHour = if (job.isPerHour) SalaryType.HOURLY else SalaryType.YEARLY,
                            isFeatured = job.isFeatured,
                            postedAt = job.postedAt,
                            expiryAt = job.expiryAt,
                            isDeleted = job.isDeleted,
                            isExpired = job.isExpired
                        )
                    }
                    update { copy(isJobLoading = false, isError = false) }
                }
            )
        }
    }
}
