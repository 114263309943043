package org.jjoy.ltd.presentation.admin.users

import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.domain.use_cases.admin.users.AdminUsersUseCases
import org.jjoy.ltd.util.base.launch
import org.kodein.di.DI
import org.kodein.di.instance

class UserDetailViewModel(private val id: String, di: DI = useCasesModule) :
    BaseViewModel<UserDetailUIState, CommonUIEvent, ActionEvent>() {

    private val adminUsersUseCases: AdminUsersUseCases by di.instance()

    override fun initialState(): UserDetailUIState {
        return  UserDetailUIState(
            isPageLoading = true,
            isError = false,
            errorMsg = ""
        )
    }

    init {

        launch {

            adminUsersUseCases.getUserDetails(
                input = id,
                onLoading = { update { copy(isPageLoading = true) } },
                onFailure = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = true,
                            errorMsg = "Cloud not fetch user"
                        )
                    }
                },
                onSuccess = {
                    update {
                        copy(
                            isPageLoading = false,
                            isError = false,
                            errorMsg = "",
                            currentUser = it
                        )
                    }
                }
            )
        }

    }

    override fun onActionEvent(action: ActionEvent) {

    }


}

data class UserDetailUIState(
    val currentUser: UserFormDetailsDto? = null,
    val isPageLoading: Boolean,
    val isError: Boolean,
    val errorMsg: String
): UIState
