package org.jjoy.ltd.data.repositoryImpl

import org.jjoy.ltd.core.data.model.auth.AuthRequest
import org.jjoy.ltd.core.data.model.auth.AuthResponse
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.core.domain.model.user.UserDto
import org.jjoy.ltd.core.domain.model.user.UserFormDetailsDto
import org.jjoy.ltd.domain.repository.UserRepository
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.util.const.EndPoints.LOGIN_USER
import org.jjoy.ltd.util.const.EndPoints.REGISTER_USER
import org.jjoy.ltd.core.util.toBody
import org.kodein.di.DI
import org.kodein.di.instance

class UserRepositoryImpl(
    di: DI = netWorkModule,
) : UserRepository {

    private val apiClient: ApiClient by di.instance()

    override suspend fun registerUser(userDto: UserDto): ApiFlow<AuthResponse> {
        return apiFlowResponse {
            apiClient.fetch(
                path = REGISTER_USER,
                method = "POST",
                body = userDto.toBody(),
            )
        }
    }

    override suspend fun loginUser(authRequest: AuthRequest): ApiFlow<AuthResponse> {
        return apiFlowResponse {
            apiClient.fetch(
                path = LOGIN_USER,
                method = "POST",
                body = authRequest.toBody(),
            )
        }
    }


}
