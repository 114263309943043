package org.jjoy.ltd.core.domain.model


data class ServiceItem(
    val title: String,
    val desc: String = "",
    val icon: String,
    val header: String,
    val items: List<String>
){
    companion object {
        fun List<ServiceItem>.getList(): List<String> {
           return map {
               it.title
           }
        }
    }
}
