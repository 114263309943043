package org.jjoy.ltd

import androidx.compose.runtime.Composable
import androidx.compose.runtime.LaunchedEffect
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.ScrollBehavior
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fontFamily
import com.varabyte.kobweb.compose.ui.modifiers.minHeight
import com.varabyte.kobweb.compose.ui.modifiers.overflowY
import com.varabyte.kobweb.compose.ui.modifiers.scrollBehavior
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.core.App
import com.varabyte.kobweb.silk.SilkApp
import com.varabyte.kobweb.silk.components.layout.Surface
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.common.SmoothColorStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.init.InitSilk
import com.varabyte.kobweb.silk.init.InitSilkContext
import com.varabyte.kobweb.silk.init.registerBaseStyle
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import com.varabyte.kobweb.silk.theme.colors.ColorMode
import com.varabyte.kobweb.silk.theme.colors.getColorMode
import kotlinx.browser.localStorage
import org.jetbrains.compose.web.css.vh
import org.jjoy.ltd.theme.Dimension
import org.jjoy.ltd.theme.getDimension
import org.jjoy.ltd.util.const.LocalStorageConst.BREAK_POINT

private const val COLOR_MODE_KEY = "ltd:colorMode"

var dynamicDimension: Dimension = getDimension(0.93)

@InitSilk
fun initSilk(ctx: InitSilkContext) {

    ctx.apply {
        config.apply {
            initialColorMode =
                localStorage.getItem(COLOR_MODE_KEY)?.let { ColorMode.valueOf(it) }
                    ?: ColorMode.LIGHT
        }

        stylesheet.apply {
            registerBaseStyle("body") {
                Modifier.fontFamily(
                        "-apple-system",
                        "BlinkMacSystemFont",
                        "Segoe UI",
                        "Roboto",
                        "Oxygen",
                        "Ubuntu",
                        "Cantarell",
                        "Fira Sans",
                        "Droid Sans",
                        "Helvetica Neue",
                        "sans-serif"
                    )
                    .styleModifier { property("src", "url(Oswald-Regular.ttf") }
            }

            registerBaseStyle("html") {
                // Always show a vertical scroller, or else our page content shifts when switching
                // from one page that
                // can scroll to one that can't
                Modifier.scrollBehavior(ScrollBehavior.Smooth).overflowY(Overflow.Scroll)
            }
        }
    }
}

@App
@Composable
fun MyApp(content: @Composable () -> Unit) {

    SilkApp {
        val bk = rememberBreakpoint()
        val brk = localStorage.getItem(BREAK_POINT)
        val breakpoint =
            if (brk.isNullOrBlank()) {
                saveBreakpoint(bk)
                bk
            } else {
                val res = enumValueOf<Breakpoint>(brk)
                if (res != bk) {
                    saveBreakpoint(bk)
                    bk
                } else res
            }

        dynamicDimension =
            when (breakpoint) {
                Breakpoint.ZERO -> getDimension(0.77)
                Breakpoint.SM -> getDimension(0.83)
                Breakpoint.MD -> getDimension(0.88)
                Breakpoint.LG -> getDimension(0.96)
                Breakpoint.XL -> getDimension(1.03)
            }

        val colorMode = getColorMode()
        LaunchedEffect(colorMode) { localStorage.setItem(COLOR_MODE_KEY, colorMode.name) }

        Surface(SmoothColorStyle.toModifier().minHeight(100.vh)) { content() }
    }
}

private fun saveBreakpoint(brk: Breakpoint) {
    localStorage.setItem(BREAK_POINT, brk.name)
}
