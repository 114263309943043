import androidx.compose.runtime.CompositionLocalProvider
import com.varabyte.kobweb.core.AppGlobalsLocal
import com.varabyte.kobweb.navigation.RoutePrefix
import com.varabyte.kobweb.navigation.Router
import com.varabyte.kobweb.navigation.UpdateHistoryMode
import com.varabyte.kobweb.silk.components.animation.registerKeyframes
import com.varabyte.kobweb.silk.defer.renderWithDeferred
import kotlin.Unit
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.renderComposable

public fun main(): Unit {
    RoutePrefix.set("")
    val router = Router()
    com.varabyte.kobweb.core.init.initKobweb(router) { ctx ->
        ctx.router.register("/") { org.jjoy.ltd.pages.HomePage() }
        ctx.router.register("/about/contactUs") { org.jjoy.ltd.pages.ContactUs() }
        ctx.router.register("/aboutUs") { org.jjoy.ltd.pages.AboutUs() }
        ctx.router.register("/admin/dash") { org.jjoy.ltd.pages.jjoy.admin.AdminHomePage() }
        ctx.router.register("/admin/jobs/edit/{id}") {
                org.jjoy.ltd.pages.jjoy.admin.job.JobEditPage() }
        ctx.router.register("/admin/user/{id}") { org.jjoy.ltd.pages.jjoy.admin.UserDetailsPage() }
        ctx.router.register("/form/bank/update") { org.jjoy.ltd.pages.jjoy.form.BankDetailsForm() }
        ctx.router.register("/form/update") { org.jjoy.ltd.pages.jjoy.form.UserForm() }
        ctx.router.register("/job/details/{jobId}/{isApplied}") {
                org.jjoy.ltd.pages.jjoy.job.JobDetails() }
        ctx.router.register("/login") { org.jjoy.ltd.pages.jjoy.user_login.UserLogin() }
        ctx.router.register("/markdown") { org.jjoy.ltd.pages.MarkdownPage() }
        ctx.router.register("/search/jobs/{query}/{category}") { org.jjoy.ltd.pages.SearchJobs() }
        ctx.router.register("/service/{index}/show") { org.jjoy.ltd.pages.jjoy.ServiceDescription()
                }
        ctx.router.register("/simple/admin/dash/Gb72KGa/Xhsjbywgdl") {
                org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs.AdminRegister() }
        ctx.router.register("/simple/admin/dashboard/login") {
                org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs.AdminLogin() }
        ctx.router.register("/terms") { org.jjoy.ltd.pages.TermsAndConditions() }
        ctx.router.register("/terms/modernSlavery") { org.jjoy.ltd.pages.TermsModernSlavery() }
        ctx.router.register("/terms/privacy-policy") { org.jjoy.ltd.pages.PrivacyPolicy() }

    }
    router.addRouteInterceptor {
        path = path.removeSuffix(".html").removeSuffix(".htm")
    }

    com.varabyte.kobweb.silk.init.initSilkHook = { ctx ->
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.text.LargeTitleStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.text.BaseTextStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.text.BoldTextStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.text.TextFieldTextStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.text.TextFieldSmallTextStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.TopBarStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.NavItemStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.NavigationItemStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.LogoStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.SocialLinkStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.NavButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.theme.style.MainImageStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.pages.jjoy.plainVariant)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs.SimpleButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.pages.simpleContactUsCardStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.layouts.SectionStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.BigButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.AuthProgress)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.OutlineButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.BlurButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.BaseButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.components.widgets.buttons.mobileButtonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.composables.GoBackStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.sections.FooterStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.sections.NavLinkStyleBase)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.JobItemCardStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.text.ClickableTextStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.closeIconStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.TextInputFieldStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.TextInputFieldSmallStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.SearchJobFieldStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.SearchJobFieldInputStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.EmptyInputStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.passwordBoxStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.input.SimpleInputStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.ServiceCardStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.ServiceExpandingColumn)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.errorAlertStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.DividerStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.AvatarStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.widgets.dropDownStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.widgets.linearProgressStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.widgets.modernDropDownStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.widgets.GradientBoxStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.wrapper.IconMainStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.wrapper.IconWrapperStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.BadgeStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.AdvBadgeStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.components.SimpleBadgeStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.admin.home.NavRailItemStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.admin.home.NavRailItemStyleAlt)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.admin.home.NavRailBG)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.home.stepsCardStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.home.buttonStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.home.SearchJobsStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.home.SearchJobsInputStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.home.JobsCategoryCardStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.user_login.LoginBrandStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapperStyle)
        ctx.theme.registerComponentStyle(org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapperStyle)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.theme.style.text.LargeTitleComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.theme.style.text.BaseTextComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.components.widgets.buttons.BigButtonStyleComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.components.widgets.buttons.OutlineButtonComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.components.widgets.buttons.BlurButtonComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.components.widgets.buttons.BaseButtonComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.components.text.SimpleClickComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.components.input.TextSimple)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.components.input.FilePickerButtonComp)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.components.widgets.MenuItemVariant)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.components.SMBadgeNormalStyle)
        ctx.theme.registerComponentVariants(org.jjoy.ltd.presentation.screen.user_form.pageSwitchButton)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.pages.jjoy.user_login.tra)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.components.widgets.buttons.opacity)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.components.widgets.buttons.scale)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.presentation.components.widgets.blinkingAnimation)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.presentation.components.widgets.widthAnimation)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.presentation.components.wrapper.opacity1)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.presentation.components.wrapper.scale1)
        ctx.stylesheet.registerKeyframes(org.jjoy.ltd.presentation.screen.user_login.transitionTest)
        org.jjoy.ltd.initSilk(ctx)
    }

    router.navigateTo(window.location.href.removePrefix(window.location.origin),
            UpdateHistoryMode.REPLACE)

    // For SEO, we may bake the contents of a page in at build time. However, we will overwrite them
    // the first time we render this page with their composable, dynamic versions. Think of this as
    // poor man's hydration :)
    // See also: https://en.wikipedia.org/wiki/Hydration_(web_development)
    val root = document.getElementById("root")!!
    while (root.firstChild != null) {
        root.removeChild(root.firstChild!!)
    }

    renderComposable(rootElementId = "root") {
        CompositionLocalProvider(
            AppGlobalsLocal provides mapOf("title" to "jJoy")
        ) { org.jjoy.ltd.MyApp {
              router.renderActivePage { renderWithDeferred { it() } }
        } }
    }
}
