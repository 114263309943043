package org.jjoy.ltd.presentation.admin.jobs.edit

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import kotlinx.browser.document
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.components.widgets.buttons.BigButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.core.domain.model.ServiceItem.Companion.getList
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.extension.isDigit
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.presentation.admin.jobs.SalaryType
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.widgets.DotAnimation
import org.jjoy.ltd.presentation.components.widgets.DropDownWithLabel
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.presentation.components.wrapper.Icon
import org.jjoy.ltd.presentation.components.wrapper.PanelWrapper
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.composables.GoBackButton
import org.jjoy.ltd.presentation.screen.user_form.FormRow
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.css
import org.w3c.dom.HTMLElement

@Composable
fun JobEditScreen(
    id: String,
    onFinishTask: () -> Unit,
) {
    val viewModel = rememberViewModel { JobEditViewModel(id) }
    val state by viewModel.collectState()
    val uiEvent by viewModel.collectEvent()

    PanelWrapper(
        modifier = Modifier,
        isLoading = state.isJobLoading,
        contentAlignment = Alignment.TopCenter,
    ) {
        Column(
            modifier = Modifier.fillMaxWidth(80.percent),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            GoBackButton(
                text = "Go Back",
                modifier = Modifier.align(Alignment.Start).margin(top = dimension.largePadding),
                onBack = onFinishTask
            )
            Content(
                modifier = Modifier.fillMaxWidth(),
                onEvent = viewModel::onActionEvent,
                state = state
            )
        }
    }

    uiEvent?.let {
        when (it) {
            is CommonUIEvent.NavigateUp -> onFinishTask()
            else -> {}
        }
    }
}

@Composable
fun Content(
    onEvent: TCallback<JobEditActionEvent>,
    state: JobEditUIState,
    modifier: Modifier = Modifier,
) {

    val focusList =
        listOf(
            "job_title",
            "job_location",
            "work_location",
            "job_desc",
            "job_salary",
            "job_requirements",
            "job_certificates"
        )

    var focus by remember { mutableStateOf<String?>(null) }

    ContentWrap(
        modifier = modifier.padding(bottom = dimension.mediumPadding),
        columnModifier =
            Modifier.gap(dimension.extraSmallPadding)
                .padding(dimension.largePadding)
                .margin(dimension.normalPadding)
                .fillMaxWidth(),
        contentId = Section.Jobs.id,
        contentVerticalArrangement = Arrangement.Top,
        contentHorizontalAlignment = Alignment.CenterHorizontally
    ) {
        FormRow {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(50.percent),
                label = "Job Title",
                id = "job_title",
                stateVsEvent =
                    StateVsEvent(value = state.title) {
                        onEvent(JobEditActionEvent.TitleChanged(it))
                    },
                isMandatory = true,
                error = state.errors.title,
                onIme = { focus = focusList[1] }
            )
            FormTextInput(
                modifier = Modifier.fillMaxWidth(25.percent),
                label = "Job Location",
                id = "job_location",
                stateVsEvent =
                    StateVsEvent(value = state.location) {
                        onEvent(JobEditActionEvent.LocationChanged(it))
                    },
                isMandatory = true,
                error = state.errors.location,
                onIme = { focus = focusList[2] }
            )
            FormTextInput(
                modifier = Modifier.fillMaxWidth(25.percent),
                label = "Work Location",
                id = "work_location",
                stateVsEvent =
                    StateVsEvent(value = state.workLocation) {
                        onEvent(JobEditActionEvent.WorkLocationChanged(it))
                    },
                isMandatory = true,
                error = state.errors.workLocation,
                onIme = { focus = focusList[3] }
            )
        }

        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Description",
            id = "job_desc",
            stateVsEvent =
                StateVsEvent(value = state.description) {
                    onEvent(JobEditActionEvent.DescriptionChanged(it))
                },
            isMandatory = true,
            singleLine = false,
            error = state.errors.description,
            onIme = { focus = focusList[4] }
        )

        FormRow(horizontalArrangement = Arrangement.Start) {
            FormTextInput(
                modifier = Modifier,
                label = "Wage",
                id = "job_salary",
                stateVsEvent =
                    StateVsEvent(value = state.salary) {
                        if (it.isDigit() || it.contains("-")) {
                            onEvent(JobEditActionEvent.SalaryChanged(it))
                        }
                    },
                placeHolder = "14-25 (use this format)",
                isMandatory = true,
                error = state.errors.salary,
                onIme = { focus = focusList[5] }
            )
            DropDownWithLabel(
                label = "Is Hourly",
                selectedItem = state.isPerHour,
                list = SalaryType.getList(),
                itemToName = { it?.text },
                onItemSelected = { onEvent(JobEditActionEvent.IsPerHourChanged(it)) },
                placeHolder = "Please Select One",
                error = state.errors.isPerHour,
            )

            DropDownWithLabel(
                label = "Job Type",
                selectedItem = state.jobType,
                list = JobType.getList(),
                itemToName = { it?.getName() },
                onItemSelected = { onEvent(JobEditActionEvent.JobTypeChanged(it)) },
                placeHolder = "Please Select One",
                error = state.errors.jobType,
            )
            DropDownWithLabel(
                label = "Job Category",
                selectedItem = state.jobCategoryName,
                list = servicesList.getList(),
                itemToName = { it },
                onItemSelected = { onEvent(JobEditActionEvent.JobCategoryNameChanged(it)) },
                placeHolder = "Please Select One",
                error = state.errors.jobCategoryName,
            )
        }

        FormRow(
            verticalAlignment = Alignment.Top,
        ) {
            var reqList by
                remember(state.requirements) {
                    mutableStateOf(state.requirements.toMutableStateList())
                }
            var reqText by remember { mutableStateOf("") }
            Column(modifier = Modifier.fillMaxWidth(50.percent)) {
                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Requirements",
                    id = "job_requirements",
                    stateVsEvent = StateVsEvent(value = reqText) { reqText = it },
                    isMandatory = true,
                    error = state.errors.requirements,
                    onIme = {
                        if (!reqList.contains(reqText) && reqText.isNotBlank()) {
                            reqList.add(reqText)
                            reqText = ""
                        } else {
                            reqText = ""
                        }
                    }
                )

                SimpleGrid(
                    modifier = Modifier.gap(dimension.smallPadding).fillMaxWidth(),
                    numColumns = numColumns(1, 1, 1, 1, 1)
                ) {
                    reqList.sortDescending()
                    reqList.forEach {
                        Box(
                            modifier =
                                Modifier.boxShadow(
                                        offsetY = 1.px,
                                        offsetX = 1.px,
                                        blurRadius = 0.45.cssRem,
                                        spreadRadius = 0.34.cssRem,
                                        color = color.primary.css(10)
                                    )
                                    .borderRadius(dimension.mediumRoundness * 0.5)
                                    .fillMaxWidth()
                                    .padding(dimension.normalPadding),
                            contentAlignment = Alignment.CenterStart
                        ) {
                            Text(
                                modifier =
                                    Modifier.fontSize(dimension.smallText)
                                        .fontWeight(FontWeight.Bold)
                                        .color(color.primary.css),
                                text = it
                            )
                            Icon(
                                modifier = Modifier.align(Alignment.CenterEnd).scale(1).opacity(1),
                                name = "xmark",
                                onClick = { reqList.remove(it) }
                            )
                        }
                    }
                }
                LaunchedEffect(reqList.toList()) {
                    onEvent(JobEditActionEvent.RequirementsChanged(reqList.toList()))
                }
            }

            var certList by
                remember(state.certificatesRequired) {
                    mutableStateOf(state.certificatesRequired.toMutableStateList())
                }
            var certText by remember { mutableStateOf("") }
            Column(modifier = Modifier.fillMaxWidth(50.percent)) {
                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    label = "Require Certificates",
                    id = "job_certificates",
                    stateVsEvent = StateVsEvent(value = certText) { certText = it },
                    isMandatory = true,
                    error = state.errors.certificatesRequired,
                    onIme = {
                        if (!certList.contains(certText) && certText.isNotBlank()) {
                            certList.add(certText)
                            certText = ""
                        } else {
                            certText = ""
                        }
                    }
                )

                SimpleGrid(
                    modifier = Modifier.gap(dimension.smallPadding).fillMaxWidth(),
                    numColumns = numColumns(1, 1, 1, 1, 1)
                ) {
                    certList.sortDescending()
                    certList.forEach {
                        Box(
                            modifier =
                                Modifier.boxShadow(
                                        offsetY = 1.px,
                                        offsetX = 1.px,
                                        blurRadius = 0.45.cssRem,
                                        spreadRadius = 0.34.cssRem,
                                        color = color.primary.css(10)
                                    )
                                    .borderRadius(dimension.mediumRoundness * 0.5)
                                    .fillMaxWidth()
                                    .padding(dimension.normalPadding),
                            contentAlignment = Alignment.CenterStart
                        ) {
                            Text(
                                modifier =
                                    Modifier.fontSize(dimension.smallText)
                                        .fontWeight(FontWeight.Bold)
                                        .color(color.primary.css),
                                text = it
                            )
                            Icon(
                                modifier = Modifier.align(Alignment.CenterEnd).scale(1).opacity(1),
                                name = "xmark",
                                onClick = { reqList.remove(it) }
                            )
                        }
                    }
                }
                LaunchedEffect(certList.toList()) {
                    onEvent(JobEditActionEvent.CertificatesRequiredChanged(certList.toList()))
                }
            }
        }

        Spacer()

        FormRow(
            modifier =
                Modifier.margin(dimension.largePadding)
                    .scale(0)
                    .thenIf(state.isLoading, Modifier.scale(1))
        ) {
            DotAnimation()
        }

        FormRow {
            BigButton(
                modifier = Modifier.backgroundColor(color.error.css).fillMaxWidth(),
                text = "Delete",
                onClick = { onEvent(JobEditActionEvent.DeleteJob) }
            )
            BigButton(
                modifier = Modifier.fillMaxWidth().backgroundColor(color.primary.css),
                text = "Update",
                onClick = { onEvent(JobEditActionEvent.UpdateJob) }
            )
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}
