package org.jjoy.ltd.presentation.admin.users

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.core.domain.model.user.UserInfo

data class UsersUIState(
    val isPageLoading: Boolean,
    val isError: Boolean = false,
    val errorMessage: String = "",
    val isEmpty : Boolean = false,
    val users: List<UserInfo>,
): UIState
