package org.jjoy.ltd.pages.jjoy.form

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.Spacer
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.gap
import com.varabyte.kobweb.compose.ui.modifiers.margin
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIf
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.browser.document
import kotlinx.browser.localStorage
import kotlinx.browser.window
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.percent
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.FormColumn
import org.jjoy.ltd.presentation.screen.user_form.FormRow
import org.jjoy.ltd.presentation.screen.user_form.desktop.FormDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.mobile.FormMobileWrapper
import org.jjoy.ltd.presentation.screen.user_form.pageSwitchButton
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.LocalStorageConst
import org.w3c.dom.HTMLElement

@Page("/form/bank/update")
@Composable
fun BankDetailsForm() {

    val ctx = rememberPageContext()

    val userId =
        localStorage.getItem(LocalStorageConst.USER_ID) ?: run { ctx.router.navigateTo("/login") }

    val list =
        listOf(
            "bank_name",
            "account_holder_name",
            "account_number",
            "sort_code",
            "sign_name",
        )
    val viewModel = rememberViewModel { FormBankDetailsViewModel() }
    val state by viewModel.collectState()
    val uiEvent by viewModel.collectEvent()

    FullPageWrapper("Bank Details", isLoading = state.isLoading) {
        PageSimpleTitle(textMain = "Bank Account Details", textDesc = "")

        DesktopContent(
            onActionEvent = viewModel::onActionEvent,
            state = state,
            formInputs = list,
            modifier = Modifier.displayIf(Breakpoint.MD)
        )
        MobileContent(
            onActionEvent = viewModel::onActionEvent,
            state = state,
            formInputs = list,
            modifier = Modifier.displayUntil(Breakpoint.MD)
        )

        FormRow(
            Modifier.fillMaxWidth(80.percent).gap(0.85.cssRem).margin(top = dimension.normalPadding)
        ) {
            Spacer()
            NormalButton(
                text = " FINISH ",
                onClick = { viewModel.onActionEvent(FormBankActionEvent.Save) },
                variant = pageSwitchButton
            )
        }
    }

    LaunchedEffect(state.isError) {
        if (state.isError) {
            window.alert("Could not Update your details, please try again later")
        }
    }

    uiEvent?.let {
        when (it) {
            is CommonUIEvent.NavigateNext -> {
                window.alert("Successfully Updated your details")
                ctx.router.navigateTo("/")
            }
            else -> Unit
        }
    }
}

@Composable
private fun DesktopContent(
    onActionEvent: (FormBankActionEvent) -> Unit,
    state: FormBankUIState,
    modifier: Modifier = Modifier,
    formInputs: List<String>,
) {

    var focus by remember { mutableStateOf<String?>(null) }

    FormDesktopWrapper(modifier = modifier.gap(dimension.mediumPadding)) {
        FormRow {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Bank/Building Society Name",
                id = "bank_name",
                stateVsEvent =
                    StateVsEvent(value = state.bankName) {
                        onActionEvent(FormBankActionEvent.BankNameChanged(it))
                    },
                isMandatory = true,
                error = state.errors.bankName
            ) {
                focus = formInputs[1]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Account Holder Name",
                id = "account_holder_name",
                stateVsEvent =
                    StateVsEvent(value = state.accountHolder) {
                        onActionEvent(FormBankActionEvent.AccountHolderChanged(it))
                    },
                isMandatory = true,
                error = state.errors.accountHolder
            ) {
                focus = formInputs[2]
            }
        }

        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Account Number",
            id = "account_number",
            stateVsEvent =
                StateVsEvent(value = state.accountNumber) {
                    onActionEvent(FormBankActionEvent.AccountNumberChanged(it))
                },
            isMandatory = true,
            isNumber = true,
            limit = 30,
            error = state.errors.accountNumber
        ) {
            focus = formInputs[3]
        }

        FormRow {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Sort Code",
                id = "sort_code",
                stateVsEvent =
                    StateVsEvent(value = state.sortCode) {
                        onActionEvent(FormBankActionEvent.SortCodeChanged(it))
                    },
                isMandatory = true,
                error = state.errors.sortCode
            ) {
                focus = formInputs[4]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "ENTER YOUR NAME to Declare the above information is correct",
                id = "sign_name",
                stateVsEvent =
                    StateVsEvent(value = state.signName) {
                        onActionEvent(FormBankActionEvent.SignNameChanged(it))
                    },
                isMandatory = true,
                error = state.errors.signName
            ) {
                it.clearFocus()
            }
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}

@Composable
private fun MobileContent(
    onActionEvent: (FormBankActionEvent) -> Unit,
    state: FormBankUIState,
    modifier: Modifier = Modifier,
    formInputs: List<String>,
) {

    var focus by remember { mutableStateOf<String?>(null) }

    FormMobileWrapper(modifier.gap(dimension.mediumPadding)) {
        FormColumn {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Bank/Building Society Name",
                id = "bank_name",
                stateVsEvent =
                    StateVsEvent(value = state.bankName) {
                        onActionEvent(FormBankActionEvent.BankNameChanged(it))
                    },
                isMandatory = true,
                error = state.errors.bankName
            ) {
                focus = formInputs[1]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Account Holder Name",
                id = "account_holder_name",
                stateVsEvent =
                    StateVsEvent(value = state.accountHolder) {
                        onActionEvent(FormBankActionEvent.AccountHolderChanged(it))
                    },
                isMandatory = true,
                error = state.errors.accountHolder
            ) {
                focus = formInputs[2]
            }
        }

        FormTextInput(
            modifier = Modifier.fillMaxWidth(),
            label = "Account Number",
            id = "account_number",
            stateVsEvent =
                StateVsEvent(value = state.accountNumber) {
                    onActionEvent(FormBankActionEvent.AccountNumberChanged(it))
                },
            isMandatory = true,
            isNumber = true,
            limit = 30,
            error = state.errors.accountNumber
        ) {
            focus = formInputs[3]
        }

        FormColumn {
            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "Sort Code",
                id = "sort_code",
                stateVsEvent =
                    StateVsEvent(value = state.sortCode) {
                        onActionEvent(FormBankActionEvent.SortCodeChanged(it))
                    },
                isMandatory = true,
                error = state.errors.sortCode
            ) {
                focus = formInputs[4]
            }

            FormTextInput(
                modifier = Modifier.fillMaxWidth(),
                label = "ENTER YOUR NAME to Declare the above information is correct",
                id = "sign_name",
                stateVsEvent =
                    StateVsEvent(value = state.signName) {
                        onActionEvent(FormBankActionEvent.SignNameChanged(it))
                    },
                isMandatory = true,
                error = state.errors.signName
            ) {
                it.clearFocus()
            }
        }
    }

    LaunchedEffect(focus) { focus?.let { (document.getElementById(it) as HTMLElement).focus() } }
}
