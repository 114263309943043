package org.jjoy.ltd.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME
import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME_CAPS
import org.jjoy.ltd.util.extension.css

@Page("/terms/modernSlavery")
@Composable
fun TermsModernSlavery() {
    val ctx = rememberPageContext()
    FullPageWrapper(
        title = "modern slavery",
    ) {
        PageSimpleTitle(
            textMain = "Modern Slavery Statement ",
            textDesc =
                "This Statement is published in accordance with section 54(1) of the United Kingdom Modern Slavery Act 2015 (the “Act”) on behalf of $WEBSITE_NAME."
        )

        SimpleDesktopWrapper(
            modifier = Modifier.displayIfAtLeast(Breakpoint.MD),
        ) {
            Content()
        }
        SimpleMobileWrapper(
            modifier = Modifier.displayUntil(Breakpoint.MD),
        ) {
            Content()
        }
    }
}

@Composable
private fun ColumnScope.Content() {
    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "$WEBSITE_NAME_CAPS was created to offer recruitment solutions to public and private sector employers,and it’s main basis of operation is in the United Kingdom.  $WEBSITE_NAME_CAPS is committed to acting responsibly, ethically and with integrity in all aspects of our business. This includes, in particular, ensuring that  $WEBSITE_NAME_CAPS does not act in a way that might give rise to the furtherance of servitude or modern slavery."
    )

    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "Our supply chains include, but are not limited to, sourcing candidates for clients. This may involve the introduction by external agencies to $WEBSITE_NAME_CAPS of candidates for onward supply to our clients. We expect our suppliers and potential suppliers to aim for high ethical standards and to operate in an ethical, legally compliant and professional manner by adhering to our Supplier Code of Conduct. We also expect our suppliers to promote similar standards in their own supply chain."
    )

    Text(
        modifier =
            Modifier.fontSize(dimension.smallText)
                .fontWeight(450)
                .color(color.onBackground.css(90)),
        text =
            "We have considered the risks of modern slavery arising out of our business activities and have concluded that the risk is low based on the nature of our business and supply chain. We will consider appropriate targeted training on modern slavery for certain employees where necessary. This will enable us to better understand our role in mitigating the risks of modern slavery. We will also update our existing policies to specifically address the risks of modern slavery.\n" +
                " $WEBSITE_NAME_CAPS takes responsibility for overseeing our modern slavery efforts. This Statement will be reviewed annually and updated as necessary."
    )
}
