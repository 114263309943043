package org.jjoy.ltd.domain.use_cases.admin.jobs

import org.jjoy.ltd.domain.repository.AdminRepository

class AdminJobUseCases(
    repository: AdminRepository
) {
    val getAllJobs = GetAllJobs(repository)
    val searchAllJobs = SearchAllJobs(repository)
    val addJob = AddJob(repository)
    val updateJob = UpdateJob(repository)
    val deleteJob = DeleteJob(repository)
}