package org.jjoy.ltd.theme.style

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.styleModifier
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.theme.toSilkPalette
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css

val TopBarStyle by ComponentStyle {
    base {
        Modifier.fillMaxWidth()
            .backgroundColor(colorMode.toSilkPalette().background)
            .position(Position.Relative)
            .top(0.percent)
            .padding(dimension.normalPadding * 0.9)
            .boxShadow(color = color.primary.css(20), blurRadius = dimension.smallBlurRadius, spreadRadius = 1.px)
    }
}

val NavItemStyle by ComponentStyle {
    // Intentionally invert the header colors from the rest of the page
    val linkColor = colorMode.toSilkPalette().color

    base { Modifier.margin(leftRight = 15.px) }

    link { Modifier.color(linkColor) }
    visited { Modifier.color(linkColor) }
}

val NavigationItemStyle by ComponentStyle {
    base {
        Modifier.color(color.onBackground.css)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    anyLink { Modifier.color(color.onBackground.css) }
    hover { Modifier.color(color.primary.css) }
}

@OptIn(ExperimentalComposeWebApi::class)
val LogoStyle by ComponentStyle {
    base {
        Modifier.transform { rotate(0.deg) }
            .transition(CSSTransition(property = "transform", duration = 200.ms))
    }
    hover { Modifier.transform { rotate((-10).deg) } }
}

val SocialLinkStyle by ComponentStyle {
    base {
        Modifier.color(color.onBackground.css)
            .transition(CSSTransition(property = "color", duration = 200.ms))
    }
    hover { Modifier.color(color.primary.css) }
}

val NavButtonStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.Blue)
            .width(100.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }

    hover {
        Modifier.backgroundColor(Colors.Blue)
            .width(120.px)
            .transition(CSSTransition(property = "width", duration = 200.ms))
    }
}

@OptIn(ExperimentalComposeWebApi::class)
val MainImageStyle by ComponentStyle {
    base {
        Modifier.styleModifier { filter { grayscale(100.percent) } }
            .transition(CSSTransition(property = "filter", duration = 200.ms))
    }
    hover { Modifier.styleModifier { filter { grayscale(0.percent) } } }
}
