package org.jjoy.ltd.presentation.admin.users

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import org.jetbrains.compose.web.css.percent
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectState

@Composable
fun UserDetailScreen(
    viewModel: UserDetailViewModel
){
    val state by viewModel.collectState()
    FullPageWrapper(title = "user_details", isLoading = state.isPageLoading){
        PageSimpleTitle(
            textMain = "User Form Info",
            textDesc = ""
        )
        Content(
            state = state
        )
    }

}

@Composable
private fun Content(
    state: UserDetailUIState,
    modifier: Modifier = Modifier
){
    ContentWrap(
        modifier = modifier.fillMaxSize(80.percent),
        columnModifier =
        Modifier.gap(dimension.extraSmallPadding)
            .padding(dimension.largePadding)
            .margin(dimension.normalPadding)
            .fillMaxWidth(),
        contentVerticalArrangement = Arrangement.Top,
        contentHorizontalAlignment = Alignment.CenterHorizontally
    ) {


        Text(text = "TODO::")

    }
}