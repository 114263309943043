package org.jjoy.ltd.core.domain.model.user

import com.benasher44.uuid.uuid4
import kotlinx.serialization.Serializable

@Serializable
data class UserBankDetails(
    val parentUserId: String = uuid4().toString(),
    val bankName: String,
    val accountHolder: String,
    val accountNumber: String,
    val sortCode: String,
    val signName: String,
    val date: String,
)
