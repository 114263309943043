package org.jjoy.ltd.presentation.screen.home

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.css.ObjectFit
import com.varabyte.kobweb.compose.css.OverflowWrap
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.graphics.Image
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.layout.SimpleGrid
import com.varabyte.kobweb.silk.components.layout.numColumns
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.core.domain.model.ServiceItem
import org.jjoy.ltd.core.domain.model.job.JobCategoryInfo
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.domain.model.Section
import org.jjoy.ltd.pages.MainActionEvent
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun JobCategories(
    ctx: PageContext,
    isLoading: Boolean,
    jobsInfoList: List<JobCategoryInfo>,
    onEvent: (MainActionEvent) -> Unit
) {
    val breakpoint = rememberBreakpoint()

    ContentWrap(
        modifier =
            Modifier.cursor(Cursor.Default)
                .overflowWrap(OverflowWrap.BreakWord)
                .thenIf(
                    breakpoint <= Breakpoint.MD,
                    Modifier.margin(
                        top = dimension.extraLargePadding * dimension.mediumPadding.value
                    )
                )
                .fillMaxWidth(),
        columnModifier = Modifier.fillMaxWidth(),
        contentId = Section.Jobs.id,
        contentVerticalArrangement = Arrangement.Top
    ) {
        Box(modifier = Modifier.fillMaxSize(), contentAlignment = Alignment.Center) {
            Image(
                modifier =
                    Modifier.objectFit(ObjectFit.Cover)
                        .borderRadius(
                            bottomLeft = dimension.mediumRoundness,
                            bottomRight = dimension.mediumRoundness
                        )
                        .width(100.vw)
                        .height(40.vh),
                src = "/categories_header.jpg"
            )

            Text(
                modifier =
                    Modifier.padding(dimension.mediumPadding)
                        .fontSize(dimension.largeText * 1.3)
                        .fontWeight(800)
                        .color(color.background.css(90)),
                text = "TOP CATEGORIES",
            )
        }

        Column(
            modifier = Modifier.fillMaxWidth().margin(topBottom = dimension.mediumPadding),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.CenterHorizontally
        ) {

            // ColorPalette()

            SimpleGrid(
                modifier =
                    Modifier.flexDirection(
                            if (breakpoint <= Breakpoint.MD) FlexDirection.Column
                            else FlexDirection.Row
                        )
                        .then(
                            if (breakpoint > Breakpoint.MD) {
                                Modifier.padding(dimension.largePadding)
                            } else Modifier.padding(dimension.largePadding)
                        )
                        .gap(dimension.mediumPadding * dimension.mediumPadding.value)
                        .margin(top = dimension.mediumPadding, bottom = dimension.largePadding),
                numColumns = numColumns(base = 1, md = 2, lg = 3, xl = 3)
            ) {
                servicesList.forEachIndexed() { index, category ->
                    JobsCategoryCard(
                        item = category,
                        numberOfItems =
                            "${jobsInfoList.find { it.jobCategory == category.title }?.jobCount ?: 0}",
                        onClick = { ctx.router.navigateTo("/search/jobs/all/${category.title}") },
                    )
                }
            }

            NormalButton(
                modifier =
                    Modifier.width(25.vw)
                        .padding(dimension.normalPadding)
                        .margin(top = dimension.normalPadding, bottom = dimension.mediumPadding),
                text = "EXPLORE ALL",
                onClick = { ctx.router.navigateTo("/search/jobs/all/all") }
            )
        }
    }
}

@Composable
private fun JobsCategoryCard(
    item: ServiceItem,
    numberOfItems: String,
    onClick: Callback,
    modifier: Modifier = Modifier,
) {

    Box(
        modifier
            .width(dimension.stepsCard)
            .height(dimension.stepsCard * 0.8)
            .onClick { onClick() }
            .padding(top = dimension.normalPadding),
        contentAlignment = Alignment.TopCenter
    ) {
        Column(
            modifier =
                modifier
                    .gap(dimension.normalPadding * 1.1)
                    .then(JobsCategoryCardStyle.toModifier())
                    .padding(dimension.normalPadding),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            FaIcon(
                modifier =
                    Modifier.padding(top = dimension.mediumPadding)
                        .fontWeight(600)
                        .fontSize(dimension.extraLargeText)
                        .color(color.onSurfaceVariant.css(95)),
                name = item.icon
            )
            Text(
                modifier =
                    Modifier.fontWeight(600)
                        .fontSize(dimension.smallText * 1.4)
                        .color(color.onSurfaceVariant.css(90)),
                text = item.title
            )
            Text(
                modifier =
                    Modifier.fontWeight(800)
                        .fontSize(dimension.mediumText)
                        .color(color.onSurfaceVariant.css(80)),
                text = numberOfItems
            )
        }
    }
}

val JobsCategoryCardStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(color.surfaceVariant.css)
            .borderRadius(dimension.normalRoundness)
            .boxShadow(
                color = color.surfaceVariant.css(30),
                blurRadius = dimension.smallBlurRadius,
                spreadRadius = dimension.smallBlurRadius,
                offsetX = 1.px,
                offsetY = 1.px,
            )
            .fillMaxWidth()
            .border(dimension.divider, LineStyle.Solid, Colors.Transparent)
            .padding(dimension.normalPadding)
            .animate(
                prop = listOf("box-shadow", "border", "height", "color", "translate"),
                dur = 700
            )
    }

    hover {
        Modifier.boxShadow(
                color = color.surfaceVariant.css(40),
                blurRadius = dimension.mediumBlurRadius,
                spreadRadius = 1.px,
                offsetX = 1.px,
                offsetY = 1.px,
            )
            .translateY((-2).percent)
            .border(dimension.divider, LineStyle.Solid, color.onPrimaryContainer.rgb)
    }
}

@Composable
fun ColorPalette() {
    SimpleGrid(numColumns(10)) {
        // create simple box of all colors from $color
        color.iterator().forEach { color ->
            Box(modifier = Modifier.bg(color.css).padding(dimension.normalPadding)) {}
        }
    }
}
