package org.jjoy.ltd.pages.jjoy.form

import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.core.domain.model.user.UserBankDetails
import org.jjoy.ltd.domain.use_cases.form.FormUseCases
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.extension.getDate
import org.kodein.di.DI
import org.kodein.di.instance

class FormBankDetailsViewModel(
    di: DI = useCasesModule
): BaseViewModel<FormBankUIState, CommonUIEvent, FormBankActionEvent>() {

    private val formUseCases: FormUseCases by di.instance()

    override fun initialState(): FormBankUIState {
        return FormBankUIState(
            isLoading = false,
            isError = false,
            bankName = "",
            accountHolder = "",
            accountNumber = "",
            sortCode = "",
            signName = "",
            date = getDate().toString(),
        )
    }

    override fun onActionEvent(action: FormBankActionEvent) {
        when(action){
            is FormBankActionEvent.AccountHolderChanged -> update { copy(accountHolder = action.accountHolder) }
            is FormBankActionEvent.AccountNumberChanged -> update { copy(accountNumber = action.accountNumber) }
            is FormBankActionEvent.BankNameChanged -> update { copy(bankName = action.bankName) }
            is FormBankActionEvent.DateChanged -> update { copy(date = action.date) }
            FormBankActionEvent.Save -> save()
            is FormBankActionEvent.SortCodeChanged -> update { copy(sortCode = action.sortCode) }
            is FormBankActionEvent.SignNameChanged -> update { copy(signName = action.signName) }
        }
    }

    private fun save(){

        val errors = currentState.bankFormErrors()
        if (errors.hasErrors()) {
            update { copy(errors = errors) }
            return
        }

        val data = UserBankDetails(
            accountHolder = currentState.accountHolder,
            accountNumber = currentState.accountNumber,
            bankName = currentState.bankName,
            sortCode = currentState.sortCode,
            signName = currentState.signName,
            date = currentState.date,
        )

        launch {
            formUseCases.updateBankDetails(
                input = data,
                onLoading = {
                    sendUIEvent(CommonUIEvent.Loading)
                    update { copy(isLoading = true, isError = false) }
                },
                onFailure = {
                    update { copy(isLoading = false, isError = true) }
                    sendUIEvent(CommonUIEvent.Error("Could not update form data"))
                },
                onSuccess = {
                    update { copy(isLoading = false) }
                    sendUIEvent(CommonUIEvent.NavigateNext)
                }
            )
        }



    }

}