package org.jjoy.ltd.di

import org.jjoy.ltd.data.remote.ApiService
import org.jjoy.ltd.data.remote.network.Api
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.util.const.Constants
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.singleton

val netWorkModule = DI {

    bind<ApiService> { singleton { ApiService() } }

    bind<ApiClient> {
        singleton {
            Api()
                .baseUrl(Constants.API_BASE_URL)
                .build()
        }
    }

}
