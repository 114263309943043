package org.jjoy.ltd.theme

import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.dynamicDimension

data class Dimension(
    val normalRoundness: CSSSizeValue<CSSUnit.rem>,
    val largeRoundness: CSSSizeValue<CSSUnit.rem>,
    val mediumRoundness: CSSSizeValue<CSSUnit.rem>,
    val extraSmallPadding: CSSSizeValue<CSSUnit.rem>,
    val smallPadding: CSSSizeValue<CSSUnit.rem>,
    val normalPadding: CSSSizeValue<CSSUnit.rem>,
    val mediumPadding: CSSSizeValue<CSSUnit.rem>,
    val largePadding: CSSSizeValue<CSSUnit.rem>,
    val extraLargePadding: CSSSizeValue<CSSUnit.rem>,
    val largeButtonText: CSSSizeValue<CSSUnit.rem>,
    val normalButtonText: CSSSizeValue<CSSUnit.rem>,
    val smallButtonText: CSSSizeValue<CSSUnit.rem>,
    val extraSmallText: CSSSizeValue<CSSUnit.rem>,
    val smallText: CSSSizeValue<CSSUnit.rem>,
    val smallMediumText: CSSSizeValue<CSSUnit.rem>,
    val normalText: CSSSizeValue<CSSUnit.rem>,
    val mediumText: CSSSizeValue<CSSUnit.rem>,
    val semiLargeText: CSSSizeValue<CSSUnit.rem>,
    val largeText: CSSSizeValue<CSSUnit.rem>,
    val extraLargeText: CSSSizeValue<CSSUnit.rem>,
    val textFieldLabelText: CSSSizeValue<CSSUnit.rem>,
    val navRailWidthPercent: CSSSizeValue<CSSUnit.percent>,
    val navRailContentPercent: CSSSizeValue<CSSUnit.percent>,
    val smallBlurRadius: CSSSizeValue<CSSUnit.rem>,
    val mediumBlurRadius: CSSSizeValue<CSSUnit.rem>,
    val outlineWidthSmall: CSSSizeValue<CSSUnit.rem>,
    val avatarSize: CSSSizeValue<CSSUnit.rem>,
    val dividerWidth: CSSSizeValue<CSSUnit.rem>,
    val divider: CSSSizeValue<CSSUnit.rem>,


    val brandLogo: CSSSizeValue<CSSUnit.rem>,

    val stepsCard: CSSSizeValue<CSSUnit.rem>,

)

val dimension: Dimension
    get() = dynamicDimension


 val dimensionMd = Dimension(
    normalRoundness = 0.5.cssRem, // Equivalent to 10px / 16px (assuming base font size of 16px)
    mediumRoundness = 1.02.cssRem, // Equivalent to 10px / 16px (assuming base font size of 16px)
    largeRoundness = 1.5.cssRem,
    extraSmallPadding = 0.1.cssRem,
    smallPadding = 0.35.cssRem,
    normalPadding = 0.85.cssRem,
    mediumPadding = 1.3.cssRem,
    largePadding = 2.cssRem,
    extraLargePadding = 3.5.cssRem,

    largeButtonText = 1.875.cssRem,
    normalButtonText = 1.125.cssRem,
    smallButtonText = 1.2.cssRem,

    // TextSize
    extraSmallText = 0.65.cssRem,
    smallText = 1.cssRem,
    smallMediumText = 1.33.cssRem,
    normalText = 1.85.cssRem,
    mediumText = 2.5.cssRem,
    semiLargeText = 2.9.cssRem,
    largeText = 3.2.cssRem,
    extraLargeText = 4.6.cssRem,
    textFieldLabelText = 1.5.cssRem,

    navRailWidthPercent = 16.percent,
    navRailContentPercent = 84.percent,

    smallBlurRadius = 1.cssRem,
    mediumBlurRadius = 2.5.cssRem,
    outlineWidthSmall = 0.2.cssRem,

    avatarSize = 2.5.cssRem,

    dividerWidth = 10.cssRem,
    divider = 0.12.cssRem,

    brandLogo = 10.cssRem,
    stepsCard = 22.cssRem,
)


 fun getDimension(num: Number): Dimension {
    return Dimension(
        normalRoundness = dimensionMd.normalRoundness * num,
        mediumRoundness = dimensionMd.mediumRoundness * num,
        largeRoundness = dimensionMd.largeRoundness * num,
        extraSmallPadding = dimensionMd.extraSmallPadding * num,
        smallPadding = dimensionMd.smallPadding * num,
        normalPadding = dimensionMd.normalPadding * num,
        mediumPadding = dimensionMd.mediumPadding * num,
        largePadding = dimensionMd.largePadding * num,
        extraLargePadding = dimensionMd.extraLargePadding * num,
        largeButtonText = dimensionMd.largeButtonText * num,
        normalButtonText = dimensionMd.normalButtonText * num,
        smallButtonText = dimensionMd.smallButtonText * num,
        extraSmallText = dimensionMd.extraSmallText * num,
        smallText = dimensionMd.smallText * num,
        smallMediumText = dimensionMd.smallMediumText * num,
        normalText = dimensionMd.normalText * num,
        mediumText = dimensionMd.mediumText * num,
        semiLargeText = dimensionMd.semiLargeText * num,
        largeText = dimensionMd.largeText * num,
        extraLargeText = dimensionMd.extraLargeText * num,
        textFieldLabelText = dimensionMd.textFieldLabelText * num,
        navRailWidthPercent = dimensionMd.navRailWidthPercent,
        navRailContentPercent = dimensionMd.navRailContentPercent,
        smallBlurRadius = dimensionMd.smallBlurRadius * num,
        mediumBlurRadius = dimensionMd.mediumBlurRadius * num,
        outlineWidthSmall = dimensionMd.outlineWidthSmall * num,
        avatarSize = dimensionMd.avatarSize * num,
        dividerWidth = dimensionMd.dividerWidth * num,
        divider = dimensionMd.divider * num,
        brandLogo = dimensionMd.brandLogo * num,
        stepsCard = dimensionMd.stepsCard * num
    )
}