package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.ref
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.ExperimentalComposeWebApi
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.core.domain.model.ServiceItem
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb
import org.jjoy.ltd.util.extension.smoothBlur

@OptIn(ExperimentalComposeWebApi::class)
@Composable
fun ServiceCard(
    item: ServiceItem,
    onClick: Callback,
    modifier: Modifier = Modifier,
) {

    val breakpoint = rememberBreakpoint()

    Box(
        modifier
            .fillMaxSize()
            .onClick { onClick() }
            .padding(top = dimension.normalPadding),
        contentAlignment = Alignment.TopCenter
    ) {
        var calculatedHeight by remember { mutableStateOf(0.px) }
        var currentHeight by remember { mutableStateOf(0.px) }
        var ifTake by remember { mutableStateOf(false) }

        Column(
            modifier =
                modifier
                    .then(ServiceCardStyle.toModifier())
                    .thenIf(breakpoint <= Breakpoint.MD, Modifier.width(85.vw))
                    .padding(dimension.normalPadding)
                    .onMouseOver { currentHeight = calculatedHeight }
                    .onMouseOut { currentHeight = 0.px },
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            Column(
                modifier =  Modifier
                    .fillMaxWidth()
                    .padding(dimension.normalPadding)
                    .flexWrap(FlexWrap.Wrap),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {
                FaIcon(
                    name = item.icon,
                    modifier =
                        Modifier.color(color.primary.css).padding(top = dimension.smallPadding),
                    style = IconCategory.SOLID,
                    size = IconSize.X4
                )

                Text(
                    text = item.title.take(28),
                    modifier =
                        BaseTextStyle.toModifier()
                            .padding(top = dimension.mediumPadding)
                            .color(color.onBackground.css)
                            .textAlign(TextAlign.Center)
                            .fontSize(dimension.smallMediumText)
                            .fontWeight(FontWeight.Bold)
                            .margin(bottom = dimension.normalPadding)
                )
            }


                Column(
                    Modifier.fillMaxWidth().padding(dimension.smallPadding),
                    ref = ref { calculatedHeight = it.getBoundingClientRect().height.px }
                ) {
                    item.items.take(4).forEach {
                        ServiceItemList(modifier = Modifier.fillMaxWidth(), text = it)
                    }
                }
            
        }
    }
}

@Composable
private fun ServiceItemList(modifier: Modifier = Modifier, text: String) {
    Row(
        modifier = modifier.padding(dimension.smallPadding),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {

        FaIcon(
            name = "circle-check",
            modifier = Modifier.color(color.primary.css),
            size = IconSize.X1,
            style = IconCategory.SOLID
        )

        Text(
            modifier = Modifier
                .fontSize(dimension.smallText)
                .fontWeight(FontWeight.Bold)
                .padding(left = dimension.normalPadding)
                .textAlign(TextAlign.Left),
            text = text
        )
    }
}

private val modifier =
    Modifier.backgroundColor(Colors.White)
        .borderRadius(dimension.normalRoundness)
        .smoothBlur()
        .fillMaxWidth()
        .border(dimension.divider, LineStyle.Solid, Colors.Transparent)

val ServiceCardStyle by ComponentStyle {
    base {
        modifier
            .padding(dimension.normalPadding)
            .animate(
                prop =
                    listOf(
                        "box-shadow",
                        "border",
                        "height",
                        "color",
                        "translate"
                    ),
                dur = 450
            )
    }

    hover {
        Modifier.boxShadow(
                color = color.primary.css(60),
                blurRadius = dimension.mediumBlurRadius,
                spreadRadius = 1.px,
                offsetX = 0.px,
                offsetY = 2.px,
            )
            .translateY((-2).percent)
            .border(dimension.divider, LineStyle.Solid, color.primary.rgb)
    }
}

val ServiceExpandingColumn by ComponentStyle.base { Modifier.fillMaxWidth().scaleY(0) }
