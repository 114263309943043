package org.jjoy.ltd.di

import org.jjoy.ltd.data.repositoryImpl.*
import org.jjoy.ltd.domain.repository.*
import org.jjoy.ltd.domain.use_cases.admin.apps.AdminApplicationUseCases
import org.jjoy.ltd.domain.use_cases.admin.jobs.AdminJobUseCases
import org.jjoy.ltd.domain.use_cases.admin.users.AdminUsersUseCases
import org.jjoy.ltd.domain.use_cases.form.FormUseCases
import org.jjoy.ltd.domain.use_cases.jobs.JobsUseCases
import org.jjoy.ltd.domain.use_cases.user.UserUseCases
import org.kodein.di.DI
import org.kodein.di.bind
import org.kodein.di.instance
import org.kodein.di.singleton

val useCasesModule = DI {
    //AdminRepo
    bind<AdminRepository> {  singleton { AdminRepositoryImpl() } }
    bind<AdminJobUseCases> { singleton { AdminJobUseCases(instance()) } }

    //AdminRepo
    bind<AdminUserRepository> {  singleton { AdminUserRepositoryImpl() } }
    bind<AdminUsersUseCases> { singleton { AdminUsersUseCases(instance()) } }



    //JobRepo
    bind<JobRepository> {  singleton { JobRepositoryImpl() } }
    bind<JobsUseCases> { singleton { JobsUseCases(instance()) } }

    //UserRepo
    bind<UserRepository> {  singleton { UserRepositoryImpl() } }
    bind<UserUseCases> { singleton { UserUseCases(instance()) } }

    //FormRepo
    bind<FormRepository> {  singleton { FormRepositoryImpl() } }
    bind<FormUseCases> { singleton { FormUseCases(instance()) } }

    //AdminApplicationRepo
    bind<AdminApplicationRepository> {  singleton { AdminApplicationRepositoryImpl() } }
    bind<AdminApplicationUseCases> { singleton { AdminApplicationUseCases(instance()) } }


}
