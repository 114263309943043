package org.jjoy.ltd.presentation.screen.job

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.dom.clearFocus
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.graphics.lightened
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint.*
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import kotlinx.browser.document
import kotlinx.browser.window
import kotlinx.coroutines.delay
import kotlinx.coroutines.launch
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.components.widgets.buttons.AuthButtonWithIcon
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.pages.SupportRequest
import org.jjoy.ltd.presentation.components.*
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.composables.GoBackButton
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.const.JobSectionID.JOB_DETAILS
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.*
import org.kodein.di.DI
import org.kodein.di.instance
import org.w3c.dom.HTMLElement


//TODO: Fix Apply Button
@Composable
fun JobDetailsScreen(
    ctx: PageContext,
    viewModel: JobDetailsViewModel,
    modifier: Modifier = Modifier.id(JOB_DETAILS),
    di: DI = netWorkModule
) {
     val apiClient: ApiClient by di.instance()
    val breakpoint = rememberBreakpoint()
    val scope = rememberCoroutineScope()

    val state by viewModel.collectState()

    FullPageWrapper(
        title = "Details",
        isLoading = state.isPageLoading || state.isChecking
    ) {
        DesktopContent(
            state = state,
            navigateUp = { ctx.router.navigateTo("/search/jobs/all/all") },
            onApply = viewModel::onActionEvent,
            modifier = Modifier.displayIfAtLeast(MD),
            onContact = { name, tele ->
                //viewModel.onActionEvent(JobDetailsActionEvent.ContactUs(name, tele))
                scope.launch {
                    apiClient.fetch(
                        path = "contact/request",
                        method = "POST",
                        body = SupportRequest(
                            name = name,
                            email = "",
                            phone = tele,
                            msg = "I am interested in the job ${state.job?.title}"
                        ).encodeToString()
                    )
                }
                window.alert("You will be contacted soon!")
            }
        )
        JobDetailsMobileContent(
            state = state,
            onApply = viewModel::onActionEvent,
            modifier = Modifier.displayUntil(MD)
        )
    }

    LaunchedEffect(state.isError) {
        if (state.isError && state.errorMsg.isNotBlank()) {
            window.alert(message = state.errorMsg)
        }
        if (state.isError) {
            delay(1500)
            viewModel.onActionEvent(JobDetailsActionEvent.RestError)
        }
    }
}

@Composable
private fun DesktopContent(
    state: JobDetailsUIState,
    navigateUp: Callback,
    onApply: TCallback<JobDetailsActionEvent>,
    onContact: (String, String) -> Unit,
    modifier: Modifier = Modifier
) {

    Row(
        modifier = modifier.width(90.percent),
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
                Modifier
                    // .align(Alignment.CenterHorizontally)
                    .gap(dimension.largePadding)
                    .fillMaxWidth(),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Top
        ) {
            GoBackButton(
                text = "See all jobs",
                modifier = Modifier.align(Alignment.Start).margin(top = dimension.largePadding),
                onBack = navigateUp
            )
            Row(
                modifier = Modifier.fillMaxWidth().gap(dimension.mediumPadding),
            ) {
                Column(
                    modifier =
                        Modifier.padding(dimension.largePadding * 2.2)
                            .backgroundColor(Colors.White)
                            .boxShadow(
                                offsetY = 1.px,
                                offsetX = 1.px,
                                blurRadius = dimension.smallBlurRadius,
                                spreadRadius = 0.5.cssRem,
                                color = color.primary.css(13)
                            )
                            .width(75.percent)
                            .rounded(),
                    verticalArrangement = Arrangement.Top,
                    horizontalAlignment = Alignment.Start
                ) {
                    JobDetailsView(
                        jobDTO = state.job ?: JobDTO.dummy(),
                        state = state,
                        onApply = {
                            // ctx.router.navigateTo(FORM_DETAILS)
                            onApply(JobDetailsActionEvent.ApplyForJob)
                        }
                    )
                }

                Column(
                    modifier =
                        Modifier.boxShadow(
                                offsetY = 1.px,
                                offsetX = 1.px,
                                blurRadius = dimension.smallBlurRadius,
                                spreadRadius = 0.5.cssRem,
                                color = color.primary.css(13)
                            )
                            .width(24.percent)
                            .rounded()
                            .padding(dimension.normalPadding),
                    horizontalAlignment = Alignment.CenterHorizontally
                ) {
                    val scope = rememberCoroutineScope()
                    var name by remember { mutableStateOf("") }
                    var email by remember { mutableStateOf("") }
                    var tele by remember { mutableStateOf("") }
                    var subject by remember { mutableStateOf("") }

                    var nameE by remember { mutableStateOf(false) }
                    var teleE by remember { mutableStateOf(false) }

                    SpanText(
                        text = "Contact Us",
                        modifier =
                            Modifier.fontSize(dimension.textFieldLabelText)
                                .fontWeight(FontWeight.SemiBold)
                                .color(color.onBackground.css(85))
                    )

                    Column(
                        modifier = Modifier.fillMaxWidth(),
                        horizontalAlignment = Alignment.CenterHorizontally,
                        verticalArrangement = Arrangement.Top
                    ) {
                        val list =
                            listOf(
                                "name_support",
                                "tele_support",
                            )
                        var focus by remember { mutableStateOf<String?>(null) }

                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            label = "Name",
                            id = "name_support",
                            stateVsEvent = StateVsEvent(name) { name = it },
                            isMandatory = true,
                            error = FormInputError(isError = nameE),
                            gap = dimension.extraSmallPadding,
                            onIme = { focus = list[1] }
                        )
                        FormTextInput(
                            modifier = Modifier.fillMaxWidth(),
                            label = "Telephone",
                            id = "tele_support",
                            stateVsEvent = StateVsEvent(tele) { tele = it },
                            isMandatory = true,
                            isNumber = true,
                            error = FormInputError(isError = teleE),
                            gap = dimension.extraSmallPadding,
                            onIme = { it.clearFocus() }
                        )

                        LaunchedEffect(focus) {
                            focus?.let { (document.getElementById(it) as HTMLElement).focus() }
                        }
                    }

                    AuthButtonWithIcon(
                        modifier =
                            Modifier.fillMaxWidth()
                                .padding(dimension.smallPadding)
                                .margin(top = dimension.largePadding),
                        iconName = "paper-plane",
                        isLoading = false,
                        isEnabled = true,
                        label = "Contact us",
                        onClick = {
                            if (name.isNotBlank() && tele.isNotBlank()) {

                                nameE = false
                                teleE = false

                                onContact(name, tele)
                                name = ""
                                tele = ""
                            } else {
                                if (name.isNullOrBlank()) nameE = true

                                if (tele.isNullOrBlank()) teleE = true
                            }
                        } // TODO: Add more buttons and onClick
                    )
                }
            }
        }
    }
}

@Composable
fun ColumnScope.JobDetailsView(
    jobDTO: JobDTO,
    state: JobDetailsUIState,
    onApply: Callback
) {

    val breakpoint = rememberBreakpoint()

    Row(
        modifier =
            Modifier.fillMaxWidth()
                .then(
                    when (breakpoint) {
                        ZERO -> Modifier.margin(top = dimension.smallPadding)
                        SM -> Modifier.margin(top = dimension.smallPadding)
                        MD -> Modifier.margin(top = dimension.smallPadding)
                        LG -> Modifier
                        XL -> Modifier
                    },
                ),
        horizontalArrangement = Arrangement.Center,
        verticalAlignment = Alignment.Top
    ) {
        val icon by remember { mutableStateOf(servicesList.find { it.title == jobDTO.jobCategory }?.icon ?: "user-nurse") }

        JobDetailsIcon(modifier = Modifier.displayIfAtLeast(MD), iconName = icon)
        Column(
            modifier =
                Modifier.then(
                        when (breakpoint) {
                            ZERO -> Modifier
                            SM -> Modifier
                            MD -> Modifier.margin(left = dimension.mediumPadding)
                            LG -> Modifier.margin(left = dimension.mediumPadding)
                            XL -> Modifier.margin(left = dimension.mediumPadding)
                        },
                    )
                    .fillMaxWidth(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            Text(
                text = jobDTO.title,
                modifier =
                    Modifier.fontWeight(600)
                        .fontSize(
                            when (breakpoint) {
                                ZERO -> dimension.normalText * 1.3
                                SM -> dimension.normalText * 1.45
                                MD -> dimension.normalText * 1.8
                                else -> dimension.mediumText
                            }
                        )
                        .color(color.onBackground.css(90))
            )

            Row(
                modifier = Modifier.align(Alignment.Bottom).fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                SimpleBadge(
                    modifier =
                        SimpleBadgeStyle.toModifier(SMBadgeNormalStyle)
                            .padding(
                                top = dimension.smallPadding,
                                bottom = dimension.smallPadding,
                                left = dimension.smallPadding * 2,
                                right = dimension.smallPadding * 2,
                            ),
                    badgeText = jobDTO.jobCategory.capitalize(),
                    onClick = {}
                )
            }
            Row(
                modifier = Modifier.align(Alignment.Bottom).fillMaxWidth(),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.End
            ) {
                SpanText(
                    text = jobDTO.postedAt.toDateString(),
                    modifier =
                        Modifier.fontSize(1.cssRem)
                            .fontWeight(FontWeight.Normal)
                            .color(color.onBackground.css(65))
                )
                FaIcon(
                    name = "clock",
                    modifier =
                        Modifier.color(color.primary.css(90)).margin(left = dimension.smallPadding),
                    style = IconCategory.REGULAR,
                    size = IconSize.SM
                )
            }
        }
    }

    Row(
        modifier = Modifier.fillMaxWidth().margin(top = dimension.mediumPadding).gap(1.cssRem),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.Top
    ) {
        TextWithIcon(text = jobDTO.jobType.getName(), "clock")
        SmallDivider(
            modifier =
                Modifier.align(Alignment.CenterVertically).height(1.8.cssRem).width(0.1.cssRem)
        )
        TextWithIcon(text = jobDTO.location, "location-dot")
    }
    SmallDivider(Modifier.fillMaxWidth().height(0.08.cssRem).margin(top = 1.3.cssRem))

    Row(
        modifier =
            Modifier.fillMaxWidth()
                .margin(top = dimension.mediumPadding, bottom = dimension.largePadding)
                .gap(1.cssRem),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.Top
    ) {
        TextWithIcon(text = jobDTO.getSalary(), "money-bill")
        SmallDivider(
            modifier =
                Modifier.align(Alignment.CenterVertically).height(1.8.cssRem).width(0.1.cssRem)
        )
        TextWithIcon(text = jobDTO.workLocation, "building")
        /*if (jobDTO.isCertificationRequired) {
            SmallDivider(
                modifier =
                    Modifier.align(Alignment.CenterVertically).height(1.8.cssRem).width(0.1.cssRem)
            )
            TextWithIcon(text = "certificates required", "certificate")
        }*/
    }
    JobDescription(jobDTO = jobDTO, state = state, onApply = onApply)
}

@Composable
private fun JobDescription(
    jobDTO: JobDTO,
    state: JobDetailsUIState,
    onApply: Callback
) {

    val brkp = rememberBreakpoint()

    Column(
        modifier = Modifier
            .overflow(Overflow.Hidden)
            .gap(1.cssRem)
            .flexWrap(FlexWrap.Wrap)
            .fillMaxWidth()
        ,
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.Start
    ) {
        Row(
            modifier = Modifier.fillMaxSize(),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        ) {
            SpanText(
                text = "Job Description",
                modifier =
                    Modifier.fontSize(dimension.normalText)
                        .fontWeight(800)
                        .color(color.onBackground.css)
            )
            Spacer()
            if (!state.isJobApplied){
                AuthButtonWithIcon(
                    modifier =
                    Modifier.thenIf(
                        state.isApplied,
                        Modifier.backgroundColor(Colors.Grey.lightened())
                    ),
                    iconName = "paper-plane",
                    isLoading = state.isLoading,
                    isEnabled = !state.isApplied,
                    label = "Apply Now",
                    onClick = onApply
                )
            }

        }

        SpanText(
            text = jobDTO.description,
            modifier =
                Modifier
                    .fillMaxWidth(95.percent)
                    .textAlign(TextAlign.Left)
                    .fontWeight(FontWeight.SemiBold)
                    .color(color.onBackground.css(85))
        )

        SpanText(
            text = "Requirements",
            modifier = Modifier.fontSize(1.35.cssRem).fontWeight(700).color(color.onBackground.css)
        )

        Column(modifier = Modifier.gap(0.2.cssRem)) {
            if (jobDTO.requirements.isEmpty()) {
                SpanText(
                    text = "None",
                    modifier =
                        Modifier.fontSize(1.cssRem)
                            .fontWeight(FontWeight.Bold)
                            .color(color.onBackground.css(85))
                )
            } else {

                jobDTO.requirements.forEach {
                    TextWithIcon(
                        text = it,
                        icon = "circle-dot",
                        iconSize = IconSize.SM,
                        fontSize = 1.cssRem,
                        fontWeight = FontWeight.Bold
                    )
                }
            }
        }

        SpanText(
            text = "Certificates",
            modifier = Modifier.fontSize(1.35.cssRem).fontWeight(700).color(color.onBackground.css)
        )

        Column(modifier = Modifier.gap(0.2.cssRem)) {
            if (!jobDTO.isCertificationRequired) {
                SpanText(
                    text = "None",
                    modifier =
                        Modifier.fontSize(1.cssRem)
                            .fontWeight(FontWeight.Bold)
                            .color(color.onBackground.css(85))
                )
            } else {

                jobDTO.certificatesRequired.forEach {
                    TextWithIcon(
                        text = it,
                        icon = "circle-dot",
                        iconSize = IconSize.SM,
                        fontSize = 1.cssRem,
                        fontWeight = FontWeight.Bold
                    )
                }
            }
        }

        if (!state.isJobApplied){
            AuthButtonWithIcon(
                modifier =
                Modifier.then(
                    when (brkp) {
                        ZERO -> Modifier.fillMaxWidth()
                        SM -> Modifier.fillMaxWidth()
                        MD -> Modifier.fillMaxWidth()
                        else -> Modifier
                    }
                )
                    .padding(dimension.smallPadding)
                    .margin(top = dimension.largePadding)
                    .thenIf(state.isApplied, Modifier.backgroundColor(Colors.Grey.lightened())),
                iconName = "paper-plane",
                isLoading = state.isLoading,
                isEnabled = !state.isApplied,
                label = if (state.isApplied) "Applied" else "Apply Now",
                onClick = onApply
            )
        } else {

            TextWithIcon(
                modifier =
                Modifier
                    .animate(prop = listOf("scale")),
                text = "Applied, awaiting response.",
                icon = "check",
                iconSize = IconSize.XL,
                fontSize = 1.25.cssRem,
                fontColor = color.primary.css,
                iconColor = color.primary.css,
                fontWeight = FontWeight.Bold
            )
        }


        Box(modifier = Modifier.margin(left = 0.5.cssRem), contentAlignment = Alignment.Center) {
            TextWithIcon(
                modifier =
                    Modifier.scale(0)
                        .thenIf(state.isError, Modifier.scale(1))
                        .animate(prop = listOf("scale")),
                text = state.errorMsg,
                icon = "xmark",
                iconSize = IconSize.XL,
                fontSize = dimension.normalText,
                fontColor = color.error.css,
                iconColor = color.error.css,
                fontWeight = FontWeight.Bold
            )

            TextWithIcon(
                modifier =
                    Modifier.scale(0)
                        .thenIf(!state.isError && state.errorMsg.isNotBlank(), Modifier.scale(1))
                        .animate(prop = listOf("scale")),
                text = state.errorMsg,
                icon = "check",
                iconSize = IconSize.XL,
                fontSize = dimension.normalText,
                fontColor = color.primary.css,
                iconColor = color.primary.css,
                fontWeight = FontWeight.Bold
            )
        }
    }
}
