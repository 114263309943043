package org.jjoy.ltd.presentation.screen.user_form.desktop

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.bg
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.extension.smoothBlur

@Composable
fun FormDesktopWrapper(
    modifier: Modifier,
    content: @Composable ColumnScope.() -> Unit
){
    Column(
        modifier =
        modifier
            .width(80.percent)
            .margin(dimension.normalPadding)
            .padding(dimension.largePadding * 2)
            .bg()
            .smoothBlur()
            .rounded(),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Top
    ) {

        content(this)

    }
}

@Composable
fun SimpleDesktopWrapper(
    modifier: Modifier = Modifier,
    variant: ComponentStyle = SimpleDesktopWrapperStyle,
    horizontal: Alignment.Horizontal = Alignment.Start,
    vertical: Arrangement.Vertical = Arrangement.Top,
    content: @Composable ColumnScope.() -> Unit
){
    Column(
        modifier = modifier.width(80.percent)
            .margin(dimension.normalPadding)
            .padding(dimension.largePadding * 2)
            .gap(dimension.mediumPadding).then(variant.toModifier()),
        horizontalAlignment = horizontal,
        verticalArrangement = vertical
    ) {
        content(this)
    }
}

val SimpleDesktopWrapperStyle by ComponentStyle {
    base {
        Modifier.bg()
            .smoothBlur()
            .rounded()
    }
}

@Composable
fun SimpleMobileWrapper(
    modifier: Modifier = Modifier,
    variant: ComponentStyle = SimpleMobileWrapperStyle,
    horizontal: Alignment.Horizontal = Alignment.Start,
    vertical: Arrangement.Vertical = Arrangement.Top,
    content: @Composable ColumnScope.() -> Unit
){
    Column(
        modifier = modifier.fillMaxWidth()
            .margin(
                left = dimension.normalPadding,
                right = dimension.normalPadding,
                top = dimension.mediumPadding,
                bottom = dimension.normalPadding
            )
            .padding(dimension.mediumPadding)
            .gap(dimension.mediumPadding).then(variant.toModifier()),
        horizontalAlignment = horizontal,
        verticalArrangement = vertical
    ) {
        content(this)
    }
}

val SimpleMobileWrapperStyle by ComponentStyle {
    base {
        Modifier.bg()
            .smoothBlur()
            .rounded()
    }
}
