package org.jjoy.ltd.theme

import androidx.compose.runtime.Stable

val light_primary = AppColor(27, 94, 175)
val light_onPrimary = AppColor(255, 255, 255)
val light_primaryContainer = AppColor(214, 227, 255)
val light_onPrimaryContainer = AppColor(0, 27, 61)
val light_secondary = AppColor(86, 95, 113)
val light_onSecondary = AppColor(255, 255, 255)
val light_secondaryContainer = AppColor(218, 226, 249)
val light_onSecondaryContainer = AppColor(19, 28, 43)
val light_tertiary = AppColor(111, 85, 117)
val light_onTertiary = AppColor(255, 255, 255)
val light_tertiaryContainer = AppColor(249, 216, 253)
val light_onTertiaryContainer = AppColor(40, 19, 46)
val light_error = AppColor(186, 26, 26)
val light_errorContainer = AppColor(255, 218, 214)
val light_onError = AppColor(255, 255, 255)
val light_onErrorContainer = AppColor(65, 0, 2)
val light_background = AppColor(253, 251, 255)
val light_onBackground = AppColor(26, 27, 30)
val light_outline = AppColor(116, 119, 127)
val light_inverseOnSurface = AppColor(241, 240, 244)
val light_inverseSurface = AppColor(47, 48, 51)
val light_inversePrimary = AppColor(169, 199, 255)
val light_surfaceTint = AppColor(27, 94, 175)
val light_outlineVariant = AppColor(196, 198, 208)
val light_scrim = AppColor(0, 0, 0)
val light_surface = AppColor(250, 249, 253)
val light_onSurface = AppColor(26, 27, 30)
val light_surfaceVariant = AppColor(224, 226, 236)
val light_onSurfaceVariant = AppColor(68, 71, 78)
val light_shadow = AppColor(0, 0, 0)
val light_bgGray = AppColor(242, 242, 242)

val dark_primary = AppColor(169, 199, 255)
val dark_onPrimary = AppColor(0, 48, 99)
val dark_primaryContainer = AppColor(0, 70, 140)
val dark_onPrimaryContainer = AppColor(214, 227, 255)
val dark_secondary = AppColor(190, 199, 220)
val dark_onSecondary = AppColor(40, 44, 65)
val dark_secondaryContainer = AppColor(62, 71, 89)
val dark_onSecondaryContainer = AppColor(218, 226, 249)
val dark_tertiary = AppColor(220, 188, 225)
val dark_onTertiary = AppColor(63, 40, 69)
val dark_tertiaryContainer = AppColor(86, 62, 92)
val dark_onTertiaryContainer = AppColor(249, 216, 253)
val dark_error = AppColor(255, 180, 171)
val dark_errorContainer = AppColor(147, 0, 10)
val dark_onError = AppColor(105, 0, 5)
val dark_onErrorContainer = AppColor(255, 218, 214)
val dark_background = AppColor(26, 27, 30)
val dark_onBackground = AppColor(227, 224, 230)
val dark_outline = AppColor(142, 144, 153)
val dark_inverseOnSurface = AppColor(26, 27, 30)
val dark_inverseSurface = AppColor(227, 224, 230)
val dark_inversePrimary = AppColor(27, 94, 175)
val dark_surfaceTint = AppColor(169, 199, 255)
val dark_outlineVariant = AppColor(68, 71, 78)
val dark_scrim = AppColor(0, 0, 0)
val dark_surface = AppColor(18, 19, 22)
val dark_onSurface = AppColor(199, 198, 202)
val dark_surfaceVariant = AppColor(68, 71, 78)
val dark_onSurfaceVariant = AppColor(196, 198, 208)
val dark_shadow = AppColor(0, 0, 0)
val dark_bgGray = AppColor(242, 242, 242)






@Stable
data class ColorScheme(
    val primary: AppColor,
    val onPrimary: AppColor,
    val primaryContainer: AppColor,
    val onPrimaryContainer: AppColor,
    val secondary: AppColor,
    val onSecondary: AppColor,
    val secondaryContainer: AppColor,
    val onSecondaryContainer: AppColor,
    val tertiary: AppColor,
    val onTertiary: AppColor,
    val tertiaryContainer: AppColor,
    val onTertiaryContainer: AppColor,
    val error: AppColor,
    val errorContainer: AppColor,
    val onError: AppColor,
    val onErrorContainer: AppColor,
    val background: AppColor,
    val onBackground: AppColor,
    val surface: AppColor,
    val onSurface: AppColor,
    val surfaceVariant: AppColor,
    val onSurfaceVariant: AppColor,
    val outline: AppColor,
    val inverseOnSurface: AppColor,
    val inverseSurface: AppColor,
    val inversePrimary: AppColor,
    val shadow: AppColor,
    val surfaceTint: AppColor,
    val outlineVariant: AppColor,
    val scrim: AppColor,
    val bgGray: AppColor
){
    operator fun iterator(): Iterator<AppColor> {
        return listOf(
            primary,
            onPrimary,
            primaryContainer,
            onPrimaryContainer,
            secondary,
            onSecondary,
            secondaryContainer,
            onSecondaryContainer,
            tertiary,
            onTertiary,
            tertiaryContainer,
            onTertiaryContainer,
            error,
            errorContainer,
            onError,
            onErrorContainer,
            background,
            onBackground,
            surface,
            onSurface,
            surfaceVariant,
            onSurfaceVariant,
            outline,
            inverseOnSurface,
            inverseSurface,
            inversePrimary,
            shadow,
            surfaceTint,
            outlineVariant,
            scrim,
            bgGray
        ).iterator()
    }
}

val lightColorScheme = ColorScheme(
    primary = light_primary,
    onPrimary = light_onPrimary,
    primaryContainer = light_primaryContainer,
    onPrimaryContainer = light_onPrimaryContainer,
    secondary = light_secondary,
    onSecondary = light_onSecondary,
    secondaryContainer = light_secondaryContainer,
    onSecondaryContainer = light_onSecondaryContainer,
    tertiary = light_tertiary,
    onTertiary = light_onTertiary,
    tertiaryContainer = light_tertiaryContainer,
    onTertiaryContainer = light_onTertiaryContainer,
    error = light_error,
    errorContainer = light_errorContainer,
    onError = light_onError,
    onErrorContainer = light_onErrorContainer,
    background = light_background,
    onBackground = light_onBackground,
    surface = light_surface,
    onSurface = light_onSurface,
    surfaceVariant = light_surfaceVariant,
    onSurfaceVariant = light_onSurfaceVariant,
    outline = light_outline,
    inverseOnSurface = light_inverseOnSurface,
    inverseSurface = light_inverseSurface,
    inversePrimary = light_inversePrimary,
    shadow = light_shadow,
    surfaceTint = light_surfaceTint,
    outlineVariant = light_outlineVariant,
    scrim = light_scrim,
    bgGray = light_bgGray
)


val darkColorScheme = ColorScheme(
    primary = dark_primary,
    onPrimary = dark_onPrimary,
    primaryContainer = dark_primaryContainer,
    onPrimaryContainer = dark_onPrimaryContainer,
    secondary = dark_secondary,
    onSecondary = dark_onSecondary,
    secondaryContainer = dark_secondaryContainer,
    onSecondaryContainer = dark_onSecondaryContainer,
    tertiary = dark_tertiary,
    onTertiary = dark_onTertiary,
    tertiaryContainer = dark_tertiaryContainer,
    onTertiaryContainer = dark_onTertiaryContainer,
    error = dark_error,
    errorContainer = dark_errorContainer,
    onError = dark_onError,
    onErrorContainer = dark_onErrorContainer,
    background = dark_background,
    onBackground = dark_onBackground,
    surface = dark_surface,
    onSurface = dark_onSurface,
    surfaceVariant = dark_surfaceVariant,
    onSurfaceVariant = dark_onSurfaceVariant,
    outline = dark_outline,
    inverseOnSurface = dark_inverseOnSurface,
    inverseSurface = dark_inverseSurface,
    inversePrimary = dark_inversePrimary,
    shadow = dark_shadow,
    surfaceTint = dark_surfaceTint,
    outlineVariant = dark_outlineVariant,
    scrim = dark_scrim,
    bgGray = dark_bgGray
)
