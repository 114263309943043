package org.jjoy.ltd.domain.use_cases.admin.users

import kotlinx.serialization.Serializable
import org.jjoy.ltd.core.base.ApiUseCase
import org.jjoy.ltd.domain.repository.AdminUserRepository

@Serializable
data class AdminLoginRequest(
    val email: String,
    val password: String
)

@Serializable
data class AdminRegisterRequest(
    val email: String,
    val password: String,
    val adminRegToken: String
)

@Serializable
data class AdminRegisterResponse(
    val error: String,
    val message: String
)

@Serializable
data class AdminResponse(
    val token: String
)

class AdminLogin (repository: AdminUserRepository) :
    ApiUseCase<AdminLoginRequest, AdminResponse>({ repository.adminLogin(it!!) })