package org.jjoy.ltd.domain.model

import org.jjoy.ltd.util.const.NavRailItemId

enum class NavRailItem(
    val id: String,
    val itemName: String,
    val icon: String,
    var isactive: Boolean = false,
) {
    HOME(
        id = NavRailItemId.JOBS,
        itemName = "Overview",
        icon = "wallet"
    ),
    JOBS(
        id = NavRailItemId.JOBS,
        itemName = "Jobs",
        icon = "person-digging"
    ),
    USERS(
        id = NavRailItemId.USERS,
        itemName = "Users",
        icon = "user"
    ),
    APPS(
        id = NavRailItemId.APPS,
        itemName = "Applications",
        icon = "graduation-cap"
    ),;



    companion object {
        fun NavRailItem.isEnabled(value: Boolean = true){
            this.isactive = value
        }
        fun get(): List<NavRailItem> {
            return enumValues<NavRailItem>().toList()
        }

    }


}
