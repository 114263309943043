package org.jjoy.ltd.presentation.admin.home

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.backgroundColor
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.compose.ui.modifiers.position
import com.varabyte.kobweb.core.PageContext
import kotlinx.browser.window
import org.jetbrains.compose.web.css.Position
import org.jjoy.ltd.domain.model.NavRailItem
import org.jjoy.ltd.presentation.admin.application.ApplicationScreen
import org.jjoy.ltd.presentation.admin.application.ApplicationViewModel
import org.jjoy.ltd.presentation.admin.jobs.JobDashBoard
import org.jjoy.ltd.presentation.admin.jobs.JobsDashBoardViewModel
import org.jjoy.ltd.presentation.admin.users.UsersScreen
import org.jjoy.ltd.presentation.admin.users.UsersViewModel
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.MainWrapper
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.extension.rounded
import org.jjoy.ltd.util.extension.smoothBlur

@Composable
fun AdminHomeScreen(
    ctx: PageContext,
    onEvent: (AdminHomeActionEvent) -> Unit
) {

    val item =  window.sessionStorage.getItem("navRailItem") ?: NavRailItem.JOBS.name


    var selectedItem by remember { mutableStateOf(NavRailItem.valueOf(item)) }

    val jobsViewModel = rememberViewModel { JobsDashBoardViewModel() }
    val usersViewModel = rememberViewModel { UsersViewModel() }
    val applicationViewModel = rememberViewModel { ApplicationViewModel() }

    MainWrapper(contentAlignment = Alignment.TopEnd) {

        when (selectedItem) {
            NavRailItem.HOME -> Text(text = "TODO >> ${selectedItem.itemName}")
            NavRailItem.JOBS -> JobDashBoard(viewModel = jobsViewModel)
            NavRailItem.USERS -> UsersScreen(viewModel = usersViewModel)
            NavRailItem.APPS -> ApplicationScreen(viewModel = applicationViewModel)
        }

        NavRail(
            modifier =
                Modifier.smoothBlur()
                    .rounded()
                    .position(Position.Fixed)
                    .fillMaxWidth(dimension.navRailWidthPercent)
                    .align(Alignment.TopStart)
                    .backgroundColor(Colors.White),
            selectedItem = selectedItem
        ) {
            selectedItem = it
        }

    }

    LaunchedEffect(selectedItem) {
        window.sessionStorage.setItem("navRailItem", selectedItem.name)
        onEvent(AdminHomeActionEvent.NavRailItemChanged(selectedItem))
        //localStorage.setItem("navRailItem", selectedItem.name)
    }
}
