package org.jjoy.ltd.pages.jjoy.form

import org.jjoy.ltd.core.base.UIState
import org.jjoy.ltd.presentation.composables.FormInputError

data class FormBankUIState(
    val isLoading: Boolean = false,
    val isError: Boolean = false,
    val bankName: String,
    val accountHolder: String,
    val accountNumber: String,
    val sortCode: String,
    val signName: String,
    val date: String,
    val errors: FormBankErrors = FormBankErrors()
) : UIState {


    fun bankFormErrors(): FormBankErrors {
        val errorField = FormBankErrors()

        if (bankName.isBlank()){
            errorField.bankName.apply {
                isError = true
                msg = "Bank name is required"
            }
        } else if (bankName.length < 3){
            errorField.bankName.apply {
                isError = true
                msg = "Bank name must be at least 3 characters"
            }
        }

        if (accountHolder.isBlank()){
            errorField.accountHolder.apply {
                isError = true
                msg = "Account holder is required"
            }
        } else if (accountHolder.length < 3){
            errorField.accountHolder.apply {
                isError = true
                msg = "Account holder must be at least 3 characters"
            }
        }

        if (accountNumber.isBlank()){
            errorField.accountNumber.apply {
                isError = true
                msg = "Account number is required"
            }
        } else if (accountNumber.length < 4){
            errorField.accountNumber.apply {
                isError = true
                msg = "Account number must be at least 4 characters"
            }
        }

        if (sortCode.isBlank()){
            errorField.sortCode.apply {
                isError = true
                msg = "Sort code is required"
            }
        }

        if (signName.isBlank()){
            errorField.signName.apply {
                isError = true
                msg = "Signature is required"
            }
        } else if (signName.length < 3){
            errorField.signName.apply {
                isError = true
                msg = "Signature must be at least 3 characters"
            }
        }
        return errorField
    }

}

data class FormBankErrors(
    val bankName: FormInputError = FormInputError(),
    val accountHolder: FormInputError = FormInputError(),
    val accountNumber: FormInputError = FormInputError(),
    val sortCode: FormInputError = FormInputError(),
    val signName: FormInputError = FormInputError(),
){
    fun hasErrors(): Boolean {
        return bankName.isError ||
                accountHolder.isError ||
                accountNumber.isError ||
                sortCode.isError ||
                signName.isError
    }
}
