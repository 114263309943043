package org.jjoy.ltd.core.util.extension



fun Number.toAlpha(): Double {
    return this.toDouble() / 100.0
}

fun Double.formatDouble(): String {
   return this.toInt().toString()
}

