package org.jjoy.ltd.core.data.model

import kotlinx.serialization.Serializable
import org.jjoy.ltd.core.util.BaseSerializer

@Serializable(with = BaseSerializer::class)
data class BaseResponse<T>(
    val success: Boolean,
    val response: T
)
