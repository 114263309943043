package org.jjoy.ltd.data.remote

import com.varabyte.kobweb.browser.ApiFetcher
import com.varabyte.kobweb.browser.api
import com.varabyte.kobweb.compose.http.AbortController
import kotlinx.browser.window
import kotlinx.serialization.SerializationException
import org.jjoy.ltd.core.data.model.BaseResponse
import org.jjoy.ltd.core.util.ApiResponse
import org.jjoy.ltd.core.util.ResourceState
import org.jjoy.ltd.core.util.decode
import org.jjoy.ltd.core.util.encode
import org.jjoy.ltd.util.extension.fetchError
import org.w3c.fetch.RequestInit

class ApiService {
    val webAPi: ApiFetcher = window.api

    /*val token = "YOUR_JWT_TOKEN"

    val headers = Headers().apply {
        append("Authorization", "Bearer $token")
    }*/

    val requestOptions = RequestInit(method = "GET")

    suspend inline fun <reified PostType> apiPost(
        apiPath: String,
        body: PostType,
        abortController: AbortController? = null,
        autoPrefix: Boolean = true,
        exception: (Throwable) -> Unit = {}
    ): ResourceState<BaseResponse<String>> {

        return try {

            val result =
                webAPi.post(
                    apiPath = apiPath,
                    body = body.encode(),
                    abortController = abortController,
                    autoPrefix = autoPrefix
                )
            ResourceState.Success(result.decode<BaseResponse<String>>())
        } catch (exception: SerializationException) {

            console.log("SerializationException >>\n $exception")
            ResourceState.Error("Serialization Error")
        } catch (throwable: Throwable) {

            console.log(throwable)
            exception(throwable)
            ResourceState.Error("Throwable")
        }
    }

    suspend inline fun <reified PostType, reified ReturnType> apiPostReturn(
        apiPath: String,
        body: PostType,
        abortController: AbortController? = null,
        autoPrefix: Boolean = true,
        exception: (Throwable) -> Unit = {}
    ): ApiResponse<ReturnType> {

        return try {
            val result =
                webAPi.post(
                    apiPath = apiPath,
                    body = body.encode(),
                    abortController = abortController,
                    autoPrefix = autoPrefix
                )
            val data = result.decode<BaseResponse<ReturnType>>()
            ResourceState.Success(data)
        } catch (exception: SerializationException) {

            console.log("SerializationException >>\n $exception")
            ResourceState.SerializationError("Serialization Error")
        } catch (throwable: Throwable) {

            console.log("Throwable")
            exception(throwable)
            ResourceState.Error("Exception", errorData = throwable.fetchError())
        }
    }

    suspend inline fun apiGet(
        apiPath: String,
        abortController: AbortController? = null,
        autoPrefix: Boolean = true,
        exception: (Throwable) -> Unit = {}
    ): ResourceState<BaseResponse<String>> {

        return try {
            val result =
                webAPi.get(
                    apiPath = apiPath,
                    abortController = abortController,
                    autoPrefix = autoPrefix
                )
            val data = result.decode<BaseResponse<String>>()
            ResourceState.Success(data)
        } catch (exception: SerializationException) {

            console.log("SerializationException >>\n $exception")
            ResourceState.Error("Serialization Error")
        } catch (throwable: Throwable) {

            console.log(throwable)
            exception(throwable)
            ResourceState.Error("Throwable")
        }
    }

    suspend inline fun <reified ReturnType> apiGetReturn(
        apiPath: String,
        abortController: AbortController? = null,
        autoPrefix: Boolean = true,
        exception: (Throwable) -> Unit = {}
    ): ApiResponse<ReturnType> {

        return try {

           /* val result1 =
                apiFlowResponse<ReturnType> {
                    window.fetch("http://0.0.0.0:8081/api/user/test", RequestInit(method = "GET"))
                }

            result1.collect { console.log("Flow::$it") }*/

            val result =
                webAPi.get(
                    apiPath = apiPath,
                    abortController = abortController,
                    autoPrefix = autoPrefix
                )
            val data = result.decode<BaseResponse<ReturnType>>()
            ResourceState.Success(data)
        } catch (exception: SerializationException) {

            console.log("SerializationException >>\n $exception")
            ResourceState.Error("Serialization Error")
        } catch (throwable: Throwable) {

            console.log("Throwable")
            exception(throwable)
            ResourceState.Error("Exception", errorData = throwable.fetchError())
        }
    }
}
