package org.jjoy.ltd.theme.style.text

import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import org.jetbrains.compose.web.css.ms
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

private val textColor = color.onBackground.rgb
private val stockModifier =
    Modifier
        .color(textColor)
        .fontWeight(FontWeight.Normal)
        .fontSize(dimension.normalText)

val BaseTextStyle by ComponentStyle {
    base { stockModifier }
}

val BaseTextComp by SpanTextStyle.addVariant {
    base {
        stockModifier.transition(CSSTransition(property = "translate,box-shadow", duration = 400.ms))
    }
}

val BoldTextStyle by ComponentStyle.base {
    Modifier.color(color.onBackground.css)
        .padding(dimension.smallPadding * 1.85)
        .fontWeight(550)
        .fontSize(dimension.normalText)
}

val TextFieldTextStyle by ComponentStyle.base {
    Modifier.color(color.onBackground.css)
        .padding(dimension.smallPadding * 1.40)
        .fontWeight(500)
        .fontSize(dimension.normalText * 0.9)
}

val TextFieldSmallTextStyle by ComponentStyle.base {
    Modifier.color(color.onBackground.css)
        .padding(dimension.smallPadding * 1.35)
        .fontWeight(450)
        .fontSize(dimension.normalText * 0.6)
}

