package org.jjoy.ltd.presentation.screen.user_login

import org.jjoy.ltd.core.base.ActionEvent

sealed interface LoginActionEvent: ActionEvent {
    data class UserEmailChanged(val text: String): LoginActionEvent
    data class UserFullNameChanged(val text: String): LoginActionEvent
    data class UserPhoneChanged(val text: String): LoginActionEvent
    data class UserPasswordChanged(val text: String): LoginActionEvent
    data class UserPasswordConfirmChanged(val text: String): LoginActionEvent
    object Register: LoginActionEvent
    object Login: LoginActionEvent
    object OnForgot: LoginActionEvent
    object ResetError: LoginActionEvent
    data class SwitchView(val view: LoginViews) : LoginActionEvent
}

enum class LoginViews {
    LOGIN,
    REGISTER,
    FORGOT
}