package org.jjoy.ltd.components.widgets.buttons

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.forms.Button
import com.varabyte.kobweb.silk.components.style.*
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.CSSSizeValue
import org.jetbrains.compose.web.css.CSSUnit
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun NormalButton(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = BaseButtonComp,
    text: String,
    onClick: () -> Unit,
) {

    Button(
        onClick = { onClick() },
        modifier = modifier.then(BaseButtonStyle.toModifier()),
        variant = variant,
    ) {
        Row(verticalAlignment = Alignment.CenterVertically) {
            SpanText(
                text = text,
                modifier =
                    Modifier.fontWeight(FontWeight.Bold)
                        .fontSize(dimension.normalButtonText * 0.95f)
            )
        }
    }
}

val baseButtonModifier =
    Modifier.backgroundColor(color.primary.rgb)
        .color(color.onPrimary.rgb)
        .padding(dimension.normalPadding)
        .borderRadius(dimension.mediumRoundness)

val BaseButtonStyle by ComponentStyle { base { baseButtonModifier } }

val BaseButtonComp by
    BaseButtonStyle.addVariant {
        base { Modifier.animate(prop = listOf("color", "scale"), dur = 800) }
        hover { Modifier.backgroundColor(color.primary.rgb.darkened()).scale(1.03) }
    }

@Composable
fun MobileButton(
    modifier: Modifier = Modifier,
    variant: ComponentVariant? = BaseButtonComp,
    bgColor: CSSColorValue = color.primary.css,
    textColor: CSSColorValue = color.onPrimary.css,
    textSize: CSSSizeValue<CSSUnit.rem> = dimension.largeText,
    text: String,
    onClick: () -> Unit,
) {
    Button(
        onClick = { onClick() },
        modifier = modifier.then(mobileButtonStyle.toModifier()).backgroundColor(bgColor),
        variant = variant,
    ) {
        SpanText(
            text = text,
            modifier = Modifier.color(textColor).fontWeight(FontWeight.Bold).fontSize(textSize)
        )
    }
}

val mobileButtonStyle by ComponentStyle {
    base { Modifier.padding(dimension.normalPadding).borderRadius(dimension.mediumRoundness) }

    hover { Modifier.backgroundColor(color.primary.rgb.darkened(0.4f)) }
}
