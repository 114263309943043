package org.jjoy.ltd.util.res

import org.jjoy.ltd.util.const.Constants.WEBSITE_NAME_SHORT

object ResourceString {
    const val servicesTitle = "What Kind Of Jobs You Can Find?"
    const val servicesDesc = "We support local authorities and private companies with temporary and permanent recruitment services. Our team consist of experienced managers with experience in various industries such as accounting, security, nursing, parking enforcement, recruitment and training."
    const val latest = "LATEST"
    const val latestJobs = "Jobs"
    const val jobsDesc = "Explore Parking Jobs, SIA Security Jobs & Care Jobs"
    const val siteName = WEBSITE_NAME_SHORT
    const val siteLoginDesc = "Discover Your Perfect Fit: Full-Time and Part-Time Opportunities Await!"

    const val signupTitle = "Register With Us"
    const val loginTitle = "Welcome Back"
    const val signQuestion = "Have an account already ? "
    const val signupQuestion = "Doesnt have an account ? "




}