package org.jjoy.ltd.presentation.components.widgets

import androidx.compose.runtime.Composable
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.css.AlignItems
import com.varabyte.kobweb.compose.css.Cursor
import com.varabyte.kobweb.compose.dom.ElementTarget
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.overlay.KeepPopupOpenStrategy
import com.varabyte.kobweb.silk.components.overlay.Popover
import com.varabyte.kobweb.silk.components.overlay.onHover
import com.varabyte.kobweb.silk.components.overlay.shouldKeepOpen
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.text.SpanText
import com.varabyte.kobweb.silk.components.text.SpanTextStyle
import kotlinx.browser.window
import kotlinx.coroutines.CoroutineScope
import kotlinx.coroutines.asCoroutineDispatcher
import kotlinx.coroutines.flow.launchIn
import kotlinx.coroutines.flow.merge
import kotlinx.coroutines.flow.onEach
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.Avatar
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.*
import org.w3c.dom.HTMLElement

@Composable
fun AvatarPopover(
    modifier: Modifier = Modifier,
    userName: String = "U",
    onClick: (AvatarMenu) -> Unit
) {
    val keepOpenStrategyHover = remember { KeepPopupOpenStrategy.onHover() }
    val nestedKeepOpenStrategy = remember { KeepPopupOpenStrategy.onHover() }
    val outerKeepPopupOpen = remember {
        KeepPopupOpenStrategy.customCombine(keepOpenStrategyHover, nestedKeepOpenStrategy)
    }

    Avatar(userName = userName ?: "U", onclick = {})

    Popover(
        target = ElementTarget.PreviousSibling,
        modifier = Modifier.cursor(Cursor.Default).margin(top = dimension.divider).zIndex(3),
        hideDelayMs = 100,
        keepOpenStrategy = outerKeepPopupOpen
    ) {
        Column(
            modifier =
                Modifier.bg(color.background.css)
                    .padding(dimension.normalPadding)
                    .rounded()
                    .smoothBlur()
                    .alignItems(AlignItems.Stretch),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            AvatarMenu.values().forEach {
                AvatarMenuItem(avatarMenu = it, onClick = { onClick(it) })
            }
            /*MenuItem("More")
            Popover(
                ElementTarget.PreviousSibling,
                Modifier.margin(left = (-1).cssRem),
                placement = PopupPlacement.RightTop,
                hideDelayMs = 100,
                keepOpenStrategy = nestedKeepOpenStrategy,
            ) {
                Column {
                    MenuItem("Team 4")
                    MenuItem("Team 5")
                    MenuItem("Team 6")
                }
            }*/
        }
    }
}

enum class AvatarMenu(val displayText: String) {
    //OPTION1("Option 1"),
    //OPTION2("Option 2"),
    LOGOUT("Logout"),
}

fun KeepPopupOpenStrategy.Companion.customCombine(
    mainStrategy: KeepPopupOpenStrategy,
    otherStrategy: KeepPopupOpenStrategy,
) =
    object : KeepPopupOpenStrategy() {
        init {
            listOf(mainStrategy, otherStrategy)
                .map { it.keepOpenFlow }
                .merge()
                .onEach {
                    val anyKeepOpen = mainStrategy.shouldKeepOpen || otherStrategy.shouldKeepOpen
                    if (anyKeepOpen != keepOpenFlow.value) {
                        emitShouldKeepOpen(anyKeepOpen)
                    }
                }
                .launchIn(CoroutineScope(window.asCoroutineDispatcher()))
        }

        override fun onInit(popupElement: HTMLElement) {
            mainStrategy.init(popupElement)
        }

        override fun onResetting() {
            mainStrategy.reset()
        }
    }

val MenuItemVariant by
    SpanTextStyle.addVariant {
        base {
            Modifier.fontSize(dimension.smallText)
                .color(color.onSurfaceVariant.css)
                .rounded()
                .padding(
                    left = dimension.smallPadding * 2.5,
                    right = dimension.smallPadding * 2.5,
                    top = dimension.smallPadding * 1.3,
                    bottom = dimension.smallPadding * 1.3
                )
                .fontWeight(600)
                .animate(prop = listOf("color", "background-color", "border-radius"), dur = 800)
        }
        hover {
            Modifier.backgroundColor(color.primaryContainer.css(90))
                .color(color.onPrimaryContainer.css)
                .rounded()
        }
    }

@Composable
fun AvatarMenuItem(avatarMenu: AvatarMenu, onClick: () -> Unit) {
    SpanText(
        text = avatarMenu.displayText,
        modifier = Modifier.onClick { onClick() },
        variant = MenuItemVariant
    )
}
