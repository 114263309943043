package org.jjoy.ltd.presentation.admin.application

import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.use_cases.admin.apps.AdminApplicationUseCases
import org.jjoy.ltd.util.base.launch
import org.kodein.di.DI
import org.kodein.di.instance

class ApplicationViewModel(di: DI = useCasesModule) :
    BaseViewModel<ApplicationUIState, CommonUIEvent, ApplicationActionEvent>() {

    private val adminApplicationUseCases: AdminApplicationUseCases by di.instance()

    override fun initialState(): ApplicationUIState {
        return ApplicationUIState(
            isPageLoading = true,
            isError = false,
            errorMessage = "",
            isEmpty = false,
            applications = emptyList()
        )
    }

    init {
        getAllApplications()
    }

    override fun onActionEvent(action: ApplicationActionEvent) {
        when (action) {
            ApplicationActionEvent.Refresh -> getAllApplications()
            is ApplicationActionEvent.SearchUser -> searchApplications(action.v)
        }
    }

    private fun getAllApplications(){
        launch {
            adminApplicationUseCases.getApplications(
                onLoading = {
                   update { copy(isPageLoading = true) }
                },
                onFailure = {
                   update { copy(isPageLoading = false, isError = true, errorMessage = "Could not fetch applications") }
                },
                onUnauthorized = {
                    update { copy(isPageLoading = false, isError = true, errorMessage = "Unauthorized") }
                },
                onSuccess = {
                    update { copy(isPageLoading = false, isError = false, errorMessage = "", applications = it) }
                },

            )
        }
    }


    private fun searchApplications(query: String){
        launch {
            val filterList = currentState.applications.filter { it.jobTitle.contains(query, true) }
            update { copy(applications = filterList, isEmpty = filterList.isEmpty()) }
        }
    }


}
