package org.jjoy.ltd.domain.use_cases.admin.users

import org.jjoy.ltd.domain.repository.AdminUserRepository

class AdminUsersUseCases(
    repository: AdminUserRepository
) {
    val getAllUsers = GetAllUsers(repository)
    val searchUsers = SearchUsers(repository)
    val getUserDetails = GetUserDetails(repository)
    val adminLogin = AdminLogin(repository)
    val adminRegister = AdminRegister(repository)
    val validateToken = ValidateToken(repository)
}