package org.jjoy.ltd.presentation.screen.user_form

import org.jjoy.ltd.core.base.ActionEvent
import org.jjoy.ltd.core.domain.model.UKWorkDocument
import org.jjoy.ltd.core.domain.model.WorkingArrangement
import org.jjoy.ltd.core.domain.model.job.JobType
import org.w3c.files.File

sealed interface UserFormActionEvent : ActionEvent {

    object SubmitForm : UserFormActionEvent

    data class FirstNameChanged(val v: String) : UserFormActionEvent
    data class MiddleNameChanged(val v: String) : UserFormActionEvent
    data class LastNameChanged(val v: String) : UserFormActionEvent
    data class AddressChanged(val v: String) : UserFormActionEvent
    data class PostCodeChanged(val v: String) : UserFormActionEvent
    data class TelePhoneChanged(val v: String) : UserFormActionEvent
    data class NationalInsuranceNumChanged(val v: String) : UserFormActionEvent
    data class DobChanged(val v: String) : UserFormActionEvent
    data class UkWorkDocChanged(val v: List<UKWorkDocument>) : UserFormActionEvent
    data class PreferredJobType(val v: JobType) : UserFormActionEvent
    data class UploadFile(val path: String, val file: File) : UserFormActionEvent
    data class SectionChange(val section: UserFormSection) : UserFormActionEvent
    object SectionGoBack: UserFormActionEvent


    //SECOND
    data class PrefrRoleChanged(val v: String): UserFormActionEvent
    data class QualitiesChanged(val v: String): UserFormActionEvent
    data class WorkingArrangementChanged(val v: List<WorkingArrangement>) : UserFormActionEvent
    data class WorkReLocationChanged(val v: String) : UserFormActionEvent
    data class DrivingLicenceChanged(val v: String) : UserFormActionEvent
    data class MopedScooterChanged(val v: String) : UserFormActionEvent
    data class CBTCertChanged(val v: String) : UserFormActionEvent

    //THIRD
    data class GenderChanged(val v: String) : UserFormActionEvent
    data class RelationshipStatusChanged(val v: String) : UserFormActionEvent
    data class AgeGroupChanged(val v: String) : UserFormActionEvent
    data class RaceChanged(val v: FormRace, val e: String) : UserFormActionEvent
    data class FourthDataChanged(val e: FourthStringEvents,val v: String): UserFormActionEvent
    data class FinalDataChanged(val e: FinalEvents,val v: String): UserFormActionEvent

}


enum class FourthStringEvents{
    DISABILITY,
    DISABILITY_DESC,
    SEXUAL_ORI,
    RELIGION,
    CRIMINAL_CONV,
    SIGNATURE
}

enum class FinalEvents {
    P2cName,
    RelationToU,
    Tele,
    Address,
    Email,
    DECLR_NAME,
}


enum class FormRace{
    WHITE,
    MIXED,
    ASIAN,
    BLACK,
    OTHER
}