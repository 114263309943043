package org.jjoy.ltd.core.data.model.auth

import kotlinx.serialization.Serializable
import org.jjoy.ltd.core.domain.model.user.UserDto
import org.jjoy.ltd.core.domain.model.user.UserResponse

@Serializable
data class AuthResponse(
    val success: Boolean = false,
    val emailError: Boolean = false,
    val password: Boolean = false,
    val error: String = "",
    val data: UserResponse? = null
){
    companion object{
        fun emailError(error: String) = AuthResponse(emailError = true, error = error)
        fun passwordError(error: String) = AuthResponse(password = true, error = error)
        fun emailAndPass(error: String) = AuthResponse(password = true, emailError = true, error = error)
    }
}
