package org.jjoy.ltd.domain.use_cases.form

import org.jjoy.ltd.core.base.ApiUseCase
import org.jjoy.ltd.domain.repository.FormRepository
import org.w3c.files.File

class UploadFile(repository: FormRepository): ApiUseCase<UploadFileParam, String>(
    {
        repository.uploadFile(it!!.path,it.file)
    }
)

data class UploadFileParam(
    val file: File,
    val path: String
)
