package org.jjoy.ltd.presentation.components.input


import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.focus
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.attributes.InputType
import org.jetbrains.compose.web.attributes.placeholder
import org.jetbrains.compose.web.css.LineStyle
import org.jetbrains.compose.web.css.px
import org.jetbrains.compose.web.dom.Input
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.style.text.TextFieldTextStyle
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb

@Composable
fun SimpleTextField(
    modifier: Modifier = Modifier,
    stateVsEvent: StateVsEvent,
    label: String
) {

    Input(
        type = InputType.Text,
        attrs = listOf(SimpleInputStyle, TextFieldTextStyle).toModifier()
            .padding(15.px)
            .fontSize(30.px)
            .backgroundColor(color.surfaceVariant.css)
            .borderRadius(10.px)
            .outline(
                width = 0.px,
                style = LineStyle.None,
                color = Colors.Transparent
            )
            .color(color.onSurfaceVariant.css).then(modifier)
            .toAttrs{
                placeholder(label)
                onInput {
                    stateVsEvent.onValueChange(it.value)
                }
            }
    )

}

val SimpleInputStyle by ComponentStyle {
    base {
        Modifier.border(
            width = 3.px,
            style = LineStyle.Solid,
            color = color.surfaceVariant.rgb.darkened()
        ).animate(prop = listOf("border","border-color","color"))

    }

    hover {
        Modifier.border(
            width = 3.px,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }

    focus {
        Modifier.border(
            width = 3.px,
            style = LineStyle.Solid,
            color = color.primary.rgb
        )
    }
}