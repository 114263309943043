package org.jjoy.ltd.pages.jjoy.sas.jdfjgjsgjgfs

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.animation.toAnimation
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import kotlinx.browser.localStorage
import kotlinx.browser.window
import kotlinx.coroutines.delay
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.core.StateVsEvent
import org.jjoy.ltd.core.base.CommonUIEvent
import org.jjoy.ltd.components.widgets.buttons.AuthButton
import org.jjoy.ltd.presentation.components.AlertToast
import org.jjoy.ltd.presentation.components.input.PasswordInputWrap
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.composables.FormTextInput
import org.jjoy.ltd.presentation.screen.user_login.LoginFormFieldError
import org.jjoy.ltd.presentation.screen.user_login.transitionTest
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel
import org.jjoy.ltd.util.const.LocalStorageConst
import org.jjoy.ltd.util.extension.*

@Page("/simple/admin/dashboard/login")
@Composable
fun AdminLogin(
    ctx: PageContext = rememberPageContext(),
    viewModel: AdminLoginViewModel = rememberViewModel { AdminLoginViewModel() }
) {

    val token =
        localStorage.getItem(LocalStorageConst.TOKEN)

   /*if (token != null) {
        ctx.router.navigateTo("/admin/dash")
    }*/

    val state by viewModel.collectState()
    val uiEventFlow by viewModel.collectEvent()

    AdminLoginContent(
        onEvent = viewModel::onActionEvent,
        state = state,
        modifier = Modifier
            .fillMaxWidth()
            .padding(dimension.mediumPadding)
            .height(100.vh)
    )



    uiEventFlow?.let {
        when (it) {
            is CommonUIEvent.Error -> Unit
            CommonUIEvent.Loading -> Unit
            CommonUIEvent.NavigateNext -> {
               ctx.router.navigateTo("/admin/dash")
            }
            CommonUIEvent.NavigateUp -> Unit
        }
    }


   /* LaunchedEffect(state.isError) {
        if (state.isError) {
            window.alert("LOGIN FAILED")
        }
    }*/

    // Disable back navigation
    window.disableNavigateBack()


}


@Composable
private fun AdminLoginContent(
    onEvent: TCallback<AdminLoginActionEvent>,
    state: AdminLoginUIState,
    modifier: Modifier = Modifier
) {

    Box(
        modifier = modifier
            .displayIfAtLeast(Breakpoint.LG)
            .backgroundColor(Colors.White).padding(dimension.largePadding * 2),
        contentAlignment = Alignment.Center
    ) {
        AdminLoginForm(
            modifier =
            Modifier.backgroundColor(Colors.White)
                .buttonShadow()
                .rounded()
                .width(40.percent),
            isLoading = state.isLoading,
            error = LoginFormFieldError(),
            isAlert = false,
            userEmailStateVsEvent =
            StateVsEvent(state.userEmail) { onEvent(AdminLoginActionEvent.UserEmailChanged(it)) },
            userPasswordStateVsEvent =
            StateVsEvent(state.password) { onEvent(AdminLoginActionEvent.UserPasswordChanged(it)) },
            onSubmit = { onEvent(AdminLoginActionEvent.Login) },
        )
    }



}



@Composable
private fun AdminLoginForm(
    modifier: Modifier = Modifier,
    isLoading: Boolean,
    error: LoginFormFieldError,
    isAlert: Boolean,
    userEmailStateVsEvent: StateVsEvent,
    userPasswordStateVsEvent: StateVsEvent,
    onSubmit: Callback
) {
    var loading by remember { mutableStateOf(false) }
    var forgot by remember { mutableStateOf(false) }

    // TODO: Forgot Pass Impl

    Column(
        modifier = modifier.padding(dimension.normalPadding),
        horizontalAlignment = Alignment.CenterHorizontally,
        verticalArrangement = Arrangement.Center
    ) {
        Column(
            modifier =
            Modifier.fillMaxSize()
                .scale(1)
                .opacity(1)
                .translateY(0.percent)
                .thenIf(forgot) {
                    Modifier.animation(
                        transitionTest.toAnimation(
                            duration = 0.4.s,
                            iterationCount = AnimationIterationCount.of(1),
                            fillMode = AnimationFillMode.Both
                        )
                    )
                    // Modifier.scale(0).opacity(0).translateY((-30).percent)
                }
                .animate(prop = listOf("scale", "opacity", "translate")),
            horizontalAlignment = Alignment.CenterHorizontally,
            verticalArrangement = Arrangement.Center
        ) {
            Column(
                modifier = Modifier.width(80.percent).margin(top = dimension.largePadding),
            ) {
                Text(
                    modifier =
                    Modifier.fontWeight(800)
                        .fontSize(dimension.largeText)
                        .color(color.primary.css),
                    text = "Admin Sign In"
                )
                Row {
                    Text(
                        modifier =
                        Modifier.fontWeight(FontWeight.Bold)
                            .fontSize(dimension.normalText)
                            .color(color.onBackground.css),
                        text = "Contact your admin if you don't have an account"
                    )
                }

                AlertToast(
                    modifier = Modifier.margin(top = 1.4.cssRem),
                    show = isAlert,
                    msg = error.msg
                )
            }

            Column(
                modifier =
                Modifier.margin(top = 2.cssRem)
                    .fillMaxWidth(80.percent)
                    .gap(dimension.normalPadding),
                horizontalAlignment = Alignment.CenterHorizontally
            ) {

                FormTextInput(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userEmailStateVsEvent,
                    label = "Email",
                    id = "email",
                    error = FormInputError(isError = error.email),
                    onIme = {

                    }
                )

                PasswordInputWrap(
                    modifier = Modifier.fillMaxWidth(),
                    stateVsEvent = userPasswordStateVsEvent,
                    label = "Password",
                    isError = error.password,
                    visibilityToggle = true,
                    isVisible = userPasswordStateVsEvent.value.isNullOrBlank(),
                )

                AuthButton(
                    modifier = Modifier.margin(top = dimension.largePadding, bottom = dimension.largePadding * 3),
                    isLoading = loading,
                    label = "LOGIN",
                    onClick = onSubmit,
                )

            }
        }
    }

    LaunchedEffect(isLoading) {
        if (isLoading && !loading) {
            loading = true
        } else if (!isLoading && loading) {
            delay(1500)
            loading = false
        }
    }
}