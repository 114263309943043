package org.jjoy.ltd.pages.jjoy.form

import org.jjoy.ltd.core.base.ActionEvent

sealed interface FormBankActionEvent : ActionEvent {
        data class BankNameChanged(val bankName: String) : FormBankActionEvent
        data class AccountHolderChanged(val accountHolder: String) : FormBankActionEvent
        data class AccountNumberChanged(val accountNumber: String) : FormBankActionEvent
        data class SortCodeChanged(val sortCode: String) : FormBankActionEvent
        data class SignNameChanged(val signName: String) : FormBankActionEvent
        data class DateChanged(val date: String) : FormBankActionEvent
        object Save : FormBankActionEvent
}