package org.jjoy.ltd.presentation.screen.user_form.error_handler

import org.jjoy.ltd.presentation.composables.FormInputError
import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState

data class FormErrorField(
    val firstName: FormInputError = FormInputError(),
    val middleName: FormInputError = FormInputError(),
    val lastName: FormInputError = FormInputError(),
    val address: FormInputError = FormInputError(),
    val postcode: FormInputError = FormInputError(),
    val telephone: FormInputError = FormInputError(),
    val ni: FormInputError = FormInputError(),
    val dob: FormInputError = FormInputError(),
    val jobType: FormInputError = FormInputError(),
    val workDocument: FormInputError = FormInputError(),

    val workArrangements: FormInputError = FormInputError(),
    val relocate: FormInputError = FormInputError(),
    val drivingLicence: FormInputError = FormInputError(),
    val mopedScooter: FormInputError = FormInputError(),
    val cbt: FormInputError = FormInputError(),

    //val telephone: FormInputError,
    val roles: FormInputError = FormInputError(),

    val gender: FormInputError = FormInputError(),
    val relation: FormInputError = FormInputError(),
    val age: FormInputError = FormInputError(),
    val race: FormInputError = FormInputError(),
    val ethnicity: FormInputError = FormInputError(),

    val disability: FormInputError = FormInputError(),
    val sexualOri: FormInputError = FormInputError(),
    val religion:  FormInputError = FormInputError(),
    val signature:  FormInputError = FormInputError(),

    val p2cName:  FormInputError = FormInputError(),
    val p2cRelationToYou:  FormInputError = FormInputError(),
    val p2cTele:  FormInputError = FormInputError(),
    val p2cHomeAddress:  FormInputError = FormInputError(),
    val p2cEmail:  FormInputError = FormInputError(),
    val p2cPersonsName:  FormInputError = FormInputError(),
){
    fun hasErrors(): Boolean {
        return firstName.isError ||
                middleName.isError ||
                lastName.isError ||
                address.isError ||
                postcode.isError ||
                telephone.isError ||
                ni.isError ||
                dob.isError ||
                jobType.isError ||
                workDocument.isError 
    }

    fun hasErrors2(): Boolean {
        return workArrangements.isError ||
                relocate.isError ||
                drivingLicence.isError ||
                mopedScooter.isError ||
                cbt.isError
    }

    fun hasErrors3(): Boolean {
        return gender.isError ||
                age.isError ||
                relation.isError /*||
                ethnicity.isError ||
                race.isError*/
    }
    fun hasErrors4(): Boolean {
        return disability.isError ||
                sexualOri.isError ||
                religion.isError ||
                signature.isError

    }

    fun hasErrors5(): Boolean {
        return p2cName.isError ||
                p2cRelationToYou.isError ||
                p2cTele.isError ||
                p2cHomeAddress.isError ||
                p2cEmail.isError ||
                p2cPersonsName.isError

    }


}

fun UserFormUIState.firstSectionError(): FormErrorField {
    val errorField = FormErrorField()

    // Check first name
    if (firstName.isBlank()) {
        errorField.firstName.isError = true
        errorField.firstName.msg = "First name is required"
    } else if (firstName.length <= 2){
        errorField.firstName.isError = true
        errorField.firstName.msg = "First name should be at least 3 char long"
    }

    // Check middle name
    /*if (middleName.isBlank()) {
        errorField.middleName.isError = true
        errorField.middleName.msg = "Middle name is required"
    }*/

    // Check last name
    if (lastName.isBlank()) {
        errorField.lastName.isError = true
        errorField.lastName.msg = "Last name is required"
    }

    // Check address
    if (address.isBlank()) {
        errorField.address.isError = true
        errorField.address.msg = "Address is required"
    } else if (address.length <= 2){
        errorField.address.isError = true
        errorField.address.msg = "Please provide proper address"
    }

    // Check postcode
    if (postcode.isBlank()) {
        errorField.postcode.isError = true
        errorField.postcode.msg = "Postcode is required"
    } else if (address.length <= 2){
        errorField.postcode.isError = true
        errorField.postcode.msg = "Invalid Postcode "
    }

    // Check telephone
    /*if (telephone.isBlank()) {
        errorField.telephone.isError = true
        errorField.telephone.msg = "Telephone number is required"
    }*/

    // Check date of birth
    if (dateOfBirth.isBlank()) {
        errorField.dob.isError = true
        errorField.dob.msg = "Date of birth is required"
    }

    // Check job type
    /*if (preferredJobType == JobType.FULL_TIME) {
        errorField.jobType.isError = true
        errorField.jobType.msg = "Job type is required"
    }*/

    // Check work document
    if (ukWorkDocument.isEmpty()) {
        errorField.workDocument.isError = true
        errorField.workDocument.msg = "Please choose at least one option"
    }

    // Check national insurance number
    if (nationalInsuranceNumber.isBlank()) {
        errorField.ni.isError = true
        errorField.ni.msg = "National insurance number is required"
    }

    return errorField
}
