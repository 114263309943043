package org.jjoy.ltd.presentation.composables

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.Overflow
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.graphics.Image
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.vh
import org.jetbrains.compose.web.css.vw
import org.jjoy.ltd.presentation.components.widgets.DotAnimation
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate

@Composable
fun LoaderView(
    isLoading: Boolean
){
    Box(
        modifier = Modifier
            .backgroundColor(Colors.White)
            .fillMaxSize()
            .translateY(if (isLoading) 0.percent else (-100).percent)
            .overflow(Overflow.Hidden)
            .animate(prop = listOf("opacity","scale","translate"), dur = 800)
        ,
        contentAlignment = Alignment.Center,

    ){

        DotAnimation()

    }
}

@Composable
fun LoaderViewHome(
    isLoading: Boolean
){
    Column(
        modifier = Modifier
            .gap(dimension.mediumPadding)
            .zIndex(4)
            .backgroundColor(Colors.White)
            .width(100.vw).height(100.vh)
            .translateY(if (isLoading) 0.percent else (-100).percent)
            .animate(prop = listOf("opacity","scale","translate"), dur = 1200)
        ,
        verticalArrangement = Arrangement.Center,
        horizontalAlignment = Alignment.CenterHorizontally
    ){

        Image(
            src = "/logo.png",
            desc = "",
            modifier = Modifier.size(dimension.extraLargeText)
        )

        DotAnimation(size = dimension.smallText)

    }
}