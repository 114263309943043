package org.jjoy.ltd.pages.jjoy.admin

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.PageContext
import com.varabyte.kobweb.core.rememberPageContext
import org.jjoy.ltd.presentation.admin.users.UserDetailScreen
import org.jjoy.ltd.presentation.admin.users.UserDetailViewModel
import org.jjoy.ltd.util.base.rememberViewModel

@Page("/admin/user/{id}")
@Composable
fun UserDetailsPage(
    ctx: PageContext = rememberPageContext(),
) {

    val id = ctx.route.params["id"] ?: error("Missing id")
    val viewModel = rememberViewModel { UserDetailViewModel(id) }

    UserDetailScreen(
        viewModel = viewModel
    )

}