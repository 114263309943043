package org.jjoy.ltd.presentation.admin.jobs

import com.benasher44.uuid.uuid4
import kotlinx.browser.localStorage
import kotlinx.coroutines.Job
import kotlinx.datetime.Clock
import org.jjoy.ltd.core.base.BaseViewModel
import org.jjoy.ltd.core.base.UIEvent
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.domain.model.job.JobType
import org.jjoy.ltd.core.util.decode
import org.jjoy.ltd.data.repositoryImpl.AdminRepositoryImpl
import org.jjoy.ltd.di.useCasesModule
import org.jjoy.ltd.domain.repository.AdminRepository
import org.jjoy.ltd.domain.use_cases.admin.jobs.AdminJobUseCases
import org.jjoy.ltd.domain.use_cases.jobs.JobsUseCases
import org.jjoy.ltd.domain.use_cases.jobs.SearchQuery
import org.jjoy.ltd.util.base.launch
import org.jjoy.ltd.util.const.LocalStorageConst.CERT
import org.jjoy.ltd.util.const.LocalStorageConst.REQ
import org.jjoy.ltd.util.const.servicesList
import org.kodein.di.DI
import org.kodein.di.instance
import kotlin.time.Duration.Companion.seconds

class JobsDashBoardViewModel(di: DI = useCasesModule) :
    BaseViewModel<JobsDashUIState, JobDashUIEvent, JobsDashActionEvent>(),
    AdminRepository by AdminRepositoryImpl() {

    private val adminJobUseCases: AdminJobUseCases by di.instance()
    private val jobUseCases: JobsUseCases by di.instance()

    private var jobsListJob: Job? = null

    override fun initialState(): JobsDashUIState {
        val req = localStorage.getItem(REQ)?.decode<List<String>>()
        val cert = localStorage.getItem(CERT)?.decode<List<String>>()

        return JobsDashUIState(
            jobs = listOf(),
            isLoading = false,
            currentPage = JobPanel.LIST_ADD,
            editJobId = "",

            isJobLoading = false,
            isError = false,
            errorMsg = "",
            jobsEmpty = false,
            adminId = "",

            // JObDto
            id = uuid4().toString(),
            title = "",
            description = "",
            location = "",
            workLocation = "",
            requirements = req ?: listOf(),
            certificatesRequired = cert ?: listOf(),
            jobType = JobType.FULL_TIME,
            jobCategoryId = "",
            jobCategoryName = servicesList.random().title,
            salary = "",
            isPerHour = SalaryType.HOURLY,
            isFeatured = false,
            isDeleted = false,
            isExpired = false,
        )
    }

    init {

        // TODO: Fix Loading State and evrything after added
        // move to users and applications
        jobsListJob?.cancel()
        jobsListJob = onIO {
            adminJobUseCases.getAllJobs(
                onLoading = {
                    update { copy(isJobLoading = true) }
                },
                onFailure = {
                    update {
                        copy(
                            isJobLoading = false,
                            isError = true,
                            errorMsg = "Could Not Fetch Jobs"
                        )
                    }
                },
                onSuccess = {
                    update { copy(isJobLoading = false, isError = false, jobs = it) }
                }
            )
        }
    }

    override fun onActionEvent(action: JobsDashActionEvent) {
        when (action) {
            is JobsDashActionEvent.CertificatesRequiredChanged ->
                update { copy(certificatesRequired = action.v) }
            is JobsDashActionEvent.DescriptionChanged -> update { copy(description = action.v) }
            is JobsDashActionEvent.ExpiryAtChanged -> update { copy(expiryAt = action.v) }
            is JobsDashActionEvent.IsDeletedChanged -> update { copy(isDeleted = action.v) }
            is JobsDashActionEvent.IsExpiredChanged -> update { copy(isExpired = action.v) }
            is JobsDashActionEvent.IsFeaturedChanged -> update { copy(isFeatured = action.v) }
            is JobsDashActionEvent.IsPerHourChanged -> update { copy(isPerHour = action.v) }
            is JobsDashActionEvent.JobCategoryIdChanged -> update { copy(jobCategoryId = action.v) }
            is JobsDashActionEvent.JobCategoryNameChanged ->
                update { copy(jobCategoryName = action.v) }
            is JobsDashActionEvent.JobTypeChanged -> update { copy(jobType = action.v) }
            is JobsDashActionEvent.LocationChanged -> update { copy(location = action.v) }
            is JobsDashActionEvent.RequirementsChanged -> update { copy(requirements = action.v) }
            is JobsDashActionEvent.SalaryChanged -> {

                if (action.v.contains("--")) return
                if (action.v == "-") return

                val parts = action.v.split("-")
                val result =
                    when (parts.size) {
                        1 -> action.v.trim()
                        2 -> {
                            val firstPart = parts[0].trim()
                            val secondPart = parts[1].trim()
                            if (secondPart.isBlank()) action.v
                            if (
                                firstPart.toIntOrNull() != null && secondPart.toIntOrNull() != null
                            ) {
                                "$firstPart-$secondPart"
                            } else {
                                action.v.trim()
                            }
                        }
                        else -> return
                    }
                update { copy(salary = result) }
            }
            is JobsDashActionEvent.TitleChanged -> update { copy(title = action.v) }
            is JobsDashActionEvent.WorkLocationChanged -> update { copy(workLocation = action.v) }
            JobsDashActionEvent.AddJob -> {

                if (!currentState.isJobLoading && !currentState.isLoading) {
                    addJob()
                }
            }
            is JobsDashActionEvent.SearchJob -> searchJobs(
                query = action.v.ifBlank { "all" },
                category = action.c.ifBlank { "all" }
            )
            is JobsDashActionEvent.UpdateCurrentPanel -> update { copy(currentPage = action.v) }
            is JobsDashActionEvent.UpdateJobId -> update { copy(editJobId = action.v) }
        }
    }

    private fun addJob() {

        val errors = currentState.checkErrors()
        if (errors.hasError()) {
            update { copy(errors = errors) }
            return
        }

        val jobItem =
            JobDTO(
                id = uuid4().toString(),
                title = currentState.title,
                description = currentState.description,
                location = currentState.location,
                workLocation = currentState.workLocation,
                requirements = currentState.requirements,
                certificatesRequired = currentState.certificatesRequired,
                jobType = currentState.jobType,
                jobCategory = currentState.jobCategoryName,
                salary = currentState.salary.toSalary(),
                isPerHour = currentState.isPerHour.v,
                isFeatured = false,
                postedAt = Clock.System.now().toEpochMilliseconds(),
                updatedAt = Clock.System.now().toEpochMilliseconds(),
                expiryAt = Clock.System.now().plus(2000000000.seconds).toEpochMilliseconds(),
                isDeleted = false,
                isExpired = false,
            )
        onIO {
            adminJobUseCases.addJob(
                input = jobItem,
                onLoading = { update { copy(isLoading = true) } },
                onFailure = {
                    sendUIEvent(JobDashUIEvent.Error(it ?: "Could Not Add Job"))
                    update { copy(isLoading = false) }
                    return@addJob
                },
                onSuccess = {
                    update { initialState().copy(jobs = currentState.jobs, isLoading = false) }
                    sendUIEvent(JobDashUIEvent.JobAdded)
                    return@addJob
                }
            )
        }
    }

    private fun searchJobs(query: String,category: String) {
        jobsListJob?.cancel()
        jobsListJob = launch {
            adminJobUseCases.searchAllJobs(
                input = SearchQuery(query, category),
                onLoading = {
                    update { copy(isJobLoading = true) }
                },
                onFailure = {
                    update {
                        copy(isJobLoading = false, isError = true, errorMsg = "No Matches Found")
                    }
                },
                onSuccess = { it ->
                    if (it.isEmpty()) {
                        update {
                            copy(
                                isJobLoading = false,
                                isError = true,
                                jobsEmpty = true,
                                errorMsg = "No Matches Found"
                            )
                        }
                        return@searchAllJobs
                    } else {
                        update {
                            copy(
                                isJobLoading = false,
                                isError = false,
                                jobsEmpty = false,
                                jobs = it,
                            )
                        }
                    }
                }
            )
        }
    }
}

fun String.toSalary(): Pair<Double, Double> {
    return try {
        val part = this.split("-")
        val first = part[0].toDouble()
        val second = part[1].toDouble()
        Pair(first, second)
    } catch (exception: Exception) {
        console.log(exception)
        Pair(0.0, 0.0)
    }
}

sealed interface JobDashUIEvent : UIEvent {
    object NavigateNext : JobDashUIEvent
    object NavigateBack : JobDashUIEvent
    data class Error(val msg: String) : JobDashUIEvent
    object JobAdded : JobDashUIEvent

    object ListCollected : JobDashUIEvent
}
