package org.jjoy.ltd.theme

enum class Theme(val colorScheme: ColorScheme) {
    Color(lightColorScheme)
}

enum class WebColor{

}
class AppTheme {
    companion object {

    }
}

val color = lightColorScheme

data class AppColor(val r: Number, val g: Number, val b: Number)
