package org.jjoy.ltd.util.extension

import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.rgb

val emailRegex = Regex("[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}")
fun String.toRgb(): CSSColorValue {
    require(length == 7 && startsWith("#")) { "Invalid hex color format" }

    val red = substring(1, 3).toInt(16)
    val green = substring(3, 5).toInt(16)
    val blue = substring(5, 7).toInt(16)

    return rgb(red, green, blue)
}

fun String.isEmail(): Boolean {
    return emailRegex.matches(this)
}

fun String.capitalize(): String {
    if (isEmpty()) {
        return this
    }
    return this[0].uppercaseChar() + substring(1)
}

fun String.limit(maxLength: Int = 20): String {
    if (this.length <= maxLength) {
        return this
    }

    return this.take(maxLength) + ".."
}

/*
fun String.dobFormat(output: TCallback<String> = {}): String {

        val formattedDate = StringBuilder()

        if (this.length == 3) {
            formattedDate.append("/")
        } else if ()

        return formattedDate.toString()


}*/
