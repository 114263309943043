package org.jjoy.ltd.presentation.screen.user_form.error_handler

import org.jjoy.ltd.presentation.screen.user_form.UserFormUIState

fun UserFormUIState.secondSectionError(): FormErrorField {
    val errorField = FormErrorField()

    if (arrangements.isNullOrEmpty()) {
        errorField.workArrangements.isError = true
        errorField.workArrangements.msg = ""
    }
    if (relocate.isNullOrEmpty()) {
        errorField.relocate.isError = true
        errorField.relocate.msg = ""
    }
    if (ukDrivingLicence.isNullOrEmpty()) {
        errorField.drivingLicence.isError = true
        errorField.drivingLicence.msg = ""
    }
    if (mopedScooter.isNullOrEmpty()) {
        errorField.mopedScooter.isError = true
        errorField.mopedScooter.msg = ""
    }
    if (cbt.isNullOrEmpty()) {
        errorField.cbt.isError = true
        errorField.cbt.msg = ""
    }

    return errorField
}

fun UserFormUIState.thirdSectionError(): FormErrorField {
    val errorField = FormErrorField()

    if (gender.isNullOrEmpty()) {
        errorField.gender.isError = true
    }
    if (relationshipStatus.isNullOrEmpty()) {
        errorField.relation.isError = true
    }
    if (ageGroup.isNullOrEmpty()) {
        errorField.age.isError = true
    }
    if (ethinic.isNullOrEmpty()) {
        errorField.ethnicity.isError = true
    }
    if (race.isNullOrEmpty()) {
        errorField.race.isError = true
        errorField.race.msg = " !! Pick one!!"
    }

    return errorField
}

fun UserFormUIState.fourthSectionError(): FormErrorField {
    val errorField = FormErrorField()

    if (disability.isNullOrEmpty()) {
        errorField.disability.isError = true
    }
    if (sexualOri.isNullOrEmpty()) {
        errorField.sexualOri.isError = true
    }
    if (religion.isNullOrEmpty()) {
        errorField.religion.isError = true
    }
    if (signature.isNullOrEmpty()) {
        errorField.signature.isError = true
    }

    return errorField
}

fun UserFormUIState.fifthSectionError(): FormErrorField {
    val errorField = FormErrorField()

    if (p2cName.isNullOrEmpty()) {
        errorField.p2cName.isError = true
        errorField.p2cName.msg = "required"
    }
    if (p2cAddress.isNullOrEmpty()) {
        errorField.p2cHomeAddress.isError = true
    }
    if (p2cRelation.isNullOrEmpty()) {
        errorField.p2cRelationToYou.isError = true
        errorField.p2cRelationToYou.msg = "required"
    }
    if (p2cTele.isNullOrEmpty()) {
        errorField.p2cTele.isError = true
        errorField.p2cTele.msg = "required"
    }
    if (p2cEmail.isNullOrEmpty()) {
        errorField.p2cEmail.isError = true
        errorField.p2cEmail.msg = "required"
    }
    if (p2cPersonName.isNullOrEmpty()) {
        errorField.p2cPersonsName.isError = true
        errorField.p2cPersonsName.msg = "required"
    }

    return errorField
}
