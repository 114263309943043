package org.jjoy.ltd.presentation.admin.jobs

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.presentation.components.JobItemCardStyle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.ContentWrap
import org.jjoy.ltd.presentation.composables.wrappers.ListWrapper
import org.jjoy.ltd.presentation.screen.home.FilterJobsView
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.limit
import org.jjoy.ltd.util.extension.rounded

@Composable
fun AdminJobList(
    state: JobsDashUIState,
    onSearch: (search: String, category: String) -> Unit,
    onApply: TCallback<JobListItem>,
    modifier: Modifier = Modifier
) {

    ContentWrap(
        modifier = modifier.fillMaxSize(),
        columnModifier =
            Modifier.gap(dimension.mediumPadding)
                .padding(dimension.largePadding)
                .margin(dimension.normalPadding)
                .fillMaxWidth(),
        contentId = "admin_jobs",
        contentVerticalArrangement = Arrangement.Top,
        contentHorizontalAlignment = Alignment.CenterHorizontally
    ) {

        FilterJobsView(Modifier.fillMaxWidth(), onSearch = onSearch, showCategory = true)

        ListWrapper(
            isLoading = state.isJobLoading,
            isError = state.isError,
            isEmpty = state.jobsEmpty,
            errorMsg = state.errorMsg,
            list = state.jobs,
        ) {
            DashBoardJobItem(
                jobDTO = it,
                onApply = { onApply(it) }
            )
        }
    }
}

@Composable
fun DashBoardJobItem(modifier: Modifier = Modifier, jobDTO: JobListItem, onApply: Callback) {

    val icon = servicesList.find { it.title == jobDTO.jobCategory }?.icon ?: "user-nurse"

    Row(
        modifier =
            modifier
                .then(JobItemCardStyle.toModifier())
                .padding(dimension.extraSmallText)
                .onClick { onApply() },
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Start
    ) {
        Box(
            modifier =
            Modifier.backgroundColor(color.primary.css)
                .rounded()
                .padding(dimension.smallPadding),
            contentAlignment = Alignment.Center
        ) {
            FaIcon(
                modifier = Modifier.fontSize(dimension.normalText * 1.3)
                    .margin(
                        left = dimension.extraSmallText,
                        right = dimension.extraSmallText,
                        bottom = dimension.smallPadding,
                        top = dimension.smallPadding
                    ).color(color.onPrimary.css),
                name = icon,
                style = IconCategory.SOLID,
            )
        }

        Column(
            modifier =
                Modifier.margin(left = dimension.normalPadding).fillMaxWidth().fillMaxHeight(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.Start
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {
                Text(
                    text = jobDTO.title,
                    modifier =
                        Modifier.fontWeight(600)
                            .fontSize(dimension.textFieldLabelText)
                            .color(color.onBackground.css(90))
                )
                Spacer()
            }

            Row(
                modifier = Modifier,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Column(
                    modifier = Modifier,
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start
                ) {
                    /*Row(
                        modifier =
                            Modifier.gap(
                                dimension.extraSmallPadding.plus(dimension.extraSmallPadding)
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaIcon(
                            name = "location-dot",
                            modifier = Modifier.color(color.primary.css(80)),
                            style = IconCategory.SOLID,
                            size = IconSize.X1
                        )
                        Text(
                            text = jobDTO.location,
                            modifier =
                                Modifier.fontWeight(600)
                                    .fontSize(dimension.smallText)
                                    .color(color.onBackground.css(80))
                        )
                    }*/

                    Spacer()

                    Box(
                        modifier =
                            Modifier.backgroundColor(color.primary.css(20))
                                .color(color.primary.css)
                                .scale(1)
                                .translateY(0.percent)
                                .rounded(0.35)
                                .padding(
                                    top = dimension.smallPadding * 0.8,
                                    bottom = dimension.smallPadding * 0.8,
                                    left = dimension.smallPadding * 1.3,
                                    right = dimension.smallPadding * 1.3,
                                )
                                .animate(
                                    prop = listOf("scale", "translate", "color", "background-color")
                                ),
                        contentAlignment = Alignment.Center
                    ) {
                        SpanText(
                            text = jobDTO.jobCategory.limit(45),
                            modifier =
                                Modifier.fontSize(dimension.smallText * 0.8)
                                    .fontWeight(FontWeight.Bold)
                        )
                    }
                }
            }
        }
    }
}
