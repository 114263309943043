package org.jjoy.ltd.data.repositoryImpl

import kotlinx.browser.localStorage
import org.jjoy.ltd.core.domain.model.job.JobAppDto
import org.jjoy.ltd.core.domain.model.job.JobCategoryInfo
import org.jjoy.ltd.core.domain.model.job.JobDTO
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.core.util.encodeToString
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.ApiClient
import org.jjoy.ltd.di.netWorkModule
import org.jjoy.ltd.domain.repository.JobRepository
import org.jjoy.ltd.util.const.EndPoints.APPLY_FOR_JOB
import org.jjoy.ltd.util.const.EndPoints.GET_APPLICATIONS
import org.jjoy.ltd.util.const.EndPoints.GET_JOBS
import org.jjoy.ltd.util.const.EndPoints.GET_JOBS_INFO
import org.jjoy.ltd.util.const.EndPoints.GET_JOB_DETAILS
import org.jjoy.ltd.util.const.EndPoints.JOB_CHECK
import org.jjoy.ltd.util.const.EndPoints.SEARCH_JOBS
import org.jjoy.ltd.util.const.LocalStorageConst.USER_ID
import org.kodein.di.DI
import org.kodein.di.instance

class JobRepositoryImpl(
    di: DI = netWorkModule,
) : JobRepository {

    val id = localStorage.getItem(USER_ID)
    private val apiClient: ApiClient by di.instance()

   /* override suspend fun addJob(jobDTO: JobDTO): ApiFlow<String> {
        val json = jobDTO.encodeToString()
        console.log("json::${json}")
        console.log("decoded::${json.decode<JobDTO>()}")
        return apiFlowResponse<String> {
            apiClient.fetch(path = ADD_JOB, method = "POST", body = jobDTO.encodeToString())
        }
    }*/

    override suspend fun getJobs(id: String?): ApiFlow<List<JobListItem>> {
        return apiFlowResponse<List<JobListItem>> { apiClient.fetch(path = GET_JOBS, method = "GET") }
    }

    override suspend fun getJobsListInfo(): ApiFlow<List<JobCategoryInfo>> {
        return apiFlowResponse<List<JobCategoryInfo>> {
            apiClient.fetch(path = GET_JOBS_INFO, method = "GET")
        }
    }

    override suspend fun searchJobs(query: String,category: String): ApiFlow<List<JobListItem>> {
        return apiFlowResponse<List<JobListItem>> { apiClient.fetch(path = "$SEARCH_JOBS$query/$category", method = "GET") }
    }

    override suspend fun getJobDetails(id: String): ApiFlow<JobDTO> {
        return apiFlowResponse<JobDTO> {
            apiClient.fetch(path = "$GET_JOB_DETAILS$id", method = "GET")
        }
    }

    override suspend fun applyForJob(jobAppDto: JobAppDto): ApiFlow<String> {
        return apiFlowResponse<String> {
            apiClient.fetch(
                path = APPLY_FOR_JOB,
                method = "POST",
                body = jobAppDto.encodeToString()
            )
        }
    }

    override suspend fun getApplications(): ApiFlow<List<JobAppDto>> {
        return apiFlowResponse<List<JobAppDto>> {
            apiClient.fetch(
                path = GET_APPLICATIONS, //TODO: ADD admin key
                method = "GET"
            )
        }
    }

    override suspend fun checkIfApplied(jobId: String): ApiFlow<Boolean> {
        return apiFlowResponse<Boolean> {
            apiClient.fetch(
                path = "$JOB_CHECK/$jobId",
                method = "GET"
            )
        }
    }
}
