package org.jjoy.ltd.util.const

import org.jjoy.ltd.core.domain.model.ServiceItem

object Constants {
    const val SECTION_WIDTH = 1920
    const val SECTION_HEIGHT = 1080
    const val FONT_FAMILY = "Roboto"
    const val WEBSITE_NAME = "JJOY Ltd"
    const val WEBSITE_NAME_CAPS = "JJOY SERVICES LTD"
    const val WEBSITE_NAME_SHORT = "JJOY"
    const val CONTACT_EMAIL = "contact@jjoyservicesltd.com"

    const val API_BASE_URL = "https://api.jjoyservicesltd.com/"
    //const val API_BASE_URL = "http://localhost:8081/"

    const val ADDRESS = "J Joy services Ltd, " + "206A Railton Road," + "SE24 0JT," + "LONDON"
    const val PHONE = "+44 7469643564"

    const val WELCOME_DESC =
        "$WEBSITE_NAME_SHORT is a  combination of recruitment, training, and consultancy services by providing tailored solutions to public and private sector employers across the UK. \n \nWe are a team of experienced managers with core principles of quality, passion, drive, and dedication in all that we do. \n \n We provide a wide range of solutions to our varied client base, which includes public, private, and third sectors. This is why we pride ourselves in saying that we are a \"recruitment solution\"."
}

object NavRailItemId {
    const val JOBS = "jobs_id"
    const val USERS = "users_id"
    const val APPS = "apps_id"
}

object LoginSectionID {
    const val LOGIN_VIEW = "login"
    const val REGISTER_VIEW = "register"
    const val FORGOT_PASS_VIEW = "forgot_pass"
}

object JobSectionID {
    const val JOB_DETAILS = "job_details"
    const val USER_FORMS = "user_form"
}

object Groups {
    val ethinic =
        listOf(
            "English",
            "Welsh",
            "Scottish",
            "Northern Irish",
            "Irish",
            "European",
            "Eastern EU",
            "Prefer not to say"
        )

    val mix = listOf("White and Black Caribbean", "White and Black African", "White and Asian")

    val asian = listOf("Indian", "Pakistani", "Bangladeshi", "Chinese")

    val black = listOf("African", "Caribbean")

    val other = listOf("Arab", "Middle Eastern")

    val sexualOri =
        listOf("Heterosexual", "Gay woman/lesbian", "Gay man", "Bisexual", "Prefer not to say")

    val religions =
        listOf(
            "Christian",
            "Muslim",
            "Buddhist",
            "Hindu",
            "Sikh",
            "Jewish",
            "No religious belief",
            "Prefer not to say"
        )
}

val servicesList =
    listOf(
        ServiceItem(
            title = "Information Technology",
            desc =
                "Our teams of IT and computing associates and contractors cover the following technologies and skills areas",
            icon = "computer",
            items =
                listOf(
                    "Application Development",
                    "Business Analyst",
                    "Database Administration",
                    "Database Development",
                    "Datacomms",
                    "IT Management",
                    "Network Security",
                    "Project Management",
                    "Support and Administration",
                    "Systems Analyst",
                    "Test Analyst",
                    "Web Design",
                    "Web Development"
                ),
            header = "/it.jpg"
        ),
        ServiceItem(
            title = "Parking",
            desc =
                "Our parking recruitment team helps organizations to find people to fulfill parking roles, such as enforcing parking restrictions. We provide people on a contract, temporary or permanent basis, including parking attendants/civil enforcement officers and parking officers with City & Guilds Level 2 Certificate for Parking Attendants and NVQ.",
            icon = "square-parking",
            items =
                listOf(
                    "Parking Appeals supervisors",
                    "Parking Appeals manager",
                    "Debt Recovery Officer",
                    "Representations Officer",
                    "CCTV Manager",
                    "Interim Manager",
                    "Parking Services Manager",
                    "Parking Administration",
                    "Parking call center Officer",
                    "Parking Customer Service Officer",
                    "Parking Appeals Officer",
                    "Adjudication Officer",
                    "Parking Supervisor On – Street supervisor/enforcement supervisor",
                    "Parking Enforcement Manager - Base Manager / Contract Manager",
                    "Mobile Civil Enforcement Officer",
                    "CCTV Officer (Btech Qualified)",
                    "Civil Enforcement Officer (Parking Attendants)",
                    "Parking correspondence Officer",
                ),
            header = "/parking.jpg"
        ),
        ServiceItem(
            title = "Security officers",
            desc = "Our teams of associates and contractors cover the following areas",
            icon = "shield-halved",
            items =
                listOf(
                    "Door Supervisors",
                    "Security Officer",
                    "Concierge",
                    "CCTV Services",
                    "Mobile Officer",
                    "Security Guard",
                    "Corporate Security Officer",
                    "Residential Security Officer",
                    "Retail Security Officer",
                    "Manned Guarding"
                ),
            header = "/security.jpg"
        ),
        ServiceItem(
            title = "Professional Cleaners",
            desc =
                "We are specialist   providers in Domestic and Commercial Cleanings. We understand the importance of your Company's image and office appearance. We believe that our trained professionals can provide you with the highest level of performance on a consistent basis.\n" +
                    "\n" +
                    "We also provide honest and reliable Domestic Cleaners for all your Housework chores in Homes, Houses, and Flats. Duties include Washing, Laundry, and Ironing services as well as a variety of other domestic cleaning services. We guarantee to locate a replacement if your domestic cleaner is unsuitable. We also guarantee to organize cover if your cleaner is absent for any reason.",
            icon = "broom",
            items =
                listOf(
                    "Domestic",
                    "Professional",
                    "Domestic cleaning services",
                    "Professional cleaners",
                    "Domestic cleaners",
                    "Professional cleaners"
                ),
            header = "/clean.jpg"
        ),
        ServiceItem(
            title = "Care Services",
            icon = "hospital-user",
            items =
                listOf(
                    "Registered Nurse",
                    "Support Worker",
                    "Care Assistant",
                    "Healthcare Assistant"
                ),
            header = "/care.jpg"
        ),
        ServiceItem(
            title = "Logistic & Facility Service",
            icon = "truck",
            items =
                listOf(
                    "Warehouse Operative",
                    "Forklift Driver",
                    "Cleaning Operatives",
                    "House Keeping Associates",
                    "Delivery Drivers"
                ),
            header = "/warehouse.jpg"
        ),
    )


object MetaConst{
    const val HomeTitle = "J JOY Services"
    const val HomeDescription = "J Joy Services offers quality services for all your needs. Contact us for reliable service provider solutions."
    const val JobsTitle = "Find Jobs"
    const val JobsDescription = "We support local authorities and private companies with temporary and permanent recruitment services. Our team consist of experienced managers with experience in various industries such as accounting, security, nursing, parking enforcement, recruitment and training."
}