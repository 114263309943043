package org.jjoy.ltd.pages

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.WhiteSpace
import com.varabyte.kobweb.compose.foundation.layout.ColumnScope
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.color
import com.varabyte.kobweb.compose.ui.modifiers.fontSize
import com.varabyte.kobweb.compose.ui.modifiers.fontWeight
import com.varabyte.kobweb.compose.ui.modifiers.whiteSpace
import com.varabyte.kobweb.core.Page
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayIfAtLeast
import com.varabyte.kobweb.silk.components.layout.breakpoint.displayUntil
import com.varabyte.kobweb.silk.components.style.breakpoint.Breakpoint
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.PageSimpleTitle
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.composables.wrappers.FullPageWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleDesktopWrapper
import org.jjoy.ltd.presentation.screen.user_form.desktop.SimpleMobileWrapper
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.extension.css

@Page("/aboutUs")
@Composable
fun AboutUs() {

    val ctx = rememberPageContext()

    val breakPoint = rememberBreakpoint()

    FullPageWrapper(
        title = "About Us",
        contentId = "aboutUs"
    ) {
        PageSimpleTitle(textMain = "About Us", textDesc = "")

        SimpleDesktopWrapper(
            modifier = Modifier.displayIfAtLeast(Breakpoint.MD),
        ) {
            Content()
        }
        SimpleMobileWrapper(
            modifier = Modifier.displayUntil(Breakpoint.MD),
        ) {
            Content()
        }
    }
}

@Composable
private fun ColumnScope.Content() {
    Text(
        modifier =
            Modifier.fontSize(dimension.smallText * 1.2)
                .fontWeight(550)
                .color(color.onBackground.css(90)),
        text =
            "${Constants.WEBSITE_NAME} is a motivational organisation which outsource human resources for various service provisions. Our mission is to provide well disciplined, honest professionals with required essential skills in service provision. "
    )
    Text(text = "")
    Text(
        modifier =
            Modifier.fontSize(dimension.smallText * 1.2)
                .fontWeight(550)
                .color(color.onBackground.css(90)),
        text =
            "${Constants.WEBSITE_NAME} provides recruitment, training and consultancy solutions to employers across the UK. We offer a broad range of solutions to organisations by combining recruitment and training which is one of the most effective ways of solving resourcing issues."
    )

    TextWithTitle(
        title = "Objective",
        desc =
            "Our objective is to establish excellent service standards to suit every requirement of the client. We are here to provide solutions to all your staffing requirements from emergency cover through to longer term bookings."
    )

    TextWithTitle(
        title = "What we do with the information we gather",
        desc =
            "The way we are structured sets us widely apart from other competitors. Our goal is to offer wide range of solutions by seamlessly combining recruiting with the training requirements. Everyone at Squadrant is focused on delivering services with integrity and professionalism by firmly establishing success stories both for ourselves and our clients."
    )
    Text(text = "")
    Text(
        modifier =
            Modifier.whiteSpace(WhiteSpace.PreLine)
                .fontSize(dimension.smallText * 1.3)
                .fontWeight(550)
                .color(color.onBackground.css(90)),
        text =
            "We pride ourselves in our team consisting of experienced managers in various industries to provide necessary support to public and private companies with temporary and permanent business solutions."
    )

    Text(text = "")
    Text(
        modifier =
            Modifier.whiteSpace(WhiteSpace.PreLine)
                .fontSize(dimension.smallText * 1.3)
                .fontWeight(550)
                .color(color.onBackground.css(90)),
        text = "Contact us today to find out what we can offer you."
    )

    TextWithTitle(
        title = "Our Mission",
        desc = "For the purpose of helping people in finding their perfect jobs and to assist businesses in finding the greatest talent. We think that everyone deserves a fulfilling profession and we are dedicated to assisting people in achieving their objectives. Additionally, as we think that businesses must have the most outstanding talent to flourish, we are dedicated to assisting them in finding the best."
    )

    /*TextWithTitle(
        title = "Vision statement ",
        desc = "We operate the squad to help you accomplish your objectives and goals.")
*/

}
