package org.jjoy.ltd.presentation.admin.jobs

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxSize
import com.varabyte.kobweb.compose.ui.modifiers.fillMaxWidth
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.disclosure.Tabs
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.pages.MainViewModel
import org.jjoy.ltd.presentation.admin.jobs.edit.JobEditScreen
import org.jjoy.ltd.presentation.components.sections.TabTitle
import org.jjoy.ltd.presentation.components.wrapper.PanelWrapper
import org.jjoy.ltd.util.base.collectEvent
import org.jjoy.ltd.util.base.collectState
import org.jjoy.ltd.util.base.rememberViewModel

enum class JobPanel {
    LIST_ADD,
    EDIT,
}

@Composable
fun JobDashBoard(
    viewModel: JobsDashBoardViewModel,
    modifier: Modifier = Modifier,
) {

    val ctx = rememberPageContext()
    val mainViewModel = rememberViewModel { MainViewModel() }
    val state by viewModel.collectState()
    val uiEventFlow by viewModel.collectEvent()


    PanelWrapper(
        isLoading = state.isLoading,
        contentModifier = Modifier.fillMaxSize()
    ) {
        when (state.currentPage) {
            JobPanel.LIST_ADD ->
                ListAddJob(
                    state = state,
                    uiEventFlow = uiEventFlow,
                    onActionEvent = viewModel::onActionEvent,
                    onApply = {
                        viewModel.onActionEvent(
                            JobsDashActionEvent.UpdateCurrentPanel(JobPanel.EDIT)
                        )
                        viewModel.onActionEvent(JobsDashActionEvent.UpdateJobId(it.id))
                        // ctx.router.navigateTo("/admin/jobs/edit/${it.id}")
                    },
                )
            JobPanel.EDIT -> {
                JobEditScreen(
                    id = state.editJobId,
                ) {
                    viewModel.onActionEvent(
                        JobsDashActionEvent.UpdateCurrentPanel(JobPanel.LIST_ADD)
                    )
                }
            }
        }
    }
}

@Composable
private fun ListAddJob(
    state: JobsDashUIState,
    uiEventFlow: JobDashUIEvent?,
    onActionEvent: TCallback<JobsDashActionEvent>,
    onApply: TCallback<JobListItem>,
    modifier: Modifier = Modifier
) {
    val (selected,setSelected) = remember { mutableStateOf(0) }

    Tabs(
        modifier = modifier.fillMaxWidth(),
        onTabSelected = setSelected
    ) {
        TabPanel(isDefault = selected == 0) {
            TabTitle(title = "JOBS")
            Panel {
                AdminJobList(
                    state = state,
                    onSearch = { it,it2 -> onActionEvent(JobsDashActionEvent.SearchJob(it,it2)) },
                    onApply = {
                        onApply(it)
                        // ctx.router.navigateTo("/admin/jobs/edit/${it.id}")
                    },
                )
            }
        }
        TabPanel(isDefault = selected == 1) {
            TabTitle(title = "ADD")
            Panel { AdminAddJob(state = state, onEvent = onActionEvent, uiEvent = uiEventFlow) }
        }
    }
}
