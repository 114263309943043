package org.jjoy.ltd.domain.model

enum class Section(
    val id: String,
    val title: String,
    val subtitle: String,
    val path: String,
) {

    Home(
        id = "home",
        title = "Home",
        subtitle = "",
        path = "#home"
    ),
    Register(
        id = "register",
        title = "Register",
        subtitle = "",
        path = "#register"
    ),
    Welcome(
        id = "welcome",
        title = "Welcome",
        subtitle = "",
        path = "#welcome"
    ),
    Service(
        id = "service",
        title = "Service",
        subtitle = "",
        path = "#service"
    ),
    Process(
        id = "process",
        title = "Process",
        subtitle = "",
        path = "#process"
    ),
    Jobs(
        id = "jobs",
        title = "Jobs",
        subtitle = "",
        path = "#jobs"
    ),

}


enum class NavItems(
    val title: String,
    val path: String,
){
    HOME(
        title = "Home",
        path = "/"
    ),
    JOBS(
        title = "Jobs",
        path = "/search/jobs/"
    ),
    ABOUT(
        title = "About",
        path = "/aboutUs"
    ),
    CONTACT(
        title = "Contact",
        path = "/about/contactUs"
    ),
}