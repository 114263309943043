package org.jjoy.ltd.data.repositoryImpl

import org.jjoy.ltd.core.domain.model.job.JobApplicationInfo
import org.jjoy.ltd.core.util.ApiFlow
import org.jjoy.ltd.data.remote.apiFlowResponse
import org.jjoy.ltd.data.remote.network.AdminApi
import org.jjoy.ltd.domain.repository.AdminApplicationRepository
import org.jjoy.ltd.util.const.Constants
import org.jjoy.ltd.util.const.EndPoints

class AdminApplicationRepositoryImpl(): AdminApplicationRepository {


    private val adminApi by lazy { AdminApi().baseUrl(Constants.API_BASE_URL).build() }


    override suspend fun getApplications(): ApiFlow<List<JobApplicationInfo>> {
        return apiFlowResponse {
            adminApi.fetch(path = EndPoints.GET_APPLICATIONS, method = "GET")
        }
    }
}