package org.jjoy.ltd.presentation.components.input

import androidx.compose.runtime.*
import com.varabyte.kobweb.compose.css.CSSTransition
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.css.VerticalAlign
import com.varabyte.kobweb.compose.file.LoadContext
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.foundation.layout.Row
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.compose.ui.thenIf
import com.varabyte.kobweb.compose.ui.toAttrs
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import kotlinx.browser.document
import kotlinx.browser.window
import org.jetbrains.compose.web.css.FlexWrap
import org.jetbrains.compose.web.css.cssRem
import org.jetbrains.compose.web.css.times
import org.jetbrains.compose.web.dom.Span
import org.jjoy.ltd.components.widgets.buttons.BaseButtonStyle
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.presentation.components.wrapper.Icon
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.theme.style.text.BaseTextStyle
import org.jjoy.ltd.util.extension.animate
import org.jjoy.ltd.util.extension.animateColor
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.extension.rgb
import org.w3c.dom.Document
import org.w3c.dom.HTMLInputElement
import org.w3c.files.File
import org.w3c.files.FileReader

@Composable
fun FilePickerWithLabel(
    label: String,
    accept: String =
        "image/jpeg,image/png,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    modifier: Modifier = Modifier,
    isError: Boolean = false,
    isMandatory: Boolean = false,
    onPicked: (File) -> Unit
) {

    Column(
        modifier = modifier.gap(dimension.extraSmallPadding * 2),
        horizontalAlignment = Alignment.Start,
        verticalArrangement = Arrangement.Top
    ) {
        Row(
            modifier = Modifier.margin(bottom = dimension.smallPadding * 1.1),
            verticalAlignment = Alignment.CenterVertically
        ) {
            Span(attrs = Modifier.verticalAlign(VerticalAlign.Middle).toAttrs()) {
                Text(
                    text = label,
                    modifier =
                        Modifier.fontSize(dimension.smallButtonText)
                            .fontWeight(550)
                            .color(color.onBackground.css(85))
                            .thenIf(isError, Modifier.color(color.error.css))
                            .animateColor()
                )
                if (isMandatory) {
                    Text(
                        text = "*",
                        modifier =
                            Modifier.fontSize(dimension.smallButtonText)
                                .fontWeight(550)
                                .color(color.error.css)
                    )
                }
            }
        }

        FilePickerInput(accept = accept, onPicked = onPicked)
    }
}

@Composable
fun FilePickerInput(
    accept: String = "image/*",
    modifier: Modifier = Modifier,
    onPicked: (File) -> Unit
) {

    // val scope = rememberCoroutineScope()
    val defaultText = "No files selected.."
    var infoText by remember { mutableStateOf(defaultText) }
    var errorText by remember { mutableStateOf("") }
    var onItemPicked by remember { mutableStateOf(false) }

    Row(
        modifier =
            modifier
                .backgroundColor(color.bgGray.css)
                .borderRadius(dimension.mediumRoundness * 0.6)
                .flexWrap(FlexWrap.Wrap)
                .gap(dimension.smallPadding),
        horizontalArrangement = Arrangement.Start,
        verticalAlignment = Alignment.CenterVertically
    ) {
        NormalButton(
            text = "Choose File",
            onClick = {
                document.loadFileDisk(accept = accept) { payload ->
                    infoText = filename
                    onItemPicked = true
                    onPicked(payload)
                }
            },
            variant = FilePickerButtonComp
        )

        Row(
            modifier = Modifier.margin(right = dimension.smallPadding * 1.5).gap(dimension.smallPadding),
            verticalAlignment = Alignment.CenterVertically,
            horizontalArrangement = Arrangement.Start
        ) {
            Text(
                modifier = BaseTextStyle.toModifier().textAlign(TextAlign.Left),
                variant = TextSimple,
                text = infoText.take(25)
            )

            Icon(
                modifier =
                Modifier.opacity(0).scale(0).thenIf(onItemPicked, Modifier.scale(1).opacity(1)),
                name = "xmark",
                onClick = {
                    infoText = defaultText
                    onItemPicked = false
                }
            )
        }

    }
}

val TextSimple by
    BaseTextStyle.addVariant {
        base { Modifier.fontSize(dimension.smallText * 0.9).fontWeight(600) }
    }

val FilePickerButtonComp by
    BaseButtonStyle.addVariant {
        base {
            Modifier.fontSize(dimension.smallText * 0.9)
                .fontWeight(550)
                .margin(
                    top = dimension.smallPadding * 1.3,
                    bottom = dimension.smallPadding * 1.3,
                    left = dimension.smallPadding * 1.3,
                    right = dimension.mediumPadding
                )
                .padding(
                    top = dimension.smallPadding * 1.5,
                    bottom = dimension.smallPadding * 1.5,
                    left = dimension.mediumPadding,
                    right = dimension.mediumPadding
                ).borderRadius(dimension.mediumRoundness * 0.6)
                .transition(CSSTransition(property = "color"))
        }
        hover { Modifier.backgroundColor(color.primary.rgb.darkened()) }
    }

val closeIconStyle by ComponentStyle {
    base { Modifier.opacity(0).scale(0).animate(prop = listOf("opacity", "cover", "translate")) }
    hover { Modifier.scale(1.3).color(color.primary.css).translateY((-.2).cssRem) }
}

fun Document.loadFileDisk(
    accept: String = "",
    onProgress: (Int) -> Unit = {},
    onLoaded: LoadContext.(File) -> Unit,
) {
    loadFromDisk(accept = accept, onFileLoaded = onLoaded, onProgress = onProgress)
}

private fun Document.loadFromDisk(
    accept: String = "",
    maxSizeInBytes: Long = 1 * 1024 * 1024,
    onFileLoaded: LoadContext.(File) -> Unit,
    onProgress: (Int) -> Unit
) {
    val tempInput = createElement("input") as HTMLInputElement

    tempInput.apply {
        type = "file"
        style.display = "none"
        this.accept = accept
        multiple = false

        onchange = { changeEvt ->
            val file = changeEvt.target.asDynamic().files[0] as File

            if (maxSizeInBytes > 0 && file.size.toLong() > maxSizeInBytes) {
                // File size exceeds the specified limit
                // Handle the error or notify the user
                window.alert("File size exceeds the limit. Please select a smaller file.")
            }

            val reader = FileReader()

            reader.onload = {
                onFileLoaded(LoadContext(file.name, file.type.takeIf { it.isNotBlank() }), file)
            }

            reader.onprogress = { progressEvt ->
                val progress =
                    ((progressEvt.loaded.asDynamic() / progressEvt.total.asDynamic()) * 100)
                onProgress(progress as Int)
            }

            reader.readAsArrayBuffer(file)
        }
    }

    body!!.append(tempInput)
    tempInput.click()
    tempInput.remove()
}
