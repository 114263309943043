package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import androidx.compose.runtime.getValue
import androidx.compose.runtime.mutableStateOf
import androidx.compose.runtime.remember
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.graphics.Colors
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.icons.fa.FaIcon
import com.varabyte.kobweb.silk.components.icons.fa.IconCategory
import com.varabyte.kobweb.silk.components.icons.fa.IconSize
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.core.domain.model.job.JobListItem
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.const.servicesList
import org.jjoy.ltd.util.extension.*

@Composable
fun JobItemCard(jobDTO: JobListItem, onApply: Callback, modifier: Modifier = Modifier) {

    val icon by remember {
        mutableStateOf(servicesList.find { it.title == jobDTO.jobCategory }?.icon ?: "user-nurse")
    }

    Row(
        modifier = modifier.then(JobItemCardStyle.toModifier()).onClick { onApply() },
        verticalAlignment = Alignment.Top,
        horizontalArrangement = Arrangement.Start
    ) {
        Box(
            modifier =
                Modifier.backgroundColor(color.primary.css)
                    .rounded()
                    .boxShadow(
                        offsetY = 1.px,
                        offsetX = 1.px,
                        blurRadius = 0.90.cssRem,
                        spreadRadius = 0.5.cssRem,
                        color = color.primary.css(10)
                    )
                    .padding(dimension.mediumPadding),
            contentAlignment = Alignment.Center
        ) {
            FaIcon(
                modifier =
                    Modifier.margin(
                            left = dimension.smallPadding * 2.5,
                            right = dimension.smallPadding * 2.5,
                            bottom = dimension.smallPadding,
                            top = dimension.smallPadding
                        )
                        .fontSize(dimension.largeText)
                        .color(color.onPrimary.css),
                name = icon,
                style = IconCategory.SOLID,
            )
        }

        Column(
            modifier =
                Modifier.margin(left = dimension.normalPadding).fillMaxWidth().fillMaxHeight(),
            verticalArrangement = Arrangement.Center,
            horizontalAlignment = Alignment.Start
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {
                Text(
                    text = jobDTO.title,
                    modifier =
                        Modifier.fontWeight(600)
                            .fontSize(dimension.textFieldLabelText)
                            .color(color.onBackground.css(90))
                )
                Spacer()
            }

            Row(
                modifier = Modifier,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Column(
                    modifier = Modifier,
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start
                ) {
                    Row(
                        modifier =
                            Modifier.gap(
                                dimension.extraSmallPadding.plus(dimension.extraSmallPadding)
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaIcon(
                            name = "location-dot",
                            modifier = Modifier.color(color.primary.css(80)),
                            style = IconCategory.SOLID,
                            size = IconSize.X1
                        )
                        Text(
                            text = jobDTO.location,
                            modifier =
                                Modifier.fontWeight(600)
                                    .fontSize(dimension.smallText)
                                    .color(color.onBackground.css(80))
                        )
                    }
                    Row(
                        modifier =
                            Modifier.gap(
                                dimension.extraSmallPadding.plus(dimension.extraSmallPadding)
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        if (jobDTO.isPerHour) {
                            HourlyWage(
                                salary = jobDTO.salary.first.format(0),
                                rangeSalary = jobDTO.salary.second.format(0)
                            )
                        } else {
                            FaIcon(
                                name = "money-bill",
                                modifier = Modifier.color(color.primary.css(80)),
                                style = IconCategory.SOLID,
                                size = IconSize.X1
                            )
                            Text(
                                text = "£${jobDTO.salary.second.format(0)} / Year",
                                modifier =
                                    Modifier.fontWeight(600)
                                        .fontSize(dimension.smallText)
                                        .color(color.onBackground.css(80))
                            )
                        }
                    }

                    Spacer()
                }
            }

            Spacer()

            Row(
                modifier = Modifier.align(Alignment.End).gap(0.2.cssRem),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                SimpleBadge(
                    modifier = SimpleBadgeStyle.toModifier(SMBadgeNormalStyle),
                    badgeText = jobDTO.jobCategory.limit(35),
                    onClick = {}
                )

                SimpleBadge(
                    badgeText = if (jobDTO.userApplied) "Applied" else "Apply",
                    onClick = onApply
                )
            }
        }
    }
}

@Composable
fun JobItemCardMobile(jobDTO: JobListItem, onApply: Callback, modifier: Modifier = Modifier) {

    Row(
        modifier = modifier.then(JobItemCardStyle.toModifier()).onClick { onApply() },
        verticalAlignment = Alignment.CenterVertically,
        horizontalArrangement = Arrangement.Start
    ) {
        Column(
            modifier =
                Modifier.margin(left = dimension.normalPadding).fillMaxWidth().fillMaxHeight(),
            verticalArrangement = Arrangement.Top,
            horizontalAlignment = Alignment.Start
        ) {
            Row(
                modifier = Modifier.fillMaxWidth(),
                verticalAlignment = Alignment.Top,
                horizontalArrangement = Arrangement.Start
            ) {
                Text(
                    text = jobDTO.title,
                    modifier =
                        Modifier.fontWeight(600)
                            .fontSize(dimension.textFieldLabelText)
                            .color(color.onBackground.css(90))
                )
                Spacer()
            }

            Row(
                modifier = Modifier,
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                Column(
                    modifier = Modifier,
                    verticalArrangement = Arrangement.Center,
                    horizontalAlignment = Alignment.Start
                ) {
                    Row(
                        modifier =
                            Modifier.gap(
                                dimension.extraSmallPadding.plus(dimension.extraSmallPadding)
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        FaIcon(
                            name = "location-dot",
                            modifier = Modifier.color(color.primary.css(80)),
                            style = IconCategory.SOLID,
                            size = IconSize.XS
                        )
                        Text(
                            text = jobDTO.location,
                            modifier =
                                Modifier.fontWeight(600)
                                    .fontSize(dimension.smallText)
                                    .color(color.onBackground.css(80))
                        )
                    }
                    Row(
                        modifier =
                            Modifier.gap(
                                dimension.extraSmallPadding.plus(dimension.extraSmallPadding)
                            ),
                        verticalAlignment = Alignment.CenterVertically
                    ) {
                        if (jobDTO.isPerHour) {
                            HourlyWage(
                                salary = jobDTO.salary.first.format(0),
                                rangeSalary = jobDTO.salary.second.format(0)
                            )
                        } else {
                            //TODO: Add this
                            /*HourlyWage(
                                salary = jobDTO.salary.first.format(0),
                                rangeSalary = jobDTO.salary.second.format(0)
                            )*/
                            FaIcon(
                                name = "money-bill",
                                modifier = Modifier.color(color.primary.css(80)),
                                style = IconCategory.SOLID,
                                size = IconSize.X1
                            )
                            Text(
                                text = "£${jobDTO.salary.second.format(0)} / Year",
                                modifier =
                                    Modifier.fontWeight(600)
                                        .fontSize(dimension.smallText)
                                        .color(color.onBackground.css(80))
                            )
                        }
                    }

                    Spacer()
                }
            }

            Spacer()

            Row(
                modifier = Modifier.align(Alignment.End).gap(0.2.cssRem),
                verticalAlignment = Alignment.CenterVertically,
                horizontalArrangement = Arrangement.Start
            ) {
                SimpleBadge(
                    modifier = SimpleBadgeStyle.toModifier(SMBadgeNormalStyle),
                    badgeText = jobDTO.jobCategory.limit(30),
                    onClick = {}
                )

                SimpleBadge(badgeText = "Apply", onClick = onApply)
            }
        }
    }
}

@Composable
private fun HourlyWage(modifier: Modifier = Modifier, salary: String, rangeSalary: String) {
    Row(
        modifier = modifier.gap(dimension.extraSmallPadding.plus(dimension.extraSmallPadding)),
        verticalAlignment = Alignment.CenterVertically
    ) {

        // sterling-sign
        FaIcon(
            name = "money-bill",
            modifier = Modifier.color(color.primary.css(80)),
            style = IconCategory.SOLID,
            size = IconSize.X1
        )

        if (salary.toDouble() == rangeSalary.toDouble()) {
            Text(
                text = "£$salary",
                modifier =
                    Modifier.fontWeight(600)
                        .fontSize(dimension.smallText)
                        .color(color.onBackground.css)
            )
        } else {
            Text(
                text = "£$salary -",
                modifier =
                    Modifier.fontWeight(600)
                        .fontSize(dimension.smallText)
                        .color(color.onBackground.css)
            )
            Text(
                text = " £$rangeSalary",
                modifier =
                    Modifier.fontWeight(600)
                        .fontSize(dimension.smallText)
                        .color(color.onBackground.css)
            )
        }
    }
}

val JobItemCardStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(Colors.White)
            .scale(1)
            .translateY(0.percent)
            .rounded()
            .gap(dimension.extraSmallPadding)
            .padding(dimension.mediumPadding)
            .margin(dimension.smallPadding)
            .boxShadow(
                offsetY = 1.px,
                offsetX = 1.px,
                blurRadius = 0.90.cssRem,
                spreadRadius = 0.5.cssRem,
                color = color.primary.css(10)
            )
            .animate(prop = listOf("scale", "translate", "box-shadow"))
    }

    hover {
        Modifier.scale(1.01)
            .translateY((-1.7).percent)
            .boxShadow(
                offsetY = 1.px,
                offsetX = 1.px,
                blurRadius = 1.25.cssRem,
                spreadRadius = 0.89.cssRem,
                color = color.primary.css(15)
            )
    }
}
