package org.jjoy.ltd.core.domain.model.job

import com.benasher44.uuid.uuid4
import kotlinx.datetime.Clock
import kotlinx.serialization.Serializable
import org.jjoy.ltd.core.util.extension.formatDouble
import org.jjoy.ltd.util.const.servicesList
import kotlin.random.Random
import kotlin.time.Duration.Companion.seconds

@Serializable
data class JobDTO(
    val id: String = uuid4().toString(),
    val title: String,
    val description: String,
    val location: String,
    val workLocation: String,
    val requirements: List<String>,
    val certificatesRequired: List<String>,
    val jobType: JobType,
    val jobCategory: String,
    val salary: Pair<Double, Double>,
    val isPerHour: Boolean = true,
    val isFeatured: Boolean = false,
    val postedAt: Long = Clock.System.now().toEpochMilliseconds(),
    val updatedAt: Long = Clock.System.now().toEpochMilliseconds(),
    val expiryAt: Long = Clock.System.now().plus(20000000.seconds).toEpochMilliseconds(),
    val isDeleted: Boolean = false,
    val isExpired: Boolean = false,
) {

    fun getSalary(): String {
        val (low, high) = salary
        if (low.formatDouble() == high.formatDouble()) {
            return if (isPerHour) {
                "£${low.formatDouble()} / Hour"
            } else {
                "£${low.formatDouble()} / Year"
            }
        }
        return if (isPerHour) {
            "£${low.formatDouble()} - £${high.formatDouble()} / Hour"
        } else {
            "£${low.formatDouble()} - £${high.formatDouble()} / Year"
        }
    }

    val isCertificationRequired: Boolean
        get() = certificatesRequired.isNotEmpty()

    companion object {
        fun dummy(): JobDTO {
            return JobDTO(
                id = "posidonium",
                title = "hinc",
                description = "intellegat",
                location = "phasellus",
                workLocation = "ferri",
                requirements = listOf(),
                certificatesRequired = listOf(),
                jobType = JobType.FULL_TIME,
                jobCategory = "Johnie Gross",
                salary = Pair(18.00, 25.00),
                isPerHour = false,
                isFeatured = false,
                postedAt = 5105,
                updatedAt = 3573,
                expiryAt = 4718,
                isDeleted = false,
                isExpired = false
            )
        }
    }
}

@Serializable data class JobCategoryInfo(val jobCategory: String?, val jobCount: Int?)

@Serializable
data class JobListItem(
    val id: String,
    val title: String,
    val location: String,
    val workLocation: String,
    val jobType: JobType,
    val jobCategory: String,
    val salary: Pair<Double, Double>,
    val isPerHour: Boolean = true,
    val isFeatured: Boolean = false,
    val userApplied: Boolean = false
)

enum class JobType {
    FULL_TIME,
    CASUAL,
    PART_TIME;

    fun getName(): String {
        return when (this) {
            FULL_TIME -> "FULL TIME"
            PART_TIME -> "PART TIME"
            CASUAL -> "CASUAL"
        }
    }

    companion object {
        fun getList(): List<JobType> {
            return enumValues<JobType>().toList()
        }
    }
}

fun generateJobs(count: Int): List<JobDTO> {
    val jobTitles =
        listOf(
            "Software Engineer",
            "Graphic Designer",
            "Marketing Manager",
            "Data Analyst",
            "Project Manager",
            "Sales Representative",
            "Accountant",
            "Human Resources Coordinator",
            "Web Developer",
            "Product Manager",
            "Parking officer",
            "Android Developer",
            "iOS Developer",
        )

    val jobLocations =
        listOf(
            "New York",
            "Los Angeles",
            "Chicago",
            "San Francisco",
            "Seattle",
            "Boston",
            "Dallas",
            "Atlanta",
            "Miami",
            "Denver"
            // Add more job locations as needed
        )

    val jobDescriptions =
        listOf(
            "As a Software Engineer, you will be responsible for developing clean and efficient code using Kotlin and collaborating with cross-functional teams to deliver high-quality software products. You'll participate in code reviews, identify areas of improvement, and implement best practices to ensure optimal performance and maintainability.",
            "As a Graphic Designer, your role involves creating visually appealing graphics and designs that align with the company's brand identity. Using various design software, you'll produce engaging marketing materials, web graphics, and illustrations. Your creativity and attention to detail will play a crucial role in enhancing the company's visual communication.",
            "As a Marketing Manager, you'll be leading marketing initiatives to promote products or services. This role requires analyzing market trends, identifying target audiences, and executing strategic marketing campaigns across various channels. Your ability to track performance metrics and adapt strategies will contribute to boosting brand awareness and achieving business objectives.",
            "As a Data Analyst, you will analyze and interpret complex data sets using Kotlin to derive valuable insights for business decision-making. Your responsibilities include data cleaning, statistical analysis, and data visualization to communicate findings effectively. You'll collaborate with stakeholders to understand requirements and provide data-driven solutions for optimizing business processes.",
            "As a Project Manager, you'll take charge of planning, executing, and closing projects within defined timelines and budgets. You'll lead project teams, allocate resources, and ensure effective communication with stakeholders to achieve project objectives. Your strong organizational skills and ability to manage risks will be essential in delivering successful project outcomes.",
            "As a Sales Representative, you'll play a crucial role in building customer relationships and driving revenue growth. Using Kotlin, you'll manage customer data and sales activities to identify potential leads, address customer inquiries, and negotiate contracts. Your persuasive communication and negotiation skills will be instrumental in achieving sales targets.",
            "As an Accountant, your responsibilities will include managing financial transactions, preparing financial reports, and ensuring compliance with accounting principles and regulations. Using Kotlin for data analysis, you'll assist in budgeting, forecasting, and conducting internal audits. Your meticulous attention to detail and accuracy will contribute to maintaining the company's financial health.",
            "As a Human Resources Coordinator, you'll support HR processes, including recruitment, onboarding, and employee engagement initiatives. Your role involves using Kotlin for data management and analysis to help HR make informed decisions. Creating a positive work environment and promoting employee well-being will be vital to fostering a productive workforce.",
            "As a Web Developer, you'll use Kotlin and web technologies to build and maintain user-friendly websites and web applications. Your focus will be on designing responsive and visually appealing interfaces while ensuring optimal website performance and accessibility. Collaborating with UI/UX designers and backend developers, you'll contribute to creating seamless online experiences for users.",
            "As a Product Manager, you'll oversee product development and strategy, conducting market research, and defining product roadmaps. Using Kotlin to analyze data, you'll identify customer needs and opportunities for product enhancement. Your role will involve coordinating cross-functional teams to deliver successful product launches and updates, driving business growth and customer satisfaction.",
            "As a Parking Officer, you'll enforce parking regulations, monitor parking areas, and issue citations for violations. You'll ensure smooth traffic flow and safety compliance while maintaining a positive and respectful interaction with the public. Your attention to detail and ability to handle challenging situations will contribute to an orderly and organized parking system.",
            "As an Android Developer, you will design and develop cutting-edge mobile applications for the Android platform using Kotlin. Your responsibilities include collaborating with cross-functional teams, integrating with backend services, and ensuring app performance and stability. Staying up-to-date with the latest Android trends and technologies will be vital to delivering innovative and user-friendly applications.",
            "As an iOS Developer, you'll be responsible for creating innovative and user-friendly iOS applications using Kotlin and Swift. Collaborating with designers and backend developers, you'll deliver seamless user experiences, from conceptualization to implementation. Staying updated with the latest iOS trends and best practices will be crucial to creating high-quality and performance-driven mobile applications."
        )

    val jobCategories = servicesList.map { it.title }

    val jobs = mutableListOf<JobDTO>()
    val random = Random

    repeat(count) {
        val title = jobTitles[random.nextInt(jobTitles.size)]
        val description = jobDescriptions.random()
        val location = jobLocations[random.nextInt(jobLocations.size)]
        val workLocation = if (random.nextBoolean()) "On-site" else "Remote"
        val requirements = generateRequirements()
        val certificatesRequired = generateCertificatesRequired()
        val jobType = if (random.nextBoolean()) JobType.FULL_TIME else JobType.PART_TIME
        val jobCategoryId = uuid4().mostSignificantBits.toString()
        val jobCategoryName = jobCategories.random()
        val salary = generateSalary()
        val isPerHour = true
        val isFeatured = random.nextBoolean()
        val isDeleted = false
        val isExpired = false

        val job =
            JobDTO(
                title = title,
                description = description,
                location = location,
                workLocation = workLocation,
                requirements = requirements,
                certificatesRequired = certificatesRequired,
                jobType = jobType,
                jobCategory = jobCategoryName,
                salary = salary,
                isPerHour = isPerHour,
                isFeatured = isFeatured,
                isDeleted = isDeleted,
                isExpired = isExpired
            )

        jobs.add(job)
    }

    return jobs
}

fun generateRequirements(): List<String> {
    val requirements =
        listOf(
            "Bachelor's degree in Computer Science",
            "Strong problem-solving skills",
            "Excellent communication skills",
            "Experience with Agile methodologies",
            "Knowledge of HTML, CSS, and JavaScript",
            "Ability to work independently and in a team",
            "Attention to detail",
            "Experience with data analysis tools",
            "Strong organizational skills",
            "Ability to meet deadlines"
            // Add more requirements as needed
        )

    val random = Random
    val count = random.nextInt(3, requirements.size)

    return requirements.shuffled().take(count)
}

fun generateCertificatesRequired(): List<String> {
    val certificates =
        listOf(
            "Java Certification",
            "Google Analytics Certification",
            "Project Management Professional (PMP) Certification",
            "Microsoft Certified Solutions Expert (MCSE)",
            "Certified ScrumMaster (CSM)",
            "Adobe Certified Expert (ACE)",
            "Cisco Certified Network Associate (CCNA)",
            "AWS Certified Solutions Architect",
            "CompTIA A+ Certification",
            "Salesforce Administrator Certification"
            // Add more certificates as needed
        )

    val random = Random
    val count = random.nextInt(certificates.size)

    return certificates.shuffled().take(count)
}

fun generateSalary(): Pair<Double, Double> {
    val minSalary = 12.0
    val maxSalary = 44.0

    val random = Random
    val start = random.nextDouble(minSalary, maxSalary)
    val end = random.nextDouble(start, maxSalary)

    return Pair(start, end)
}
