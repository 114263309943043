package org.jjoy.ltd.presentation.screen.user_login

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextAlign
import com.varabyte.kobweb.compose.foundation.layout.Arrangement
import com.varabyte.kobweb.compose.foundation.layout.Column
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.core.rememberPageContext
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.base
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.theme.breakpoint.rememberBreakpoint
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.components.widgets.buttons.NormalButton
import org.jjoy.ltd.core.util.TCallback
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.css
import org.jjoy.ltd.util.res.ResourceString

@Composable
fun RegistrationBrandView(
    modifier: Modifier = Modifier,
    view: LoginViews,
    onViewChange: TCallback<LoginViews>,
) {

    val ctx = rememberPageContext()
    val breakpoint = rememberBreakpoint()

    Column(
        modifier =
            modifier
                .then(LoginBrandStyle.toModifier()),
        verticalArrangement = Arrangement.Top,
        horizontalAlignment = Alignment.Start
    ) {

        Column(
            modifier = Modifier.textAlign(TextAlign.Left),
            horizontalAlignment = Alignment.Start,
            verticalArrangement = Arrangement.Top
        ) {

            Text(
                modifier =
                Modifier.fontWeight(FontWeight.Black)
                    .fontSize(dimension.extraLargeText.times(dimension.smallMediumText.value))
                    .color(color.primary.css(95)),
                text = ResourceString.siteName
            )

            Text(
                modifier =
                    Modifier.fontWeight(FontWeight.Black)
                        .fontSize(dimension.largeText * dimension.normalText.value)
                        .color(color.background.css),
                text = "Start your Journey"
            )
            Text(
                modifier =
                    Modifier.fontWeight(FontWeight.Black)
                        .fontSize(dimension.largeText * dimension.normalText.value)
                        .color(color.background.css),
                text = "With Us."
            )
            Text(
                modifier =
                    Modifier.fontWeight(FontWeight.SemiBold).textAlign(TextAlign.Left)
                        .fontSize(dimension.mediumPadding)
                        .color(color.background.css),
                text = "Discover Your Perfect Fit: Full-Time and Part-Time Opportunities Await!"
            )

            NormalButton(
                modifier = Modifier.margin(top = dimension.normalPadding),
                text =
                    if (view == LoginViews.LOGIN) ResourceString.signupQuestion
                    else ResourceString.signQuestion,
                onClick = {
                    onViewChange(
                        when (view) {
                            LoginViews.LOGIN -> LoginViews.REGISTER
                            LoginViews.REGISTER -> LoginViews.LOGIN
                            else -> LoginViews.REGISTER
                        }
                    )
                }
            )
        }
    }
}

val LoginBrandStyle by ComponentStyle.base { Modifier }
