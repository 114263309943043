package org.jjoy.ltd.util.const

object PageRoutes {

    const val ADMIN_PANEL = "/admin/dash"
    const val ADMIN_JOB_EDIT = "/admin/jobs/edit/"

    const val USER_LOGIN = "/jjoy/userlogin/userlogin"
    const val TERMS = "/jjoy/termsandconditions"
    const val ABOUT_US = "/jjoy/aboutus"
    const val SERVICE_DESC = "/jjoy/servicedescription"


    const val JOB_DETAILS = "/job/details"

    const val FORM_DETAILS = "/jjoy/form/userform"
}

object Pages {
    const val searchJobs = "/search/jobs/"
}