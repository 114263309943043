package org.jjoy.ltd.util.extension

import kotlinx.datetime.LocalDate
import kotlin.js.Date

fun today(): String {
    val date = Date()
    return "${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}"
}

fun getToday(): String {
    val date = Date()
    return "${date.getDay()}/${date.getMonth() + 1}/${date.getFullYear()}"
}

fun getDate(): LocalDate {
    val currentDate = Date()
    val year = currentDate.getFullYear()
    val month = currentDate.getMonth() + 1
    val day = currentDate.getDate()

    return LocalDate(year, month, day)
}
