package org.jjoy.ltd.presentation.components

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.FontWeight
import com.varabyte.kobweb.compose.css.TextOverflow
import com.varabyte.kobweb.compose.foundation.layout.Box
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.style.ComponentStyle
import com.varabyte.kobweb.silk.components.style.addVariant
import com.varabyte.kobweb.silk.components.style.hover
import com.varabyte.kobweb.silk.components.style.toModifier
import com.varabyte.kobweb.silk.components.text.SpanText
import org.jetbrains.compose.web.css.CSSColorValue
import org.jetbrains.compose.web.css.percent
import org.jetbrains.compose.web.css.times
import org.jjoy.ltd.presentation.components.text.Text
import org.jjoy.ltd.theme.color
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.core.util.Callback
import org.jjoy.ltd.util.extension.*

@Composable
fun Badge(
    badgeText: String,
    modifier: Modifier = Modifier,
    componentStyle: ComponentStyle = BadgeStyle,
    fontStyle: Modifier = Modifier.fontSize(dimension.smallText).fontWeight(FontWeight.Bold),
    backgroundColor: CSSColorValue = color.primary.css(30),
    textColor: CSSColorValue = color.onPrimary.css(90),
    onClick: Callback = {}
) {

    Box(
        modifier =
            modifier.then(componentStyle.toModifier()).backgroundColor(backgroundColor).onClick {
                onClick()
            },
        contentAlignment = Alignment.Center
    ) {
        Text(
            modifier =
                Modifier.color(textColor).then(fontStyle).textOverflow(TextOverflow.Ellipsis),
            text = badgeText.capitalize(),
        )
    }
}

@Composable
fun SimpleBadge(
    badgeText: String,
    modifier: Modifier = SimpleBadgeStyle.toModifier(),
    onClick: Callback = {}
) {

    Box(modifier = modifier.onClick { onClick() }, contentAlignment = Alignment.Center) {
        SpanText(
            text = badgeText.capitalize(),
            modifier = Modifier
                .fontSize(dimension.smallText)
                .fontWeight(FontWeight.Bold)
        )
    }
}

val BadgeStyle by ComponentStyle {
    base {
        Modifier.scale(1)
            .translateY(0.percent)
            .rounded(0.25)
            .padding(
                top = dimension.smallPadding,
                bottom = dimension.smallPadding,
                left = dimension.smallPadding * 1.7,
                right = dimension.smallPadding * 1.7,
            )

    }
}

val AdvBadgeStyle by ComponentStyle {
    base {
        Modifier.scale(1)
            .translateY(0.percent)
            .rounded(0.25)
            .padding(
                top = dimension.smallPadding,
                bottom = dimension.smallPadding,
                left = dimension.smallPadding * 1.7,
                right = dimension.smallPadding * 1.7,
            )
            .animate(prop = listOf("scale", "translate", "color", "background-color"))
    }

    hover { Modifier.scale(1.01).translateY((-1).percent).color(color.onPrimary.css) }
}

val SimpleBadgeStyle by ComponentStyle {
    base {
        Modifier.backgroundColor(color.primary.css(20))
            .color(color.primary.css)
            .scale(1)
            .translateY(0.percent)
            .rounded(0.35)
            .padding(
                top = dimension.smallPadding,
                bottom = dimension.smallPadding,
                left = dimension.smallPadding * 1.7,
                right = dimension.smallPadding * 1.7,
            )
            .animate(prop = listOf("scale", "translate", "color", "background-color"))
    }

    hover {
        Modifier.scale(1.01)
            .translateY((-1).percent)
            .backgroundColor(color.primary.css)
            .color(color.onPrimary.css)
    }
}


val SMBadgeNormalStyle by SimpleBadgeStyle.addVariant {

    base {
        Modifier
            .backgroundColor("#E4E5E8".toRgb())
            .color(color.onBackground.css(90))
    }

    hover {
        Modifier
            .backgroundColor(color.primary.css)
            .color(color.onPrimary.css)
    }

}