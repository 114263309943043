package org.jjoy.ltd.presentation.components.wrapper

import androidx.compose.runtime.Composable
import com.varabyte.kobweb.compose.css.AnimationIterationCount
import com.varabyte.kobweb.compose.foundation.layout.*
import com.varabyte.kobweb.compose.ui.Alignment
import com.varabyte.kobweb.compose.ui.Modifier
import com.varabyte.kobweb.compose.ui.modifiers.*
import com.varabyte.kobweb.silk.components.animation.Keyframes
import com.varabyte.kobweb.silk.components.animation.toAnimation
import org.jetbrains.compose.web.css.*
import org.jjoy.ltd.presentation.composables.LoaderView
import org.jjoy.ltd.theme.dimension
import org.jjoy.ltd.util.extension.animate

@Composable
fun MainWrapper(
    modifier: Modifier = Modifier.fillMaxSize(),
    contentAlignment: Alignment = Alignment.Center,
    content: @Composable BoxScope.() -> Unit
) {

    Box(modifier = modifier, contentAlignment = contentAlignment) { content(this) }
}

@Composable
fun PanelWrapper(
    isLoading: Boolean = false,
    modifier: Modifier = Modifier,
    contentAlignment: Alignment = Alignment.TopStart,
    contentModifier: Modifier =
        Modifier.gap(dimension.largePadding)
            .padding(dimension.largePadding)
            .margin(dimension.normalPadding)
            .fillMaxSize(),
    contentVerticalArrangement: Arrangement.Vertical = Arrangement.Top,
    contentHorizontalAlignment: Alignment.Horizontal = Alignment.CenterHorizontally,
    content: @Composable ColumnScope.() -> Unit
) {

    Box(
        modifier =
            modifier
                .animation(
                    opacity1.toAnimation(
                        duration = 0.4.s,
                        timingFunction = AnimationTimingFunction.EaseInOut,
                        iterationCount = AnimationIterationCount.of(1),
                        fillMode = AnimationFillMode.Both,
                        direction = AnimationDirection.Alternate
                    )
                ).animate(prop = listOf("opacity","scale"))
                .fillMaxWidth(dimension.navRailContentPercent)
                .fillMaxHeight(),
        contentAlignment = contentAlignment
    ) {
        Column(
            modifier = contentModifier,
            verticalArrangement = contentVerticalArrangement,
            horizontalAlignment = contentHorizontalAlignment,
            content = content
        )
        LoaderView(isLoading)
    }
}

val opacity1 by Keyframes {
    from { Modifier.opacity(0) }
    to { Modifier.opacity(1) }
}

val scale1 by Keyframes {
    90.percent { Modifier.scale(0) }
    100.percent { Modifier.scale(1) }
}
